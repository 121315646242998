import { Outlet } from "react-router-dom"
import { useUserStore } from '../store/user.store'
import { observer } from "mobx-react"
import { useEffect, useState } from "react"
import { Box, Button, Paper, Typography } from "@mui/material"
import warningImg from '../assets/waiting.svg'
import { erpDashboardLink } from "../utils/utilities"

const RequireAuth = observer( ( { allowedRoles } ) => {

    const [loading, setLoading] = useState( true )

    const UserStore = useUserStore()


    useEffect( () => {
        const getUser = async () => {
            await UserStore.fetchUser()
            setLoading( false )
        }
        getUser()
    }, [UserStore, allowedRoles] )

    return (
        loading ? "" : allowedRoles.includes( UserStore.getUser['user_role'].toUpperCase() )
            ? <Outlet />
            : <Box sx={{ background: "url('/imgs/bg-pattern1.png')", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
                <Paper sx={{ display: "flex", width: "200px", padding: "20px", flexDirection: "column", gap: "20px", alignItems: "center" }}>
                    <img width="100%" src={warningImg} alt="session ended" />
                    <Typography variant='h6' color="GrayText">
                        Session Ended!
                    </Typography>
                    <Button variant="outlined" href={erpDashboardLink} color="secondary" sx={{ textTransform: "capitalize" }}>Sign in</Button>
                </Paper>
            </Box>
    )
} )

export default RequireAuth
