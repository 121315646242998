import { Box } from '@mui/material'
import React from 'react'
import Navbar from './AdminNavbar'
import SideDrawer from './AdminSideDrawer'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import AnalyticsStoreInstance, { AnalyticsStoreProvider } from '../../store/analytics.store'
import ApiProviderStoreInstance, { ApiProviderStoreProvider } from '../../store/apiprovider.store'
import UploadStatusListInstance, { UploadStatusListProvider } from '../../store/uploadstatuslistdialog.store'
import UploadsStatusList from '../online-course-components/UploadsStatusList'
import { useLayoutContext } from '../../contexts/layout.context'
import DownloadStatusListDialog from '../study-material-components/DownloadStatusListDialog'
import AdmissionExamStoreInstance, { AdmissionExamStoreProvider } from '../../store/admission-exam.store'


const Layout = ( { navHeadText } ) => {

    const { isSmallScreen, setIsSmallScreen, setSidedrawerState, setCurrentLoc } = useLayoutContext()
    const loc = useLocation()
    let drawerWidth = 270

    const handleResize = () => {
        if ( window.innerWidth < 900 )
            setIsSmallScreen( true )
        else {
            setIsSmallScreen( false )
            setSidedrawerState( false )
        }
    }

    const handleLoad = () => {
        if ( window.innerWidth < 900 )
            setIsSmallScreen( true )
        else {
            setIsSmallScreen( false )
            setSidedrawerState( false )
        }
    }

    useEffect( () => {
        window.addEventListener( 'resize', handleResize )
        window.addEventListener( 'load', handleLoad )
        setCurrentLoc( loc.pathname )
        return () => {
            window.removeEventListener( 'resize', handleResize )
            window.removeEventListener( 'resize', handleLoad )
        }
        // eslint-disable-next-line
    }, [] )

    return (
        <AnalyticsStoreProvider store={AnalyticsStoreInstance}>
            <AdmissionExamStoreProvider store={AdmissionExamStoreInstance}>
                <ApiProviderStoreProvider store={ApiProviderStoreInstance}>
                    <UploadStatusListProvider store={UploadStatusListInstance}>
                        <Box>
                            <Navbar navHeadText={navHeadText} />
                            <SideDrawer width={drawerWidth} />
                            <Box id="page-content" sx={{ transition: "0.2s ease" }} marginLeft={isSmallScreen ? "0" : "60px"} padding="20px" paddingTop="88px" height="calc(100vh - 118px)" overflow="auto">
                                <Outlet />
                            </Box>
                            <UploadsStatusList />
                            <DownloadStatusListDialog />
                        </Box>
                    </UploadStatusListProvider>
                </ApiProviderStoreProvider>
            </AdmissionExamStoreProvider>
        </AnalyticsStoreProvider>
    )
}

export default Layout
