import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import networkErrorImg from '../assets/networkerror.svg'
import { useNavigate } from 'react-router-dom'

const NetworkError = () => {
    const navigate = useNavigate()

    return (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box height="100%" sx={{ background: "url('/imgs/bg-pattern1.png')", zIndex: "0" }} width="100%" display="flex" position="fixed" top="0" left="0" right="0" bottom="0" flexWrap="wrap" overflow="hidden">
            </Box>
            <Paper sx={{ position: "relative", zIndex: 2, maxWidth: "300px", width: "90%" }}>
                <Box padding="20px" alignItems="center" display="flex" flexDirection="column" gap="10px">
                    <img width="100px" src={networkErrorImg} alt="Alive Logo" />
                    <Typography variant="subtitle2">{window.navigator.onLine ? "Server network down!" : "Please connect to your internet"}</Typography>
                    <Button onClick={() => navigate( -1, { replace: true } )} variant="outlined" sx={{ textTransform: "capitalize" }}>Refresh</Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default NetworkError


// < Box display = "flex" flexDirection = "column" alignItems = "center" justifyContent = "center" height = "100%" width = "100%" >
//     <Paper sx={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
//         <img width="200px" src={networkErrorImg} alt="NetworkError" />
//         <Typography marginTop="20px" variant='h5' color="textSecondary">{window.navigator.onLine ? "Server network down!" : "Please connect to your internet"}</Typography>
//         <Button sx={{ textTransform: "capitalize", marginTop: "20px" }} onClick={() => navigate( -1 )} variant="contained" disableElevation>Retry</Button>
//     </Paper>
//     </Box >