import { Box, Button, CircularProgress, Dialog, DialogContent, Card, Checkbox, FormControlLabel, FormControl, FormHelperText, TextField, Typography, InputLabel, Select, OutlinedInput, Chip, MenuItem, Tooltip, IconButton } from '@mui/material'
// import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InfoIcon from '@mui/icons-material/Info'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import { supportedFormats, attachmentsSupportedFormats, formatDateTimeForInputField, formatDateTimeForDB, chipColors } from '../../utils/exam-utilities'
import * as Yup from 'yup'
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import api from '../../service/api'
import { useExamStore } from '../../store/exam.store'
import sessionExpiredImg from '../../assets/warning.svg'
import { Icons, erpDashboardLink } from '../../utils/utilities'
import { ArrowBack } from '@mui/icons-material'

const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
const minutes = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]

export default function EditExam() {
    // const attachmentsRef = useRef()
    const { id } = useParams()
    const ExamStore = useExamStore()

    // const [previewUrl, setPreviewUrl] = useState( null )
    const [isDisabled, setIsDisabled] = useState( true )
    const [exam, setExam] = useState( null )
    const [isFetchingBatches, setIsFetchingBatches] = useState( false )
    const [examName, setExamName] = useState( '' )
    const [description, setDescription] = useState( '' )
    const [examTopics, setExamTopics] = useState( '' )
    const [examStartDate, setExamStartDate] = useState( '' )
    const [examEndDate, setExamEndDate] = useState( '' )
    const [inProgress, setInProgress] = useState( true )
    const [selectedBatches, setSelectedBatches] = useState( [] )
    // const [selectedBatchesNames, setSelectedBatchesNames] = useState( [] )
    const [newBatches, setNewBatches] = useState( [] )
    const [isUpdating, setIsUpdating] = useState( false )
    const [batches, setBatches] = useState( {} )
    const [sections, setSections] = useState( {} )
    const [academicYear, setAcademicYear] = useState( "-1" )
    const [academicYears, setAcademicYears] = useState( [] )
    const [isOpenExam, setIsOpenExam] = useState( false )
    const [openExamInfo, setOpenExamInfo] = useState( false )
    const [durationHours, setDurationHours] = useState( 0 )
    const [maximumAttempt, setMaximumAttempt] = useState( 0 )
    const [durationMinutes, setDurationMinutes] = useState( 0 )
    const [sessionExpired, setSessionExpired] = useState( false )
    const navigate = useNavigate()


    const initialValues = {
        title: '',
        description: '',
        topics: '',
        startDateTime: '',
        endDateTime: '',
        attachments: null,
        thumbnail: null,
        selectedBatches: '',
        duration: null
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Exam title is required." ),
        description: Yup.string().required( "Exam description is required." ).min( 50, "Description as to be least 50 characters." ),
        topics: Yup.array().required( "Please provide the exam topics." ),
        thumbnail: Yup.mixed().nullable().notRequired().test( "FILE_FORMAT", "Unsupported file format, accepted formats: JPG,JPEG,PNG.", ( value ) => {
            if ( value )
                return supportedFormats.includes( value.type )
            return true
        } ),
        // startDateTime: Yup.date().required().min( new Date(), "Date is invalid" ),
        // endDateTime: Yup.date().required().when( "startDateTime", ( startDate ) => {
        //     if ( startDate ) return Yup.date().min( startDate, "End date must be after start date" )
        // } ),
        attachments: Yup.mixed().nullable().notRequired().test( "ATTACHMENTS_FILE_FORMAT", "Unsupported attachment format accepted formats: PDF,DOCX,DOC,XLS.", ( value ) => {
            if ( value ) {
                const files = Object.values( value )
                for ( let i = 0; i < files.length; i++ ) {
                    const file = files[i]
                    if ( !attachmentsSupportedFormats.includes( file.type ) ) {
                        return false
                    }
                }
                return true
            }
            else return true
        } ),
        duration: Yup.string().nullable().test( "CHECK_DURATION", "Invalid exam duration", ( value ) => {
            if ( isOpenExam ) {
                if ( value && value !== '00:00' ) return true
                return false
            } else return true
        } ),
        maximumAttempt: Yup.number().required( "This field is required!" ).min( 1, "At least 1 attempt must be given" )
    } )

    const getBatches = async ( year = academicYear ) => {
        setIsFetchingBatches( true )
        try {
            const batchesData = await api.getAllBatch( year )
            if ( !batchesData.data.success && ( batchesData.data.unauth || batchesData.data.message === "Invalid Token" ) ) {
                setSessionExpired( true )
            }
            if ( ( batchesData.data.data && batchesData.data.data.batches && batchesData.data.data.batches.length > 0 ) ) {
                const batchesFetched = batchesData.data.data.batches
                const batchesForSelect = {}
                for ( let i = 0; i < batchesFetched.length; i++ ) {
                    const batch = batchesFetched[i]
                    // if ( !selectedBatches.includes( `${batch.batch_short_name}-${batch.batch_assignment_id}` ) ) {
                    batchesForSelect[`${batch.batch_assignment_id}`] = batch.batch_name
                    console.log( "Batches", batchesForSelect )
                    // }
                }
                setBatches( { ...batchesForSelect } )
            }
            if ( ( batchesData.data.data && batchesData.data.data.sections && batchesData.data.data.sections.length > 0 ) ) {
                const sectionsFetched = batchesData.data.data.sections
                const sectionsForSelect = {}
                for ( let i = 0; i < sectionsFetched.length; i++ ) {
                    const section = sectionsFetched[i]
                    // if ( !selectedBatches.includes( `${section.section_short_name}-${section.section_assignment_id}` ) ) {
                    sectionsForSelect[`${section.section_assignment_id}-${section.section_short_name}`] = `${section.course_branch_short_name}-${section.current_year}-${section.section_short_name}`
                    console.log( "sections", sectionsForSelect )
                    // }
                }
                setSections( { ...sectionsForSelect } )
            }
            // setSelectedBatchesNames( selectedBatchesAndSectionsNames )
        } catch ( e ) {
            console.log( e )
            if ( e.message === "Network Error" )
                navigate( '/networkerror' )
            toast.error( "Something went wrong!" )
        } finally {
            setIsFetchingBatches( false )
        }
    }

    const handleBatchChange = ( e ) => {
        if ( e.target.value ) {
            setNewBatches( e.target.value )
        }
    }

    const submit = async ( values, props ) => {
        if ( new Date( formatDateTimeForInputField( exam.exam_start_date, exam.exam_start_time ) ) < new Date() ) {
            toast.error( "Exam already started! can't edit now" )
            navigate( `/faculty/exams/${exam.exam_id}` )
        }
        setIsUpdating( true )
        const formData = new FormData()
        formData.append( 'title', values.title )
        if ( values.thumbnail )
            formData.append( "thumbnail", values.thumbnail, `${values.title} - thumb.${values.thumbnail.name.split( "." )[values.thumbnail.name.split( "." ).length - 1]}` )
        formData.append( 'description', values.description )
        formData.append( 'topics', `{ ${values.topics}}` )
        formData.append( 'startDateTime', JSON.stringify( formatDateTimeForDB( values.startDateTime, 'en-IN', '-' ) ) )
        formData.append( 'endDateTime', JSON.stringify( formatDateTimeForDB( values.endDateTime, 'en-IN', '-' ) ) )
        formData.append( 'selectedBatches[]', [...newBatches] )
        formData.append( 'duration', isOpenExam ? values.duration : null )
        formData.append( 'maximumAttempt', values.maximumAttempt )
        if ( values.attachments )
            for ( let i = 0; i < values.attachments.length; i++ ) {
                formData.append( "attachments[]", values.attachments[i], `${values.title}-${Date.now()}${String( i + 1 )}.${values.attachments[i].name.split( "." )[values.attachments[i].name.split( "." ).length - 1]}` )
            }

        // const res = await Axios.put( '/exams/' + exam.exam_id, formData, {
        //     headers: {
        //         "Authorization": `Bearer ${localStorage.getItem( "id_token" )}`
        //     }
        // } )
        await ExamStore.updateExam( id, formData )
        setIsDisabled( true )
        setIsUpdating( false )
        navigate( `/faculty/exams/${id}` )
    }

    const setFields = ( setFieldValue ) => {
        setFieldValue( 'title', document.getElementById( 'title' ).value )
        setFieldValue( 'description', document.getElementById( 'description' ).value )
        setFieldValue( 'topics', document.getElementById( 'topics' ).value.trim() !== "" ? document.getElementById( 'topics' ).value.split( "," ) : '' )
        setFieldValue( 'startDateTime', dayjs( examStartDate ) )
        setFieldValue( 'endDateTime', dayjs( examEndDate ) )
        setFieldValue( 'selectedBatches', selectedBatches )
        setFieldValue( 'duration', `${durationHours ? durationHours : "0"}:${durationMinutes ? durationMinutes : "0"}` )
        setFieldValue( 'maximumAttempt', maximumAttempt )
    }

    useEffect( () => {
        const getData = async () => {
            const getAcedemicYears = async () => {
                try {
                    const res = await api.getAllBatchAcademicYear()
                    if ( res.data.success === false && res.data.message === "Invalid Token" ) {
                        setSessionExpired( true )
                        console.log( "Session Expired" )
                    }
                    setAcademicYears( res.data.data ? res.data.data : [] )
                } catch ( e ) {
                    console.log( e )
                    if ( e.message === "Network Error" )
                        navigate( '/networkerror' )
                    else toast.error( "Something went wrong! try again later" )
                }
            }
            try {
                // const examData = await Axios.get( `/exams/${id}`, {
                //     headers: {
                //         "Authorization": `Bearer ${localStorage.getItem( "id_token" )}`
                //     }
                // } )
                await ExamStore.fetchExam( id )
                const e = ExamStore.getExam.exam
                if ( new Date( formatDateTimeForInputField( e.exam_start_date, e.exam_start_time ) ) < new Date() ) {
                    toast.error( "Exam already started! can't edit now" )
                    navigate( `/faculty/exams/${e.exam_id}` )
                } else if ( e.exam_status === 'Published' ) {
                    toast.error( "Exam already published! can't edit now" )
                    navigate( `/faculty/exams/${e.exam_id}` )
                }
                else {
                    setExam( e )
                    // setPreviewUrl( `${BASE_URL}/thumbnail/${e.exam_thumbnail}` )
                    setExamName( e.exam_name )
                    setDescription( e.exam_description )
                    setExamTopics( e.exam_topics )
                    setExamStartDate( formatDateTimeForInputField( e.exam_start_date, e.exam_start_time ) )
                    setExamEndDate( dayjs( formatDateTimeForInputField( e.exam_end_date, e.exam_end_time ) ) )
                    setSelectedBatches( e.batches_ids )
                    setNewBatches( e.batches_ids )
                    if ( e.exam_duration !== null && e.exam_duration !== 'null' ) {
                        setIsOpenExam( true )
                        setDurationHours( dh => e.exam_duration.split( ":" )[0] )
                        setDurationMinutes( dh => e.exam_duration.split( ":" )[1] )
                    } else {
                        setIsOpenExam( false )
                        setDurationHours( dh => "" )
                        setDurationMinutes( dh => "" )
                    }
                    setMaximumAttempt( e.maximum_attempts )
                    // let initiallySelectedBatches = []
                    // e.batches_ids.forEach( batch => {
                    //     // const splittedBatchDetails = batch.split( "-" )
                    //     initiallySelectedBatches.push( { batch_assignment_id: batch } )
                    // } )
                    await getAcedemicYears()
                    // setBatches( initiallySelectedBatches )
                    setInProgress( false )
                }
            } catch ( e ) {
                if ( e.response && e.response.data )
                    if ( e.response.data === "Exam not found!" ) {
                        toast.error( "Exam not found" )
                        navigate( '/faculty/exams' )
                    } else
                        toast.error( e.response.data )
                else if ( e.message )
                    toast.error( e.message )
            }
        }
        getData()
    }, [id, ExamStore, navigate] )

    return (
        // <Layout>
        <Box sx={{ padding: "0 20px 20px 20px", overflow: "auto", borderRadius: "10px" }} >
            <Dialog sx={{}} onClose={() => setSessionExpired( true )} open={sessionExpired}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img width="150px" src={sessionExpiredImg} alt="Session Expired!" />
                        <Typography marginTop="10px">Session has expired! please login again</Typography>
                        <Button onClick={() => window.location.replace( erpDashboardLink )} color='error'>Login</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Box sx={{ marginBottom: "10px" }}>
                <Typography fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to={`/faculty/exams`} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} <Link to={`/faculty/exams/${id}`} className="breadcrumb-link">{exam ? exam.exam_name : <CircularProgress size={12} />}</Link> {Icons.SmallChevronRightIcon} Edit </Typography>
            </Box>
            {exam && !inProgress && <Box bgcolor="white" borderRadius="5px" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)">
                <Box display="flex" alignItems="center" gap="10px" padding="20px 10px" borderBottom="1px solid #d3d3d3">
                    <Tooltip title="Back to exam">
                        <IconButton onClick={() => navigate( `/faculty/exams/${id}` )} size='small'>
                            <ArrowBack fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Box>
                        <Typography variant='h5' textTransform="capitalize">{exam ? exam.exam_name : ""}</Typography>
                        <Typography variant='subtitle2' color="textSecondary" textTransform="capitalize">Edit exam details</Typography>
                    </Box>
                </Box>
                <Box padding="20px">
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue } ) => (
                            <Form method='POST' autoComplete='off' style={{ height: "100%" }} id="edit-form">
                                <FormControl variant='filled' fullWidth>
                                    <Field as={TextField}
                                        InputLabelProps={{ sx: { fontSize: "14px !important", } }}
                                        fullWidth name='title'
                                        margin="normal"
                                        label="Exam title*"
                                        value={examName}
                                        InputProps={{ sx: { fontSize: "14px" } }}
                                        id='title'
                                        onChange={( e ) => { setIsDisabled( false ); setExamName( e.target.value ); setFields( setFieldValue ); setFieldValue( 'title', e.target.value ) }}
                                    />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px" }}><span style={{ color: "#d32f2f", fontWeight: "bold", dispaly: "block" }}><ErrorMessage name='title' /></span></FormHelperText>
                                </FormControl>
                                <FormControl variant='outlined' fullWidth>
                                    <Field as={TextField}
                                        multiline
                                        rows={4}
                                        InputLabelProps={{ sx: { fontSize: "14px !important", } }}
                                        fullWidth name='description'
                                        margin="normal"
                                        InputProps={{ sx: { fontSize: "14px" } }}

                                        id='description'
                                        value={description}
                                        onChange={( e ) => { setIsDisabled( false ); setDescription( e.target.value ); setFields( setFieldValue ); setFieldValue( 'description', e.target.value ) }}
                                        label="Exam description*" />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", color: "#d32f2f", fontWeight: "bold" }}><ErrorMessage name='description' /></FormHelperText>
                                </FormControl>
                                <FormControl variant='outlined' fullWidth>
                                    <Field as={TextField} onChange={( e ) => { setIsDisabled( false ); setExamTopics( e.target.value.split( "," ) ); setFields( setFieldValue ); setFieldValue( 'topics', e.target.value.trim() !== "" ? e.target.value.split( "," ) : '' ) }}
                                        onBlur={( e ) => { setFields( setFieldValue ) }}
                                        InputLabelProps={{ sx: { fontSize: "14px !important", } }}
                                        fullWidth
                                        name='topics'
                                        margin="normal"
                                        InputProps={{ sx: { fontSize: "14px" } }}
                                        label="Topics*"
                                        id="topics"
                                        value={examTopics}
                                    />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px" }}>Enter topics as comma separated values.<br />
                                        <span style={{ color: "#d32f2f", fontWeight: "bold" }}><ErrorMessage name='topics' /></span>
                                    </FormHelperText>
                                </FormControl>
                                <Box marginTop="20px" bgcolor="#eee" padding="20px 20px 10px 20px" gap="20px" borderRadius="5px 5px 0 0" display="flex" alignItems="center" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }}>
                                    <FormControl fullWidth>
                                        <InputLabel sx={{ fontSize: "14px" }} >Select academic year</InputLabel>
                                        {academicYears && <Select sx={{ fontSize: "14px" }} inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "10px !important", height: "28px !important" } }} value={academicYear} onChange={( e ) => { setAcademicYear( e.target.value ); getBatches( e.target.value ) }} input={<OutlinedInput label="Select academic year" />}>
                                            <MenuItem sx={{ fontSize: "14px" }} value="-1" disabled>{academicYears.length === 0 ? "No academic years!" : "Select academic year"}</MenuItem>
                                            {academicYears.length > 0 && academicYears.map( year => (
                                                <MenuItem sx={{ fontSize: "14px" }} key={year.ac_year_id} value={year.ac_year_id}>{year.ac_year}</MenuItem>
                                            ) )}
                                        </Select>}
                                    </FormControl>
                                    {/* <FormControl fullWidth>
                                    <InputLabel sx={{ fontSize: "14px" }} >Select acedemic year</InputLabel>
                                    {academicYears && <Select inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "12px !important", height: "28px !important" } }} value={academicYear} onChange={( e ) => { setAcademicYear( e.target.value ); getBatches( e.target.value ) }} input={<OutlinedInput label="Select acedemic year" />}>
                                        <MenuItem disabled value={"-1"}>{academicYears.length === 0 ? "No academic years!" : "Select academic year"}</MenuItem>
                                        {academicYears && academicYears.map( year => (
                                            <MenuItem key={year.ac_year_id} value={year.ac_year_id}>{year.ac_year}</MenuItem>
                                        ) )}
                                    </Select>}
                                </FormControl> */}
                                    <FormControl sx={{ position: "relative" }} fullWidth>
                                        <InputLabel sx={{ fontSize: "14px" }} >Select student batch</InputLabel>
                                        <Select disabled={isFetchingBatches || academicYear === "-1"} inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "10px !important", height: "28px !important" } }} name='selectedBatches' multiple value={newBatches} onChange={( e ) => { handleBatchChange( e ); setFieldValue( 'selectedBatches', typeof e.target.value === 'string' ? e.target.value.split( "," ) : e.target.value ); setIsDisabled( false ); setFields( setFieldValue ) }} input={<OutlinedInput label="Select student batch" />}
                                            renderValue={( selected ) => (
                                                <Typography noWrap fontSize="14px" sx={{ margin: "5px" }}>
                                                    {newBatches.length > 0 && newBatches.join( ", " )}
                                                </Typography>
                                            )}>
                                            <Typography paddingX="15px" fontWeight="bold" color="secondary">Batches</Typography>
                                            {batches && Object.keys( batches ).length > 0 && Object.keys( batches ).map( batch => (
                                                <MenuItem sx={{ fontSize: "14px" }} key={batch} value={batch}>{String( batches[batch] )}</MenuItem>
                                            ) )}
                                            {( !batches || Object.keys( batches ).length === 0 ) && <MenuItem value={"No batches"} sx={{ fontSize: "14px", fontWeight: "bold" }} disabled>No batches for selected year!</MenuItem>}
                                            <hr />
                                            <Typography paddingX="15px" fontWeight="bold" color="secondary">Sections</Typography>
                                            {sections && Object.keys( sections ).length > 0 && Object.keys( sections ).map( section => (
                                                <MenuItem sx={{ fontSize: "14px" }} key={section} value={section}>{sections[section]}</MenuItem>
                                            ) )}
                                            {( !sections || Object.keys( sections ).length === 0 ) && <MenuItem value={"No sections"} sx={{ fontSize: "14px", fontWeight: "bold" }} disabled>No sections for selected year!</MenuItem>}
                                        </Select>
                                        {isFetchingBatches && <Typography position="absolute" top="100%" marginLeft="5px" variant='body2' color="textSecondary"> <CircularProgress size={10} sx={{ marginRight: "5px" }} /> Loading batches...</Typography>}
                                    </FormControl>
                                </Box>
                                {selectedBatches.length > 0 && <Box padding="20px" paddingTop="0" bgcolor="#eee">
                                    <Typography color="secondary" fontSize="16px" fontWeight="bold">Selected batches</Typography>
                                    <Box display="flex" marginTop="10px" gap="10px" flexWrap="wrap">
                                        {selectedBatches.map( ( batch, index ) => (
                                            <Chip sx={{ color: "white", background: chipColors[index % chipColors.length] }} key={batch} label={batch} />
                                        ) )}
                                    </Box>
                                </Box>}
                                {/* <FormControl margin='normal' variant="standard" sx={{ width: "100%" }}>
                                <FormLabel sx={{ margin: "0 0 5px 0", fontSize: "14px" }}>Exam attachments(optinal)</FormLabel>
                                <div >
                                    <div style={{ position: "relative" }}>
                                        <Button sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" }, textTransform: "capitalize !important", border: "rgba(0,0,0,0.3) 1px solid !important", borderRadius: "5px", height: "53px", cursor: "pointer !important", "&:hover": { background: "white !important" } }} onClick={() => attachmentsRef.current.click()} type='button' variant='primary' startIcon={<CloudUploadIcon />} color="primary">{( !values.attachments && exam.exam_attachments ) ? "Change attachments" : "Upload Attachments"}</Button>
                                        <input hidden accept='image/png,image/jpg,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onChange={( e ) => { setFieldValue( 'attachments', e.target.files ); setIsDisabled( false ); setFields( setFieldValue ) }} type="file" name="attachments" ref={attachmentsRef} multiple />
                                    </div>
                                    {values.attachments && (
                                        <>
                                            <Typography variant='body2' marginY={"5px"}>New Attachments</Typography>
                                            <div style={{ marginBottom: "10px", flexWrap: "wrap", display: "flex", alignItems: "center", gap: "10px" }} id='attachments'>
                                                {
                                                    Object.values( values.attachments ).map( ( attachment, index ) => (
                                                        <Chip sx={{ background: chipColors[index], color: "white" }} key={index} label={attachment.name} />
                                                    ) )
                                                }
                                            </div>
                                        </>
                                    )}
                                    {!values.attachments && exam.exam_attachments &&
                                        <>
                                            <Typography variant='body2' marginY={"5px"}>Attachments</Typography>
                                            <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", gap: "10px", flexWrap: "wrap" }} id='attachments'>
                                                {
                                                    exam.exam_attachments.map( ( attachment, index ) => (
                                                        <Chip key={index} clickable href={`${ATTACHMENTS_BASE_URL}${attachment}`} sx={{ background: chipColors[index], color: "white", cursor: "pointer", transition: "none", "&:hover": { color: "blue" } }} label={attachment.split( "exam/" )} />
                                                    ) )
                                                }
                                            </div >
                                        </>
                                    }
                                </div >
                                <FormHelperText sx={{ margin: "-10px 0 10px 2px" }}><span style={{ color: "#d32f2f", fontWeight: "bold" }}><ErrorMessage name="attachments" /></span></FormHelperText>
                            </FormControl > */}
                                <FormControl margin='normal' variant='filled' fullWidth sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" } }}>
                                    <Field as={TextField} sx={{ fontSize: "14px" }} InputLabelProps={{ sx: { fontSize: "14px !important", } }} value={maximumAttempt} fullWidth name='maximumAttempt' margin="normal" onChange={e => { setMaximumAttempt( e.target.value ); setIsDisabled( false ); setFields( setFieldValue ); setFieldValue( 'maximumAttempt', e.target.value ) }} label="Maximum attempt*" />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", fontWeight: "bold" }}><span style={{ color: "#de1738", dispaly: "block" }}><ErrorMessage name='maximumAttempt' /></span></FormHelperText>
                                </FormControl>
                                <FormControl margin='normal' variant='outlined' sx={{ display: "flex" }}>
                                    <div style={{ display: "flex", gap: "10px", margin: "10px 0 5px 0" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                minDateTime={dayjs( new Date() )}
                                                label="Start date and time"
                                                value={dayjs( examStartDate )}
                                                format="DD-MM-YYYY hh:mm A"
                                                onChange={( e ) => { setIsDisabled( false ); setExamStartDate( e.$d ); setFields( setFieldValue ); setFieldValue( 'startDateTime', e.$d ) }}
                                                onAccept={( e ) => { setIsDisabled( false ); setFieldValue( 'startDateTime', e.$d ) }}
                                            // renderInput={( params ) => <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} {...params} sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" } }} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px" }}>Enter start date and time. {new Date( examStartDate ).toString()}<br />
                                        <span style={{ color: "#d32f2f", fontWeight: "bold" }}><ErrorMessage name='startDateTime' /></span><br />
                                    </FormHelperText>

                                </FormControl>
                                <FormControl margin='normal' variant='outlined' sx={{ display: "flex" }}>
                                    <div style={{ display: "flex", gap: "10px", margin: "10px 0 5px 0" }} >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                label="End date and time"
                                                minDateTime={dayjs( examStartDate )}
                                                value={dayjs( examEndDate )}
                                                format="DD-MM-YYYY hh:mm A"
                                                onChange={( e ) => { setIsDisabled( false ); setExamEndDate( e.$d );; setFields( setFieldValue ); setFieldValue( 'endDateTime', e.$d ) }}
                                            // onAccept={( e ) => { setIsDisabled( false ); setFieldValue( 'endDateTime', e.$d ) }}
                                            // renderInput={( params ) => <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} {...params} sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" } }} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px" }}>Enter end date and time. {new Date( examEndDate ).toString()}<br />
                                        <span style={{ color: "#d32f2f", fontWeight: "bold" }}><ErrorMessage name='endDateTime' /></span>
                                    </FormHelperText>
                                </FormControl>{<Box position="relative">
                                    {openExamInfo && <Card sx={{ width: "300px", borderRadius: "5px", padding: "20px", position: "absolute", bottom: "100%", background: "white", zIndex: "100" }} raised>
                                        <Typography textAlign="justify" variant="h6" color="secondary">Open Exams</Typography>
                                        <Typography textAlign="justify" variant='body2'>In open exams students can take exams whenever they want with in the specified interval of time</Typography>
                                        <Button onClick={e => setOpenExamInfo( false )} variant='outlined' sx={{ textTransform: "capitalize", marginTop: "10px" }}>Okay</Button>
                                    </Card>}
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox checked={isOpenExam ? true : false} value={isOpenExam} onChange={e => { setIsOpenExam( e.target.checked ); setFields( setFieldValue ); setIsDisabled( false ) }} />} label="Is open exam?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setOpenExamInfo( true )} />
                                    </Box>
                                </Box>}
                                {isOpenExam && <FormControl margin='normal' variant='outlined' sx={{ display: "flex" }}>
                                    <Typography fontSize="14px" margin="5px 0 10px 0" gutterBottom>Exam duration</Typography>
                                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                        <FormControl fullWidth>
                                            <InputLabel sx={{ fontSize: "14px" }} >Hours</InputLabel>
                                            <Select inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "12px !important", height: "28px !important" } }} label="Hours" sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" }, fontSize: "14px" }} onChange={e => { setDurationHours( e.target.value ); setIsDisabled( false ); setFields( setFieldValue ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours}>
                                                {hours.map( hour => (
                                                    <MenuItem sx={{ fontSize: "14px" }} key={hour} value={hour}>{hour}</MenuItem>
                                                ) )}
                                            </Select>
                                            {/* <TextField type="number" sx={{ width: "214px" }} InputLabelProps={{ sx: {fontSize: "14px !important",} }} label="Hours" onWheel={e => e.target.blur()} onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours} /> */}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel sx={{ fontSize: "14px" }} >Minutes</InputLabel>
                                            <Select inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "12px !important", height: "28px !important" } }} label="Minutes" sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" }, fontSize: "14px" }} onChange={e => { setDurationMinutes( e.target.value ); setIsDisabled( false ); setFields( setFieldValue ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes}>
                                                {minutes.map( min => (
                                                    <MenuItem sx={{ fontSize: "14px" }} key={min} value={min}>{min}</MenuItem>
                                                ) )}
                                            </Select>
                                            {/* <TextField type="number" sx={{ width: "214px" }} InputLabelProps={{ sx: {fontSize: "14px !important",} }} label="Minutes" onWheel={e => e.target.blur()} onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes} /> */}
                                        </FormControl>
                                    </Box>
                                    <FormHelperText sx={{ margin: "5px 0 0 2px", fontWeight: "bold", color: "black" }}>Exam duration: {`${durationHours ? durationHours : 0} hours ${durationMinutes ? durationMinutes : 0} minutes`}<br />
                                        <span style={{ color: "#d32f2f", fontWeight: "bold" }}><ErrorMessage name='duration' /></span>
                                    </FormHelperText>
                                </FormControl>}
                                <Box display="flex" alignItems="center" marginTop="20px" gap="10px">
                                    <Button sx={{ marginBottom: "20px", textTransform: "capitalize" }} disabled={( isDisabled || isUpdating )} type='submit'
                                        variant='contained' >{isUpdating ? "Updating..." : "Update"}</Button>
                                    <Button sx={{ marginBottom: "20px", textTransform: "capitalize" }} variant='outlined' color="error" onClick={() => navigate( `/faculty/exams/${exam.exam_id}` )}>Cancel</Button>
                                </Box>
                            </Form >
                        )}
                    </Formik >
                </Box>
            </Box>}
            {inProgress && <Typography display="flex" alignItems="center"> <CircularProgress sx={{ marginRight: "10px" }} size={20} /> Loading exam... </Typography>}
        </Box >
        // </Layout>
    )
}
