import { Box, Button, CircularProgress, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { useNavigate } from 'react-router-dom'
import noFormImg from '../../../assets/no-resume-templates.svg'
import { useUserStore } from '../../../store/user.store'
import { Icons } from '../../../utils/utilities'
import warningImg from '../../../assets/warning.svg'

const tableColumns = [
    { label: "Sl.No", minWidth: "70px" },
    { label: "Form", minWidth: "200px" },
    { label: "Added by", minWidth: "150px" },
    { label: "For institutes", minWidth: "100px" },
]

const ResumeForms = observer( () => {

    const [creatingForm, setCreatingForm] = useState( false )
    const [loading, setLoading] = useState( true )
    const [hasPermission, setHasPermission] = useState( true )

    const ResumeBuilderStore = useResumeBuilderStore()
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const { palette } = useTheme()

    const createAndNavigateToConfigureForm = async () => {
        setCreatingForm( true )
        const { success, data } = await ResumeBuilderStore.createForm( { formName: '', formDescription: '' } )
        setCreatingForm( false )
        if ( success ) {
            const role = UserStore.getUser.user_role.toUpperCase()
            if ( ["ADMIN", "SUBADMIN"].includes( role ) ) {
                navigate( `/admin/resume-forms/${data.id}` )
            } else if ( ["HOD", "PRESENTER", "FACULTY", "PRINCIPAL"].includes( role ) ) {
                navigate( `/faculty/resume-forms/${data.id}` )
            } else if ( ["STUDENT", "OBSERVER"].includes( role ) ) {
                navigate( `/student/resume-forms/${data.id}` )
            } else {
                navigate( `/resume-forms/${data.id}` )
            }
        }
    }

    const navigateToForm = ( id ) => {
        const role = UserStore.getUser.user_role.toUpperCase()
        if ( ["ADMIN", "SUBADMIN"].includes( role ) ) {
            navigate( `/admin/resume-forms/${id}` )
        } else if ( ["HOD", "PRESENTER", "FACULTY", "PRINCIPAL"].includes( role ) ) {
            navigate( `/faculty/resume-forms/${id}` )
        } else if ( ["STUDENT", "OBSERVER"].includes( role ) ) {
            navigate( `/student/resume-forms/${id}` )
        } else {
            navigate( `/resume-forms/${id}` )
        }
    }

    useEffect( () => {
        const getData = async () => {
            await ResumeBuilderStore.fetchForms()
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        } else
            getData()
    }, [ResumeBuilderStore, UserStore] )

    return (
        <Box height="100%" overflow="hidden">
            {hasPermission && <Box>
                <Paper sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
                    <Box padding="20px">
                        <Typography variant='h5'>Resume Form</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Manage and build resume Form here</Typography>
                    </Box>
                    {!loading && <Fab sx={{ position: "fixed", bottom: "20px", right: "20px" }} color='secondary' onClick={createAndNavigateToConfigureForm}>
                        {!creatingForm ? Icons.AddIcon : <CircularProgress sx={{ color: "white !important" }} size={20} />}
                    </Fab>}
                    {!loading && ResumeBuilderStore.getForms.length === 0 && <Box bgcolor="#eee" padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="center">
                        <img src={noFormImg} height="150px" style={{ marginBottom: "20px" }} alt="No Form" />
                        <Typography color="textSecondary" variant='subtitle2'>Seems like there are no resume forms to show!</Typography>
                        {creatingForm ? <Button disabled variant='outlined'><Typography textTransform="capitalize" display="flex" gap="10px" alignItems="center"> <CircularProgress size={14} /> creating form... </Typography></Button> : <Button onClick={createAndNavigateToConfigureForm} variant="outlined" startIcon={Icons.AddIcon} sx={{ textTransform: "capitalize" }} > Add form </Button>}
                    </Box>
                    }
                    {!loading && ResumeBuilderStore.getForms.length > 0 && <Box flexGrow={1} >
                        <TableContainer className='custom-scrollbar' sx={{ maxHeight: "calc(100% - 1px)", flexGrow: 1, overflow: "auto" }}>
                            <Table stickyHeader >
                                <TableHead>
                                    <TableRow>
                                        {tableColumns.map( column => (
                                            <TableCell key={column.label} sx={{ background: palette.primary.main, color: "white", minWidth: column.minWidth, padding: "10px" }}>{column.label}</TableCell>
                                        ) )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ResumeBuilderStore.getForms.map( ( form, index ) => (
                                        <TableRow sx={{ cursor: "pointer", "&:hover": { background: "#eee" } }} onClick={() => navigateToForm( form.id )} key={form.id}>
                                            <TableCell >{index + 1}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{form.name}</TableCell>
                                            <TableCell >{form.added_by.user_name}</TableCell>
                                            <TableCell >{form.access_list.join( "," )}</TableCell>
                                        </TableRow>
                                    ) )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                    }
                    {loading && <Typography padding="20px" variant='subtitle2' alignItems="center" gap="10px" display="flex">
                        <CircularProgress size={20} /> Loading forms...
                    </Typography>}
                </Paper>
            </Box>}
            {!hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box >
    )
} )

export default ResumeForms
