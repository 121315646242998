import { FormControl, Box, FormHelperText, TextField, Typography, Button, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, Checkbox, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { formatDateTimeForInputField } from '../../utils/exam-utilities'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useExamStore } from '../../store/exam.store'
import api from '../../service/api'
import { addStyles, EditableMathField } from "react-mathquill"
import { Icons } from '../../utils/utilities'
import AudioInput from '../../components/exams-components/AudioInput'
import { Mic } from '@mui/icons-material'


export default function AddQuestions() {
    const [exam, setExam] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [isAdding, setIsAdding] = useState( false )
    const [numberOfAnswer, setNumberOfAnswer] = useState( [1] )
    const [audioRecorderStatus, setAudioRecorderStatus] = useState( false )
    const [answers, setAnswers] = useState( {} )
    const [plainAnswers, setPlainAnswers] = useState( {} )
    const [latex, setLatex] = useState( '' )
    const [latexOptions, setLatexOptions] = useState( {} )
    const [multipleCorrectAnswers, setMultipleCorrectAnswers] = useState( {} )
    const [selectedAudioFiles, setSelectedAudioFiles] = useState( [] )

    const { id } = useParams()
    const ExamStore = useExamStore()
    const navigate = useNavigate()

    addStyles()

    const initialValues = {
        question: "",
        questionUrl: "",
        hint: "",
        marks: 1,
        questionType: "MCQ",
        selectionType: "single",
        answers: '',
        correctAnswer: null
    }

    const validationSchema = Yup.object().shape( {
        question: Yup.string().required( "This field is required" ),
        marks: Yup.number().required( "This field is required" ).min( 1, "Minimum marks should be 1" ),
        questionUrl: Yup.string().notRequired().matches( /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Please provide a valid URL" ),
        answers: Yup.mixed().required( "Answer is required!" )
            .test( {
                name: "MCQ_ANSWERS", message: "Provide options for MCQ type questions", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' ) {
                        if ( context.parent.answers ) {
                            if ( Object.keys( context.parent.answers ).length < 2 ) return false
                            else return true
                        }
                    } else return true
                }
            } )
            .test( {
                name: "CORRECT_ANSWER", message: "Select the correct answer", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' && context.parent.selectionType === 'single' ) {
                        if ( !context.parent.correctAnswer ) return false
                        return true
                    } else return true
                }
            } )
            .test( {
                name: "MULTIPLE_CORRECT_ANSWER", message: "Please select multiple correct answers", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' && context.parent.selectionType !== 'single' ) {
                        if ( !context.parent.correctAnswer ) {
                            return false
                        } else {
                            let correctAnswersCount = 0
                            for ( const answer in context.parent.correctAnswer ) {
                                if ( context.parent.correctAnswer[answer] ) correctAnswersCount++
                                if ( correctAnswersCount > 1 ) return true
                            }
                            return false
                        }
                    } else return true
                }
            } )
            .test( {
                name: "EMPTY_ANSWERS", message: "Please fill all the answer fileds!", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' ) {
                        if ( context.parent.answers ) {
                            for ( const ans in plainAnswers ) {
                                if ( plainAnswers[ans].trim() === '' ) {
                                    return false
                                }
                            }
                            if ( numberOfAnswer.length !== Object.keys( context.parent.answers ).length )
                                return false
                            return true
                        }
                    } else return true
                }
            } )
    } )

    const addAnswerField = () => {
        setNumberOfAnswer( [...numberOfAnswer, numberOfAnswer.length + 1] )
    }

    // const setQuestionType = ( e ) => {
    //     if ( e.target.value === 'descriptive' ) {
    //         setNumberOfAnswer( [1] )
    //         setMultipleCorrectAnswers( {} )
    //     }
    // }

    const setNewAnswers = ( number, answer, text ) => {
        const newAnswers = { ...answers, [number]: `LATEX-${answer}` }
        setAnswers( newAnswers )
        setPlainAnswers( { ...plainAnswers, [number]: `LATEX-${text}` } )
        return newAnswers
    }

    const submit = async ( values, props ) => {
        setIsAdding( true )
        try {
            const formData = new FormData()
            for ( const name in values ) {
                if ( typeof values[name] === 'object' )
                    formData.append( name, JSON.stringify( values[name] ) )
                else
                    formData.append( name, values[name] )
            }
            if ( selectedAudioFiles.length > 0 ) {
                for ( let j = 0; j < selectedAudioFiles.length; j++ ) {
                    const item = selectedAudioFiles[j]
                    console.log( item )
                    formData.append( 'audioattachment[]', item, item.name )
                }
            }
            const res = await api.addQuestion( id, formData )
            if ( res.status === 200 ) {
                props.resetForm()
                toast.success( "Question added" )
                setPlainAnswers( {} )
                setAnswers( {} )
                setNumberOfAnswer( [1] )
                setMultipleCorrectAnswers( {} )
                document.querySelectorAll( '.ql-editor' ).forEach( editor => {
                    editor.textContent = ""
                } )
                setLatex( '' )
                setLatexOptions( {} )
                setSelectedAudioFiles( [] )
            }
        } catch ( e ) {
            // toast( e.response.data )
            console.log( e )
        } finally {
            setIsAdding( false )
        }
    }

    useEffect( () => {
        const getExam = async () => {
            try {
                await ExamStore.fetchExam( id )
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() ) {
                    toast.error( "Exam already started! can't add questions now" )
                    navigate( `/faculty/exams/${id}` )
                } else if ( ExamStore.getExam.exam.exam_status === 'Published' ) {
                    toast.error( "Exam already published! can't add questions now" )
                    navigate( `/faculty/exams/${id}` )
                }
                else setExam( ExamStore.getExam.exam )
            }
            catch ( e ) {
                if ( e.response && e.response.data && "Network Error" === e.response.data ) {
                    navigate( '/networkerror' )
                }
                toast.error( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! try again later" )
            } finally {
                setInProgress( false )
            }
        }
        getExam()
    }, [id, navigate, ExamStore] )

    return (
        <Box sx={{ padding: "0 20px 20px 20px" }}>
            {exam && <Typography fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to={`/faculty/exams`} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} <Link to={`/faculty/exams/${id}`} className="breadcrumb-link">{exam ? exam.exam_name : <CircularProgress size={12} />}</Link> {Icons.SmallChevronRightIcon} Add question </Typography>}
            <Paper>
                {exam && !inProgress &&
                    <Box>
                        <Box padding="20px" marginTop="10px" borderBottom="1px solid #d3d3d3">
                            <Typography textTransform="capitalize" variant='h5'>{exam.exam_name}</Typography>
                            <Typography variant='subtitle2' color="textSecondary">Add questions and options</Typography>
                        </Box>
                    </Box>
                }
                {!exam && !inProgress && <Box padding="20px">
                    <Typography variant='h6' color="textSecondary">Exam not found!</Typography>
                    <Button sx={{ textTransform: "capitalize" }} variant='outlined' size="small" onClick={() => navigate( `/faculty/exams`, { replace: true } )}>Back to exam</Button>
                </Box>}
                {exam && !inProgress && <>
                    <Box bgcolor="white" marginTop="20px" padding="10px 20px 20px 20px" borderRadius="5px">
                        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={submit}>
                            {
                                ( { values, setFieldValue } ) => (
                                    <Form autoComplete='off' style={{ display: exam ? "block" : "none" }}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{ fontSize: "14px", marginBottom: "10px" }}>Question*</FormLabel>
                                            <EditableMathField
                                                latex={latex}
                                                onChange={( mathField ) => {
                                                    setFieldValue( 'question', `LATEX-${mathField.latex()}` )
                                                    setLatex( mathField.latex() )
                                                }}
                                                placeholder='Question goes here...'
                                            />
                                            {/* <Field as={ReactQuill} theme='snow' className='editor' modules={modules} name='question' onChange={( e ) => { setFieldValue( 'question', e ) }} onBlur={( e, b, editor ) => setFieldValue( 'question', editor.getHTML() )} /> */}
                                            <FormHelperText sx={{ color: "#d32f2f", fontWeight: "bold", margin: "0 0 0 0" }}><ErrorMessage name='question' /></FormHelperText>
                                        </FormControl>
                                        {!audioRecorderStatus && <Button color="secondary" onClick={() => setAudioRecorderStatus( !audioRecorderStatus )} sx={{ fontSize: "12px", textTransform: "capitalize" }} size="small" startIcon={<Mic sx={{ fontSize: "14px" }} />}>Add audio attachment</Button>}
                                        {audioRecorderStatus &&
                                            <FormControl margin='normal' fullWidth>
                                                <FormLabel sx={{ fontSize: "14px", marginBottom: "10px" }}>Question audio attachment</FormLabel>
                                                <AudioInput maxSize={1024} multiple={false} container="bordered-box" setRecordedCardStatus={setAudioRecorderStatus} selectedAudioFiles={selectedAudioFiles} setSelectedAudioFiles={setSelectedAudioFiles} maxLength={30} />
                                            </FormControl>
                                        }
                                        <FormControl fullWidth>
                                            <Field as={TextField} margin="normal" InputLabelProps={{ sx: { fontSize: "14px !important" } }} label="Hint" name="hint" />
                                            <FormHelperText sx={{ color: "#d32f2f", fontWeight: "bold", margin: "-5px 0 0 0" }}><ErrorMessage name='hint' /></FormHelperText>
                                        </FormControl>
                                        {/* <FormControl fullWidth>
                                        <Field as={TextField} InputLabelProps={{ sx:{fontSize:"14px !important"} }} margin="normal" label="Question Video URL" name="questionUrl" />
                                        <FormHelperText sx={{ color: "#d32f2f", fontWeight: "bold", margin: "-5px 0 0 0" }}><ErrorMessage name='questionUrl' /></FormHelperText>
                                    </FormControl> */}
                                        <FormControl fullWidth>
                                            <Field as={TextField} onWheel={( e ) => e.target.blur()} InputLabelProps={{ sx: { fontSize: "14px !important" } }} type="number" margin="normal" label="Marks*" name="marks" />
                                            <FormHelperText sx={{ color: "#d32f2f", fontWeight: "bold", margin: "-5px 0 0 0" }}><ErrorMessage name='marks' /></FormHelperText>
                                        </FormControl>
                                        {/* <FormControl margin='normal' fullWidth>
                                        <FormLabel sx={{ fontSize: "16px" }}>Question Type</FormLabel>
                                        <RadioGroup name='questionType' value={values.questionType} onChange={( e ) => { ( setFieldValue( 'questionType', e.target.value ) ); setQuestionType( e ); e.target.value === 'descriptive' && setFieldValue( 'correctAnswer', null ) }} row>
                                            <FormControlLabel value="MCQ" control={<Radio />} label="MCQ" />
                                            <FormControlLabel value="descriptive" control={<Radio />} label="Descriptive" />
                                        </RadioGroup>
                                    </FormControl> */}
                                        <FormControl margin='normal' fullWidth>
                                            <FormLabel sx={{ fontSize: "16px" }}>Answer selection type</FormLabel>
                                            <RadioGroup name='selectionType' value={values.selectionType} onChange={( e ) => ( setFieldValue( 'selectionType', e.target.value ) )} row>
                                                <FormControlLabel value="single" control={<Radio />} label="Single" />
                                                <FormControlLabel value="multiple" control={<Radio />} label="Multiple" />
                                            </RadioGroup>
                                        </FormControl>
                                        <RadioGroup name='correctAnswer' onChange={( e ) => { setFieldValue( 'correctAnswer', e.target.value ) }} value={values.correctAnswer}>
                                            {numberOfAnswer.map( ( i, index ) => (
                                                <FormControl key={index} fullWidth>
                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                        <FormLabel sx={{ fontSize: "14px", margin: "10px 0" }}>Answer {index === 0 ? ' ' : index + 1}*</FormLabel>
                                                        {values.questionType === 'MCQ' && values.selectionType === 'single' && <FormControlLabel value={`${index}`} control={<Radio />} labelPlacement="end" label="Correct answer" />}
                                                        {values.questionType === 'MCQ' && values.selectionType !== 'single' && <FormControlLabel value={`${index}`} control={<Checkbox name='correctAnswer' onChange={e => { setFieldValue( 'correctAnswer', { ...multipleCorrectAnswers, [index]: e.target.checked } ); setMultipleCorrectAnswers( { ...multipleCorrectAnswers, [index]: e.target.checked } ) }} />} labelPlacement="end" label="Correct answer" />}
                                                    </Box>
                                                    <EditableMathField
                                                        latex={latexOptions[index] ? latexOptions[index] : ""}
                                                        onChange={( mathField ) => {
                                                            setFieldValue( 'answers', setNewAnswers( index, mathField.latex(), mathField.latex().replace( /\\\\/g, "" ) ) )
                                                            setLatexOptions( { ...latexOptions, [index]: mathField.latex() } )
                                                        }}
                                                        placeholder='Option goes here...'
                                                    />
                                                    {/* <ReactQuill theme='snow' className='editor' modules={modules} name={`answers`} onChange={( e, d, s, editor ) => { setFieldValue( 'answers', setNewAnswers( index, e, editor.getText() ) ) }} onBlur={( e, b, editor ) => setFieldValue( `answers`, setNewAnswers( index, editor.getHTML(), editor.getText() ) )} /> */}
                                                </FormControl> ) )}
                                            <FormHelperText sx={{ color: "#d32f2f", fontWeight: "bold", margin: "5px 0 5px 0" }}><ErrorMessage name='answers' /></FormHelperText>
                                        </RadioGroup>
                                        {values.questionType === 'MCQ' && <Button variant='contained' onClick={addAnswerField} sx={{ textTransform: "capitalize", marginTop: "10px" }} color='warning' >Add another answer option</Button>}
                                        <Button variant='contained' disabled={( !exam || isAdding )} type="submit" sx={{ display: "block", marginTop: "10px", textTransform: "capitalize" }}>{isAdding ? "Adding question..." : "Add"}</Button>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Box>
                </>}
                {inProgress &&
                    <Typography height="100%" borderRadius="5px" padding="20px" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)" display="flex" alignItems="center" variant="h6" bgcolor="white" color="textSecondary"><CircularProgress size={20} sx={{ marginRight: "10px" }} />Loading...  </Typography>
                }
            </Paper>

        </Box >
    )
}




// const modules = {
//     toolbar: [
//         [{ header: 1 }, { header: 2 }, { header: [3, 4, 5, 6] }, { font: [] }],
//         [{ size: [] }],
//         [{ script: 'sub' }, { script: 'super' }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ list: 'ordered' }, { list: 'bullet' }],
//         ['link', 'image', 'video'],
//         ['clean'],
//         ['code-block'],
//     ]
// }