import { Box, Button, Card, FormControl, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Icons } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'
import { toast } from 'react-toastify'

const Password = () => {

    const userStore = useUserStore()

    const [showPassword, setShowPassword] = useState( false )
    const [password, setPassword] = useState( '' )

    const save = async () => {
        const pattern = ``
        // const pattern = `^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,32}$`
        if ( password.match( [pattern] ) ) {
            let payload = {
                "password": password
            }
            await userStore.updatePassword( payload )
            toast.success( "Password changed successfully!" )
            setPassword( "" )
        } else {
            toast.error( "Please provide a strong password" )
        }
    }

    return (
        <Box >
            <Box display="flex" justifyContent="center">
                <Card sx={{ flexGrow: 1, padding: "20px", minWidth: "250px", maxWidth: "450px", display: "flex", flexDirection: "column", gap: "20px", alignItems: "flex-start" }}>
                    <Typography variant='h5'>Change Password</Typography>
                    <FormControl fullWidth variant="filled">
                        <OutlinedInput
                            fullWidth
                            sx={{ width: "100%" }}
                            id="filled-adornment-password"
                            placeholder='Enter new password'
                            onChange={( e ) => setPassword( e.target.value )}
                            type={!showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword( !showPassword )}
                                        onMouseDown={( e ) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? Icons.VisibilityOffIcon : Icons.VisibilityIcon}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Button onClick={save} sx={{ width: "100%" }} disableElevation variant='contained'>Save new password</Button>
                </Card>
            </Box>
        </Box>
    )
}

export default Password
