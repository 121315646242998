import { Button, Chip, CircularProgress, Collapse, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete'
import TableRowsIcon from '@mui/icons-material/TableRows'
import GridViewIcon from '@mui/icons-material/GridView'
import GridView from "../../components/study-material-components/GridView"
import ListView from "../../components/study-material-components/ListView"
import { Icons, flatColors } from "../../utils/utilities"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import FileInput from "../../components/study-material-components/FileInput"
import MaterialEdit from "../../components/study-material-components/MaterialEdit"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Refresh from "@mui/icons-material/Refresh"
import DetailsViewer from "../../components/study-material-components/DetailsViewer"
import Download from "@mui/icons-material/Download"
import { useUserStore } from "../../store/user.store"
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import { observer } from "mobx-react"
import api from "../../service/api"
import ConfirmDialog from "../../components/ConfirmDialog"
import { useDownloadStatusListStore } from "../../store/downloadstatuslistdialog.store"

let headBgColor = flatColors[Math.floor( Math.random() * flatColors.length )]

const Material = observer( () => {

    const [viewType, setViewType] = useState( localStorage.getItem( "studyMaterialView" ) || "grid" )
    const [selectedAttachments, setSelectedAttachments] = useState( [] )
    const [attachmentsSelectedForDetails, setAttachmentsSelectedForDetails] = useState( null )
    const [showDetails, setShowDetails] = useState( false )
    const [addMode, setAddMode] = useState( false )
    const [data, setData] = useState( null )
    const [attachments, setAttachments] = useState( null )
    const [isLoading, setIsLoading] = useState( true )
    const [editMode, setEditMode] = useState( false )
    const [filteredAttachments, setFilteredAttachments] = useState( null )
    const [isUploading, setIsUploading] = useState( false )
    const [progress, setProgress] = useState( 0 )
    const [searchParameter, setSearchParameter] = useState( '' )
    const [isUpdating, setIsUpdating] = useState( false )
    const [attachmentsNames, setAttachmentsNames] = useState( [] )
    const [isDownloading, setIsDownloading] = useState( false )
    const [materialDeleteDialogState, setMaterialDeleteDialogState] = useState( false )

    const { material_id } = useParams()

    const UserStore = useUserStore()
    const StudyMaterialStore = useStudyMaterialStore()
    const DownloadStatusListStore = useDownloadStatusListStore()

    const navigate = useNavigate()


    const searchAttachments = ( e ) => {
        const filter = attachments.filter( a => a.study_material_attachment_name.includes( e.target.value ) )
        setSearchParameter( e.target.value )
        setFilteredAttachments( filter )
    }

    const progressHandler = ( event ) => {
        const { loaded, total } = event
        let percent = Math.round( ( loaded * 100 ) / total )
        if ( percent <= 100 ) {
            setProgress( percent )
        }
    }

    const getAttachments = async () => {
        try {
            await StudyMaterialStore.fetchAttachments( material_id )
            setAttachments( StudyMaterialStore.getAttachments )
            const filtered = StudyMaterialStore.getAttachments.filter( attachment => attachment.study_material_attachment_name.includes( searchParameter ) )
            setFilteredAttachments( filtered )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        }
    }

    const uploadAttachments = async () => {
        setIsUploading( true )
        try {
            const formData = new FormData()
            let emptyName = false
            for ( let i = 0; i < attachmentsNames.length; i++ ) {
                const name = attachmentsNames[i]
                if ( name === "" ) {
                    emptyName = true
                }
            }
            if ( !emptyName ) {
                selectedAttachments.forEach( ( attachment, i ) => {
                    const ext = attachment.name.split( "." ).pop()
                    let fileName = encodeURIComponent( `${attachment.name.split( ext )[0]}-${Date.now()}${i}.${ext}`.replace( /\s/g, '-' ) )
                    formData.append( 'attachments[]', attachment, fileName )
                } )
                formData.append( 'attachmentNames[]', JSON.stringify( attachmentsNames ) )
                await StudyMaterialStore.addAttachments( material_id, formData, progressHandler )
                setIsUploading( false )
                setProgress( 0 )
                setSelectedAttachments( [] )
                setAttachmentsNames( [] )
                await getAttachments()
            } else {
                toast( "Please provide names for all the attachments" )
            }
        } catch ( e ) {
            console.log( e )
            setIsUploading( false )
            setProgress( 0 )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsUploading( false )
            setProgress( 0 )
        }
    }

    const downloadStudyMaterial = async () => {
        setIsDownloading( true )
        toast( `Downloading ${data.study_material_name}...`, { position: "bottom-left" } )
        try {
            await DownloadStatusListStore.downloadMaterial( material_id, data )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsDownloading( false )
        }
    }

    const deleteAttachment = async ( id ) => {
        try {
            await StudyMaterialStore.deleteAttachment( id )
            getAttachments()
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        }
    }

    const deleteMaterial = async () => {
        try {
            await StudyMaterialStore.deleteMaterial( material_id )
            navigate( '/faculty/material' )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        }
    }

    const renameAttachment = async ( id, value ) => {
        try {
            const res = await api.renameAttachment( material_id, id, { new_title: value } )
            getAttachments()
            toast( res.data.data )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data )
        }
    }

    const removefilter = () => {
        setFilteredAttachments( [...attachments] )
        setSearchParameter( "" )
    }

    const update = async ( values ) => {
        setIsUpdating( true )
        try {
            setData( await StudyMaterialStore.updateMaterial( material_id, { name: values.materialName, description: values.materialDescription, tags: values.tags } ) )
            setEditMode( false )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsUpdating( false )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                await StudyMaterialStore.fetchMaterial( material_id )
                if ( !StudyMaterialStore.getMaterial['studyMaterial'] ) {
                    navigate( '/notfound', { replace: true } )
                }
                setData( StudyMaterialStore.getMaterial['studyMaterial'] )
                console.log( StudyMaterialStore.getMaterial['studyMaterial'] )
                setAttachments( StudyMaterialStore.getMaterial['attachments'] )
                setFilteredAttachments( StudyMaterialStore.getMaterial['attachments'] )
            } catch ( e ) {
                console.log( e )
            } finally {
                setIsLoading( false )
            }
        }
        getData()
    }, [material_id, navigate, UserStore, StudyMaterialStore] )


    return (
        // <Layout navHeadText="Study Material">
        <Box padding="0 20px 20px 20px">
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link className="breadcrumb-link" to={UserStore.getUser['user_role'] === "STUDENT" ? '/student/material' : '/faculty/material'}>Study Materials</Link>{Icons.SmallChevronRightIcon} {data && data.study_material_name} {editMode && Icons.SmallChevronRightIcon} {editMode && 'Edit'}</Typography>
            <ConfirmDialog status={materialDeleteDialogState} confirmAction={deleteMaterial} cancelAction={() => setMaterialDeleteDialogState( false )} actionName="Delete" message={<><Typography>Do you really want to delete <b>{data?.study_material_name}</b> .</Typography> <Typography fontWeight="500" fontSize="12px">All attachments will be deleted!</Typography></>} />
            {!isLoading && data && !editMode && <>
                <Box bgcolor="white" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)" borderRadius="5px">
                    {attachmentsSelectedForDetails && <DetailsViewer attachment={attachmentsSelectedForDetails} setShowDetails={setShowDetails} open={showDetails} />}
                    <Box bgcolor={headBgColor} borderRadius="5px 5px 0 0" padding="20px" color="white">
                        <Box width="100%" display="flex" gap="10px" flexDirection={{ sm: "row", xs: "column" }} justifyContent="space-between" alignItems={{ sm: "center", xs: "flex-start" }}>
                            <Typography variant="h5">{data.study_material_name}</Typography>
                            <Box borderBottom="10px" display="flex" gap="10px" alignItems="center">
                                {UserStore.getUser.user_role !== "STUDENT" && <Box display="flex" gap="10px" alignItems="center">
                                    {data.empcode === UserStore.getUser['user_id'] && <Tooltip title="Edit" placement="top">
                                        <IconButton onClick={() => { setEditMode( true ); window.location.hash = "#Edit" }} sx={{ padding: "10px", background: "white", transition: "0.2s ease", color: "secondary.main", "&:hover": { background: "white", transform: "scale(1.1)" } }}>
                                            <EditIcon sx={{ fontSize: "20px" }} />
                                        </IconButton>
                                    </Tooltip>}
                                    {UserStore.getUser.user_id === data.empcode && <Tooltip title="Delete" placement="top">
                                        <IconButton onClick={() => setMaterialDeleteDialogState( true )} sx={{ padding: "10px", background: "white", transition: "0.2s ease", color: "error.main", "&:hover": { background: "white", transform: "scale(1.1)" } }}>
                                            <DeleteIcon sx={{ fontSize: "20px" }} />
                                        </IconButton>
                                    </Tooltip>}
                                </Box>}
                                {attachments.length > 0 && <Tooltip title={isDownloading ? "Downloading material..." : "Download all materials"} placement="top">
                                    <span>
                                        <IconButton disabled={isDownloading} onClick={downloadStudyMaterial} sx={{ padding: "10px", background: "white", transition: "0.2s ease", color: "textSecondary", "&:disabled": { background: "whitesmoke" }, "&:hover": { background: "white", transform: "scale(1.1)" } }}>
                                            {isDownloading ? Icons.DownloadingIcon : <Download sx={{ fontSize: "20px" }} />}
                                        </IconButton>
                                    </span>
                                </Tooltip>}
                            </Box>
                        </Box>
                        {data.study_material_desc !== "" && <Typography fontSize="14px" textAlign="justify">{data.study_material_desc}</Typography>}
                        <Box display="flex" gap="10px" marginY="10px" flexWrap="wrap">
                            {data.study_material_tags && data.study_material_tags.split( "," ).length > 0 && data.study_material_tags.split( "," ).map( ( tag, index ) => (
                                <Chip key={index} sx={{ background: "white" }} label={tag} size="small" />
                            ) )}
                        </Box>
                        <Typography fontSize="14px" > {attachments.length} attachments </Typography>
                        <Typography fontSize="14px" >Total {data.download_count} Downloads </Typography>
                    </Box>
                    <Box>
                        <Box padding="20px 20px" borderBottom="1px solid grey">
                            <Box flexDirection={{ sm: "row", xs: "column" }} gap="10px" display="flex" alignItems={{ sm: "center", xs: "flex-start" }} justifyContent="space-between">
                                <Typography width="100%" variant="h6" display="flex" alignItems="center" gap="10px" fontWeight="normal" >
                                    All Documents
                                    {data.empcode === UserStore.getUser['user_id'] && <Tooltip title={addMode ? "Cancel" : "Add more attachments"}>
                                        <IconButton onClick={() => setAddMode( !addMode )} sx={{ padding: "10px", background: "#eee", transform: addMode ? "rotate(45deg)" : "rotate(0)", transition: "0.2s ease", color: addMode ? "error.main" : "secondary.main", "&:hover": { color: "#273679" } }} >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>}
                                    <Tooltip title="Refresh" sx={{ padding: "10px", background: "#eee" }}>
                                        <IconButton onClick={getAttachments} color="secondary" >
                                            <Refresh />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                                <Box display="flex" width="100%" alignItems={{ sm: "center", xs: "flex-start" }} flexDirection={{ sm: "row", xs: "column" }} justifyContent={{ sm: "flex-end", xs: "space-between" }}>
                                    <TextField onChange={searchAttachments} value={searchParameter} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px" }, startAdornment: <SearchIcon sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", minWidth: "150px", width: "100%", maxWidth: { sm: "300px", xs: "100%" }, color: "black" }} placeholder="Search attachments..." variant="standard" type="text" />
                                    <Box display="flex" alignItems="center">
                                        <Tooltip title="List view" placement="top">
                                            <IconButton onClick={() => { localStorage.setItem( "studyMaterialView", "list" ); setViewType( "list" ) }} sx={{ color: viewType !== "grid" ? "primary.main" : "#666" }}>
                                                <TableRowsIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Grid view" placement="top">
                                            <IconButton onClick={() => { localStorage.setItem( "studyMaterialView", "grid" ); setViewType( "grid" ) }} sx={{ color: viewType === "grid" ? "primary.main" : "#666" }}>
                                                <GridViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                            <Collapse in={addMode} sx={{ position: "relative" }}>
                                <FileInput size={60} progress={progress} isUploading={isUploading} setFieldValue={( name, value ) => { }} setAttachmentsNames={setAttachmentsNames} attachmentsNames={attachmentsNames} selectedAttachments={selectedAttachments} setSelectedAttachments={setSelectedAttachments} />
                                {selectedAttachments.length > 0 && <Button onClick={uploadAttachments} disabled={isUploading} startIcon={<CloudUploadIcon />} sx={{ textTransform: "capitalize", marginTop: "20px" }} variant="contained" disableElevation>{isUploading ? <Typography display="flex" alignItems="center" gap="10px" fontWeight={600}> <CircularProgress size={10} /> Uploading attachments... </Typography> : "Upload Attachments"}</Button>}
                            </Collapse>
                        </Box>
                        {viewType === "grid" ? <GridView progressHandler={progressHandler} renameAttachment={renameAttachment} removefilter={removefilter} setShowDetails={setShowDetails} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} attachments={filteredAttachments} allAttachments={attachments} material={data} deleteAttachment={deleteAttachment} /> : <ListView progressHandler={progressHandler} renameAttachment={renameAttachment} removefilter={removefilter} material={data} setShowDetails={setShowDetails} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} attachments={filteredAttachments} allAttachments={attachments} deleteAttachment={deleteAttachment} />}
                    </Box>

                </Box>
            </>}
            {!isLoading && data && editMode && <Box>
                <MaterialEdit material={data} update={update} isUpdating={isUpdating} currentTags={data.study_material_tags.split( "," )} setEditMode={setEditMode} />
            </Box>}
            {/* {!isLoading && data && !editMode && manageAccessMode && <ManageAccess data={data} setManageAccessMode={setManageAccessMode} />} */}
            {isLoading && <Typography color="textSecondary" display="flex" variant="h6" alignItems="center" gap="20px" > <CircularProgress size={20} />Loading... </Typography>}
        </Box>
        // </Layout>
    )
} )

export default Material
