import { Box, Button, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material'
import parse from 'html-react-parser'
import { convert } from 'html-to-text'
import React from 'react'

const QuestionsPreviewDialog = ( { questionsPreview, setQuestionsPreview, importQuestions } ) => {
    return (
        <Dialog open={questionsPreview ? true : false} PaperProps={{ sx: { minWidth: { md: "70vw", xs: "90%" } } }}>
            {questionsPreview?.status === "loaded" && <DialogContent>
                <Typography gutterBottom variant='h6'>Import file preview</Typography>
                <Box >
                    {questionsPreview && questionsPreview.errors.length > 0 && <Box bgcolor="rgba(200,0,0,0.1)" border="2px solid rgba(200,0,0,0.7)" borderRadius="5px" padding="20px">
                        {questionsPreview.errors.map( error => (
                            <Box key={error.line}>
                                <Typography color="error" variant="subtitle2">Line {error.line}</Typography>
                                {error.messages.map( ( msg, i ) => (
                                    <Typography key={`key-${i}`} color="error" variant="subtitle2" marginLeft="10px">{msg}</Typography>
                                ) )}
                            </Box>
                        ) )}
                    </Box>}
                    <Box padding="20px" marginTop="20px" borderRadius="5px" bgcolor="rgba(0,200,0,0.1)" border="2px solid rgba(0,200,0,0.7)">
                        <Typography color="success.main" variant='subtitle2'>{questionsPreview?.questions.length} questions detected</Typography>
                    </Box>
                    <Box marginTop="10px" borderRadius="5px" maxHeight="300px" overflow="auto" bgcolor="#eee">
                        {questionsPreview && questionsPreview.data.map( ( line, k ) => (
                            <Box key={`line-${k}`} position="relative" paddingLeft="35px" gap="20px" display="flex" height="100%" alignItems="center">
                                <Box bgcolor={questionsPreview?.errorLines.includes( k + 1 ) ? "rgba(200,0,0,0.3)" : "#aaa"} borderRight="1px solid grey" fontSize="12px" color="white" display="flex" alignItems="center" justifyContent="center" position="absolute" left="0" height="100%" width="25px">{k + 1}</Box>
                                <Box sx={{ "& >p": { margin: "5px 0" } }} fontSize="12px !important">{convert( line ).trim() !== "" ? parse( line ) : <p> &nbsp;</p>}</Box>
                            </Box>
                        ) )}
                    </Box>
                </Box>
                <Box marginTop="20px" gap="10px" alignItems="center" display="flex">
                    {questionsPreview && <Button onClick={async () => { importQuestions( questionsPreview.questions ); setQuestionsPreview( null ) }} variant="contained">Import</Button>}
                    <Button onClick={() => { setQuestionsPreview( null ) }} color='error' variant="contained">Close</Button>
                </Box>
            </DialogContent>}
            {questionsPreview?.status === "loading" && <DialogContent sx={{ padding: "0" }}>
                <Box display="flex" alignItems="center" justifyContent="center" height="200px" bgcolor="rgba(0,0,0,0.7)">
                    <Typography color="white" display="flex" alignItems="center" gap="10px"> <CircularProgress sx={{ color: "white" }} size={14} /> Extracting questions...</Typography>
                </Box>
            </DialogContent>}
        </Dialog>
    )
}

export default QuestionsPreviewDialog
