import { Box, Button, FormControl, FormControlLabel, Select, TextField, Typography, MenuItem, Checkbox, CircularProgress, useTheme, Popover } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useState } from 'react'
import { formatDateTimeForDB } from '../../utils/exam-utilities'
import { useNavigate } from 'react-router-dom'
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'

const hours = ["00", "01", "02", "03", "04", "05", "06"]
const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

const CreateExamComponent = observer( ( { setStepsStatus, setActiveStep, examCreationData, setExamCreationData } ) => {
    const initialDate = new Date().toISOString().split( 'T' )[0] + "T00:00"

    const [isCreatingExam, setIsCreatingExam] = useState( false )
    const [isOpenExam] = useState( true )
    const [randomizeInfo, setRandomizeInfo] = useState( false )
    const [durationHours, setDurationHours] = useState( examCreationData?.exam?.duration ? examCreationData?.exam?.duration?.split( ":" )[0] : "00" )
    const [durationMinutes, setDurationMinutes] = useState( examCreationData?.exam?.duration ? examCreationData?.exam?.duration?.split( ":" )[1] : "00" )

    const navigate = useNavigate()

    const { palette, border } = useTheme()

    const initialValues = examCreationData.exam || {
        title: "",
        description: "",
        topics: '',
        status: 'Draft',
        startDateTime: initialDate,
        endDateTime: new Date( `2099-01-01` ),
        duration: null,
        maximumAttempt: 1,
        randomize: false,
        cutoff: 0,
    }


    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Exam title is required." ),
        cutoff: Yup.number().min( 0, "Minimum value: 0" ).max( 100, "Maximum value: 100" ),
        duration: Yup.string().nullable().test( "CHECK_DURATION", "Invalid time format hours: HH and minutes: MM", ( value ) => {
            if ( isOpenExam ) {
                if ( value && value !== '00:00' ) return true
                return false
            } else return true
        } ).test( "CHECK_FOR_CORRECT_QUIZ_DURATION", "Invalid duration or duration exceeds the set start and end time window", ( val, { parent } ) => {
            if ( val ) {
                const splitted = val?.split( ":" )
                const startDate = new Date( parent.startDateTime )
                const endDate = new Date( parent.endDateTime )
                const selectedDuration = ( endDate - startDate ) / 1000
                if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 || +splitted[0] > 6 ) ) {
                    return false
                } else {
                    if ( val === "00:00" )
                        return false
                    const timeInSeconds = ( +splitted[0] * 60 * 60 ) + splitted[1] * 60
                    if ( typeof selectedDuration === 'number' && selectedDuration >= timeInSeconds ) {
                        return true
                    }
                    else return false
                }
            } else
                return true
        }
        ),

        maximumAttempt: Yup.number().required( "This field is required!" ).min( 1, "At least 1 attempt must be given" ),
    } )

    const submit = async ( values, { resetForm } ) => {
        try {
            setIsCreatingExam( true )
            const formData = new FormData()
            formData.append( 'title', values.title )
            formData.append( 'startDateTime', JSON.stringify( formatDateTimeForDB( values.startDateTime, 'en-IN', '-' ) ) )
            formData.append( 'endDateTime', JSON.stringify( formatDateTimeForDB( values.endDateTime, 'en-IN', '-' ) ) )
            formData.append( "status", values.status )
            formData.append( 'duration', isOpenExam ? values.duration : null )
            formData.append( 'maximumAttempt', values.maximumAttempt )
            formData.append( "randomize", values.randomize )
            formData.append( "exam_type", "global" )

            const creationData = {}
            creationData.exam = values
            creationData.formData = formData
            setExamCreationData( prev => {
                return { ...prev, ...creationData }
            } )
            setStepsStatus( prev => {
                prev[0] = "completed"
                return prev
            } )
            setActiveStep( prev => prev + 1 )
        } catch ( err ) {
            toast( err?.response?.data?.message || "An errorMessage occured while creating a new exam! try again later", { containerId: "main" } )
        } finally {
            setIsCreatingExam( false )
        }
    }

    return (
        <Box flexGrow={1} minWidth="0" overflow="auto" >


            <Box sx={{ paddingBottom: "1px" }}>
                <Box alignItems="center" display="flex" gap="10px" padding="20px">
                    <Box>
                        <Typography variant='h6' color="primaryDark" >New exam</Typography>
                        <Typography color="primaryDark" variant='body2' >Create exam by providing all required details</Typography>
                    </Box>
                </Box>
                <Box margin="20px" marginTop="0" bgcolor={palette.form.formBg} padding="20px" borderRadius="5px" border={border[1]}>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue } ) => (
                            <Form method='POST' id='add-form' autoComplete='off' encType="multipart/form-data">

                                <FormControl margin="normal" fullWidth>
                                    <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam title * </Typography></label>
                                    <Field placeholder="Eg: Kannada language internal test - 1" as={TextField} id="title" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='title' />
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='title' /></Typography>
                                </FormControl>

                                {isOpenExam && <FormControl variant='outlined' sx={{ display: "flex" }}>
                                    <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Exam duration</Typography>
                                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">HOURS</Typography>} size="small" onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours}>
                                                {hours.map( hour => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={hour} value={hour}>{hour}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl >
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">MIN</Typography>} size="small" onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes}>
                                                {minutes.map( min => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={min} value={min}>{min}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Typography variant="subtitle2" >Exam duration: {`${durationHours ? durationHours : 0} hours ${durationMinutes ? durationMinutes : 0} minutes`}</Typography>
                                    <Typography fontSize="12px" color="errorMessage.main" ><ErrorMessage name='duration' /></Typography>
                                </FormControl>}


                                {<Box marginTop="15px" position="relative">
                                    <Popover open={Boolean( randomizeInfo )} onClose={e => setRandomizeInfo( null )} anchorEl={randomizeInfo} slotProps={{ paper: { sx: { maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Randomize questions and options?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>If selected the questions and their options will be in random order for each individual taking the exam.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' checked={values.randomize} onChange={e => { setFieldValue( 'randomize', e.target.checked ) }} />} label="Randamize questions and options?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setRandomizeInfo( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                <Box display="flex" gap="20px" marginTop="40px">
                                    <Button disableElevation disabled={isCreatingExam} type='submit' startIcon={isCreatingExam && <CircularProgress size={14} />} variant='contained' sx={{ textTransform: "capitalize" }}>{isCreatingExam ? "Creating exam..." : "Save and next"}</Button>
                                    <Button disableElevation color="error" onClick={() => navigate( '/admission-exam' )} disabled={isCreatingExam} variant='contained' sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Box>
    )
}

)

export default CreateExamComponent