import axios from "axios"
export const isProd = true
const baseURL = isProd ? 'https://alive-core-api-uz.alive.university/api/v1' : 'http://localhost:8080/api/v1'
const studyMaterialBaseURL = isProd ? 'https://alive-studymaterial-api-uz.alive.university/api' : 'http://localhost:5600/api'
const examBaseURL = isProd ? `https://alive-exam-api-uz.alive.university/api/` : 'http://localhost:5500/api/'
const resumeBuilderUrl = isProd ? "https://resume-api.alive.university/api/" : `http://localhost:5800/api/`
const onlineTutorialUrl = isProd ? "https://alivecourse-api.alive.university/api/" : `http://localhost:5400/api/`


const headers = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem( 'id_token' )
    }
}
//responseType: 'blob'

const headersFormdata = () => {
    return {
        'Accept': 'application/json',
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + localStorage.getItem( 'id_token' )
    }
}

const api = {
    signUp: ( payload ) => {
        return axios.post( baseURL + `/signup`, payload )
    },
    signIn: ( payload ) => {
        return axios.post( baseURL + `/signin`, payload )
    },
    signInERP: ( payload ) => {
        return axios.post( baseURL + `/login/erp`, payload )
    },
    lmsLoginWithToken: ( payload ) => {
        return axios.post( baseURL + `/login/lms`, payload )
    },
    logout: () => {
        return axios.post( baseURL + `/logout`, {}, { headers: headers() } )
    },
    // signInStudyMaterial: ( payload ) => {
    //     return axios.post( `http://localhost:5500/api/user/login`, payload )
    // },
    signInExam: ( payload ) => {
        return axios.post( `${examBaseURL}users/login`, payload )
    },
    getUser: () => {
        return axios.get( baseURL + `/user`, { headers: headers() } )
    },
    fetchProfileImage: ( path ) => {
        return axios.post( baseURL + `/user/profile`, { path }, {
            headers: headers(), responseType: "blob",
        } )
    },
    getUsers: () => {
        return axios.get( baseURL + `/user-accounts`, { headers: headers() } )
    },
    getSubAdminAccounts: () => {
        return axios.get( baseURL + '/subadmin', { headers: headers() } )
    },
    findUserDebugTimetable: ( payload ) => {
        return axios.post( baseURL + `/find-user-timetable`, payload, { headers: headers() } )
    },
    createUserAccount: ( payload ) => {
        return axios.post( baseURL + `/user`, payload, { headers: headers() } )
    },
    createSubAdminAccount: ( payload ) => {
        return axios.post( baseURL + '/subadmin', payload, { headers: headers() } )
    },
    updateUserAccount: ( payload, id ) => {
        return axios.put( baseURL + `/user/${id}`, payload, { headers: headers() } )
    },
    updateSubAdminAccount: ( payload, id ) => {
        return axios.put( baseURL + `/subadmin/${id}`, payload, { headers: headers() } )
    },
    updateUserStatus: ( payload ) => {
        return axios.put( baseURL + `/user-status`, payload, { headers: headers() } )
    },
    deleteSubAdminAccount: ( id ) => {
        return axios.delete( baseURL + `/subadmin/${id}`, { headers: headers() } )
    },
    sendFeedback: ( payload ) => {
        return axios.post( baseURL + `/feedback`, payload, { headers: headers() } )
    },
    // 
    // Batch
    getAllBatchAcademicYear: () => {
        return axios.get( baseURL + `/batch/get-academic-year`, { headers: headers() } )
    },
    getAllBatch: ( ac_year_id ) => {
        return axios.get( baseURL + `/batch?ac_year_id=${ac_year_id}`, { headers: headers() } )
    },
    getAllSubjects: () => {
        return axios.get( `${studyMaterialBaseURL}/user/subjects`, { headers: headers() } )
    },
    // Notes
    uploadNotes: ( payload ) => {
        return axios.post( baseURL + `/notes`, payload, { headers: headersFormdata() } )
    },
    deleteNotes: ( payload ) => {
        return axios.post( baseURL + `/notes/delete`, payload, { headers: headers() } )
    },
    fetchNotesByAuthor: () => {
        return axios.get( baseURL + `/notes/teacher`, { headers: headers() } )
    },
    fetchNotesByStudent: () => {
        return axios.get( baseURL + `/notes/student`, { headers: headers() } )
    },
    downloadNotes: ( url ) => {
        return axios.get( baseURL + `/notes/download?sourceUrl=${url}`, { headers: headers(), responseType: 'blob' } )
    },

    // Study material
    uploadStudyMaterial: ( payload, progressHandler ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material`, payload, { headers: headersFormdata(), onUploadProgress: progressHandler } )
    },
    deleteStudyMaterial: ( id ) => {
        return axios.delete( `${studyMaterialBaseURL}/study-material/${id}`, { headers: headers() } )
    },
    getMaterials: ( institute ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material${institute ? "?institute=" + institute : ""}`, { headers: headers() } )
    },
    getBranchMaterials: ( institute, code ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${institute}/branch/${code}`, { headers: headers() } )
    },
    getInstituteMaterials: ( institute ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/institute/${institute}`, { headers: headers() } )
    },
    getMaterial: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}`, { headers: headers() } )
    },
    updateMaterial: ( id, payload ) => {
        return axios.put( `${studyMaterialBaseURL}/study-material/${id}`, payload, { headers: headers() } )
    },
    downloadMaterial: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}/download`, { headers: headers() } )
    },
    getMaterialBatches: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}/batches`, { headers: headers() } )
    },
    getAttachments: ( id ) => {
        return axios.get( `${studyMaterialBaseURL}/study-material/${id}/attachments`, { headers: headers() } )
    },
    uploadAttachments: ( id, payload, progressHandler ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/${id}/add_attachments`, payload, { headers: headersFormdata(), onUploadProgress: progressHandler } )
    },
    renameAttachment: ( material_id, id, payload ) => {
        return axios.put( `${studyMaterialBaseURL}/study-material/${material_id}/attachment/${id}`, payload, { headers: headers() } )
    },
    deleteAttachment: ( id ) => {
        return axios.delete( `${studyMaterialBaseURL}/study-material/attachment/${id}`, { headers: headers() } )
    },
    downloadAttachment: ( id, payload ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/attachment/${id}/download`, payload, { headers: headers() } )
    },
    grantAccess: ( id, payload ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/${id}/grant`, payload, { headers: headers() } )
    },
    revokeAccess: ( id, accessId, payload ) => {
        return axios.post( `${studyMaterialBaseURL}/study-material/${id}/revoke/${accessId}`, payload, { headers: headers() } )
    },

    // Exams
    fetchExamInstructions: ( params ) => {
        return axios.get( `${examBaseURL}exams/instructions`, { headers: headers(), params } )
    },
    updateExamInstructions: ( payload ) => {
        return axios.post( `${examBaseURL}exams/instructions`, payload, { headers: headers() } )
    },
    getExam: ( id ) => {
        return axios.get( `${examBaseURL}exams/${id}`, { headers: headers() } )
    },
    fetchExamsByDept: ( deptCode ) => {
        return axios.get( `${examBaseURL}exams/dept/${deptCode}`, { headers: headers() } )
    },
    fetchExamsByInstitute: ( institute ) => {
        return axios.get( `${examBaseURL}exams/institute/${institute}`, { headers: headers() } )
    },
    getAllExams: () => {
        return axios.get( `${examBaseURL}exams/`, { headers: headers() } )
    },
    getInstituteExamStats: ( institute ) => {
        return axios.get( `${examBaseURL}exams/institute/${institute}/stats`, { headers: headers() } )
    },
    createExam: ( payload ) => {
        return axios.post( `${examBaseURL}exams/`, payload, { headers: headersFormdata() } )
    },
    updateExam: ( id, payload ) => {
        return axios.put( `${examBaseURL}exams/${id}`, payload, { headers: headersFormdata() } )
    },
    convertDocxToHtml: ( payload ) => {
        return axios.post( `${examBaseURL}exams/convert-doc-to-txt`, payload )
    },
    addQuestion: ( id, payload ) => {
        return axios.post( `${examBaseURL}exams/${id}/addquestion`, payload, { headers: headersFormdata() } )
    },
    updateQuestion: ( id, qid, payload ) => {
        return axios.put( `${examBaseURL}exams/${id}/question/${qid}`, payload, { headers: headersFormdata() } )
    },
    deleteQuestion: ( id, qid ) => {
        return axios.delete( `${examBaseURL}exams/${id}/question/${qid}`, { headers: headers() } )
    },
    fetchQuestions: ( id ) => {
        return axios.get( `${examBaseURL}exams/${id}/questions`, { headers: headers() } )
    },
    getAudioPresignedUrl: ( exam_id, question_id, payload ) => {
        return axios.post( `${examBaseURL}exams/${exam_id}/question/${question_id}/fetch-attachment`, payload, { headers: headers() } )
    },
    publish: ( id ) => {
        return axios.put( `${examBaseURL}exams/publish/${id}`, {}, { headers: headers() } )
    },
    delete: ( id ) => {
        return axios.delete( `${examBaseURL}exams/${id}`, { headers: headers() } )
    },
    attempt: ( id ) => {
        return axios.post( `${examBaseURL}exams/${id}/attempt`, {}, { headers: headers() } )
    },
    startExam: ( id, pid ) => {
        return axios.put( `${examBaseURL}exams/${id}/start/${pid}`, {}, { headers: headers() } )
    },
    finishExam: ( id, pid ) => {
        return axios.put( `${examBaseURL}exams/${id}/participation/${pid}/finish`, {}, { headers: headers() } )
    },
    fetchUserParticipations: () => {
        return axios.get( `${examBaseURL}users/participation`, { headers: headers() } )
    },
    fetchUserParticipation: ( id ) => {
        return axios.get( `${examBaseURL}users/participation/${id}`, { headers: headers() } )
    },
    fetchExamParticipation: ( id ) => {
        return axios.get( `${examBaseURL}exams/${id}/participation`, { headers: headers() } )
    },
    fetchAllParticipations: () => {
        return axios.get( `${examBaseURL}exams/participations/all`, { headers: headers() } )
    },
    fetchParticipationData: ( id, pid ) => {
        return axios.get( `${examBaseURL}exams/${id}/participation/${pid}`, { headers: headers() } )
    },
    fetchParticipationSubmissions: ( id, pid ) => {
        return axios.get( `${examBaseURL}exams/${id}/submissions/${pid}`, { headers: headers() } )
    },
    submitAnswer: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}exams/${id}/submissions/${pid}`, payload, { headers: headers() } )
    },
    submitRemark: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}exams/${id}/participation/${pid}/remark`, payload, { headers: headers() } )
    },
    fetchEvaluationScheme: ( id ) => {
        return axios.get( `${examBaseURL}exams/${id}/evaluationscheme`, { headers: headers() } )
    },

    // assignment
    uploadAssignment: ( payload ) => {
        return axios.post( baseURL + `/assignment`, payload, { headers: headersFormdata() } )
    },
    ///assignment/update
    updateAssignment: ( payload ) => {
        return axios.post( baseURL + `/assignment/update`, payload, { headers: headers() } )
    },
    deleteAssignment: ( payload ) => {
        return axios.post( baseURL + `/assignment/delete`, payload, { headers: headers() } )
    },
    uploadAssignmentSubmission: ( payload ) => {
        return axios.post( baseURL + `/assignment/submission`, payload, { headers: headersFormdata() } )
    },
    deleteAssignmentSubmitted: ( payload ) => {
        return axios.post( baseURL + `/assignment/submission/delete`, payload, { headers: headers() } )
    },
    setAssignmentScore: ( payload ) => {
        return axios.post( baseURL + `/assignment/score`, payload, { headers: headers() } )
    },
    fetchAssignmentSubmission: ( assignment_id ) => {
        return axios.get( baseURL + `/assignment/submission?assignment_id=${assignment_id}`, { headers: headers() } )
    },
    fetchAssignmentByAuthor: () => {
        return axios.get( baseURL + `/assignment/teacher`, { headers: headers() } )
    },
    fetchAssignmentByStudent: () => {
        return axios.get( baseURL + `/assignment/student`, { headers: headers() } )
    },
    downloadAssignment: ( url ) => {
        return axios.get( baseURL + `/assignment/download?sourceUrl=${url}`, { headers: headers(), responseType: 'blob' } )
    },
    //
    //Org
    //https://www.acharyainstitutes.in/index.php?r=acerp-api/institutes
    getAllOrganization: () => {
        return axios.get( "https://www.acharyainstitutes.in/index.php?r=acerp-api/institutes" )
    },
    getAllOrganizationBranches: ( instituteCode ) => {
        return axios.get( studyMaterialBaseURL + `/institute/${instituteCode}/branches` )
    },
    getOrganizationStats: ( instituteCode ) => {
        return axios.get( studyMaterialBaseURL + `/institute/${instituteCode}/stats`, { headers: headers() } )
    },
    // getOrganization: ()=>{
    //     return axios.get(baseURL+`/organization`, { headers : headers() });
    // },
    getOrganization: () => {
        return axios.get( baseURL + `/organization`, { headers: headers() } )
    },
    createOrganization: ( payload ) => {
        return axios.post( baseURL + `/organization`, payload, { headers: headers() } )
    },
    updateOrganization: ( payload ) => {
        return axios.put( baseURL + `/organization`, payload, { headers: headers() } )
    },
    deleteOrganization: ( payload ) => {
        return axios.delete( baseURL + `/organization`, payload, { headers: headers() } )
    },

    // department
    getAllDepartment: () => {
        return axios.get( baseURL + `/all-department` )
    },
    getDepartment: () => {
        return axios.get( baseURL + `/department`, { headers: headers() } )
    },
    createDepartment: ( payload ) => {
        return axios.post( baseURL + `/department`, payload, { headers: headers() } )
    },
    updateDepartment: ( payload ) => {
        return axios.put( baseURL + `/department`, payload, { headers: headers() } )
    },
    deleteDepartment: ( id ) => {
        //console.log(headers)
        return axios.delete( baseURL + `/department/${id}`, { headers: headers() } )
    },
    // class room with erp
    startSession: ( payload ) => {
        return axios.post( baseURL + `/start-session`, payload, { headers: headers() } )
    },
    joinSession: ( payload ) => {
        return axios.post( baseURL + `/join-session`, payload, { headers: headers() } )
    },
    joinSessionAsModerator: ( payload ) => {
        return axios.post( baseURL + `/join-session-moderator`, payload, { headers: headers() } )
    },
    endSession: ( payload ) => {
        return axios.post( baseURL + `/end-session`, payload, { headers: headers() } )
    },
    fetchFeedbackQuestions: () => {
        return axios.get( baseURL + `/session/feedback/questions`, { headers: headers() } )
    },
    submitSessionFeedback: ( id, payload ) => {
        return axios.post( `${baseURL}/session/${id}/feedback`, payload, { headers: headers() } )
    },
    addFeedbackQuestion: ( payload ) => {
        return axios.post( `${baseURL}/session/feedback/question`, payload, { headers: headers() } )
    },
    fetchFeedbacks: ( payload ) => {
        return axios.post( `${baseURL}/feedbacks`, payload, { headers: headers() } )
    },
    updateFeedbackQuestion: ( id, payload ) => {
        return axios.put( `${baseURL}/session/feedback/question/${id}`, payload, { headers: headers() } )
    },
    deleteFeedbackQuestion: ( id ) => {
        return axios.delete( `${baseURL}/session/feedback/question/${id}`, { headers: headers() } )
    },
    getFeedbackSessions: () => {
        return axios.get( `${baseURL}/feedback/sessions`, { headers: headers() } )
    },
    fetchStudentFeedbacks: () => {
        return axios.get( `${baseURL}/student/feedbacks`, { headers: headers() } )
    },
    getMySession: () => {
        return axios.get( baseURL + `/sessions`, { headers: headers() } )
    },
    getAllRecording: ( icode ) => {
        return axios.get( baseURL + `/recording?institute=` + icode, { headers: headers() } )
    },
    getFacultyRecording: () => {
        return axios.get( baseURL + `/recording/faculty`, { headers: headers() } )
    },
    auditSession: ( icode ) => {
        return axios.get( baseURL + `/audit-session?institute_name_short=` + icode, { headers: headers() } )
    },
    auditFeedback: ( payload ) => {
        return axios.post( baseURL + `/audit-feedback-log`, payload, { headers: headers() } )
    },
    ///exam/teacher
    // examFetch: ( payload ) => {
    //     return axios.get( baseURL + `/exam`, { headers: headers() } )
    // },

    // fetchExamSubmission: ( id ) => {
    //     return axios.get( baseURL + `/exam/submission?exam_id=${id}`, { headers: headers() } )
    // },
    // examStudent: ( payload ) => {
    //     return axios.get( baseURL + `/exam/student`, { headers: headers() } )
    // },
    // submitExamAnswers: ( payload ) => {
    //     return axios.post( baseURL + `/exam/submission`, payload, { headers: headersFormdata() } )
    // },
    // assignScore: ( payload ) => {
    //     return axios.post( baseURL + `/exam/score`, payload, { headers: headers() } )
    // },


    // room
    getOrgRoom: ( payload ) => {
        return axios.post( baseURL + `/getrooms`, payload, { headers: headers() } )
    },
    getRoom: () => {
        return axios.get( baseURL + `/room`, { headers: headers() } )
    },
    createRoom: ( payload ) => {
        return axios.post( baseURL + `/room`, payload, { headers: headers() } )
    },
    updateRoom: ( payload ) => {
        return axios.put( baseURL + `/room`, payload, { headers: headers() } )
    },
    deleteRoom: ( api_id ) => {
        return axios.delete( baseURL + `/room/${api_id}`, { headers: headers() } )
    },
    getSessionClassAttendence: ( payload ) => {
        return axios.post( baseURL + `/attentance-session`, payload, { headers: headers() } )
    },
    searchAttendence: ( payload ) => {
        return axios.post( baseURL + `/attendance/search`, payload, { headers: headers() } )
    },
    attendenceAudit: ( payload ) => {
        return axios.post( baseURL + `/attendance/audit`, payload, { headers: headers() } )
    },
    ///attendence/student
    studentAttendence: ( payload ) => {
        return axios.post( baseURL + `/attendance/student`, payload, { headers: headers() } )
    },
    // api settings -->> Start
    createApiProvider: ( payload ) => {
        return axios.post( baseURL + `/provider-api`, payload, { headers: headers() } )
    },
    getApiProviders: () => {
        return axios.get( baseURL + `/provider-api`, { headers: headers() } )
    },
    updateApiProvider: ( payload, api_id ) => {
        return axios.put( baseURL + `/provider-api/${api_id}`, payload, { headers: headers() } )
    },
    deleteApiProvider: ( api_id ) => {
        return axios.delete( baseURL + `/provider-api/${api_id}`, { headers: headers() } )
    },
    // api settings -->> End
    // analytics -->> Start
    getNodes: () => {
        return axios.get( baseURL + `/nodes`, { headers: headers() } )
    },

    getServerStat: () => {
        return axios.get( baseURL + `/serverstat` )
    },

    doLogout: () => {
        localStorage.removeItem( "isLoggedIn" )
        //return false;
    },
    isLoggedIn: () => {
        return localStorage.getItem( "isLoggedIn" )
    },
    createUser: ( payload ) => {
        return axios.post( baseURL + `/user`, payload )
    },
    updateUser: ( payload, id ) => {
        return axios.put( baseURL + `/user/${id}`, payload )
    },
    updatePassword: ( payload ) => {
        return axios.put( baseURL + `/change-password`, payload, { headers: headers() } )
    },
    deleteUser: ( id ) => {
        return axios.delete( baseURL + `/user/${id}` )
    },

    // Institute routes
    getInstituteFacultyCount: ( institute ) => {
        return axios.get( baseURL + `/stats/faculty/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteStudentCount: ( institute ) => {
        return axios.get( baseURL + `/stats/student/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteBranchCount: ( institute ) => {
        return axios.get( baseURL + `/stats/branch/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteBranchUserCount: ( institute ) => {
        return axios.get( baseURL + `/stats/branchuser/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteAssignmentCount: ( institute ) => {
        return axios.get( baseURL + `/stats/assignment/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteStudyMaterialCount: ( institute ) => {
        return axios.get( studyMaterialBaseURL + `/stats/count?institute=${institute}`, { headers: headers() } )
    },
    getInstituteExamCount: ( institute ) => {
        return axios.get( examBaseURL + `exams/stats/count?institute=${institute}`, { headers: headers() } )
    },

    // Online Tutorials
    addOnlineCourse: ( payload ) => {
        return axios.post( `${onlineTutorialUrl}online-course`, payload, { headers: headersFormdata() } )
    },
    updateCourse: ( course_id, payload ) => {
        return axios.put( `${onlineTutorialUrl}online-course/${course_id}`, payload, { headers: headersFormdata() } )
    },
    addNewModule: ( course_id, payload ) => {
        return axios.post( `${onlineTutorialUrl}online-course/${course_id}/module`, payload, { headers: headers() } )
    },
    enroll: ( course_id, payload ) => {
        return axios.post( `${onlineTutorialUrl}onine-course/${course_id}/enroll`, payload, { headers: headers() } )
    },
    getEnrolledCourses: () => {
        return axios.get( `${onlineTutorialUrl}onine-course/enrolled`, { headers: headers() } )
    },
    getEnrolledCoursesProgress: () => {
        return axios.get( `${onlineTutorialUrl}progress/enrolled`, { headers: headers() } )
    },
    getCourseProgressData: ( progress_id ) => {
        return axios.get( `${onlineTutorialUrl}progress/${progress_id}`, { headers: headers() } )
    },
    getCourseEnrollmentStatus: ( course_id ) => {
        return axios.get( `${onlineTutorialUrl}onine-course/${course_id}/enroll`, { headers: headers() } )
    },
    updateModuleName: ( module_id, payload ) => {
        return axios.put( `${onlineTutorialUrl}module/${module_id}`, payload, { headers: headers() } )
    },
    addAttachment: ( module_id, payload, progressHandler ) => {
        return axios.post( `${onlineTutorialUrl}module/${module_id}`, payload, { headers: headersFormdata(), onDownloadProgress: progressHandler } )
    },
    getCourseAttachment: ( id ) => {
        return axios.get( `${onlineTutorialUrl}attachment/${id}`, { headers: headersFormdata() } )
    },
    reorderCourseModules: ( newOrder ) => {
        return axios.put( `${onlineTutorialUrl}online-course/modules`, { newOrder }, { headers: headers() } )
    },
    deleteCourseAttachment: ( id ) => {
        return axios.delete( `${onlineTutorialUrl}attachment/${id}`, { headers: headers() } )
    },
    getCourses: () => {
        return axios.get( `${onlineTutorialUrl}online-course`, { headers: headers() } )
    },
    getCourse: ( id ) => {
        return axios.get( `${onlineTutorialUrl}online-course/${id}`, { headers: headers() } )
    },
    startCourse: ( id ) => {
        return axios.put( `${onlineTutorialUrl}progress/${id}/start`, { progress_id: id }, { headers: headers() } )
    },
    startAttachment: ( id, payload ) => {
        return axios.post( `${onlineTutorialUrl}progress/${id}`, payload, { headers: headers() } )
    },
    updateProgressDetail: ( id, payload ) => {
        return axios.put( `${onlineTutorialUrl}progress/update/${id}`, payload, { headers: headers() } )
    },
    deleteModule: ( id ) => {
        return axios.delete( `${onlineTutorialUrl}module/${id}`, { headers: headers() } )
    },
    publishCourse: ( id ) => {
        return axios.put( `${onlineTutorialUrl}online-course/${id}/publish`, {}, { headers: headers() } )
    },
    updateAttachmentName: ( id, payload ) => {
        return axios.put( `${onlineTutorialUrl}attachment/${id}`, payload, { headers: headers() } )
    },
    deleteCourse: ( id ) => {
        return axios.delete( `${onlineTutorialUrl}online-course/${id}`, { headers: headers() } )
    },

    // Resume Builder
    createResumeForm: ( payload ) => {
        return axios.post( `${resumeBuilderUrl}forms`, payload, { headers: headers() } )
    },
    fetchResumeForms: () => {
        return axios.get( `${resumeBuilderUrl}forms`, { headers: headers() } )
    },
    fetchResumeForm: ( id ) => {
        return axios.get( `${resumeBuilderUrl}forms/${id}`, { headers: headers() } )
    },
    fetchInstituteFormStatus: ( institute ) => {
        return axios.get( `${resumeBuilderUrl}forms/institute_status?institute=${institute}`, { headers: headers() } )
    },
    fetchStudentForm: () => {
        return axios.get( `${resumeBuilderUrl}forms/student-form`, { headers: headers() } )
    },
    deleteResumeForm: ( id ) => {
        return axios.delete( `${resumeBuilderUrl}forms/${id}`, { headers: headers() } )
    },
    updateResumeForm: ( id, payload ) => {
        return axios.put( `${resumeBuilderUrl}forms/${id}`, payload, { headers: headers() } )
    },
    updateResumeFormJson: ( id, payload ) => {
        return axios.put( `${resumeBuilderUrl}forms/${id}/fields`, payload, { headers: headers() } )
    },
    publishResumeForm: ( id ) => {
        return axios.put( `${resumeBuilderUrl}forms/${id}/publish`, { id: id }, { headers: headers() } )
    },
    createResumeEntity: ( payload ) => {
        return axios.post( `${resumeBuilderUrl}entity`, payload, { headers: headers() } )
    },
    fetchResumeEntities: () => {
        return axios.get( `${resumeBuilderUrl}entities`, { headers: headers() } )
    },
    fetchResumeEntity: ( id ) => {
        return axios.get( `${resumeBuilderUrl}entity/${id}`, { headers: headers() } )
    },
    deleteResumeEntity: ( id ) => {
        return axios.delete( `${resumeBuilderUrl}entity/${id}`, { headers: headers() } )
    },
    updateResumeEntity: ( payload ) => {
        return axios.put( `${resumeBuilderUrl}entity/${payload.id}`, payload, { headers: headers() } )
    },
    fetchStudentResumeInfo: () => {
        return axios.get( `${resumeBuilderUrl}student-info`, { headers: headers() } )
    },
    uploadStudentProfileToResume: ( payload ) => {
        return axios.post( `${resumeBuilderUrl}student-info/profile-image`, payload, { headers: headersFormdata() } )
    },
    updateStudentResumeInfo: ( payload ) => {
        return axios.post( `${resumeBuilderUrl}student-info`, payload, { headers: headers() } )
    },
    createTemplate: ( payload ) => {
        return axios.post( `${resumeBuilderUrl}templates`, payload, { headers: headers() } )
    },
    getTemplates: ( institute ) => {
        if ( institute )
            return axios.get( `${resumeBuilderUrl}templates`, { headers: headers() } )
        else
            return axios.get( `${resumeBuilderUrl}templates?institute=${institute}`, { headers: headers() } )
    },
    deleteResumeTemplate: ( id ) => {
        return axios.delete( `${resumeBuilderUrl}templates/${id}`, { headers: headers() } )
    },
    updateTemplate: ( id, payload ) => {
        return axios.put( `${resumeBuilderUrl}templates/${id}`, payload, { headers: headers() } )
    },
    getTemplate: ( id ) => {
        return axios.get( `${resumeBuilderUrl}templates/${id}`, { headers: headers() } )
    }
}

api.admissionExams = {
    getUser: () => {
        return axios.get( `${examBaseURL}admission-exams/user`, { headers: headers() } )
    },
    convertDocxToHtml: ( payload ) => {
        return axios.post( `${examBaseURL}exams/convert-doc-to-txt`, payload, { headers: headersFormdata() } )
    },
    createExam: ( payload ) => {
        return axios.post( `${examBaseURL}admission-exams/`, payload, { headers: headersFormdata() } )
    },
    updateAdmissionExam: ( id, payload ) => {
        return axios.put( `${examBaseURL}admission-exams/${id}`, payload, { headers: headers() } )
    },
    getAllExams: ( params ) => {
        return axios.get( `${examBaseURL}admission-exams/`, { headers: headers(), params } )
    },
    getExam: ( id ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}`, { headers: headers() } )
    },
    getQuestionFile: ( id, fileId, responseType ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/questionfiles`, { headers: headers(), params: { fileId }, responseType } )
    },
    updateOptionText: ( exam_id, option_id, payload ) => {
        return axios.put( `${examBaseURL}admission-exams/${exam_id}/option/${option_id}`, payload, { headers: headers() } )
    },
    updateQuestion: ( exam_id, question_id, payload ) => {
        return axios.put( `${examBaseURL}admission-exams/${exam_id}/question/${question_id}`, payload, { headers: headers() } )
    },
    deleteExam: ( id ) => {
        return axios.delete( `${examBaseURL}admission-exams/${id}`, { headers: headers(), } )
    },
    fetchExamParticipation: ( id ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/participation`, { headers: headers() } )
    },
    approveExam: ( id, payload ) => {
        return axios.post( `${examBaseURL}admission-exams/${id}/approve`, payload, { headers: headers() } )
    },
    fetchParticipationData: ( id, pid ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/participation/${pid}`, { headers: headers() } )
    },
    attempt: ( id ) => {
        return axios.post( `${examBaseURL}admission-exams/${id}/attempt`, {}, { headers: headers() } )
    },
    fetchQuestions: ( id ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/questions`, { headers: headers() } )
    },
    fetchParticipationSubmissions: ( id, pid ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/submissions/${pid}`, { headers: headers() } )
    },
    fetchEvaluationScheme: ( id ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/evaluationscheme`, { headers: headers() } )
    },
    fetchQuestionsForAttempt: ( id, pid ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/questions/attempt/${pid}`, { headers: headers() } )
    },
    registerExamAttemptFault: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}admission-exams/${id}/participation/${pid}/registerfault`, payload || {}, { headers: headers() } )
    },
    finishExam: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}admission-exams/${id}/participation/${pid}/finish`, payload || {}, { headers: headers() } )
    },
    startExam: ( id, pid, payload ) => {
        return axios.put( `${examBaseURL}admission-exams/${id}/start/${pid}`, payload || {}, { headers: headers() } )
    },
    submitAnswer: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}admission-exams/${id}/submissions/${pid}`, payload, { headers: headers() } )
    },
    verifyCandidate: ( payload ) => {
        return axios.post( `${examBaseURL}admission-exams/verify-candidate`, payload, { headers: headers() } )
    },
    fetchParticipationQuestions: ( id, pid ) => {
        return axios.get( `${examBaseURL}admission-exams/${id}/participation/${pid}/questions`, { headers: headers() } )
    },
    assignMarksToDescriptiveAnswer: ( id, pid, payload ) => {
        return axios.post( `${examBaseURL}exams/${id}/submission/${pid}/assignmarks`, payload, { headers: headers() } )
    },

}

export default api