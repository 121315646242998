import { Box, CircularProgress, Fab, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TemplateBuilderSideDraw from './TemplateBuilderSideDraw'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { Link, useNavigate, useParams } from 'react-router-dom'
import TemplateSectionBox from './TemplateSectionBox'
import { observer } from 'mobx-react'
import { useResumeTemplateEditStore } from '../../../store/resumetemplateEdit.store'
import { Icons } from '../../../utils/utilities'

const ResumeTemplateBuilder = observer( () => {
    const [selectedSection, setSelectedSection] = useState( null )
    const [sectionList, setSectionList] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [tab, setTab] = useState( "Home" )
    const [isDragging, setisDragging] = useState( false )
    const [newSectionPadding, setNewSectionPadding] = useState( { x: 5, y: 5 } )
    const [newSectionGap, setNewSectionGap] = useState( 5 )
    const [newSectionDataGap, setNewSectionDataGap] = useState( 10 )

    const ResumeBuilderStore = useResumeBuilderStore()
    const TemplateStore = useResumeTemplateEditStore()
    const navigate = useNavigate()
    const { template_id } = useParams()

    const handleDropOnColumn = ( e, row, column ) => {
        e.target.classList.remove( 'dragging-over' )
        const { sectionIndex } = JSON.parse( e.dataTransfer.getData( "text/plain" ) )
        const newTemplate = { ...TemplateStore.getTemplate }
        newTemplate.rows[row].columns[column].push( { ...sectionList[sectionIndex], display: "flex", alignItems: "flex-start", flexDirection: "column", gap: 5, show_section_title: true, padding: { x: 5, y: 5 } } )
        TemplateStore.updateTemplate( newTemplate )
    }

    const handleDropEnterColumn = ( e ) => {
        e.preventDefault()
        e.target.classList.add( 'dragging-over' )
    }

    const handleDropLeaveColumn = ( e ) => {
        e.target.classList.remove( 'dragging-over' )
    }

    const deleteRow = ( rowIndex ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        newTemplate.rows.splice( rowIndex, 1 )
        TemplateStore.updateTemplate( newTemplate )
    }

    const selectRowForSettings = ( rowIndex ) => {
        setTab( "Settings" )
        TemplateStore.setSelectedRowIndex( rowIndex )
    }

    const moveRowUp = ( rowIndex ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        const temp = newTemplate.rows[rowIndex]
        newTemplate.rows[rowIndex] = newTemplate.rows[rowIndex - 1]
        newTemplate.rows[rowIndex - 1] = temp
        TemplateStore.updateTemplate( newTemplate )
    }

    const moveRowDown = ( rowIndex ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        const temp = newTemplate.rows[rowIndex]
        newTemplate.rows[rowIndex] = newTemplate.rows[rowIndex + 1]
        newTemplate.rows[rowIndex + 1] = temp
        TemplateStore.updateTemplate( newTemplate )
    }

    const moveSectionUp = ( row, column, index ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        const rowColumn = newTemplate.rows[row].columns[column]
        const temp = rowColumn[index]
        rowColumn[index] = rowColumn[index - 1]
        rowColumn[index - 1] = temp
        TemplateStore.updateTemplate( newTemplate )
    }

    const moveSectionDown = ( row, column, index ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        const rowColumn = newTemplate.rows[row].columns[column]
        const temp = rowColumn[index]
        rowColumn[index] = rowColumn[index + 1]
        rowColumn[index + 1] = temp
        TemplateStore.updateTemplate( newTemplate )
    }

    const saveTemplate = () => {
        TemplateStore.updateTemplate( { ...TemplateStore.getTemplate } )
    }


    useEffect( () => {
        const getData = async () => {
            if ( await TemplateStore.fetchTemplate( template_id ) ) {
                if ( await ResumeBuilderStore.fetchForm( TemplateStore.getTemplateData.form_id ) ) {
                    if ( ResumeBuilderStore.getForm.fields ) {
                        setSectionList( ResumeBuilderStore.getForm.fields )
                    }
                }
                if ( TemplateStore.getTemplateData.template_json ) {
                    TemplateStore.setTemplate( TemplateStore.getTemplateData.template_json )
                } else {
                    TemplateStore.setTemplate( { padding: 20, fontSize: 16, rows: [], null: true } )
                }
            } else {
                navigate( `/admin/resume-templates` )
            }
            setLoading( false )
        }
        getData()
        // eslint-disable-next-line
    }, [ResumeBuilderStore, template_id] )

    return (
        <Box position="relative" display="flex" flexGrow={1} width="100%" maxHeight="calc(100% - 2px)" flexDirection="column">
            {!loading && TemplateStore.getTemplate && <Box display="flex" height="100%" flexDirection="column" gap="10px">
                <Typography color="textSecondary" fontWeight={500} display="flex" alignItems="center" fontSize="14px"> <Link className='breadcrumb-link' to="/admin/resume-templates">Resume templates</Link> {Icons.SmallChevronRightIcon} {TemplateStore.getTemplateData.name} </Typography>
                <Box alignItems="stretch" height="calc(100% - 50px)" flexGrow={1} display="flex" gap="10px">
                    <TemplateBuilderSideDraw newSectionDataGap={newSectionDataGap} setNewSectionDataGap={setNewSectionDataGap} newSectionPadding={newSectionPadding} setNewSectionPadding={setNewSectionPadding} newSectionGap={newSectionGap} setNewSectionGap={setNewSectionGap} isDragging={isDragging} setisDragging={setisDragging} tab={tab} setTab={setTab} selectedSection={selectedSection} setSelectedSection={setSelectedSection} sectionList={sectionList} />
                    <Box className='no-scrollbar' display="flex" overflow="auto" borderRadius="5px" padding="2px 0" flexGrow={1} maxHeight="calc(100% - 1px)">
                        <Box borderRadius="5px" flexGrow={1} overflow="visible">
                            <Paper id="template-canvas"
                                sx={{
                                    margin: "0 auto", marginTop: "20px", flexGrow: 1, width: "850px", minHeight: "1183px", position: "relative",
                                }}
                            >
                                <Box position="absolute" bottom="calc(100% + 5px)" left="0" right="0" display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography fontWeight="600" fontSize="10px">Template {TemplateStore.getTemplateData.name}</Typography>
                                    <Box sx={{ cursor: "pointer" }} onClick={saveTemplate} display="flex" gap="10px">
                                        <Typography fontWeight="600" fontSize="10px" display="flex" alignItems="center"> {TemplateStore.getSavingDetails.status === "Saving" && <CircularProgress size={10} />} {TemplateStore.getSavingDetails.status}</Typography>
                                        {TemplateStore.getSavingDetails.lastSaved && <Typography fontWeight="600" color="textSecondary" fontSize="10px" display="flex" alignItems="center">  {new Date( TemplateStore.getSavingDetails.lastSaved ).toLocaleDateString( 'en-IN', { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" } )}</Typography>}
                                    </Box>
                                </Box>
                                <Box sx={{ zIndex: "1" }} position="absolute" top="0" left="0" right="0" bottom="0"></Box>
                                <Box position="relative" sx={{ zIndex: "2", cursor: "default" }} padding={`${TemplateStore.getTemplate.padding}px`} display="flex" flexDirection="column" gap="20px">
                                    {TemplateStore.getTemplate.rows.map( ( row, rowIndex ) => (
                                        <Box
                                            sx={{ "&:hover .display-on-hover": { display: "block" } }}
                                            minHeight="100px"
                                            position="relative"
                                            key={rowIndex}
                                            display="grid"
                                            alignItems={row.align ? row.align : "initial"}
                                            gridTemplateColumns={row.columnRatio}
                                            gap={`${row.gap}px`}
                                            border={TemplateStore.getPreviewMode ? "none" : rowIndex === TemplateStore.getSelectedRowIndex ? "1px solid #0007ff" : "none"}
                                        >
                                            {row.columns.map( ( column, columnIndex ) => (
                                                <Box
                                                    sx={{ pointerEvents: TemplateStore.getPreviewMode ? "none" : "all", useSelect: "none" }}
                                                    display="flex"
                                                    flexDirection="column"
                                                    key={columnIndex}
                                                    border={TemplateStore.getPreviewMode ? "none" : "2px solid #d3d3d3"}
                                                    borderRadius="5px"
                                                    onDrop={( e ) => handleDropOnColumn( e, rowIndex, columnIndex )}
                                                    onDragEnter={( e ) => handleDropEnterColumn( e, rowIndex, columnIndex )}
                                                    onDragLeave={( e ) => handleDropLeaveColumn( e, rowIndex, columnIndex )}
                                                    onDragOver={( e ) => { e.preventDefault() }}
                                                    className=''
                                                >
                                                    {column.map( ( section, sectionIndex ) => (
                                                        <TemplateSectionBox moveSectionDown={moveSectionDown} moveSectionUp={moveSectionUp} newSectionDataGap={newSectionDataGap} columnLength={column.length} setNewSectionDataGap={setNewSectionDataGap} newSectionGap={newSectionGap} setNewSectionGap={setNewSectionGap} newSectionPadding={newSectionPadding} setNewSectionPadding={setNewSectionPadding} setTab={setTab} rowIndex={rowIndex} columnIndex={columnIndex} sectionIndex={sectionIndex} section={section} key={sectionIndex} />
                                                    ) )}
                                                    {!TemplateStore.getPreviewMode && <Box display="flex" flexDirection="column" alignItems="center" padding="20px" onClick={() => console.log( "Clicked column" )}>
                                                        <Typography textAlign="center" variant="subtitle2" color="GrayText" flexGrow={1} display="flex" alignItems="center" justifyContent="center">Drag and drop sections into columns</Typography>
                                                    </Box>}
                                                </Box>
                                            ) )}
                                            {!TemplateStore.getPreviewMode && <Box height="100%" className='display-on-hover' sx={{ textTransform: "capitalize", position: "absolute", zIndex: "1000", top: "0", right: "100%", display: "none" }}>
                                                <Tooltip placement='left' title="Row settings">
                                                    <Fab onClick={() => selectRowForSettings( rowIndex )} sx={{ transform: "scale(0.6)" }} size='small' color="secondary">{Icons.Settings}</Fab>
                                                </Tooltip>
                                                {rowIndex !== 0 && <Tooltip placement='left' title="Move this row up">
                                                    <Fab onClick={() => moveRowUp( rowIndex )} sx={{ transform: "scale(0.6)" }} size='small'>{Icons.ArrowUpward}</Fab>
                                                </Tooltip>}
                                                {rowIndex !== TemplateStore.getTemplate.rows.length - 1 && <Tooltip placement='left' title="Move this row down">
                                                    <Fab onClick={() => moveRowDown( rowIndex )} sx={{ transform: "scale(0.6)" }} size='small'>{Icons.ArrowDownward}</Fab>
                                                </Tooltip>}
                                                <Tooltip placement='left' title="Remove this row">
                                                    <Fab onClick={() => deleteRow( rowIndex )} sx={{ transform: "scale(0.6)" }} size='small' color="error">{Icons.RemoveIcon}</Fab>
                                                </Tooltip>
                                            </Box>}
                                        </Box>
                                    ) )}
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </Box>
            </Box>}
        </Box>
    )
} )

export default ResumeTemplateBuilder

