import { useEffect, useState } from 'react'
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, IconButton, Tooltip, Select, MenuItem, Paper, useTheme } from '@mui/material'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import { toast } from 'react-toastify'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../service/api'
import { Icons } from '../../utils/utilities'
import { useLayoutContext } from '../../contexts/layout.context'

const columns = [{ name: "Sl.no", paddingLeft: "20px", maxWidth: "20px", minWidth: "40px", align: "left" },
{ name: "Exam name", minWidth: '150px', align: "left" },
{ name: "Exam date", maxWidth: "50px", minWidth: '120px', align: "left" },
{ name: "Participations", minWidth: '100px', align: "left" },
{ name: "", position: "sticky", right: "0" },
]

const Participation = () => {

    const [data, setData] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [filteredData, setFilteredData] = useState( null )
    const [selectedExam, setSelectedExam] = useState( 0 )
    const [examList, setExamList] = useState( null )
    const { setCurrentLoc } = useLayoutContext()

    const navigate = useNavigate()
    const { palette } = useTheme()

    const handleExamChange = ( e ) => {
        setSelectedExam( e.target.value )
        let filteredExams
        if ( parseInt( e.target.value ) !== 0 )
            filteredExams = data.filter( p => p.exam_id === e.target.value )
        else filteredExams = data
        setFilteredData( filteredExams )
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const res = await api.fetchAllParticipations()
                let exams = []
                for ( let i = 0; i < res.data.length; i++ ) {
                    exams.push( { exam_id: res.data[i].exam_id, exam: res.data[i].exam_name } )
                }
                setExamList( exams )
                setData( res.data )
                setFilteredData( res.data )
                setInProgress( false )
            } catch ( e ) {
                toast.error( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! try agin later" )
            }
        }
        getData()
    }, [] )

    return (
        <Box sx={{
            padding: "20px",
            paddingTop: "0",
        }}>
            <Typography fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to="/faculty/exams" onClick={() => setCurrentLoc( '/faculty/exams' )} className="breadcrumb-link">Exams</Link>  {Icons.SmallChevronRightIcon} Participations </Typography>
            <Paper sx={{ marginTop: "10px" }}>
                <Box borderBottom="1px solid #d3d3d3" display="flex" gap="20px" padding="20px" flexDirection={{ sm: "row", xs: "column", alignItems: { sm: "center", xs: "flex-start" } }}>
                    <Box flexGrow="1">
                        <Typography variant='h5'>Participations</Typography>
                        <Typography variant='body2' color="textSecondary">Check the participations</Typography>
                    </Box>
                    <Select size='small' sx={{ fontSize: "14px" }} onChange={handleExamChange} value={selectedExam}>
                        <MenuItem sx={{ fontSize: "14px" }} value={0}>All exams</MenuItem>
                        {examList && examList.map( exam => (
                            <MenuItem sx={{ fontSize: "14px" }} key={exam.exam_id} value={exam.exam_id}>{exam.exam}</MenuItem>
                        ) )}
                    </Select>
                </Box>
                {!inProgress && data && data.length > 0 && < TableContainer sx={{ marginTop: "20px", bgcolor: "white", maxHeight: "450px", "&::-webkit-scrollbar": { width: "10px !important" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": { background: "#888" } }} >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map( ( column, index ) => (
                                    <TableCell key={index} sx={{ paddingLeft: column.paddingLeft, background: palette.primary.main, color: "white", maxWidth: column.maxWidth, minWidth: column.minWidth, textAlign: column.align, zIndex: "3", position: column.position, right: column.right }}>{column.name}</TableCell>
                                ) )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map( ( participation, index ) => (
                                <TableRow key={participation.exam_id} sx={{ "&:nth-of-type(even)": { background: "#eee" } }}>
                                    <TableCell sx={{ padding: "0 20px", fontSize: "12px", maxWidth: "20px" }}>{index + 1}</TableCell>
                                    <TableCell sx={{ padding: "0 20px" }}>
                                        <Typography fontSize="14px" fontWeight="bold">{participation.exam_name}</Typography>
                                    </TableCell>
                                    <TableCell align='left' sx={{ padding: "0 20px", fontSize: "12px" }}>{formatDateTimeForDisplay( participation.exam_start_date, participation.exam_start_time )}</TableCell>
                                    <TableCell align='left'>{participation.total_participation}</TableCell>
                                    <TableCell sx={{ position: "sticky", right: 0, zIndex: 2, background: index % 2 !== 0 ? "#eee" : "#fff", width: "54px", padding: "0", "@media(max-width:530px)": { boxShadow: "0px 5px 5px 3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" } }}>
                                        <Box width="fit-content" height="100%" padding="10px">
                                            <Tooltip title="View Details" placement='top'>
                                                <IconButton onClick={() => navigate( `/faculty/exams/${participation.exam_id}/participation` )} size='small'>
                                                    <OpenInNewIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) )}
                        </TableBody>
                    </Table>
                </TableContainer>}
                {( !data || data.length === 0 ) && !inProgress && <Typography padding="20px" borderRadius="5px" color="textSecondary" variant='h6'>No participations yet!</Typography>}
                {inProgress && <Typography marginTop="20px" padding="20px" variant='h6'> <CircularProgress size={20} sx={{ marginRight: "10px" }} />Loading... </Typography>}
            </Paper>
        </Box>
    )
}

export default Participation
