import { Box, Button, Paper, Typography } from '@mui/material'
import thankYouImg from '../../assets/thank-you-exam-completion.svg'
import React from 'react'
import { erpResultsPageLink } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'

const ThankYouPage = () => {

    const UserStore = useUserStore()

    const goBack = () => {
        window.location = `${erpResultsPageLink}?ref_no=${encodeURIComponent( localStorage.getItem( 'admission_exam_candidate_ref_no' ) || UserStore?.getUser?.application_id )}`
    }

    return (
        <Box sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" height="100%" flexGrow={1} alignItems="center" justifyContent="center">
            <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                <img width="250px" src={thankYouImg} alt="Alive" />
                <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px">Muvaffaqiyatli yakunlandi!</Typography>
                <Button variant='contained' disableElevation sx={{ textTransform: "capitalize" }} color='secondary' onClick={goBack} >Natijangizni tekshiring</Button>
            </Paper>
        </Box>
    )
}

export default ThankYouPage
