import { Box, CircularProgress, Typography, TextField, Button, FormControl, Select, MenuItem, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, TablePagination, InputLabel, Tab, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate, useSearchParams } from 'react-router-dom'
import fileLogo from '../../assets/no-material.svg'
import selectInstituteImg from '../../assets/selectinstitute.svg'
import { useUserStore } from '../../store/user.store'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import { Icons } from '../../utils/utilities'
import { useOrganizationStore } from '../../store/organization.store'
import { observer } from 'mobx-react'
import { TabContext, TabList } from '@mui/lab'

const columns = [
    { name: "Material Name", align: "left", minWidth: "200px" },
    { name: "Created By", align: "left", minWidth: "200px" },
    { name: "Subject-Code", align: "right", minWidth: "200px" },
    { name: "Document count", align: "right", minWidth: "200px" }
]

const Materials = observer( () => {

    const [materials, setMaterials] = useState( [] )
    const [docsCount, setDocsCount] = useState( {} )
    const [filteredMaterials, setFilteredMaterials] = useState( [] )
    const [isLoading, setIsLoading] = useState( true )
    const [rowsPerPage, setRowsPerPage] = useState( 10 )
    const [page, setPage] = useState( 0 )
    const [isInstitutesLoading, setIsInstitutesLoading] = useState( false )

    const [searchParams, setSearchParams] = useSearchParams( { mode: "manage", studyMaterialAuditInstitute: "0", searchText: "", institute: "0" } )
    const mode = searchParams.get( 'mode' )
    const institute = searchParams.get( 'institute' )
    const auditInstituteCode = searchParams.get( 'studyMaterialAuditInstitute' )
    const searchText = searchParams.get( 'searchText' )

    const UserStore = useUserStore()
    const OrganizationStore = useOrganizationStore()
    const { palette } = useTheme()

    const navigate = useNavigate()
    const StudyMaterialStore = useStudyMaterialStore()

    const searchMaterials = ( e ) => {
        setSearchParams( prev => {
            prev.set( "searchText", e.target.value )
            return prev
        }, { replace: true } )
        const filtered = materials.filter( material => ( material.subject_name.toLowerCase().includes( ( e.target.value ).toLowerCase() ) || material.study_material_name.toLowerCase().includes( ( e.target.value ).toLowerCase() ) || material.subject_code.toLowerCase().includes( ( e.target.value ).toLowerCase() ) ) )
        setFilteredMaterials( filtered )
    }


    const handleAuditInstituteChange = async ( event ) => {
        setSearchParams( prev => {
            prev.set( "studyMaterialAuditInstitute", event.target.value )
            return prev
        }, { replace: true } )
        setIsLoading( true )
        try {
            await StudyMaterialStore.fetchInstituteMaterials( event.target.value )
            setMaterials( StudyMaterialStore.getMaterials["materials"] )
            setFilteredMaterials( StudyMaterialStore.getMaterials["materials"] )
            const countsData = StudyMaterialStore.getMaterials["docsCount"]
            const counts = {}
            countsData.forEach( count => {
                counts[count.study_material_id] = count.docs_count
            } )
            setDocsCount( counts )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsLoading( false )
        }
        setIsLoading( false )
    }

    const handleInstituteChange = async ( e ) => {
        setIsLoading( true )
        await StudyMaterialStore.fetchMaterials( e.target.value )
        setMaterials( StudyMaterialStore.getMaterials["materials"] )
        setFilteredMaterials( StudyMaterialStore.getMaterials["materials"] )
        const countsData = StudyMaterialStore.getMaterials["docsCount"]
        const counts = {}
        countsData.forEach( count => {
            counts[count.study_material_id] = count.docs_count
        } )
        setDocsCount( counts )
        setIsLoading( false )
    }

    const getYourMaterials = async () => {
        setIsLoading( true )
        setSearchParams( prev => {
            prev.set( "mode", "manage" )
            return prev
        }, { replace: true } )
        setFilteredMaterials( [] )
        setMaterials( [] )
        setDocsCount( {} )
        setSearchParams( prev => {
            prev.set( "studyMaterialAuditInstitute", "0" )
            return prev
        }, { replace: true } )
        await StudyMaterialStore.fetchMaterials()
        setMaterials( StudyMaterialStore.getMaterials["materials"] )
        setFilteredMaterials( StudyMaterialStore.getMaterials["materials"] )
        const countsData = StudyMaterialStore.getMaterials["docsCount"]
        const counts = {}
        countsData.forEach( count => {
            counts[count.study_material_id] = count.docs_count
        } )
        setDocsCount( counts )
        setIsLoading( false )
    }

    const getAuditData = async () => {
        setSearchParams( prev => {
            prev.set( "mode", "audit" )
            return prev
        }, { replace: true } )
        setIsLoading( true )
        setFilteredMaterials( [] )
        setMaterials( [] )
        setDocsCount( {} )
        if ( UserStore.getUser.user_auditor ) {
            setIsInstitutesLoading( true )
            await OrganizationStore.fetchOrganization()
            setIsInstitutesLoading( false )
        }
        else if ( UserStore.getUser.user_role === 'PRINCIPAL' ) {
            await StudyMaterialStore.fetchInstituteMaterials( UserStore.getUser.user_org_code )
            setMaterials( StudyMaterialStore.getMaterials["materials"] )
            setFilteredMaterials( StudyMaterialStore.getMaterials["materials"] )
            const countsData = StudyMaterialStore.getMaterials["docsCount"]
            const counts = {}
            countsData.forEach( count => {
                counts[count.study_material_id] = count.docs_count
            } )
            setDocsCount( counts )
            console.log( StudyMaterialStore.getMaterials )
        }
        setIsLoading( false )
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleTabChange = async ( e, newValue ) => {
        setSearchParams( prev => {
            prev.set( "mode", newValue )
            return prev
        }, { replace: true } )
        setFilteredMaterials( [] )
        if ( newValue === "audit" ) {
            await getAuditData()
        } else {
            await getYourMaterials()
        }
        setPage( 0 )
        setSearchParams( prev => {
            prev.set( "searchText", "" )
            return prev
        }, { replace: true } )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    useEffect( () => {
        const getData = async () => {
            localStorage.setItem( "studyMaterialMode", "manage" )
            if ( ( UserStore.getUser.user_role === 'FACULTY' || UserStore.getUser.user_role === 'HOD' || UserStore.getUser.user_role === 'PRINCIPAL' ) ) {
                if ( mode === "manage" ) {
                    await StudyMaterialStore.fetchMaterials()
                    setMaterials( StudyMaterialStore.getMaterials["materials"] )
                    setFilteredMaterials( StudyMaterialStore.getMaterials["materials"].filter( material => ( material.subject_name.toLowerCase().includes( ( searchText ).toLowerCase() ) || material.study_material_name.toLowerCase().includes( ( searchText ).toLowerCase() ) || material.subject_code.toLowerCase().includes( ( searchText ).toLowerCase() ) ) ) )
                    const countsData = StudyMaterialStore.getMaterials["docsCount"]
                    const counts = {}
                    countsData.forEach( count => {
                        counts[count.study_material_id] = count.docs_count
                    } )
                    setDocsCount( counts )
                } else {
                    if ( UserStore.getUser.user_auditor ) {
                        await OrganizationStore.fetchOrganization()
                    }
                    else if ( UserStore.getUser.user_role === 'PRINCIPAL' ) {
                        await StudyMaterialStore.fetchInstituteMaterials( UserStore.getUser.user_org_code )
                    } else {
                        setSearchParams( prev => {
                            prev.set( "mode", "manage" )
                            return prev
                        }, { replace: true } )
                        localStorage.setItem( "studyMaterialMode", "manage" )
                        await StudyMaterialStore.fetchMaterials()
                    }
                    setMaterials( StudyMaterialStore.getMaterials["materials"] )
                    setFilteredMaterials( StudyMaterialStore.getMaterials["materials"].filter( material => ( material.subject_name.toLowerCase().includes( ( searchText ).toLowerCase() ) || material.study_material_name.toLowerCase().includes( ( searchText ).toLowerCase() ) || material.subject_code.toLowerCase().includes( ( searchText ).toLowerCase() ) ) ) )
                    const countsData = StudyMaterialStore.getMaterials["docsCount"]
                    const counts = {}
                    countsData.forEach( count => {
                        counts[count.study_material_id] = count.docs_count
                    } )
                    setDocsCount( counts )
                }
            } else {
                setIsInstitutesLoading( true )
                await OrganizationStore.fetchOrganization()
                setIsInstitutesLoading( false )
                await StudyMaterialStore.fetchMaterials()
                setMaterials( StudyMaterialStore.getMaterials["materials"] )
                setFilteredMaterials( StudyMaterialStore.getMaterials["materials"].filter( material => ( material.subject_name.toLowerCase().includes( ( searchText ).toLowerCase() ) || material.study_material_name.toLowerCase().includes( ( searchText ).toLowerCase() ) || material.subject_code.toLowerCase().includes( ( searchText ).toLowerCase() ) ) ) )
                const countsData = StudyMaterialStore.getMaterials["docsCount"]
                const counts = {}
                countsData.forEach( count => {
                    counts[count.study_material_id] = count.docs_count
                } )
                setDocsCount( counts )
            }
            console.log( UserStore.getUser )
            setIsLoading( false )
        }
        getData()
        // eslint-disable-next-line
    }, [UserStore, StudyMaterialStore] )

    return (
        <Box padding="0 20px 20px 20px">
            {( UserStore.getUser.user_role === 'PRINCIPAL' || UserStore.getUser.user_auditor ) && <TabContext value={mode}>
                <Box bgcolor="#fff" borderBottom="none">
                    <TabList sx={{ borderBottom: "2px solid rgba(0,0,0,0.2)" }} onChange={handleTabChange}>
                        <Tab iconPosition='start' sx={{ "&:hover": { background: "#eee" } }} icon={Icons.DescriptionIcon} value={"manage"} label="Your Materials" />
                        <Tab iconPosition='start' sx={{ "&:hover": { background: "#eee" } }} icon={Icons.InsightsIcon} value={"audit"} label="Audit" />
                    </TabList>
                </Box>
            </TabContext>}
            <Box bgcolor="white" boxShadow="0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1)" borderRadius="0 0 5px 5px" minHeight="100%">
                <Box padding="20px" display="flex" gap="20px" alignItems={{ md: "center", xs: "flex-start" }} flexDirection={{ md: "row", xs: "column" }} justifyContent="space-between">
                    <Box>
                        <Typography variant="h5" width="fit-content" fontWeight="normal">{UserStore.getUser["user_role"] === "FACULTY" ? mode === "manage" ? "Your Study Materials" : "Study material audit" : "Study Materials"}</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>{UserStore.getUser["user_role"] === "STUDENT" ? "Browse study materials here" : mode === "manage" ? "Manage your study materials here" : `Audit all ${UserStore.getUser["user_role"] === "HOD" ? UserStore.getUser["user_dept_code"] : UserStore.getUser["user_org_code"]} study materials`}</Typography>
                    </Box>
                    {!isLoading && <Box width={{ md: "auto", xs: "100%" }} display="flex" sx={{ "@media(max-width:900px)": { flexDirection: "column" } }} gap="10px" justifyContent={{ xs: "space-between", md: "flex-end" }} flexWrap="wrap">
                        {UserStore.getUser["user_role"] === "STUDENT" && <FormControl sx={{ "@media(max-width:500px)": { width: "100% !important" } }}>
                            <Select onChange={( e ) => {
                                setSearchParams( prev => {
                                    prev.set( "institute", e.target.value )
                                    return prev
                                }, { replace: true } ); handleInstituteChange( e )
                            }} value={institute} sx={{ fontSize: "14px", height: "40px", width: { md: "100%", sm: "calc(100% - 10px)", xs: "100%" } }}>
                                <MenuItem value="0">Select Institute</MenuItem>
                                {OrganizationStore.getOrganization.map( org => {
                                    return <MenuItem key={org.school_id} sx={{ fontSize: "14px" }} value={org.school_name_short}>{org.school_name}</MenuItem>
                                } )}
                            </Select>
                        </FormControl>}
                        <TextField value={searchText} onChange={searchMaterials} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", "@media(max-width:900px)": { width: "100%" } }, startAdornment: <SearchIcon sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder="Search by subjects..." variant="standard" type="text" />
                        {( UserStore.getUser.user_role === 'FACULTY' || UserStore.getUser.user_role === 'HOD' || UserStore.getUser.user_role === 'PRINCIPAL' ) && mode === "manage" && <Button size="small" startIcon={Icons.AddIcon} onClick={() => navigate( "/faculty/material/add" )} sx={{ "@media(max-width:900px)": { width: "100%" } }} variant="contained">
                            create
                        </Button>}
                    </Box>}

                </Box>
                {UserStore.getUser.user_auditor && mode === "audit" && <Box padding="0 20px">
                    <Box display="flex" gap="10px" marginBottom="10px" alignItems="center" flexDirection={{ md: "row", xs: "column" }}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Select Institute</InputLabel>
                            <Select variant="outlined" disabled={isInstitutesLoading} onChange={handleAuditInstituteChange} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={auditInstituteCode} label="Select Institute---">
                                <MenuItem sx={{ fontSize: "14px" }} value="0">{isInstitutesLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                                {
                                    OrganizationStore.getOrganization.map( ( obj, i ) => {
                                        return <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={obj.school_name_short} key={'key-' + i}> {( obj.school_name ).toLowerCase()} </MenuItem>
                                    } )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>}
                {!isLoading && mode === "audit" && UserStore.getUser["user_auditor"] && institute !== "0" && materials && materials.length > 0 &&
                    <Box marginBottom="2px" display="flex" flexDirection={{ sm: "row", xs: "column" }} sx={{ color: "white", background: "primary.main" }} gap="20px" alignItems="center" bgcolor={palette.primary.main} padding="20px">
                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                            <Typography variant='h6'>Study Materials</Typography>
                            <Typography variant="h3">{StudyMaterialStore.getMaterials.materials.length}</Typography>
                            <Typography fontSize="12px">Total number of study materials in Alive</Typography>
                        </Box>
                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                            <Typography variant='h6'>Attachments</Typography>
                            <Typography variant='h3'>{StudyMaterialStore.getMaterials.docsCount.reduce( ( sum, item ) => sum + parseInt( item.docs_count ), 0 )}</Typography>
                            <Typography fontSize="12px">Total Number of attachments</Typography>
                        </Box>
                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                            <Typography variant='h6'>Downloads</Typography>
                            <Typography variant='h3'>{StudyMaterialStore.getMaterials.docsCount.reduce( ( sum, item ) => sum + parseInt( item.download_count ), 0 )}</Typography>
                            <Typography fontSize="12px">Total downloads by students</Typography>
                        </Box>
                    </Box>}
                {!isLoading && mode === "audit" && UserStore.getUser["user_role"] === "PRINCIPAL" && !UserStore.getUser["user_auditor"] && materials && materials.length > 0 &&
                    <Box marginBottom="2px" display="flex" flexDirection={{ sm: "row", xs: "column" }} sx={{ color: "white", background: "primary.main" }} gap="20px" alignItems="center" bgcolor={palette.primary.main} padding="20px">
                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                            <Typography variant='h6'>Study Materials</Typography>
                            <Typography variant="h3">{StudyMaterialStore.getMaterials.materials.length}</Typography>
                            <Typography fontSize="12px">Total number of study materials in Alive</Typography>
                        </Box>
                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                            <Typography variant='h6'>Attachments</Typography>
                            <Typography variant='h3'>{StudyMaterialStore.getMaterials.docsCount.reduce( ( sum, item ) => sum + parseInt( item.docs_count ), 0 )}</Typography>
                            <Typography fontSize="12px">Total Number of attachments</Typography>
                        </Box>
                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                            <Typography variant='h6'>Downloads</Typography>
                            <Typography variant='h3'>{StudyMaterialStore.getMaterials.docsCount.reduce( ( sum, item ) => sum + parseInt( item.download_count ), 0 )}</Typography>
                            <Typography fontSize="12px">Total downloads by students</Typography>
                        </Box>
                    </Box>}
                {!isLoading && filteredMaterials && filteredMaterials.length > 0 && UserStore.getUser.user_role !== 'STUDENT' && <Box bgcolor="#fff">
                    <TableContainer sx={{ borderRadius: "0 0 5px 5px", minHeight: "300px", maxHeight: "450px", background: "#fff", "&::-webkit-scrollbar": { width: "10px !important", height: "10px !important" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": { background: "#c1c1c1" } }}>
                        <Table stickyHeader>
                            <TableHead sx={{ background: palette.primary.main }}>
                                <TableRow sx={{ background: palette.primary.main }}>
                                    {columns.map( item => (
                                        <TableCell key={item.name} sx={{ color: "#fff", background: palette.primary.main, display: item.display, minWidth: item.minWidth }} align={item.align}>{item.name}</TableCell>
                                    ) )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredMaterials.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                    .map( ( material, index ) => (
                                        <TableRow sx={{ background: "white", cursor: "pointer", "&:hover": { background: "#ccc !important" } }} onClick={() => navigate( `/faculty/material/${material.study_material_id}` )} key={material.study_material_id}>
                                            <TableCell sx={{ fontWeight: "bold", width: "100%", display: "table-cell", alignItems: "center", gap: "10px" }} align='left'> <Box display="flex" gap="5px" alignItems="center">
                                                {Icons.FolderIcon} {material.study_material_name}
                                            </Box> </TableCell>
                                            <TableCell align='left'>{material.study_material_created_by}</TableCell>
                                            <TableCell align='right'>{material.subject_name}-{material.subject_code}</TableCell>
                                            <TableCell align='right'>{docsCount[material.study_material_id] ? docsCount[material.study_material_id] : 0}</TableCell>
                                        </TableRow>
                                    ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        sx={{ background: "#eee" }}
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={filteredMaterials.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>}
                {!isLoading && filteredMaterials && filteredMaterials.length > 0 && UserStore.getUser.user_role === 'STUDENT' && ( institute !== "0" || searchText !== "" ) && <Box bgcolor="#eee">
                    <TableContainer sx={{ borderRadius: "0 0 5px 5px", minHeight: "300px", maxHeight: "450px", background: "#fff", "&::-webkit-scrollbar": { width: "10px !important", height: "10px !important" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": { background: "#c1c1c1" } }}>
                        <Table stickyHeader>
                            <TableHead sx={{ background: palette.primary.main }}>
                                <TableRow sx={{ background: palette.primary.main }}>
                                    {columns.map( item => (
                                        <TableCell key={item.name} sx={{ color: "#fff", background: palette.primary.main, display: item.display, minWidth: item.minWidth }} align={item.align}>{item.name}</TableCell>
                                    ) )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredMaterials.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                    .map( ( material, index ) => (
                                        <TableRow sx={{ background: "white", cursor: "pointer", "&:hover": { background: "#ccc !important" } }} onClick={() => navigate( `/student/material/${material.study_material_id}` )} key={material.study_material_id}>
                                            <TableCell sx={{ fontWeight: "bold", width: "100%", display: "table-cell", alignItems: "center", gap: "10px" }} align='left'> <Box display="flex" gap="5px" alignItems="center">
                                                {Icons.FolderIcon} {material.study_material_name}
                                            </Box> </TableCell>
                                            <TableCell align='left'>{material.study_material_created_by}</TableCell>
                                            <TableCell align='right'>{material.subject_name}-{material.subject_code}</TableCell>
                                            <TableCell align='right'>{docsCount[material.study_material_id] ? docsCount[material.study_material_id] : 0}</TableCell>
                                        </TableRow>
                                    ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        sx={{ background: "#eee" }}
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={filteredMaterials.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>}

                {!isLoading && ( !filteredMaterials || filteredMaterials.length === 0 ) && mode === "manage" && <Box padding="20px" height="100%" bgcolor="#eee" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {( UserStore.getUser.user_role === 'FACULTY' || UserStore.getUser.user_role === 'HOD' || UserStore.getUser.user_role === 'PRINCIPAL' ) ? <img src={fileLogo} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" /> : ( institute !== "0" || searchText !== "" ) ? <img src={fileLogo} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" /> : <img src={selectInstituteImg} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" />}
                    < Typography textAlign="center" color="textSecondary" variant='subtitle2'>{( UserStore.getUser.user_role === 'FACULTY' || UserStore.getUser.user_role === 'HOD' || UserStore.getUser.user_role === 'PRINCIPAL' ) ? "You doesn't seem to have any materials!" : ( institute === "0" && searchText === "" ) ? "Please select an institute to browse study materials or search" : "Selected institute doesn't seem to have any study materials"}</Typography>
                    {materials && ( materials.length > 0 || searchText !== "" || institute !== "0" ) && <Button onClick={() => {
                        setFilteredMaterials( materials ); setSearchParams( prev => {
                            prev.set( "searchText", "" )
                            return prev
                        }, { replace: true } ); setSearchParams( prev => {
                            prev.set( "institute", "0" )
                            return prev
                        }, { replace: true } )
                    }} disableElevation variant='contained' color="secondary" sx={{ textTransform: "capitalize", marginTop: "20px" }}>Remove filter</Button>}
                    {( UserStore.getUser.user_role === 'FACULTY' || UserStore.getUser.user_role === 'HOD' || UserStore.getUser.user_role === 'PRINCIPAL' ) && <Button variant="outlined" onClick={() => navigate( "/faculty/material/add" )} startIcon={Icons.AddIcon} sx={{ textTransform: "capitalize", marginTop: "20px" }}>Add one</Button>}
                </Box>}
                {!isLoading && ( !filteredMaterials || filteredMaterials.length === 0 ) && mode === "audit" && filteredMaterials.length === 0 && UserStore.getUser.user_role === "PRINCIPAL" && !UserStore.getUser.user_auditor && <Box display="flex" flexDirection="column" bgcolor="#eee" padding="20px" alignItems="center">
                    <img src={fileLogo} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" />
                    <Typography color="textSecondary" variant='subtitle2'>No materials to show!</Typography>
                </Box>}
                {!isLoading && ( !filteredMaterials || filteredMaterials.length === 0 ) && mode === "audit" && filteredMaterials.length === 0 && UserStore.getUser.user_role === "PRINCIPAL" && UserStore.getUser.user_auditor && institute !== "0" && <Box display="flex" flexDirection="column" bgcolor="#eee" padding="20px" alignItems="center">
                    <img src={fileLogo} height="150px" style={{ marginBottom: "20px" }} alt="No Material!" />
                    <Typography color="textSecondary" variant='subtitle2'>No materials to audit!</Typography>
                </Box>}
                {!isLoading && ( !filteredMaterials || filteredMaterials.length === 0 ) && mode === "audit" && UserStore.getUser.user_auditor && filteredMaterials.length === 0 && <Box display="flex" flexDirection="column" bgcolor="#eee" padding="20px" alignItems="center">
                    <img src={selectInstituteImg} height="150px" style={{ marginBottom: "20px" }} alt="Select instiute!" />
                    <Typography color="textSecondary" variant='subtitle2'>{searchText === "" ? "Please select an institute to audit study materials" : `No materials found`}</Typography>
                    {searchText !== "" && <Button variant='contained' onClick={() => searchMaterials( { target: { value: "" } } )} sx={{ textTransform: "capitalize", marginTop: "20px" }} color="secondary">Clear filter</Button>}
                </Box>}
                {!isLoading && ( !filteredMaterials || filteredMaterials.length > 0 ) && UserStore.getUser["user_role"] === "STUDENT" && ( institute === "0" && searchText === "" ) && <Box padding="20px" height="100%" bgcolor="#eee" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <img src={selectInstituteImg} width="150px" alt="Select institute" />
                    < Typography textAlign="center" color="textSecondary" marginTop="10px" variant='subtitle2'>Please select a institute to browse study materials or search</Typography>
                </Box>}
                {isLoading && <Typography textAlign="center" variant='h6' color="textSecondary" display="flex" alignItems="center" padding="20px" paddingTop="0" gap="10px" > <CircularProgress size={20} /> Fetching data... </Typography>}
            </Box>
        </Box >
    )
} )

export default Materials
