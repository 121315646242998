import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import SectionBox from '../../../components/admin/resume-builder/SectionBox'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CheckCircle } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useUserStore } from '../../../store/user.store'
import { useOrganizationStore } from '../../../store/organization.store'
import api from '../../../service/api'
import ConfirmDialog from '../../../components/ConfirmDialog'
import warningImg from '../../../assets/warning.svg'

const ResumeForm = () => {

    const [descriptionPlaceholder, setDescriptionPlaceholder] = useState( true )
    const [notFound, setNotFound] = useState( false )
    const [titlePlaceholder, setTitlePlaceholder] = useState( true )
    const [formDetails, setFormDetails] = useState( { title: '', description: '', accessList: [] } )
    const [activeSection, setActiveSection] = useState( -1 )
    const [formJson, setFormJson] = useState( [{ section_title: 'Untitled section', multiple: false, attributes: [] }] )
    const [formEntities, setFormEntities] = useState( [] )
    const [saving, setSaving] = useState( false )
    const [saveStatus, setSaveStatus] = useState( { status: "Saved", lastSaved: null } )
    const [loading, setLoading] = useState( true )
    const [successfulSave, setSuccessfulSave] = useState( { details: true, fields: true } )
    const [accessList, setAccessList] = useState( [] )
    const [manageAccessDialogState, setManageAccessDialogState] = useState( false )
    const [validating, setValidating] = useState( false )
    const [publishing, setPublishing] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    const ResumeBuilderStore = useResumeBuilderStore()
    const UserStore = useUserStore()
    const OrganizationStore = useOrganizationStore()

    const { form_id } = useParams()
    const navigate = useNavigate()

    const setSectionAsActive = ( index ) => {
        if ( index !== -1 )
            ResumeBuilderStore.fetchEntities()
        setActiveSection( index )
    }

    const navigateToForms = () => {
        const role = UserStore.getUser.user_role.toUpperCase()
        if ( ["ADMIN", "SUBADMIN"].includes( role ) ) {
            navigate( `/admin/resume-forms` )
        } else if ( ["HOD", "PRESENTER", "FACULTY", "PRINCIPAL"].includes( role ) ) {
            navigate( `/faculty/resume-forms` )
        } else if ( ["STUDENT", "OBSERVER"].includes( role ) ) {
            navigate( `/student/resume-forms` )
        } else {
            navigate( `/resume-forms` )
        }
    }

    const handleAdditionAccessList = async ( item ) => {
        setValidating( true )
        try {
            const { data } = await api.fetchInstituteFormStatus( item )
            if ( !data.data ) {
                const newFormDetails = { ...formDetails, accessList: [...accessList, item] }
                setSaving( true )
                setSaveStatus( { status: "Saving", lastSaved: new Date() } )
                if ( await ResumeBuilderStore.updateForm( form_id, newFormDetails ) ) {
                    setAccessList( [...accessList, item] )
                    setFormDetails( newFormDetails )
                    setSaveStatus( { status: "Saved", lastSaved: new Date() } )
                } else {
                    setSuccessfulSave( { ...successfulSave, details: false } )
                }
                setSaveStatus( { ...saveStatus, status: "Saved" } )
            } else {
                toast( `The form "${data.data.name}" is already asigned to ${item} ` )
            }

        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data.message : "Something went wrong!" )
        } finally {
            setValidating( false )
            setSaving( false )
        }
    }

    const removeAccess = async ( index ) => {
        const newList = [...accessList]
        newList.splice( index, 1 )
        const newFormDetails = { ...formDetails, accessList: newList }
        setSaving( true )
        setSaveStatus( { status: "Saving", lastSaved: new Date() } )
        if ( await ResumeBuilderStore.updateForm( form_id, newFormDetails ) ) {
            setSaveStatus( { status: "Saved", lastSaved: new Date() } )
            setAccessList( newList )
            setFormDetails( newFormDetails )
        } else {
            setSuccessfulSave( { ...successfulSave, details: false } )
        }
        setSaveStatus( { ...saveStatus, status: "Saved" } )
        setSaving( false )
    }

    const changeFormDetail = async ( e, field ) => {
        if ( field === 'title' ) {
            if ( e.target.textContent.trim() === "" ) {
                toast( "Please provide the name for the form!" )
            }
            else if ( formDetails.title !== e.target.textContent ) {
                console.log( formDetails )
                setFormDetails( { ...formDetails, title: e.target.textContent } )
                setSaving( true )
                setSaveStatus( { status: "Saving", lastSaved: new Date() } )
                if ( await ResumeBuilderStore.updateForm( form_id, { ...formDetails, title: e.target.textContent } ) ) {
                    setSaveStatus( { status: "Saved", lastSaved: new Date() } )
                } else {
                    setSuccessfulSave( { ...successfulSave, details: false } )
                }
                setSaveStatus( { ...saveStatus, status: "Saved" } )
            }
        } else if ( field === "description" && formDetails.description !== e.target.textContent ) {
            setFormDetails( { ...formDetails, description: e.target.textContent } )
            setSaving( true )
            setSaveStatus( { status: "Saving", lastSaved: new Date() } )
            if ( await ResumeBuilderStore.updateForm( form_id, { ...formDetails, description: e.target.textContent } ) ) {
                setSaveStatus( { status: "Saved", lastSaved: new Date() } )
            } else {
                setSuccessfulSave( { ...successfulSave, details: false } )
            }
            setSaveStatus( { ...saveStatus, status: "Saved" } )
        }
        setSaving( false )
    }

    const deleteForm = async () => {
        await ResumeBuilderStore.deleteForm( form_id )
        navigateToForms()
    }

    const addNewSection = () => {
        let newForm = [...formJson]
        newForm.push( { section_title: 'Untitled section', multiple: false, attributes: [] } )
        setFormJson( newForm )
        setSectionAsActive( newForm.length - 1 )
        if ( window.document.getElementById( "page-content" ) ) {
            console.log( window.document.getElementById( "page-content" ).scrollHeight )
            document.getElementById( "page-content" ).scrollTo( 0, window.document.getElementById( "page-content" ).scrollHeight + 1010 )
        }
    }

    const publish = async () => {
        setPublishing( true )
        await ResumeBuilderStore.publishForm()
        setPublishing( false )
    }

    useEffect( () => {
        const getData = async () => {
            ResumeBuilderStore.fetchEntities()
            if ( await ResumeBuilderStore.fetchForm( form_id ) ) {
                await OrganizationStore.fetchOrganization()
                if ( ResumeBuilderStore.getForm ) {
                    setFormDetails( { title: ResumeBuilderStore.getForm.name, description: ResumeBuilderStore.getForm.description, accessList: ResumeBuilderStore.getForm.access_list } )
                    if ( ResumeBuilderStore.getForm.fields )
                        setFormJson( ResumeBuilderStore.getForm.fields )
                    if ( ResumeBuilderStore.getForm.access_list )
                        setAccessList( ResumeBuilderStore.getForm.access_list )
                    if ( !ResumeBuilderStore.getForm.name || ResumeBuilderStore.getForm.name === '' ) setTitlePlaceholder( true )
                    else setTitlePlaceholder( false )
                    if ( !ResumeBuilderStore.getForm.description || ResumeBuilderStore.getForm.description === '' ) setDescriptionPlaceholder( true )
                    else setDescriptionPlaceholder( false )
                }
            } else {
                setNotFound( true )
            }
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        } else
            getData()
        getData()
    }, [UserStore, ResumeBuilderStore, OrganizationStore, form_id] )

    return (
        <Box id="resume-form-container" height="100%">
            {hasPermission && <Box>
                {!loading && <Typography marginBottom="10px" color="textSecondary" fontWeight={500} display="flex" alignItems="center" fontSize="14px"> <Link className='breadcrumb-link' to="/admin/resume-templates">Resume form</Link> {Icons.SmallChevronRightIcon} {formDetails?.title ? formDetails?.title : "Untitled form"} </Typography>}
                <ConfirmDialog confirmAction={deleteForm} actionName="Delete" message="All the templates related to this form will be lost." status={deleteDialogState} cancelAction={() => setDeleteDialogState( false )} />
                <Paper>
                    {!loading && <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        <Box padding="20px" borderBottom="2px solid #d3d3d3">
                            <Typography gutterBottom color={formDetails.title !== '' ? "black" : "GrayText"} className='editable-parent' fontWeight="500" variant='h5'>
                                <div onInput={( e ) => setTitlePlaceholder( false )} onBlur={( e ) => { changeFormDetail( e, 'title' ); if ( e.target.textContent.trim() === '' ) setTitlePlaceholder( true ) }} contentEditable suppressContentEditableWarning className='editable-element editable-border-lg'>{formDetails.title}</div>
                                {titlePlaceholder && <span className='editable-placeholder'>Untitled Form</span>}
                            </Typography>
                            <Typography color="GrayText" fontWeight="500" variant='subtitle2' className='editable-parent'>
                                <div onInput={( e ) => setDescriptionPlaceholder( false )} onBlur={( e ) => { changeFormDetail( e, 'description' ); if ( e.target.textContent.trim() === '' ) setDescriptionPlaceholder( true ) }} contentEditable suppressContentEditableWarning className='editable-element editable-border-sm'>{formDetails.description}</div>
                                {descriptionPlaceholder && <span className='editable-placeholder'>Description goes here...</span>}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box width="100%" flexGrow={1} bgcolor="#eee" position="relative">
                            <Box position="absolute" display="flex" alignItems="center" justifyContent="space-between" borderBottom="1px solid #d3d3d3" top="0" left="0" right="0" bgcolor="#fff" sx={{ zIndex: "10" }} padding="10px">
                                <Box>
                                    {saving ?
                                        <Typography fontSize="14px" variant='subtitle2' color="textSecondary"><CircularProgress size={12} sx={{ color: "GrayText" }} /> {saveStatus.status}</Typography>
                                        : !( !successfulSave.details || !successfulSave.fields ) ? <Box>
                                            <Box display="flex" gap="5px" alignItems="center">
                                                <CheckCircle fontSize='12px' sx={{ color: "GrayText" }} />
                                                <Typography fontSize="14px" variant='subtitle2' color="textSecondary"> {saveStatus.status}</Typography>
                                            </Box>
                                            {saveStatus.lastSaved && <Typography fontSize="10px" variant='subtitle2' color="textSecondary"> Last updated: {saveStatus.lastSaved.toLocaleDateString( 'en-IN', { hour: "numeric", minute: "numeric", second: "numeric" } ).replace( /\//g, "-" )} </Typography>}
                                        </Box> : <Button variant="contained" disableElevation color="primary" sx={{ textTransform: "capitalize" }} size='small'>Save changes</Button>}
                                </Box>
                                <Box display={{ md: "flex", xs: "none" }} alignItems="center">
                                    {ResumeBuilderStore.getForm.status === "DRAFT" && <Button disabled={publishing} onClick={publish} sx={{ textTransform: "capitalize" }} color="secondary" startIcon={publishing ? <CircularProgress size={14} /> : Icons.ArrowUpward}>Publish</Button>}
                                    <Button onClick={() => setManageAccessDialogState( true )} sx={{ textTransform: "capitalize", color: "primary.light" }} startIcon={Icons.Settings}>Manage access</Button>
                                    <Button onClick={addNewSection} sx={{ textTransform: "capitalize", color: "success.dark" }} startIcon={Icons.AddIcon}>Add new section</Button>
                                    <Button sx={{ color: 'error.dark', textTransform: "capitalize" }} onClick={() => setDeleteDialogState( true )} startIcon={Icons.DeleteIcon}>Delete form</Button>
                                </Box>
                                <Box display={{ md: "none", xs: "flex" }} alignItems="center">
                                    {ResumeBuilderStore.getForm.status === "DRAFT" && <IconButton disabled={publishing} onClick={publish} sx={{ textTransform: "capitalize" }} color="secondary">{Icons.ArrowUpward}</IconButton>}
                                    <IconButton onClick={() => setManageAccessDialogState( true )} sx={{ textTransform: "capitalize", color: "primary.light" }}>{Icons.Settings}</IconButton>
                                    <IconButton onClick={addNewSection} sx={{ textTransform: "capitalize", color: "success.dark" }}>{Icons.AddIcon}</IconButton>
                                    <IconButton sx={{ color: 'error.dark', textTransform: "capitalize" }} onClick={() => setDeleteDialogState( true )}>{Icons.DeleteIcon}</IconButton>
                                </Box>
                            </Box>
                            <Box onClick={() => { setActiveSection( -1 ) }} sx={{ background: "none", position: "absolute", top: "0", bottom: "0", left: "0", right: "0", zIndex: "1" }}></Box>
                            <Box display="flex" flexDirection="column" gap="10px" padding="20px" paddingTop="70px">
                                {formJson.map( ( section, index ) => (
                                    <SectionBox allEntities={ResumeBuilderStore.getEntities} successfulSave={successfulSave} setSuccessfulSave={setSuccessfulSave} setSaving={setSaving} setSaveStatus={setSaveStatus} setFormEntities={setFormEntities} formEntities={formEntities} formJson={formJson} setFormJson={setFormJson} setAsActive={setSectionAsActive} key={index} section={section} activeSection={activeSection} sectionIndex={index} />
                                ) )}
                            </Box>
                        </Box>
                    </Box>}
                    {loading && <Typography variant='subtitle2' padding="20px" alignItems="center" gap="10px" display="flex">
                        <CircularProgress size={20} /> Loading form...
                    </Typography>}
                </Paper>
                <Dialog open={notFound}>
                    <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant='h3'>404</Typography>
                            <Typography marginTop="10px">Form you are looking for doen't exists!</Typography>
                            <Button onClick={navigateToForms} color='error'>Back to forms</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog PaperProps={{ sx: { minWidth: "220px", width: "80vw" } }} onClose={() => setManageAccessDialogState( false )} open={manageAccessDialogState}>
                    <DialogTitle>Manage Access</DialogTitle>
                    <DialogContent sx={{ position: "relative" }}>
                        <Box display={validating ? "flex" : "none"} sx={{ zIndex: "2" }} alignItems="center" justifyContent="center" position="absolute" top="0" left="0" right="0" bottom="0" bgcolor="rgba(0,0,0,0.75)">
                            <Typography display="flex" gap="10px" sx={{ color: "white" }} alignItems="center" variant='subtitle2'> <CircularProgress sx={{ color: "white" }} size="14px" /> Please wait... </Typography>
                        </Box>
                        <Typography variant='subtitle1' color="GrayText">Institutes with access...</Typography>
                        <Box>
                            <Box padding="20px 0" display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                {accessList.length > 0 && accessList.map( ( item, index ) => {
                                    const instituteData = OrganizationStore.getOrganization.find( i => i.school_name_short === item )
                                    return instituteData ? (
                                        <Chip title={instituteData.school_name} disabled={validating} key={item} color='success' size='large' label={item} sx={{ fontWeight: "bold" }} onDelete={() => removeAccess( index )} deleteIcon={Icons.DeleteIcon} />
                                    ) : ''
                                } )}
                                {accessList.length === 0 && <Typography color="error.light" variant='subtitle2'>
                                    No institute has access to this form yet! add intittutes
                                </Typography>}
                            </Box>
                            <Divider />
                            <Box marginTop="20px" display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                {OrganizationStore.getOrganization.filter( item => !accessList.includes( item.school_name_short ) ).map( item => (
                                    <Chip title={item.school_name} disabled={validating} key={item.school_name_short} onClick={() => { handleAdditionAccessList( item.school_name_short ) }} color='error' size='large' label={item.school_name_short} sx={{ fontWeight: "bold" }} onDelete={() => { handleAdditionAccessList( item.school_name_short ) }} deleteIcon={Icons.AddCircleIcon} />
                                ) )}
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Box>}
            {!hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box >
    )
}

export default ResumeForm
