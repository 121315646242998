import { Box } from '@mui/material'
import React, { useState } from 'react'
import CreateAssignment from '../../components/presenter/CreateAssignment'
import AssignmentsList from '../../components/presenter/AssignmentsList'
import { useAssignmentStore } from '../../store/assignment.store'

const Assignment = () => {

    const [view, setView] = useState( "manage" )

    const AssignmentStore = useAssignmentStore()

    const editAssignment = ( assignment ) => {
        setView( "update" )
        AssignmentStore.setUpdateStatus( assignment, true )
    }

    return (
        <Box padding="0 20px 20px 20px">
            <Box className="container-fluid">
                <Box>
                    {( view === "create" || view === "update" ) && <Box>
                        <CreateAssignment view={view} setView={setView} />
                    </Box>}
                    {view === "manage" && <Box flexGrow="1">
                        <AssignmentsList editAssignment={editAssignment} setView={setView} />
                    </Box>}

                </Box>
            </Box>
        </Box>
    )
}

export default Assignment
