import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import ServerStatusBarChart from '../../components/admin/ServerStatusBarChart'
import { useAnalyticsStore } from '../../store/analytics.store'
import ServerAllocationPieChart from '../../components/admin/ServerAllocationPieChart'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'

const pubnub = new PubNub( {
    publishKey: 'pub-c-193e83b7-8144-4555-b574-a78ecfa00d50',
    subscribeKey: 'sub-c-f295b3d0-85f4-11ea-965b-8ea1ff3ad6ee',
    uuid: 'Client-056o4'
} )

const Home = () => {
    const analyticsStore = useAnalyticsStore()
    useEffect( () => {
        analyticsStore.fetchServerStat()
        const interval = setInterval( async () => {
            console.log( 'This will run every 10 second!' )
            await analyticsStore.fetchServerStat()
        }, 20000 )
        return () => clearInterval( interval )
    }, [analyticsStore] )


    return (
        // <Layout navHeadText={"Home"}>
        <PubNubProvider client={pubnub}>
            <Box width="100%" height="100%" gap="10px" display="grid" gridTemplateColumns={{ md: "auto 500px", sm: "1fr" }}>
                <ServerStatusBarChart />
                <ServerAllocationPieChart />
            </Box>
        </PubNubProvider>
        // </Layout >
    )
}

export default Home
