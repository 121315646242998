import { Box, Button, CircularProgress, IconButton, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import SectionBlock from './SectionBlock'
import QuestionBlock from './QuestionBlock'
import { Icons } from '../../utils/utilities'
import api from '../../service/api'
import { validateQuestionsImportFormatForQuestionBank } from '../../utils/exam-utilities'
import { toast } from 'react-toastify'
import { convert } from 'html-to-text'
import QuestionsPreviewDialog from '../common/QuestionsPreviewDialog'
import { useNavigate } from 'react-router-dom'

const ExamCreationPreviewComponent = ( { setStepsStatus, examCreationData, setActiveStep } ) => {

    const { palette, border } = useTheme()

    const [submitting, setSubmitting] = useState( false )
    const [questionsPreview, setQuestionsPreview] = useState( false )

    const navigate = useNavigate()

    const onImportChange = ( e ) => {
        const { target: { files: [file] } } = e
        if ( file.name.includes( ".txt" ) ) {
            setQuestionsPreview( { status: "loading" } )
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( fileReader.result, "txt", undefined, undefined )
                setQuestionsPreview( { status: "loaded", data: preview, file: file, type: "txt", plainData: fileReader.result, errors, questions, errorLines } )
            }
            fileReader.readAsText( file )
        } else {
            try {
                const fileReader = new FileReader()
                fileReader.onload = async () => {
                    setQuestionsPreview( { status: "loading" } )
                    const formData = new FormData()
                    formData.append( 'file', file )
                    try {
                        const res = await api.convertDocxToHtml( formData )
                        const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( res.data, "docx", undefined, undefined )
                        setQuestionsPreview( { status: "loaded", data: preview, file: file, type: "doc", plainData: convert( fileReader.result ), errors, questions, errorLines } )
                    } catch ( err ) {
                        console.log( err )
                        toast( "Something went wrong! try again later", { containerId: "main" } )
                    }
                }
                fileReader.readAsText( file )
            } catch ( err ) {
                console.log( err )
            }
        }
    }

    const move = async ( step ) => {
        if ( step === -1 ) {
            setStepsStatus( prev => {
                prev[2] = "completed"
                return prev
            } )
            setActiveStep( prev => prev - 1 )
        } else {
            try {
                setSubmitting( true )
                await api.admissionExams.createExam( examCreationData.formData )
                toast( <p>New exam submitted for review: <font color={palette.customThemeColor.main}><em><strong>{examCreationData.exam?.title}</strong></em></font> </p>, { containerId: "main" } )
                navigate( '/admission-exam/' )
            } catch ( err ) {
                console.log( err )
                toast( err?.response?.data?.message || 'Error occured while creating the exam! try again later.', { containerId: "main" } )
            } finally {
                setSubmitting( false )
            }
        }
    }

    return (
        <Box padding="20px">

            <QuestionsPreviewDialog readOnly setIsImporting={() => { }} getQuestions={() => { }} setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />

            <Box>
                <Typography variant='h6' color="primaryDark" >Exam details</Typography>
                <Typography color="primaryDark" variant='body2' >Verify all the details before submitting for review.</Typography>
            </Box>
            <Box marginTop="20px" bgcolor={palette.form.formBg} padding="20px" borderRadius="5px" border={border[1]}>
                <Typography variant='h6'><em>{examCreationData.exam?.title}</em></Typography>
                <Typography variant='body2'>{examCreationData.exam?.description}</Typography>
                <Typography variant='body2'> <strong> <em>Exam Duration: </em> </strong> {+examCreationData.exam?.duration?.split( ":" )[0]} Hours {+examCreationData.exam?.duration?.split( ":" )[1]} Minutes</Typography>

                {examCreationData.sections.length > 0 && <Box display="flex" marginTop="20px" flexDirection="column" gap="10px">
                    {examCreationData.sections.map( ( section, index ) => {
                        const sectionFiles = examCreationData.questionFiles?.filter( q => q.section === section )
                        const sectionQuestions = examCreationData.questions?.filter( q => q.section === section )
                        return section === 'Primary' && ( sectionFiles.length === 0 && sectionQuestions.length === 0 ) ? "" : (
                            <SectionBlock sectionsCutoff={examCreationData?.sectionsCutoff} sectionMaxQuestionCount={examCreationData.sectionQuestionsCountMap} onImportChange={onImportChange} parent='create' readOnly files={sectionFiles} index={index} section={section} questions={sectionQuestions} key={index} />
                        )
                    } )}
                </Box>}

                {/* {examCreationData.sections.length === 1 && examCreationData.questionFiles.length > 0 && <Box marginTop="20px" display="flex" gap="10px" >
                    {examCreationData.questionFiles?.filter( q => !q.section ).map( ( fileDetails, index ) => {
                        return <Box border={border[1]} borderColor={palette.secondary.main} display="flex" gap="10px" alignItems="center" borderRadius="10px" padding="10px" key={index}>
                            <Typography variant='subtitle2'>{fileDetails.file.name}</Typography>
                            <IconButton size='small' sx={{ padding: "2px" }} onClick={() => onImportChange( { target: { files: [fileDetails.file] } } )} >{Icons.default.OpenInNewIcon}</IconButton>
                        </Box>
                    } )}
                </Box>}
                {examCreationData.sections.length === 1 && examCreationData.questions.length > 0 && <Box marginTop="20px" display="flex" flexDirection="column" gap="10px" >
                    {examCreationData.questions?.filter( q => !q.section ).map( ( question, index ) => {
                        return <QuestionBlock readOnly defaultCollapseState={false} question={question} index={index} key={index} />
                    } )}
                </Box>} */}

            </Box>
            <Box marginTop="20px" justifyContent="flex-end" display="flex" gap="20px" alignItems="center" flexWrap="wrap">
                <Button variant='contained' disableElevation startIcon={Icons.default.KeyBoardArrowLeft} onClick={() => move( -1 )} color='error' sx={{ textTransform: "capitalize" }} >Back</Button>
                <Button disabled={submitting} variant='contained' disableElevation endIcon={submitting ? <CircularProgress size={14} /> : Icons.default.KeyBoardArrowRight} onClick={() => move( 1 )} color="primaryDark" sx={{ textTransform: "capitalize" }}  >{submitting ? "Submitting exam for review..." : "Submit for review"}</Button>
            </Box>

        </Box>
    )
}

export default ExamCreationPreviewComponent
