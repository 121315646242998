import { Box, IconButton, Tooltip, Typography, Button, Menu, MenuItem, Icon, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DownloadIcon from '@mui/icons-material/Download'
import InfoIcon from '@mui/icons-material/Info'
import { attachmentThumbnails } from '../../utils/utilities'
import { useState } from 'react'
import AttachmentIcon from '@mui/icons-material/Attachment'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUserStore } from '../../store/user.store'
import { Edit } from '@mui/icons-material'
import ConfirmDialog from '../ConfirmDialog'
// eslint-disable-next-line
const GridView = ( { attachments, allAttachments, setShowDetails, renameAttachment, downloadAttachment, setAttachmentsSelectedForDetails, material, removefilter, deleteAttachment } ) => {

    const [anchorEls, setAnchorEls] = useState( {} )
    const [openState, setOpenState] = useState( {} )
    const [renameValue, setRenameValue] = useState( "" )
    const [renameDialogState, setRenameDialogState] = useState( false )
    const [selectedAttachment, setSelectedAttachment] = useState( null )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [confirmAction, setConfirmAction] = useState( null )
    const [confirmMessage, setConfirmMessage] = useState( null )
    const UserStore = useUserStore()

    const handleOptionsClick = ( e, index ) => {
        setAnchorEls( { [index]: e.currentTarget } )
        setOpenState( { [index]: Boolean( e.currentTarget ) } )
    }

    const openDeleteDialog = ( index, id, name ) => {
        setAnchorEls( { [index]: null } )
        setOpenState( { [index]: Boolean( null ) } )
        setConfirmAction( () => async () => {
            await deleteAttachment( id )
            setConfirmDialogStatus( false )
        } )
        setConfirmMessage(
            <Box>
                <Typography>
                    Do you really want to delete <b style={{ color: "#f18f08" }}>{name}</b>?
                </Typography>
            </Box>
        )
        setConfirmDialogStatus( true )
    }

    return (
        <Box padding="20px" display="flex" flexWrap="wrap" gap="20px">
            <Dialog PaperProps={{ sx: { width: { md: "60%", xs: "85%" } } }} open={renameDialogState}>
                <DialogTitle>Rename Attachment</DialogTitle>
                <DialogContent>
                    <TextField sx={{ marginTop: "2px" }} value={renameValue} onChange={( e ) => setRenameValue( e.target.value )} variant='outlined' fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant='contained' onClick={async () => { await renameAttachment( selectedAttachment, renameValue ); setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Update</Button>
                    <Button color="error" variant='outlined' onClick={() => { setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Close</Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog status={confirmDialogStatus} confirmAction={confirmAction} cancelAction={() => setConfirmDialogStatus( false )} message={confirmMessage} actionName="Delete" />
            {attachments && attachments.length > 0 && attachments.map( ( attachment, index ) => (
                <Box onDoubleClick={() => { setAttachmentsSelectedForDetails( attachment ); setShowDetails( true ); setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} title={attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name} key={attachment.study_material_attachment_id} borderRadius="5px" border="1px solid #aaa8" sx={{ userSelect: "none" }} height="250px" width="210px" display="flex" flexDirection="column">
                    <Box bgcolor="#eee" height="230px" display="flex" alignItems="center" justifyContent="center">
                        <img style={{ objectFit: "fit", maxWidth: "130px" }} src={attachmentThumbnails[attachment.study_material_attachment_type] ? attachmentThumbnails[attachment.study_material_attachment_type] : attachmentThumbnails["default"]} alt={attachment.study_material_attachment_type} />
                    </Box>
                    <Box display="flex" padding="20px" alignItems="center" justifyContent="space-between">
                        <Box maxWidth="calc(100% - 20px)">
                            <Typography fontWeight="600" width="150px" noWrap>{attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name}</Typography>
                            <Typography fontSize="12px">{new Date( attachment.study_material_attachment_published_date ).toLocaleDateString( 'en-IN', { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" } )}</Typography>
                        </Box>
                        <Tooltip title="Options">
                            <IconButton onClick={( e ) => handleOptionsClick( e, index )}>
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "center", horizontal: "right" }} anchorEl={anchorEls[index]} open={openState[index] ? true : false} onClose={() => { setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} >
                            <MenuItem sx={{ padding: "0", width: "200px" }}>
                                <Button onClick={( e ) => { downloadAttachment( attachment.study_material_attachment_id, attachment.study_material_attachment_url, attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name, attachment.study_material_attachment_name.split( "." ).pop() );; setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} startIcon={<DownloadIcon />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>Download</Button>
                            </MenuItem>
                            <MenuItem sx={{ padding: "0", width: "200px" }}>
                                <Button onClick={() => { setAttachmentsSelectedForDetails( attachment ); setShowDetails( true ); setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} startIcon={<InfoIcon color='secondary' />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>View Details</Button>
                            </MenuItem>
                            {UserStore.getUser.user_id === material.empcode && < MenuItem sx={{ padding: "0", width: "200px" }}>
                                <Button onClick={e => { setSelectedAttachment( attachment.study_material_attachment_id ); setRenameValue( attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name ); setRenameDialogState( true ); setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} startIcon={<Edit color='primary' />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>Rename</Button>
                            </MenuItem>}
                            {UserStore.getUser.user_id === material.empcode && < MenuItem sx={{ padding: "0", width: "200px" }}>
                                <Button onClick={() => openDeleteDialog( index, attachment.study_material_attachment_id, attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name )} startIcon={<DeleteIcon color='error' />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>Delete</Button>
                            </MenuItem>}
                        </Menu>
                    </Box>
                </Box>
            ) )
            }
            {
                ( !attachments || attachments.length === 0 ) && <Box textAlign="center" width="100%">
                    <Icon sx={{ height: "fit-content", width: "fit-content" }}>
                        <AttachmentIcon sx={{ fontSize: "50px", color: "#00000099" }} />
                    </Icon>
                    <Typography variant='h6' fontWeight="bold" color="textSecondary" >No attachments!</Typography>
                    {allAttachments.length > 0 && <Button variant='contained' color='secondary' disableElevation onClick={removefilter} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Remove filter</Button>}
                </Box>
            }
        </Box >
    )
}

export default GridView
