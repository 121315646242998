import { Box } from '@mui/material'
import React from 'react'
import Navbar from './PresenterNavbar'
import SideDrawer from './PresenterSideDrawer'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useLayoutContext } from '../../contexts/layout.context'
import DownloadStatusListDialog from '../study-material-components/DownloadStatusListDialog'

const PresenterLayout = ( { navHeadText } ) => {

    const { isSmallScreen, setIsSmallScreen, setSidedrawerState, setCurrentLoc } = useLayoutContext()
    const loc = useLocation()
    let drawerWidth = 250

    const handleResize = () => {
        if ( window.innerWidth < 900 )
            setIsSmallScreen( true )
        else {
            setIsSmallScreen( false )
            setSidedrawerState( false )
        }
    }

    const handleLoad = () => {
        if ( window.innerWidth < 900 )
            setIsSmallScreen( true )
        else {
            setIsSmallScreen( false )
            setSidedrawerState( false )
        }
    }


    useEffect( () => {
        window.addEventListener( 'resize', handleResize )
        window.addEventListener( 'load', handleLoad )
        setCurrentLoc( loc.pathname )
        return () => {
            window.removeEventListener( 'resize', handleResize )
            window.removeEventListener( 'resize', handleLoad )
        }
    } )

    return (
        <Box>
            <Navbar navHeadText={navHeadText} />
            <SideDrawer width={drawerWidth} />
            <Box id="page-content" sx={{ transition: "0.2s ease" }} marginLeft={isSmallScreen ? "0" : "60px"} paddingTop="88px" height="calc(100vh - 88px)" overflow="auto">
                <Outlet />
            </Box>
            <DownloadStatusListDialog />
        </Box>
    )
}

export default PresenterLayout
