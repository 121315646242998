import React, { useState } from 'react'
import { Drawer, IconButton, Box, List, ListItem, ListItemButton, Icon, ListItemText, Typography, ListItemIcon, Collapse, Divider, useTheme } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Icons } from '../../utils/utilities'
import { Link } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { useEffect } from 'react'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useLayoutContext } from '../../contexts/layout.context'
import { DomainAdd } from '@mui/icons-material'


const allMenu = [
    { active: true, label: "Home", icon: Icons.default.HomeIcon, link: `/admin/home`, children: null },
    { active: true, permission: "live_class_audit", label: "Live Class Audit", icon: Icons.default.OndemandVideoIcon, link: `/admin/audit`, children: null },
    { active: true, permission: ["api_settings", "feedback", "feedback"], label: "Online Classes", icon: Icons.default.VideocamIcon, link: [`/admin/api-setting`, `/admin/feedback-setting`, `/admin/feedback`], children: ["API Settings", "Feedback Settings", "Feedback"] },
    { active: true, permission: "study_material_audit", label: "Study Material", icon: Icons.default.DescriptionIcon, link: `/admin/study-material`, children: null },
    { active: false, permission: "online_course", label: "Online Course", icon: Icons.default.PlayCircleFilledWhiteIcon, link: `/admin/online-course`, children: null },
    { active: true, permission: "exams", label: "Admission Exams", icon: <DomainAdd />, link: `/admission-exam`, children: null },
    { active: true, permission: "exam_audit", label: "Exam Audit", icon: Icons.default.QuizIcon, link: `/admin/exam`, children: null },
    { active: true, permission: "attendance_audit", label: "Attendance Audit", icon: Icons.default.EmojiPeopleIcon, link: `/admin/attendance`, children: null },
    { active: true, permission: "admin_permission", label: "Accounts", icon: <ManageAccountsIcon />, link: `/admin/accounts`, children: null },
    { active: true, permission: "admin_permission", label: "Password", icon: Icons.default.LockIcon, link: `/admin/password`, children: null },
    { active: true, permission: "debug_user", label: "Debug User", icon: Icons.default.DeveloperModeIcon, link: `/admin/debug`, children: null },
    { active: true, permission: "analytics", label: "Analytics", icon: Icons.default.AnalyticsIcon, link: `/admin/analytics`, children: null }
]

const SideDrawer = function ( { width, closeSideMenu } ) {

    const { setCurrentLoc, currentLoc, sidedrawerState, setSidedrawerState, isSmallScreen } = useLayoutContext()
    const UserStore = useUserStore()
    const { palette } = useTheme()

    const [dropdownStatus, setDropdownStatus] = useState( {} )
    const [menu, setMenu] = useState( [] )

    const closeOnMobileScreen = () => {
        if ( window.innerWidth < 900 )
            closeSideMenu()
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role.toLowerCase() !== 'admin' && UserStore.getUser.user_permissions ) {
            let menuList = []
            for ( let i = 0; i < allMenu.length; i++ ) {
                const menuItem = allMenu[i]
                if ( menuItem.permission ) {
                    if ( typeof menuItem.permission === "string" ) {
                        if ( UserStore.getUser.user_permissions[menuItem.permission] ) {
                            menuList.push( menuItem )
                        }
                    } else {
                        let dropDownMenu = undefined
                        for ( let j = 0; j < menuItem.permission.length; j++ ) {
                            const permission = menuItem.permission[j]
                            if ( UserStore.getUser.user_permissions[permission] ) {
                                if ( !dropDownMenu ) {
                                    dropDownMenu = { active: true, label: menuItem.label, icon: menuItem.icon, link: [], children: [] }
                                }
                                dropDownMenu.link.push( menuItem.link[j] )
                                dropDownMenu.children.push( menuItem.children[j] )
                            }
                        }
                        if ( dropDownMenu )
                            menuList.push( dropDownMenu )
                    }
                } else {
                    menuList.push( menuItem )
                }
                setMenu( menuList )
            }
        } else {
            setMenu( allMenu )
        }
    }, [UserStore] )

    return (
        <>
            <Drawer variant='permanent'
                onMouseEnter={() => setSidedrawerState( true )}
                onMouseLeave={() => !isSmallScreen && ( setSidedrawerState( false ), setDropdownStatus( {} ) )}
                sx={{ width: isSmallScreen ? sidedrawerState ? width : "0px" : sidedrawerState ? width : "60px", flexShrink: 0, height: "100%", zIndex: "1 !important", flexSrink: 0, transition: "0.1s ease-in-out !important" }}
                PaperProps={{
                    sx: {
                        width: isSmallScreen ? sidedrawerState ? width : "0px" : sidedrawerState ? width : "60px",
                        transition: "0.1s ease-in-out",
                        overflowX: sidedrawerState ? "inherit" : "hidden",
                        background: palette.primary.main,
                        color: "white", borderRight: "none",
                        borderRadius: "0"
                    },
                    className: 'slim-custom-scrollbar'
                }}
                anchor="left"
                open={sidedrawerState}>
                <Box sx={{ display: sidedrawerState ? "block" : "none" }} >
                    <Box>
                        <Icon sx={{ position: "fixed", top: "20px", borderRadius: "50%", left: "100%", padding: "10px", background: "#fff", display: "flex", alignItems: "center", justifyContent: "center", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19)", zIndex: 3 }}>
                            <ChevronRightIcon color='primary' />
                        </Icon>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex !important",
                    alignItems: "center !important",
                    padding: "30px 20px !important",
                    justifyContent: "space-between !important",
                }}>
                    <Box display="flex" gap="20px" alignItems="center" position="relative">
                        {/* <Box padding="10px" display="flex" alignItems="center" justifyContent="center" borderRadius="50%" width="60px" height="60px" bgcolor="white">
                            <img src={aliveTextLogo} width="40px" alt="Alive logo" />
                        </Box>
                        <Box>
                            <Typography variant='h6'>ALIVE</Typography>
                            <Typography fontWeight="normal" fontSize="12px">Digital Classroom</Typography>
                        </Box> */}
                        <Box sx={{ display: { md: currentLoc.includes( 'stream' ) ? "block" : "none", xs: "block" }, justifyContent: "flex-end", position: "absolute", right: "-60px", top: "0", transform: "translateY(-50%)" }}>
                            <IconButton sx={{ width: "fit-content", color: "white" }} size='small' onClick={closeSideMenu} >
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <List>
                    {menu.map( ( item, index ) => {
                        if ( item.active ) {
                            if ( item.children === null ) {
                                return <Link onClick={() => { closeOnMobileScreen(); setCurrentLoc( item.link ) }} key={item.label} target={item.target} to={item.link} style={{ textDecoration: "none !important", color: "inherit !important", display: "flex !important", alignItems: "center !important" }}>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{
                                            color: "white",
                                            background: currentLoc.includes( item.link ) ? "rgba(255,255,255,0.1) !important" : "none",
                                            transition: "0.2s ease !important",
                                            marginY: "2px !important",
                                            "&:hover": {
                                                background: "rgba(255,255,255,0.1) !important",
                                            }
                                        }}>
                                            <Box paddingLeft={sidedrawerState && !isSmallScreen ? "5px" : "0px"} sx={{ transition: "0.2s ease-in-out" }} display="flex" alignItems="center">
                                                <ListItemIcon sx={{ color: currentLoc.includes( item.link ) ? palette.customThemeColor.main : "inherit", }}>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText sx={{ marginLeft: "-5px" }}><Typography noWrap fontSize="14px" sx={{ opacity: sidedrawerState ? 1 : 0, transition: "0.2s ease-in", marginLeft: sidedrawerState ? "0px" : "-10px" }}> {item.label}</Typography> </ListItemText>
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            }
                            else {
                                return <ListItem sx={{ display: 'flex', flexDirection: "column", width: "100%" }} key={index} disablePadding>
                                    <ListItemButton sx={{
                                        color: "white",
                                        background: item.link.includes( currentLoc ) ? "rgba(255,255,255,0.1) !important" : dropdownStatus[item.label] ? "primary.light" : "inherit",
                                        transition: "0.2s ease !important",
                                        marginY: "2px !important",
                                        width: "100%",
                                        "&:hover": {
                                            background: "rgba(255,255,255,0.1) !important",
                                        }
                                    }} onClick={() => setDropdownStatus( { ...dropdownStatus, [item.label]: !dropdownStatus[item.label] } )}>
                                        <Box flexGrow={1} maxHeight="29px" paddingLeft={sidedrawerState && !isSmallScreen ? "5px" : "0px"} sx={{ transition: "0.2s ease-in-out" }} display="flex" alignItems="center">
                                            <ListItemIcon sx={{ color: item.link.includes( currentLoc ) ? palette.customThemeColor.main : "inherit", fontSize: "14px" }}>
                                                {item.icon}
                                            </ListItemIcon>
                                            <Box flexGrow={1} display="flex" justifyContent="space-between" alignItems="center">
                                                <ListItemText sx={{ flexGrow: 1, marginLeft: "-5px" }}><Typography width="100%" fontSize="14px" sx={{ opacity: sidedrawerState ? 1 : 0, transition: "0.2s ease-in", marginLeft: sidedrawerState ? "0px" : "-10px" }}> {item.label}</Typography></ListItemText>
                                                {dropdownStatus[item.label] ? <ExpandLess /> : <ExpandMore />}
                                            </Box>
                                        </Box>
                                    </ListItemButton>
                                    <Collapse sx={{ width: "100%" }} timeout={"auto"} unmountOnExit in={dropdownStatus[item.label] && sidedrawerState}>
                                        <List sx={{ flexDirection: "column", display: "flex" }} component="div" disablePadding>
                                            {item.children.map( ( sublink, k ) => {
                                                return <Link onClick={() => { closeOnMobileScreen(); setCurrentLoc( item.link[k] ) }} to={item.link[k]} key={k}>
                                                    <ListItemButton key={sublink} sx={{
                                                        color: "white",
                                                        background: currentLoc.includes( item.link[k] ) ? "rgba(255,255,255,0.1) !important" : "none",
                                                        transition: "0.2s ease !important",
                                                        marginY: "2px !important",
                                                        paddingLeft: "85px",
                                                        "& .a": {
                                                            width: "100%",
                                                            display: "block"
                                                        },
                                                        "&:hover": {
                                                            background: "rgba(255,255,255,0.1) !important",
                                                        }
                                                    }} >
                                                        <Typography sx={{ fontSize: "14px", opacity: sidedrawerState ? 1 : 0, transition: "0.2s ease-in", marginLeft: sidedrawerState ? "0px" : "-10px" }}>
                                                            {sublink}
                                                        </Typography>
                                                    </ListItemButton>
                                                </Link>
                                            } )}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </ListItem>
                            }
                        } else {
                            return ''
                        }
                    } )}
                </List>
            </Drawer >
        </>
    )
}

export default SideDrawer



// import DashboardIcon from '@mui/icons-material/Dashboard'
// import SchoolIcon from '@mui/icons-material/School'
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import EventIcon from '@mui/icons-material/Event'
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
// import MessageIcon from '@mui/icons-material/Message'
// import NotificationsIcon from '@mui/icons-material/Notifications'