import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import SectionForm from '../../../components/user/resume-builder-components/SectionForm'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { CheckCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const ResumeBuilderHome = observer( () => {
    const [loading, setLoading] = useState( true )
    const [responseJson, setResponseJson] = useState( {} )
    const [formAvailable, setFormAvailable] = useState( false )
    const [saveData, setSaveData] = useState( { status: true, statusText: "saved", lastSaved: null } )
    const [changesMade, setChangesMade] = useState( false )

    const ResumeBuilderStore = useResumeBuilderStore()
    const navigate = useNavigate()

    const deleteInfo = async () => {
        setResponseJson( {} )
        setSaveData( { ...saveData, statusText: "saving" } )
        setLoading( true )
        if ( await ResumeBuilderStore.updateStudentResumeInfo( {} ) ) {
            setSaveData( { status: true, statusText: "saved", lastSaved: new Date() } )
            await ResumeBuilderStore.fetchStudentResumeInfo()
            setLoading( false )
            console.log( ResumeBuilderStore.getStudentResumeInfo.resume_data )
        }
        else {
            setSaveData( { ...saveData, status: false } )
            setLoading( false )
        }
    }

    const saveChanges = async () => {
        setSaveData( { ...saveData, statusText: "saving" } )
        console.log( responseJson )
        if ( await ResumeBuilderStore.updateStudentResumeInfo( responseJson ) ) {
            setSaveData( { status: true, statusText: "saved", lastSaved: new Date() } )
            setChangesMade( false )
        }
        else {
            setSaveData( { ...saveData, status: false, statusText: "saved" } )
        }
    }

    useEffect( () => {
        const getData = async () => {
            if ( await ResumeBuilderStore.fetchStudentForm() )
                setFormAvailable( true )
            await ResumeBuilderStore.fetchStudentResumeInfo()
            setLoading( false )
        }
        getData()
    }, [ResumeBuilderStore] )

    return (
        <Box height="100%" borderRadius="5px" padding="0 20px">
            <Paper sx={{ borderRadius: "5px", height: "100%", display: "flex", flexDirection: "column" }}>
                <Box padding="20px" display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" gap="10px" borderBottom="1px solid #d3d3d3">
                    <Box>
                        <Typography variant='h5'>Please fill the form</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Provide the required information for us to build your resume!</Typography>
                    </Box>
                    {!loading && formAvailable && <Box display="flex" flexDirection={{ lg: "row", xs: "row-reverse" }} alignItems="center" gap="10px" flexWrap="wrap">
                        {!changesMade && <Box>
                            {saveData.status && <Box fontSize="12px" color="GrayText"><Typography display="flex" alignItems="center" gap="5px" variant="subtitle2" textAlign="right"> <CheckCircle fontSize='10px' /> Saved successfully </Typography> </Box>}
                        </Box>}
                        {changesMade && <Button disabled={saveData.statusText === 'saving'} onClick={saveChanges} size="small" variant="outlined" color="success" sx={{ textTransform: "capitalize" }}>
                            {saveData.statusText === 'saving' ? <Typography fontSize="12px" variant="subtitle2" color="success"> <CircularProgress size={14} /> Saving changes</Typography> : "Save Changes"}
                        </Button>}
                        <Button onClick={() => navigate( '/student/resume-builder/preview' )} size="small" variant="outlined" color="secondary" sx={{ textTransform: "capitalize" }}>
                            Preview Resume
                        </Button>
                        <Button onClick={deleteInfo} size="small" variant="outlined" color="error" sx={{ textTransform: "capitalize" }}>
                            Clear all
                        </Button>
                    </Box>}
                </Box>
                {!loading && formAvailable && <Box className='custom-scrollbar' padding="10px" display="flex" flexDirection="column" gap="10px" overflow="auto" flexGrow={1} bgcolor="#eee">
                    {ResumeBuilderStore.getForm?.fields?.map( ( section, index ) => (
                        <SectionForm save={saveChanges} setChangesMade={setChangesMade} saveData={saveData} stateResponseJson={responseJson} setSaveData={setSaveData} responseJson={ResumeBuilderStore.getStudentResumeInfo.resume_data ? ResumeBuilderStore.getStudentResumeInfo.resume_data : {}} setResponseJson={setResponseJson} key={index} section={section} />
                    ) )}
                    {ResumeBuilderStore.getForm?.fields?.length === 0 && <Typography>No fields!</Typography>}
                </Box>}
                {loading && <Typography flexGrow={1} variant='subtitle2' color="GrayText" gap="10px" display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={30} /> Loading your form, please wait...
                </Typography>}
                {!loading && !formAvailable && <Typography flexGrow={1} variant='subtitle2' color="GrayText" gap="10px" display="flex" alignItems="center" justifyContent="center">
                    The resume form for your institute is not yet set please contact your department HOD.
                </Typography>}
            </Paper>
        </Box>
    )
} )

export default ResumeBuilderHome
