import { Box, Button, Collapse, FormControlLabel, MenuItem, Paper, Select, Switch, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CheckBoxOutlineBlank, Home, KeyboardArrowDown, Settings, } from '@mui/icons-material'
import { Icons } from '../../../utils/utilities'
import { useResumeTemplateEditStore } from '../../../store/resumetemplateEdit.store'
import { observer } from 'mobx-react'
import { allColumnRatios, defaultColumnRatios, templateColorThemes } from '../../../utils/resume-builder-utilities'
import ConfigureSelectedSection from './ConfigureSelectedSection'
import ConfigureSelectedRow from './ConfigureSelectedRow'


const TemplateBuilderSideDraw = observer( ( { sectionList, newSectionPadding, previewMode, setNewSectionDataGap, newSectionDataGap, newSectionGap, setisDragging, tab, setTab, setNewSectionGap, setNewSectionPadding } ) => {

    const [newRowDialogState, setNewRowDialogState] = useState( false )
    const [newRowState, setNewRowState] = useState( { columnCount: 1, columnRatio: "1fr", gap: 10 } )
    const TemplateStore = useResumeTemplateEditStore()
    const [colorThemeDialogState, setColorThemeDialogState] = useState( true )
    const [layoutPaddingValue, setLayoutPaddingValue] = useState( TemplateStore.getTemplate ? TemplateStore.getTemplate.padding : 20 )

    const handleColumnCountChange = ( e, key ) => {
        let val = e.target.value
        if ( key === "columnCount" ) {
            val = val > 3 ? 3 : val < 1 ? 1 : val
            setNewRowState( { ...newRowState, columnCount: val, columnRatio: defaultColumnRatios[val] } )
        } else if ( key === "columnProportion" ) {
            setNewRowState( { ...newRowState, columnRatio: val } )
        } else {
            val = val < 1 ? 1 : val
            setNewRowState( { ...newRowState, gap: val } )
        }
    }

    const addNewRow = () => {
        const newTemplate = { ...TemplateStore.getTemplate }
        let cols = []
        for ( let i = 0; i < newRowState.columnCount; i++ ) {
            cols.push( [] )
        }
        newTemplate.rows.push( { ...newRowState, columns: cols } )
        TemplateStore.updateTemplate( newTemplate )
        setNewRowDialogState( false )
    }

    const openRowDialog = () => {
        setNewRowState( { columnCount: 1, columnRatio: "1fr", gap: 10 } )
        setNewRowDialogState( true )
    }

    const handleDragStart = ( e, sectionIndex ) => {
        e.target.classList.add( 'dragging-element' )
        e.dataTransfer.setData( "text/plain", JSON.stringify( { sectionIndex } ) )
        setisDragging( true )
    }

    const changeLayoutSettings = ( key, e ) => {
        let val = e.target.value
        if ( key === "padding" && e.type === "change" ) {
            setLayoutPaddingValue( val )
        }
        if ( key === "padding" && ( e.type === "blur" || e.type === "keydown" ) ) {
            const newTemplate = { ...TemplateStore.getTemplate }
            newTemplate[key] = val < 0 ? 0 : val
            setLayoutPaddingValue( val < 0 ? 0 : val )
            TemplateStore.updateTemplate( newTemplate )
        }
        if ( key === "colorTheme" ) {
            const newTemplate = { ...TemplateStore.getTemplate }
            newTemplate[key] = val
            TemplateStore.updateTemplate( newTemplate )
        }

    }

    return (
        <Box overflow="hidden" minWidth="270px" maxWidth="270px" position="relative" borderRadius="5px" padding="2px 0" >
            <Box display="flex" flexDirection="column" top="3px" bottom="0" left="0" bgcolor="white" sx={{ zIndex: 10 }} position="absolute" borderRight="1px solid #d3d3d3">
                <Box padding="5px" bgcolor={tab === "Home" ? "#eee" : "none"} sx={{ cursor: "pointer" }} onClick={() => setTab( "Home" )} >
                    <Tooltip placement='right' title="Home">
                        <Home />
                    </Tooltip>
                </Box>
                <Box padding="5px" bgcolor={tab === "Components" ? "#eee" : "none"} sx={{ cursor: "pointer" }} onClick={() => setTab( "Components" )} >
                    <Tooltip placement='right' title="Sections">
                        <CheckBoxOutlineBlank />
                    </Tooltip>
                </Box>
                <Box padding="5px" bgcolor={tab === "Settings" ? "#eee" : "none"} sx={{ cursor: "pointer" }} onClick={() => setTab( "Settings" )} >
                    <Tooltip placement='right' title="Settings">
                        <Settings />
                    </Tooltip>
                </Box>
            </Box>
            <Box overflow="auto" className='slim-custom-scrollbar' bgcolor="#fff" borderRadius="5px" sx={{ minWidth: "250px", height: "100%" }}>
                <Box paddingLeft="35px">
                    {tab === "Home" && <Box padding="10px 0">
                        <Box sx={{ paddingRight: "20px" }} paddingBottom="10px" borderBottom="1px solid #d3d3d3" display="flex" alignItems="center" justifyContent="space-between">
                            <Typography color="secondary" padding="0 10px" variant="h6">
                                Home
                            </Typography>
                            <FormControlLabel labelPlacement='start' control={<Switch size='small' checked={TemplateStore.getPreviewMode} onChange={( e ) => TemplateStore.setPreviewMode( e.target.checked )} />} label={<Typography fontSize="12px" color="GrayText" variant="subtitle2">Preview mode</Typography>} />
                        </Box>
                        <Box padding="0 10px">
                            {!newRowDialogState && !TemplateStore.getPreviewMode && <Button fullWidth onClick={openRowDialog} variant="outlined" startIcon={Icons.AddIcon} size="small" sx={{ textTransform: "capitalize", marginTop: "10px" }}>
                                Add New Row
                            </Button>}
                        </Box>
                        <Collapse in={newRowDialogState && !TemplateStore.getPreviewMode}>
                            <Box marginTop="10px" padding="0 10px">
                                <Typography fontSize="14px" fontWeight="500">Add new row</Typography>
                                <Box marginBottom="10px">
                                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select number of columns</Typography>
                                    <TextField fullWidth size="small" value={newRowState.columnCount} type="number" onChange={( e ) => handleColumnCountChange( e, "columnCount" )} />
                                </Box>
                                <Box marginBottom="10px">
                                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select column proportions</Typography>
                                    <Select fullWidth size="small" value={newRowState.columnRatio} type="number" onChange={( e ) => handleColumnCountChange( e, "columnProportion" )} >
                                        {allColumnRatios[newRowState.columnCount].map( ratio => (
                                            <MenuItem key={ratio.val} value={ratio.val}>{ratio.label}</MenuItem>
                                        ) )}
                                    </Select>
                                </Box>
                                <Box marginBottom="10px">
                                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Spacing between columns</Typography>
                                    <TextField fullWidth size="small" value={newRowState.gap} type="number" onChange={( e ) => handleColumnCountChange( e, "gap" )} />
                                </Box>
                                <Box display="flex" flexWrap="wrap" alignItems="center" gap="10px">
                                    <Button onClick={addNewRow} variant="outlined" color="primary" size="small" sx={{ textTransform: "capitalize", flexGrow: 1 }}>Add Row</Button>
                                    <Button variant="outlined" color="error" onClick={() => setNewRowDialogState( false )} size="small" sx={{ textTransform: "capitalize", flexGrow: 1 }}>Cancel</Button>
                                </Box>
                            </Box>
                        </Collapse>
                        {!( newRowDialogState || TemplateStore.getPreviewMode ) && <Box>
                            <Box padding="10px">
                                <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select layout padding</Typography>
                                <TextField
                                    onFocus={e => e.target.select()}
                                    onChange={e => changeLayoutSettings( "padding", e )}
                                    onBlur={e => changeLayoutSettings( "padding", e )}
                                    sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }}
                                    fullWidth size='small' type="number"
                                    onKeyDown={e => { if ( e.key === "Enter" ) changeLayoutSettings( "padding", e ) }}
                                    value={layoutPaddingValue} />
                            </Box>
                            <Box marginTop="10px">
                                <Typography sx={{ cursor: "pointer" }} onClick={() => setColorThemeDialogState( !colorThemeDialogState )} padding="10px" bgcolor="#e3e3e3" fontSize="12px" variant="subtitle2" display="flex" justifyContent="space-between" alignItems="center">Color Theme <KeyboardArrowDown sx={{ fontSize: "18px", color: "black" }} /> </Typography>
                                <Collapse in={colorThemeDialogState}>
                                    <Box bgcolor="#eee" display="flex" gap="10px" flexWrap="wrap" alignItems="center" padding="10px">
                                        {Object.keys( templateColorThemes ).map( theme => {
                                            return (
                                                <Tooltip onClick={() => changeLayoutSettings( "colorTheme", { target: { value: theme } } )} sx={{ cursor: "pointer", border: theme === TemplateStore.getTemplate.colorTheme ? "2px solid black" : "2px solid white" }} title={theme} key={theme}>
                                                    <Box overflow="hidden" display="flex" alignItems="center" justifyContent="center" width="20px" borderRadius="50%" maxWidth="20px" maxHeight="20px" height="20px">
                                                        <Box flexGrow={1} bgcolor={templateColorThemes[theme].primary} width="50%" height="100%"></Box>
                                                        <Box flexGrow={1} bgcolor={templateColorThemes[theme].secondary} width="50%" height="100%"></Box>
                                                    </Box>
                                                </Tooltip>
                                            )
                                        } )}
                                    </Box>
                                </Collapse>
                            </Box>
                        </Box>}
                        {TemplateStore.getPreviewMode && <Typography height="100px" display="flex" justifyContent="center" alignItems="center" variant="subtitle2" color="GrayText" textAlign="center">
                            Exit preview mode to start editing.
                        </Typography>}
                    </Box>}
                    {tab === "Settings" && <Box padding="10px 0 ">
                        {( !TemplateStore.getSelectedSection && TemplateStore.getSelectedRowIndex === null ) && <Typography height="100px" display="flex" justifyContent="center" alignItems="center" variant="subtitle2" color="GrayText" textAlign="center">
                            Select a section to configure
                        </Typography>}
                        {TemplateStore.getSelectedSection && <ConfigureSelectedSection setNewSectionDataGap={setNewSectionDataGap} newSectionDataGap={newSectionDataGap} setNewSectionGap={setNewSectionGap} setNewSectionPadding={setNewSectionPadding} newSectionPadding={newSectionPadding} newSectionGap={newSectionGap} />}
                        {TemplateStore.getSelectedRowIndex !== null && <ConfigureSelectedRow setTab={setTab} />}
                    </Box>}
                    {tab === "Components" && <Box className='slim-custom-scrollbar' bgcolor="#fff" borderRadius="5px" sx={{ margin: "0 auto", position: "relative" }}>
                        <Box sx={{ paddingRight: "20px" }} paddingBottom="10px" borderBottom="1px solid #d3d3d3" display="flex" alignItems="center" justifyContent="space-between">
                            <Typography color="secondary" padding="10px 10px 0 10px" variant="h6">
                                Components
                            </Typography>
                        </Box>
                        <Box padding="10px" paddingBottom="100px" sx={{ userSelect: "none" }} display="flex" flexDirection="column" gap="10px">
                            {sectionList.length > 0 && sectionList.map( ( section, sectionIndex ) => (
                                <div
                                    key={sectionIndex}
                                    onDragStart={( e ) => handleDragStart( e, sectionIndex )}
                                    onDragEnd={( e ) => {
                                        e.target.classList.remove( 'dragging-element' )
                                        setisDragging( false )
                                    }}
                                    draggable
                                >
                                    <Paper sx={{ border: "1px solid #d3d3d3", padding: "10px", cursor: "grab", "&:hover": { border: "1px solid #a3a3a3" } }}>
                                        <Typography variant='subtitle1'>{section.section_title}</Typography>
                                        {section.attributes.flatMap( ( grp ) => grp ).map( ( attr, attrIndex ) => (
                                            <Typography key={attrIndex} fontSize="12px" color="GrayText">{attr.entity_name}</Typography>
                                        ) )}
                                    </Paper>
                                </div>
                            ) )}
                        </Box>
                    </Box>}
                </Box>
            </Box>
        </Box>
    )
} )

export default TemplateBuilderSideDraw
