import React from 'react'
import { useAssignmentStore } from '../../store/assignment.store'
import { Box, DialogActions, Button, Dialog, DialogContent, Typography, Collapse, Chip, } from '@mui/material'
import UploadFile from '@mui/icons-material/UploadFile'
import { chipColors } from '../../utils/utilities'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const SubmitAssignmentDialog = ( { onClose, selectedValue, open } ) => {
    const RoomStore = useAssignmentStore()

    const [attachments, setAttachments] = React.useState( [] )
    const [uploading, setUploading] = React.useState( false )

    const handleClose = () => {
        onClose( selectedValue )
    }

    const handleFilesInput = ( e ) => {
        let files = []
        for ( let i = 0; i < e.target.files.length; i++ ) {
            const file = e.target.files[i]
            files.push( file )
        }
        setAttachments( [...attachments, ...files] )
        e.target.value = ""
    }

    const removeFile = ( index ) => {
        const updatedFiles = [...attachments]
        updatedFiles.splice( index, 1 )
        setAttachments( updatedFiles )
    }

    const submitAssignment = async () => {
        //e.preventDefault();
        setUploading( true )
        if ( attachments.length === 0 ) {
            toast.error( "Please select files you want to submit!" )
            setUploading( false )
            return
        }
        const formData = new FormData()
        for ( let i = 0; i < attachments.length; i++ ) {
            console.log( attachments )
            formData.append( "attachments", attachments[i] )
        }
        formData.append( "assignment_id", selectedValue['assignment_id'] )

        await RoomStore.uploadAssignmentSubmission( formData )
        setUploading( false )
        handleClose()
        setAttachments( [] )
    }
    return (
        <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleClose} aria-labelledby="submit-dialog-title" open={open}>
            <Box padding="20px 20px 10px 20px">
                <Typography variant='h6'>{selectedValue.assignment_name}</Typography>
                <Typography variant='subtitle2' caolor="textSecondary">Submit you answers here.</Typography>
            </Box>
            <DialogContent>
                <form>
                    <Box sx={{ position: "relative", border: "2px dashed rgba(0,0,0,0.3)", padding: "20px" }} >
                        <input type="file" disabled={uploading} style={{ position: "absolute", opacity: 0, top: 0, left: 0, right: 0, bottom: 0 }} onChange={handleFilesInput} multiple />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <UploadFile sx={{ color: "#999d" }} />
                            <Typography>Drag and drop your assignments here</Typography>
                            <Typography color="textSecondary">or</Typography>
                            <Typography color="secondary" fontWeight="bold">Click to browse</Typography>
                        </Box>
                    </Box>
                    <Collapse in={attachments.length > 0}>
                        <Box display="flex" gap="5px" flexWrap="wrap" marginTop="20px">
                            {attachments.map( ( item, index ) => (
                                <Chip sx={{ color: "white", background: chipColors[index % chipColors.length] }} deleteIcon={<RemoveCircle sx={{ color: "white !important" }} />} key={item.name} onDelete={() => removeFile( index )} label={item.name} />
                            ) )}
                        </Box>
                    </Collapse>
                </form>
            </DialogContent>
            <DialogActions>
                <Button disabled={uploading} onClick={handleClose} color="error">
                    Close
                </Button>
                <Button disabled={uploading} onClick={submitAssignment} color="primary">
                    {!uploading ? 'Upload' : "Uploading..."}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

SubmitAssignmentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.object.isRequired
}

export default SubmitAssignmentDialog
