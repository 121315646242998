import { Box, Button, CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { erpUZBLink, Icons } from '../../utils/utilities'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useUserStore } from '../../store/user.store'
import { observer } from 'mobx-react'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import { useAdmissionExamStore } from '../../store/admission-exam.store'

const AdmissionCandidateHome = observer( () => {

    const [attempting, setAttempting] = useState( false )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { border, palette } = useTheme()

    const { id } = useParams()

    const ExamStore = useAdmissionExamStore()
    const UserStore = useUserStore()

    const navigate = useNavigate()

    const attempt = async () => {
        try {
            setAttempting( true )
            const { status, data } = await api.admissionExams.attempt( id )
            if ( status === 200 ) {
                if ( !data.isNewParticipation )
                    toast.success( "Proceeding with the previously unfinished exam!", { containerId: "main" } )
                const url = `/admission-exam/${id}/${data.participation_id}/attempt/${data.attempt}`

                navigate( url )
            }
        } catch ( e ) {
            console.log( e )
            setError( { code: e?.response?.status, message: e?.response?.data?.message } )
            if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            else {
                toast.error( e?.response?.data?.message || e?.response?.data || "Something went wrong", { containerId: "main" } )
            }

        } finally {
            setAttempting( false )
        }
    }

    const backToERP = () => {
        window.location = erpUZBLink
    }

    const getExam = useCallback( async () => {
        setLoading( true )
        try {
            const { success, error } = await ExamStore.fetchExam( id )
            if ( success ) {
                setError( null )
            } else {
                setError( error )
            }
        } catch ( err ) {
            console.log( err )
            setError( { code: err?.response?.status, message: err?.response?.data?.message } )
            return
        } finally {
            setLoading( false )
        }
    }, [ExamStore, id] )


    useEffect( () => {
        getExam()
    }, [getExam] )

    return (
        <Box padding="20px" flexGrow={1} overflow="auto">
            <Paper sx={{ overflow: "hidden" }}>
                {/* <Box borderBottom={border[1]} padding="20px">
                    <Typography variant='h6' color="primaryDark" >Welcome to examination portal</Typography>
                    <Typography color="primaryDark" variant='body2' >Read instrctions carefully and start exam.</Typography>
                </Box> */}
                {!loading && !error && ExamStore.getExam?.exam && <Box>
                    <Box padding="20px" borderBottom={border[1]} bgcolor={palette.primary.main}>
                        <Typography color="white" variant='h6'><em>{ExamStore.getExam?.exam?.exam_name}</em></Typography>
                        <Typography color="white" variant='body2'> <strong> <em>Exam Duration: </em> </strong> {+ExamStore.getExam.exam?.exam_duration?.split( ":" )[0]} Hours {+ExamStore.getExam.exam?.exam_duration?.split( ":" )[1]} Minutes</Typography>
                    </Box>

                    <Box padding="20px">
                        {/* <Typography marginTop="20px" color="secondary" variant='h6'>Important Instructions</Typography> */}
                        <Box marginTop="10px" className='discussion-wysiwyg-container' borderRadius="10px">
                            <Box dangerouslySetInnerHTML={{ __html: ExamStore.getExam?.instructions }} ></Box>
                        </Box>
                        {!loading && <Box display="flex" gap="10px" marginTop="40px" alignItems="center">
                            <Button onClick={backToERP} sx={{ textTransform: "capitalize" }} variant='contained' color="errorMessage" disableElevation startIcon={Icons.default.KeyBoardArrowLeft} >Back</Button>
                            <Button onClick={attempt} disabled={attempting} sx={{ textTransform: "capitalize" }} variant='contained' color="primary" disableElevation startIcon={attempting && <CircularProgress size={14} />} endIcon={Icons.default.KeyBoardArrowRight} >Attempt exam</Button>
                        </Box>}
                    </Box>
                </Box>}
                {!error && loading && <DataLoadingSpinner waitingMessage="Loading exam details..." />}
                {error && <TryAgainMessageBlock err={error.message} code={error.code} getData={getExam} loading={loading} />}
            </Paper>
        </Box>
    )
} )

export default AdmissionCandidateHome
