import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React from 'react'
import { observer } from 'mobx-react'

// Layouts
import AdminLayout from './admin/AdminLayout'
import PresenterLayout from './presenter/PresenterLayout'
import UserLayout from './user/UserLayout'

// Exams
import Evaluate from '../pages/exam/Evaluate'
import Scores from '../pages/exam/Scores'
import ParticipationDetails from '../pages/exam/ParticipationDetails'
import EditExam from '../pages/exam/EditExam'
import Exams from '../pages/exam/Exams'
import AddExam from '../pages/exam/AddExam'
import ExamParticipation from '../pages/exam/ExamParticipation'
import Exam from '../pages/exam/Exam'
import AddQuestions from '../pages/exam/AddQuestions'
import ExamAttempt from '../pages/exam/ExamAttempt'
import Participation from '../pages/exam/Participation'
import EditQuestions from '../pages/exam/EditQuestions'

// Study Material
import AddMaterial from '../pages/study-material/AddMaterial'
import Dashboard from '../pages/common/Dashboard'
import Materials from '../pages/study-material/Materials'
import Material from '../pages/study-material/Material'

import Notfound from '../pages/Notfound'
import Logout from '../pages/Logout'
import NetworkError from '../pages/NetworkError'
import ExamNotfound from '../pages/ExamNotFound'

// Video Tutorials
// import VideoTutorial from '../pages/video-tutorials/VideoTutorial'
// import OnlineCourses from '../pages/admin/online-course-pages/OnlineCourses'
// import AddOnlineCourse from '../pages/admin/online-course-pages/AddOnlineCourse'
// import StudentOnlineCourses from '../pages/user/online-course-pages/OnlineCourses'

// Admin
import AdminSignin from '../pages/admin/Signin'
// import TestSignin from '../pages/Signin'
import Home from '../pages/admin/Home'
import LiveClass from '../pages/common/Audit'
import EmbeddBbb from '../pages/common/EmbeddBbb'
import Password from '../pages/admin/Password'
import UserAccount from '../pages/admin/Account'
// import Recording from '../pages/admin/Recordings'
import Institution from '../pages/admin/Institution'
import ApiSettings from '../pages/admin/ApiSettings'
import Analytics from '../pages/admin/Analytics'
import DebugUser from '../pages/admin/DebugUser'

// Presenter and User
import Assignment from '../pages/presenter/Assignment'
import UserAssignment from '../pages/user/Assignment'
import Attendence from '../pages/presenter/Attendence'
import UserAttendence from '../pages/user/Attendence'
import PageNotFound from '../pages/PageNotFound'
// import PresenterRecording from '../pages/presenter/Recording'
import AliveLogin from '../pages/AliveLogin'
import EvaluateAssignment from '../pages/presenter/EvaluateAssignment'

import RequireAuth from './RequireAuth'
import FAQs from '../pages/FAQs'
import StudyMaterial from '../pages/admin/StudyMaterial'
import ExamsReport from '../pages/admin/Exams'
import ManageAccounts from '../pages/admin/ManageAccounts'
import AttendanceAudit from '../pages/admin/AttendanceAudit'
import FeedbackSettings from '../pages/admin/FeedbackSettings'
import SessionFeedback from '../pages/user/SessionFeedback'
import Feedback from '../pages/admin/Feedback'
// import OnlineCourse from '../pages/admin/online-course-pages/OnlineCourse'
// import StudentOnlineCourse from '../pages/user/online-course-pages/OnlineCourse'
// import EnrolledCourses from '../pages/user/online-course-pages/EnrolledCourses'
// import LearnOnlineCourse from '../pages/user/online-course-pages/LearnOnlineCourse'

import ResumeBuilderForm from '../pages/user/resume-builder/ResumeBuilderForm'

import ResumeForms from '../pages/admin/resume-builder/ResumeForms'
import ResumeEntities from '../pages/admin/resume-builder/ResumeEntities'
import ResumeForm from '../pages/admin/resume-builder/ResumeForm'
import ResumePreview from '../pages/user/resume-builder/ResumePreview'
import ResumeTemplate from '../pages/admin/resume-builder/ResumeTemplate'
import ResumeTemplates from '../pages/admin/resume-builder/ResumeTemplates'
// import AdmissionExams from '../pages/admin/admission-exams/AdmissionExams'
// import CreateAdmissionExam from '../pages/admin/admission-exams/CreateAdmissionExam'
import ThankYouPage from '../pages/admission-exam/ThankYouPage'
import Verify from '../pages/admission-exam/Verify'
import AdmissionExamsRequireAuth from './admission-exam-layout/AdmissionExamsRequireAuth'
import AdmissionExam from '../pages/admission-exam/AdmissionExam'
import AdmissionExams from '../pages/admission-exam/AdmissionExams'
import CreateAdmissionExam from '../pages/admission-exam/CreateAdmissionExam'
import AdmissionExamParticipation from '../pages/admission-exam/ExamParticipation'
import AdmissionExamEvaluate from '../pages/admission-exam/AdmissionExamEvaluate'
import AdmissionCandidateHome from '../pages/admission-exam/AdmissionCandidateHome'
import AdmissionExamAttempt from '../pages/admission-exam/AdmissionExamAttempt'
import AdmissionExamLayout from './admission-exam-layout/AdmissionExamLayout'


const AppRoutes = observer( () => {

    return (
        <Router>
            <Routes>
                {/* <Route exact path=erpDashboardLink element={<TestSignin />} /> */}
                <Route exact path='/logout' element={<Logout />} />
                <Route exact path='/session/:access_token' element={<AliveLogin />} />
                <Route path='/admin/login' element={<AdminSignin />} />
                <Route path='/admin' element={<AdminSignin />} />
                <Route exact path='*' element={<PageNotFound />} />

                {/* <Route path="/" exact element={<Login />} /> */}
                {/* ADMISSION EXAM */}
                <Route path="/admission-exam/verify-candidate" exact element={<Verify />} />

                {/* <Route element={<AdmissionExamsRequireAuth allowedRoles={["ADMIN", "SUBADMIN"]} />}>
                    <Route element={<AdmissionExamLayout />}>
                        <Route path="/admission-exam/exam/:id" exact element={<AdmissionExam />} />
                        <Route path="/admission-exam/:id/participation" exact element={<AdmissionExamParticipation />} />
                        <Route path="/admission-exam/:id/evaluate/:participation_id" exact element={<AdmissionExamEvaluate />} />
                        <Route path="/admission-exam/" exact element={<AdmissionExams />} />
                        <Route path="/admission-exam/new" exact element={<CreateAdmissionExam />} />
                    </Route>
                </Route> */}
                <Route element={<AdmissionExamsRequireAuth allowedRoles={["CANDIDATE"]} />}>
                    <Route element={<AdmissionExamLayout />}>
                        <Route path="/thankyou" exact element={<ThankYouPage />} />
                        <Route path="/take-admission-exam/:id" exact element={<AdmissionCandidateHome />} />
                        <Route path="/admission-exam/:id/:pid/attempt/:attempt" exact element={<AdmissionExamAttempt />} />
                    </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={["ADMIN", "SUBADMIN"]} />}>
                    <Route element={<AdminLayout />} >

                        <Route path="/admission-exam/exam/:id" exact element={<AdmissionExam />} />
                        <Route path="/admission-exam/:id/participation" exact element={<AdmissionExamParticipation />} />
                        <Route path="/admission-exam/:id/evaluate/:participation_id" exact element={<AdmissionExamEvaluate />} />
                        <Route path="/admission-exam/" exact element={<AdmissionExams />} />
                        <Route path="/admission-exam/new" exact element={<CreateAdmissionExam />} />

                        <Route path='/admin/resume-forms' element={<ResumeForms />} />
                        <Route path='/admin/resume-forms/:form_id' element={<ResumeForm />} />
                        <Route path='/admin/resume-entities' element={<ResumeEntities />} />
                        <Route path='/admin/resume-templates' element={<ResumeTemplates />} />
                        <Route path='/admin/resume-templates/:template_id' element={<ResumeTemplate />} />

                        {/* <Route path='/admin/admission-exam' element={<AdmissionExams />} /> */}
                        {/* <Route path='/admin/admission-exam/new' element={<CreateAdmissionExam />} /> */}

                        <Route path='/admin/home' element={<Home />} />
                        <Route path='/admin/audit' element={<LiveClass />} />
                        <Route path='/admin/feedback' element={<Feedback />} />
                        <Route path='/admin/feedback-setting' element={<FeedbackSettings />} />
                        <Route path='/admin/study-material' element={<StudyMaterial />} />
                        <Route path='/admin/accounts' element={<ManageAccounts />} />
                        <Route path='/admin/exam' element={<ExamsReport />} />
                        <Route path='/admin/attendance' element={<AttendanceAudit />} />
                        <Route path='/admin/stream' element={<EmbeddBbb />} />
                        <Route path='/admin/password' element={<Password />} />
                        <Route path='/admin/account' element={<UserAccount />} />
                        {/* <Route path='/admin/recordings' element={<Recording />} /> */}
                        <Route path='/admin/institution' element={<Institution />} />
                        <Route path='/admin/api-setting' element={<ApiSettings />} />
                        <Route path='/admin/analytics' element={<Analytics />} />
                        <Route path='/admin/debug' element={<DebugUser />} />
                        <Route path='/admin/networkerror' element={<NetworkError />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={["HOD", "PRESENTER", "FACULTY", "PRINCIPAL"]} />}>
                    <Route element={<PresenterLayout />}>
                        <Route path='/faculty/home' element={<Dashboard />} />
                        <Route path='/faculty/assignment' element={<Assignment />} />
                        <Route path='/faculty/assignment/:assignment_id' element={<EvaluateAssignment />} />
                        <Route path='/faculty/attendance' element={<Attendence />} />
                        <Route path='/faculty/audit' element={<LiveClass />} />
                        <Route path='/faculty/stream' element={<EmbeddBbb />} />
                        {/* <Route path='/faculty/recordings' element={<PresenterRecording />} /> */}


                        <Route path='/faculty/material/add' element={<AddMaterial />} />
                        <Route path='/faculty/material' element={<Materials />} />
                        <Route path='/faculty/material/:material_id' element={<Material />} />


                        <Route path='/faculty/exams' element={<Exams />} />
                        <Route path='/faculty/exams/:id' element={<Exam />} />
                        <Route path='/faculty/exams/:id/edit' element={<EditExam />} />
                        <Route path='/faculty/exams/:id/questions' element={<EditQuestions />} />
                        <Route path='/faculty/exams/:id/participation' element={<ExamParticipation />} />
                        <Route path='/faculty/participations' element={<Participation />} />
                        <Route path='/faculty/exams/:id/evaluate/:participation_id' element={<Evaluate />} />
                        <Route path='/faculty/exams/:id/addquestions' element={<AddQuestions />} />
                        <Route path='/faculty/addexam' element={<AddExam />} />


                        <Route path='/faculty/notfound' element={<Notfound />} />
                        <Route path='/faculty/examnotfound' element={<ExamNotfound />} />
                        <Route path='/faculty/networkerror' element={<NetworkError />} />
                        <Route exact path='/faculty/faq' element={<FAQs auth="faculty" />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={["STUDENT"]} />}>
                    <Route element={<UserLayout />}>
                        <Route path='/student/dashboard' element={<Dashboard />} />
                        <Route path='/student/stream' element={<EmbeddBbb />} />
                        <Route path='/student/home' element={<Dashboard />} />
                        <Route path='/student/assignment' element={<UserAssignment />} />
                        <Route path='/student/attendance' element={<UserAttendence />} />
                        <Route path='/student/feedback' element={<SessionFeedback />} />


                        {/* <Route path='/student/online-course' element={<StudentOnlineCourses />} />
                        <Route path='/student/online-course/:course_id' element={<StudentOnlineCourse />} />
                        <Route path='/student/my-online-course/' element={<EnrolledCourses />} />
                        <Route path='/student/online-course/:course_id/learn/:progress_id' element={<LearnOnlineCourse />} /> */}


                        <Route path='/student/material' element={<Materials />} />
                        <Route path='/student/material/:material_id' element={<Material />} />


                        <Route path='/student/exams' element={<Exams />} />
                        <Route path='/student/exams/:id' element={<Exam />} />
                        <Route path='/student/scores' element={<Scores />} />
                        <Route path='/student/exams/:id/attempt/:attempt/:pid' element={<ExamAttempt />} />
                        <Route path='/student/scores/:exam_id/:participation_id' element={<ParticipationDetails />} />
                        <Route path='/student/notfound' element={<Notfound />} />
                        <Route path='/student/examnotfound' element={<ExamNotfound />} />

                        <Route path='/student/resume-builder' element={<ResumeBuilderForm />} />
                        <Route path='/student/resume-builder/preview' element={<ResumePreview />} />

                        <Route path='/student/networkerror' element={<NetworkError />} />
                        <Route exact path='/student/faq' element={<FAQs auth="student" />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    )
} )

export default AppRoutes
