import { Box, Button, Chip, CircularProgress, Collapse, FormControl, FormHelperText, TextField, Typography, Paper, Select, MenuItem, InputLabel, Dialog, DialogContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import Layout from '../../components/Layout'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FileInput from '../../components/study-material-components/FileInput'
import { toast } from 'react-toastify'
// import { Axios } from '../../utils/utilities'
import { Link, useNavigate } from 'react-router-dom'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import { useUserStore } from '../../store/user.store'
import sessionExpiredImg from '../../assets/warning.svg'
import { Icons, erpDashboardLink } from '../../utils/utilities'

const AddMaterial = () => {

    const [tags, setTags] = useState( [] )
    const [selectedAttachments, setSelectedAttachments] = useState( [] )
    const [isAddingMaterial, setIsAddingMaterial] = useState( false )
    const [isLoadingSubjects, setIsLoadingSubjects] = useState( true )
    const [availableSubjects, setAvailableSubjects] = useState( [] )
    const [sessionExpired, setSessionExpired] = useState( false )
    const [attachmentsNames, setAttachmentsNames] = useState( [] )
    const [progress, setProgress] = useState( 0 )
    const [subject, setSubject] = useState( '' )
    const navigate = useNavigate()
    const StudyMaterialStore = useStudyMaterialStore()
    const UserStore = useUserStore()

    const initialValues = {
        materialName: '',
        materialDesc: '',
        tags: '',
        subject: '',
        attachments: null
    }

    const validationSchema = Yup.object().shape( {
        materialName: Yup.string().required( "Material name is required" ),
        subject: Yup.string().required( "Subject is required" )
    } )

    const deleteTag = ( index ) => {
        const t = [...tags]
        t.splice( index, 1 )
        setTags( t )
    }

    const handleKeyDown = ( e, setFieldValue ) => {
        if ( e.code === "Enter" ) {
            e.preventDefault()
            if ( e.target.value.trim() !== '' ) {
                let newTags = e.target.value.split( "," )
                let filteredTags = []
                newTags.forEach( tag => {
                    if ( tag.trim() !== '' ) filteredTags.push( tag )
                } )
                setTags( [...tags, ...filteredTags] )
                setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
                e.target.value = ""
            }
        }
    }

    const progressHandler = ( event ) => {
        const { loaded, total } = event
        let percent = Math.round( ( loaded * 100 ) / total )
        console.log( percent )
        if ( percent <= 100 ) {
            setProgress( percent )
        }
    }

    const handleBlur = ( e, setFieldValue ) => {
        if ( e.target.value.trim() !== '' ) {
            let newTags = e.target.value.split( "," )
            let filteredTags = []
            newTags.forEach( tag => {
                if ( tag.trim() !== '' ) filteredTags.push( tag )
            } )
            setTags( [...tags, ...filteredTags] )
            setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
            e.target.value = ""
        }
    }

    const addMaterial = async ( values, { resetForm } ) => {
        setIsAddingMaterial( true )
        try {
            const formData = new FormData()
            formData.append( 'name', values.materialName )
            formData.append( 'description', values.materialDesc )
            formData.append( 'tags', values.tags )
            formData.append( 'subject', JSON.stringify( UserStore.getUserSubjects.find( sub => sub.subject_assignment_id === values.subject ) ) )
            let emptyName = false
            for ( let i = 0; i < attachmentsNames.length; i++ ) {
                const name = attachmentsNames[i]
                if ( name === "" ) {
                    emptyName = true
                }
            }
            if ( !emptyName ) {
                if ( values.attachments ) {
                    values.attachments.forEach( ( file, i ) => {
                        const ext = file.name.split( "." ).pop()
                        let fileName = encodeURIComponent( `${file.name.split( ext )[0]}-${Date.now()}${i}.${ext}`.replace( /\s/g, '-' ) )
                        formData.append( 'attachments[]', file, fileName )
                    } )
                }
                formData.append( 'attachmentNames[]', JSON.stringify( attachmentsNames ) )
                await StudyMaterialStore.createMaterial( formData, progressHandler )
                setIsAddingMaterial( false )
                navigate( '/faculty/material' )
            } else {
                toast( "Please provide names for all the attachments" )
            }
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsAddingMaterial( false )
        }
    }

    useEffect( () => {
        const getSubjects = async () => {
            if ( !await UserStore.fetchUserSubjects() ) {
                setSessionExpired( true )
            }
            await StudyMaterialStore.fetchMaterials()
            const facultyMaterials = StudyMaterialStore.getMaterials["materials"]
            let availableSubjects = []
            for ( let i = 0; i < UserStore.getUserSubjects.length; i++ ) {
                const sub = UserStore.getUserSubjects[i]
                if ( facultyMaterials.findIndex( material => String( material.subject_assignment_id ) === String( sub.subject_assignment_id ) ) < 0 ) {
                    availableSubjects.push( sub )
                }
            }
            setAvailableSubjects( availableSubjects )
            setIsLoadingSubjects( false )
        }
        getSubjects()
    }, [UserStore, StudyMaterialStore] )

    return (
        <Box padding="0 20px 20px 20px">
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link className="breadcrumb-link" to={UserStore.getUser['user_role'] === "STUDENT" ? '/student/material' : '/faculty/material'}>Study Materials</Link>{Icons.SmallChevronRightIcon} Add material</Typography>
            <Dialog open={sessionExpired}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img width="150px" src={sessionExpiredImg} alt="Session Expired!" />
                        <Typography marginTop="10px">Session has expired! please login again</Typography>
                        <Button onClick={() => window.location.replace( erpDashboardLink )} color='error'>Login</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Paper sx={{ padding: "20px" }}>
                <Typography variant='h5' color="primary" fontWeight="normal">Add new material</Typography>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={addMaterial}>
                    {( { setFieldValue, resetForm, values } ) => (
                        <Form>
                            <FormControl fullWidth margin="normal" sx={{ marginTop: "20px" }}>
                                <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} fullWidth type="text" label="Material name" name="materialName" />
                                <FormHelperText sx={{ color: "error.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="materialName" /> </FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" >
                                <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} multiline rows={5} fullWidth type="text" label="Material description" name="materialDesc" />
                                <FormHelperText> <ErrorMessage name="materialDesc" /> </FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>{isLoadingSubjects ? "Loading subjects..." : 'Subject'}</InputLabel>
                                <Field as={Select} onChange={( e ) => { setSubject( e.target.value ); setFieldValue( 'subject', e.target.value ) }} value={subject} disabled={isLoadingSubjects} name='subject' sx={{ fontSize: "14px", "&:disabled": { color: "rgba(0,0,0,0.4)" } }} label={isLoadingSubjects ? "Loading subjects...--" : 'Subject--'}>
                                    {/* <MenuItem sx={{ fontSize: "14px" }} value={0} disabled>{isLoadingSubjects ? <Typography display="flex" alignItems="center" gap="5px" fontSize="14px" > <CircularProgress size={14} />Loading subjects... </Typography> : "Select subject"}</MenuItem> */}
                                    {!isLoadingSubjects && availableSubjects.map( sub => (
                                        <MenuItem key={sub.subject_assignment_id} sx={{ fontSize: "14px" }} value={sub.subject_assignment_id}>{`${sub.subject_name}-${sub.subject_code} (${sub.ac_year})`}</MenuItem>
                                    ) )}
                                    {!isLoadingSubjects && availableSubjects.length === 0 && <Typography padding="10px" variant="subtitle2" color="GrayText"  >
                                        No subjects please contact ERP team.
                                    </Typography>}
                                </Field>
                                <Typography marginTop="5px" fontSize="12px">If subject not displayed in the dropdown then you already have a material for that subject</Typography>
                                <FormHelperText sx={{ color: "error.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="subject" /> </FormHelperText>
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography marginTop="10px" gutterBottom sx={{ color: "#777" }} fontSize="14px">Add Tags {`(Type tags as comma seperated values)`}</Typography>
                                <Box >
                                    <TextField type="text" placeholder='Add new tag' InputProps={{ sx: { fontSize: "14px !important" } }} fullWidth onBlur={( e ) => { handleBlur( e, setFieldValue ) }} onKeyDown={e => { handleKeyDown( e, setFieldValue ) }} />
                                    <Collapse in={tags.length > 0}>
                                        <Typography marginTop="10px" gutterBottom color="textSecondary">Added tags  </Typography>
                                        <Box display="flex" alignItems="center" gap="10px">
                                            {tags.length > 0 && tags.map( ( tag, index ) => (
                                                <Chip key={index} label={tag} onDelete={() => deleteTag( index, setFieldValue )} size="medium" />
                                            ) )}
                                        </Box>
                                    </Collapse>
                                </Box>
                            </FormControl>
                            <FileInput progress={progress} size={60} isUploading={isAddingMaterial} selectedAttachments={selectedAttachments} setSelectedAttachments={setSelectedAttachments} setFieldValue={setFieldValue} attachmentsNames={attachmentsNames} setAttachmentsNames={setAttachmentsNames} />
                            <Box marginTop="20px" alignItems="center" gap="20px" display="flex">
                                <Button disabled={isAddingMaterial} type="submit" variant='contained' sx={{ textTransform: "capitalize", height: "53px" }} disableElevation>{isAddingMaterial ? <Typography gap="10px" display="flex" alignItems="center"> <CircularProgress size={14} /> Adding Material please wait...</Typography> : `Add new material`}</Button>
                                <Button disableElevation variant='contained' onClick={() => navigate( '/faculty/material' )} color='error' sx={{ textTransform: "capitalize", height: "53px" }}>Cancel</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Box>
    )
}

export default AddMaterial


