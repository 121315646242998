import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useDownloadStatusListStore } from '../../store/downloadstatuslistdialog.store'
import { ArrowDropDown, CheckRounded, DescriptionOutlined, FolderOpen } from '@mui/icons-material'
import { Icons } from '../../utils/utilities'
import Progress from '../Progress'
import { observer } from 'mobx-react'

const DownloadStatusListDialog = observer( () => {

    const DownloadStatusListStore = useDownloadStatusListStore()

    const handleDownloadsDialogClose = () => {
        DownloadStatusListStore.closeDialog()
    }

    return (
        <Box>
            {Object.keys( DownloadStatusListStore.getProgressStatusList ).length > 0 && <Box sx={{ position: "fixed", display: { md: "block", xs: "none" }, zIndex: 2000, borderRadius: "5px", background: "#fff", bottom: "20px", right: "20px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", width: "250px", maxWidth: "250px" }}>
                <Box display="flex" padding="10px" alignItems="center" justifyContent="space-between">
                    {DownloadStatusListStore.getDownloadingCount > 0 ? <Typography variant="subtitle2">
                        Downloading {Object.values( DownloadStatusListStore.getProgressStatusList ).filter( item => item.inProgress || item.progress >= 100 ).length} items...
                    </Typography> : <Typography variant="subtitle2">
                        Downloaded {Object.values( DownloadStatusListStore.getProgressStatusList ).length} items
                    </Typography>}
                    <IconButton size="small" onClick={handleDownloadsDialogClose}>
                        {!DownloadStatusListStore.getDialogStatus ? <ArrowDropDown /> : Icons.CloseIcon}
                    </IconButton>
                </Box>
                {DownloadStatusListStore.getDialogStatus && <Box maxHeight="150px" overflow="auto">
                    {Object.keys( DownloadStatusListStore.getProgressStatusList ).map( key => (
                        <Box key={key} borderTop="2px solid rgba(0,0,0,0.1)" fontSize="14px" display="flex" alignItems="center" justifyContent="space-betweeen" gap="5px" padding="10px">
                            <Box flexGrow="1" display="flex" alignItems="center" gap="5px">
                                {DownloadStatusListStore.getProgressStatusList[key].type === 'material' ? <FolderOpen fontSize="small" color="textSecondary" /> : <DescriptionOutlined fontSize="small" color="textSecondary" />}
                                <Typography fontSize="12px" width="150px" noWrap>{DownloadStatusListStore.getProgressStatusList[key].name}</Typography>
                            </Box>
                            {DownloadStatusListStore.getProgressStatusList[key].inProgress ? <Progress size={30} progress={DownloadStatusListStore.getProgressStatusList[key].progress} /> : <CheckRounded color="success" />}
                        </Box>
                    ) )}
                </Box>}
            </Box>}
        </Box>
    )
} )

export default DownloadStatusListDialog
