import { Box, Table, TableRow, TableBody, TableCell, TableContainer, TableHead, Typography, useTheme } from '@mui/material'
import React from 'react'
import { calculateDurationBetween, formatDateTimeForDisplay } from '../../utils/exam-utilities'
import { useNavigate } from 'react-router-dom'

const columns = [
    { id: 0, name: "Sl.no", paddingLeft: "20px", minWidth: "30px", width: "50px" },
    { id: 1, name: "Title", minWidth: "100px", width: "100px" },
    { id: 3, name: "Details", minWidth: "200px", width: "150px" },
    { id: 2, name: "Exam Date", minWidth: "100px", width: "100px" },
    { id: 4, name: "Exam Duration", minWidth: "100px", width: "100px" },
]


export default function ListView( { exams, userRole, isAuditing } ) {

    const navigate = useNavigate()
    const { palette } = useTheme()

    return (
        <Box display="flex" height="100%" flexDirection="column" overflow="auto" >
            {exams.length > 0 && exams.length !== 0 &&
                <TableContainer sx={{ background: "white" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map( ( column ) => (
                                    <TableCell key={column.id} align="left" sx={{ paddingLeft: column.paddingLeft, background: palette.primary.main, color: "#fff", minWidth: `${column.minWidth} !important`, width: column.width, }}>{column.name}</TableCell>
                                ) )}
                                {userRole !== "STUDENT" && <TableCell key={4} align="left" sx={{ background: palette.primary.main, color: "#fff", minWidth: "50px", width: "50px", }}>Status</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {exams.map( ( exam, index ) => (
                                <TableRow key={exam.exam_id} onClick={() => { userRole === "STUDENT" ? navigate( `/student/exams/${exam.exam_id}` ) : navigate( `/faculty/exams/${exam.exam_id}` ) }} sx={{ cursor: "pointer", "&:hover": { background: "#eee" } }}>
                                    <TableCell sx={{ paddingLeft: "20px" }}>{index + 1}</TableCell>
                                    <TableCell sx={{ padding: "5px", color: "black", "&:hover": { color: "#273679 !important", textDecoration: "underlined !important" } }} ><Typography sx={{ textDecoration: "underline !important" }} noWrap fontSize="14px" fontWeight='bold'>{exam.exam_name}</Typography></TableCell>
                                    <TableCell style={{ padding: "5px", fontSize: "12px", textTransform: "capitalize" }}>{`${exam.exam_description.substring( 0, 50 )}${exam.exam_description.length > 50 ? "..." : ""}`}</TableCell>
                                    <TableCell style={{ padding: "5px", fontSize: "12px", textTransform: "capitalize" }}>{formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</TableCell>
                                    {( exam.exam_duration === "null" || exam.exam_duration === null ) && <TableCell sx={{ padding: "5px", fontSize: "12px", textTransform: "capitalize" }}>
                                        {calculateDurationBetween( { date: exam.exam_start_date, time: exam.exam_start_time }, { date: exam.exam_end_date, time: exam.exam_end_time } )}
                                    </TableCell>}
                                    {exam.exam_duration !== "null" && exam.exam_duration !== null && <TableCell sx={{ padding: "5px", fontSize: "12px", textTransform: "capitalize" }}  > {`${exam.exam_duration.split( ":" )[0]} Hrs ${exam.exam_duration.split( ":" )[1]} Min`}</TableCell>}
                                    {userRole !== "STUDENT" && <TableCell key={4} align="left" sx={{ padding: "5px", minWidth: "50px", width: "50px", background: "transparent", color: exam.exam_status === 'Published' ? "green" : "gray", fontSize: "12px" }} >{exam.exam_status.toUpperCase()}</TableCell>}
                                </TableRow>
                            ) )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {exams.length === 0 && <Typography padding="10px" borderRadius="5px" sx={{ background: "#eee" }} variant='h5' className='mt-2' >No exams!</Typography>}
        </Box >
    )
}
