import { Box, Button, Chip, Tooltip, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tab, TablePagination, TextField, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useOrganizationStore } from '../../store/organization.store'
import api from '../../service/api'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import { observer } from 'mobx-react'
import { Icons, exportStudyMaterialsReport } from '../../utils/utilities'
import GridView from '../../components/study-material-components/GridView'
import ListView from '../../components/study-material-components/ListView'
import TableRowsIcon from '@mui/icons-material/TableRows'
import GridViewIcon from '@mui/icons-material/GridView'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { toast } from 'react-toastify'
import selectIntituteImg from '../../assets/selectinstitute.svg'
import nodataImg from '../../assets/nodata.svg'
import { useUserStore } from '../../store/user.store'
import warningImg from '../../assets/warning.svg'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DetailsViewer from '../../components/study-material-components/DetailsViewer'
import { useSearchParams } from 'react-router-dom'
import { useDownloadStatusListStore } from '../../store/downloadstatuslistdialog.store'


const StudyMaterial = observer( () => {

    const [isLoading, setIsLoading] = useState( true )
    const [docsCount, setDocsCount] = useState( {} )
    const [isMaterialsLoading, setIsMaterialsLoading] = useState( false )
    const [stats, setStats] = useState( [] )
    const [deptFacultyMap, setDeptFacultyMap] = useState( {} )
    const [rowsPerPage, setRowsPerPage] = useState( 10 )
    const [page, setPage] = useState( 0 )
    const [isDownloading, setIsDownloading] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )
    const [sortState, setSortState] = useState( {} )
    const [sortedMaterials, setSortedMaterials] = useState( [] )
    const [showDetails, setShowDetails] = useState( false )
    const [attachmentsSelectedForDetails, setAttachmentsSelectedForDetails] = useState( null )

    const OrganizationStore = useOrganizationStore()
    const StudyMaterialStore = useStudyMaterialStore()
    const DownloadStatusListStore = useDownloadStatusListStore()
    const UserStore = useUserStore()
    const { palette } = useTheme()

    const [searchParams, setSearchParams] = useSearchParams( { viewType: "grid", tabValue: "0", statsInstituteCode: "0", searchText: "", mode: "list", instituteCode: "0" } )
    const viewType = searchParams.get( "viewType" )
    const tabValue = searchParams.get( "tabValue" )
    const statsInstituteCode = searchParams.get( "statsInstituteCode" )
    const searchText = searchParams.get( "searchText" )
    const mode = searchParams.get( "mode" )
    const instituteCode = searchParams.get( "instituteCode" )

    const setMode = newValue => setSearchParams( prev => { prev.set( "mode", newValue ); return prev }, { replace: true } )
    const setTabValue = newValue => setSearchParams( prev => { prev.set( "tabValue", newValue ); return prev }, { replace: true } )
    const setViewType = newValue => setSearchParams( prev => { prev.set( "viewType", newValue ); return prev }, { replace: true } )
    const setStatsInstituteCode = newValue => setSearchParams( prev => { prev.set( "statsInstituteCode", newValue ); return prev }, { replace: true } )
    const setSearchText = newValue => setSearchParams( prev => { prev.set( "searchText", newValue ); return prev }, { replace: true } )

    const statsColumns = [
        {
            name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
                Faculty name
                <IconButton onClick={() => { sortByProperty( stats, setStats, "study_material_created_by" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee6", color: "whitesmoke", width: "20px", height: "20px" } }} size='small'>
                    {sortState.study_material_created_by ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}
                </IconButton>
            </Box>, align: "left", minWidth: "100px"
        },
        { name: "Employee ID", align: "left", minWidth: "150px" },
        { name: "Institute Department", align: "left", minWidth: "200px" },
        {
            name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
                Study Materials
                <IconButton onClick={() => { sortByProperty( stats, setStats, "material_count", "number" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee6", color: "whitesmoke", width: "20px", height: "20px" } }} size='small'>
                    {sortState.material_count ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}
                </IconButton>
            </Box>, align: "left", minWidth: "170px"
        },
        {
            name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
                Documents
                <IconButton onClick={() => { sortByProperty( stats, setStats, "attachment_count", "number" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee6", color: "whitesmoke", width: "20px", height: "20px" } }} size='small'>
                    {sortState.attachment_count ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}
                </IconButton>
            </Box>, align: "left", minWidth: "100px"
        },
        {
            name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
                Downloads
                <IconButton onClick={() => { sortByProperty( stats, setStats, "sum", "number" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee6", color: "whitesmoke", width: "20px", height: "20px" } }} size='small'>
                    {sortState.sum ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}
                </IconButton>
            </Box>, align: "left", minWidth: "100px"
        },
    ]


    const columns = [
        { name: "Material Name", align: "left", minWidth: "200px" },
        { name: "Created By", align: "left", minWidth: "200px" },
        { name: "Subject-Code", align: "left", minWidth: "200px" },
        {
            name: <Box gap="10px" justifyContent="flex-end" display="flex" alignItems="center">
                Document count
                <IconButton onClick={() => { sortDocumentCount( sortedMaterials, setSortedMaterials, docsCount, "sum", "number" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee6", color: "whitesmoke", width: "20px", height: "20px" } }} size='small'>
                    {sortState.sum ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}
                </IconButton>
            </Box>, align: "left", minWidth: "200px"
        }
    ]

    const sortByProperty = ( data, setNewData, property, type ) => {
        let newFilteredData = data
        newFilteredData.sort( ( a, b ) => {
            if ( type === "number" ) {
                if ( !sortState[property] )
                    return ( parseInt( a[property] ) - parseInt( b[property] ) )
                else
                    return ( parseInt( b[property] ) - parseInt( a[property] ) )
            } else {
                if ( !sortState[property] )
                    return ( ( a[property] < b[property] ) ? -1 : 0 )
                else
                    return ( ( a[property] > b[property] ) ? -1 : 0 )
            }
        } )
        setSortState( { ...sortState, [property]: sortState[property] ? false : true } )
        setNewData( newFilteredData )
    }

    const sortDocumentCount = ( data, setData, ref, property, type ) => {
        let newFilteredData = data
        console.log( ref )
        newFilteredData.sort( ( a, b ) => {
            console.log( parseInt( ref[a["study_material_id"]] ), parseInt( ref[b["study_material_id"]] ) )
            if ( type === "number" ) {
                if ( !sortState[property] )
                    return ( parseInt( ref[a["study_material_id"]] ) - parseInt( ref[b["study_material_id"]] ) )
                else
                    return ( parseInt( ref[b["study_material_id"]] ) - parseInt( ref[a["study_material_id"]] ) )
            } else {
                if ( !sortState[property] )
                    return ( ( ref[a["study_material_id"]] < ref[b["study_material_id"]] ) ? -1 : 0 )
                else
                    return ( ( ref[a["study_material_id"]] > ref[b["study_material_id"]] ) ? -1 : 0 )
            }
        } )
        console.log( data )
        setSortState( { ...sortState, [property]: sortState[property] ? false : true } )
        setData( newFilteredData )
    }

    const openMaterial = async ( id ) => {
        setIsMaterialsLoading( true )
        setMode( "view" )
        await StudyMaterialStore.fetchMaterial( id )
        console.log( StudyMaterialStore.getMaterial )
        setIsMaterialsLoading( false )
    }

    const handleInstituteChange = async ( val ) => {
        setSearchParams( prev => { prev.set( "instituteCode", val ); return prev }, { replace: true } )
        // setDeptCode( "0" )
        // setIsDeptLoading( true )
        try {
            setIsMaterialsLoading( true )
            await StudyMaterialStore.fetchInstituteMaterials( val )
            const countsData = StudyMaterialStore.getMaterials["docsCount"]
            const counts = {}
            countsData.forEach( count => {
                counts[count.study_material_id] = count.docs_count
            } )
            setDocsCount( counts )
            setSortedMaterials( StudyMaterialStore.getMaterials.materials )
            // setDepartments( depts.data.data )
            console.log( StudyMaterialStore.getMaterials )
        } catch ( err ) {
            console.log( err )
        } finally {
            // setIsDeptLoading( false )
            setIsMaterialsLoading( false )
        }
        setIsLoading( false )
    }

    const downloadStudyMaterial = async () => {
        setIsDownloading( true )
        toast( `Downloading ${StudyMaterialStore.getMaterial.studyMaterial.study_material_name}...`, { position: "bottom-left" } )
        try {
            await DownloadStatusListStore.downloadMaterial( StudyMaterialStore.getMaterial.studyMaterial.study_material_id, StudyMaterialStore.getMaterial.studyMaterial )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsDownloading( false )
        }
    }

    const handleStatsInstituteChange = async ( val ) => {
        try {
            setIsLoading( true )
            setStatsInstituteCode( val )
            const stats = await api.getOrganizationStats( val )
            console.log( stats.data.data )
            setStats( stats.data.data.studyMaterialData )
            const mapData = {}
            for ( let i = 0; i < stats.data.data.deptData.length; i++ ) {
                const item = stats.data.data.deptData[i]
                mapData[item.session_id] = item.department
            }
            console.log( mapData )
            setDeptFacultyMap( mapData )
        } catch ( err ) {
            console.log( err )
            toast( err.response.data.message )
        } finally {
            setIsLoading( false )
        }
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    useEffect( () => {
        const getOrganizations = async () => {
            await OrganizationStore.fetchOrganization()
            if ( statsInstituteCode !== "0" )
                await handleStatsInstituteChange( statsInstituteCode )
            if ( instituteCode !== "0" )
                await handleInstituteChange( instituteCode )
            setIsLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.analytics ) {
            setHasPermission( false )
        }
        getOrganizations()
        // eslint-disable-next-line
    }, [OrganizationStore, UserStore] )

    return (
        <Box paddingTop="0" >
            {hasPermission && <Paper sx={{ padding: "20px" }}>
                <Typography variant='h5'>Study Material Audit</Typography>
                <Typography marginBottom="20px" variant='subtitle2' color="textSecondary">Audit Study materials uploaded</Typography>
                <TabContext value={tabValue}>
                    <Box sx={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                        <TabList sx={{ borderBottom: "1px solid grey" }} onChange={( e, newValue ) => { setTabValue( newValue ); setPage( 0 ); setSearchText( '' ) }}>
                            <Tab sx={{ "&:hover": { background: "#eee" }, background: tabValue === "0" ? "#eee" : "#fff" }} icon={Icons.AnalyticsIcon} iconPosition='start' value={"0"} label="Faculty Stats" />
                            <Tab sx={{ "&:hover": { background: "#eee" }, background: tabValue !== "0" ? "#eee" : "#fff" }} icon={Icons.DescriptionIcon} value={"1"} iconPosition='start' label="Study Materials" />
                        </TabList>
                        <TabPanel sx={{ padding: "10px" }} value={"1"}>
                            {mode === "list" && <Box display="flex" gap="10px" marginBottom="10px" alignItems="center" flexDirection={{ md: "row", xs: "column" }}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select Institute</InputLabel>
                                    <Select variant="outlined" disabled={isLoading} onChange={e => handleInstituteChange( e.target.value )} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={instituteCode} label="Select Institute---">
                                        <MenuItem sx={{ fontSize: "14px" }} value="0">{isLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                                        {
                                            OrganizationStore.getOrganization.map( ( obj, i ) => {
                                                return <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={obj.school_name_short} key={'key-' + i}> {( obj.school_name ).toLowerCase()} </MenuItem>
                                            } )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>}
                            {!isMaterialsLoading && <Box>
                                {mode === "list" && <Box>
                                    {!isMaterialsLoading && instituteCode !== "0" && StudyMaterialStore.getMaterials.materials.length > 0 && <Box display="flex" flexDirection={{ sm: "row", xs: "column" }} sx={{ color: "white" }} gap="20px" alignItems="center" bgcolor={palette.primary.main} borderRadius="5px" padding="20px">
                                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                            <Typography variant='h6'>Study Materials</Typography>
                                            <Typography variant="h3">{StudyMaterialStore.getMaterials.materials.length}</Typography>
                                            <Typography fontSize="12px">Total number of study materials in Alive</Typography>
                                        </Box>
                                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                            <Typography variant='h6'>Attachments</Typography>
                                            <Typography variant='h3'>{StudyMaterialStore.getMaterials.docsCount.reduce( ( sum, item ) => sum + parseInt( item.docs_count ), 0 )}</Typography>
                                            <Typography fontSize="12px">Total Number of attachments</Typography>
                                        </Box>
                                        <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                            <Typography variant='h6'>Downloads</Typography>
                                            <Typography variant='h3'>{StudyMaterialStore.getMaterials.docsCount.reduce( ( sum, item ) => sum + parseInt( item.download_count ), 0 )}</Typography>
                                            <Typography fontSize="12px">Total downloads by students</Typography>
                                        </Box>
                                    </Box>}
                                    {!isMaterialsLoading && instituteCode !== "0" && StudyMaterialStore.getMaterials.materials.length === 0 && <Box display="flex" flexDirection={{ sm: "row", xs: "column" }} gap="20px" alignItems="center" borderRadius="5px">
                                        <Box flexGrow={1} padding="20px" bgcolor="#eee" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                                            <img src={nodataImg} width="200px" alt="No materials" />
                                            <Typography marginTop="20px" textAlign="center" variant='subtitle2'>
                                                No materials for selected institutes
                                            </Typography>
                                        </Box>
                                    </Box>}
                                    {!isMaterialsLoading && StudyMaterialStore.getMaterials.materials.length > 0 && <TextField onChange={( e ) => setSearchText( e.target.value )} value={searchText} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ marginTop: "20px", border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search by faculty/subject" variant="standard" type="text" />}
                                    {!isMaterialsLoading && StudyMaterialStore.getMaterials.materials.length > 0 &&
                                        <Box bgcolor="#eee" marginTop="20px">
                                            <TableContainer sx={{ borderRadius: "0 0 5px 5px", maxHeight: "450px", borderLeft: "1px solid rgba(0,0,0,0.1)", borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                                <Table stickyHeader>
                                                    <TableHead sx={{ background: palette.primary.main }}>
                                                        <TableRow sx={{ background: palette.primary.main }}>
                                                            {columns.map( ( item, i ) => (
                                                                <TableCell key={`row-${i}`} sx={{ color: "#fff", background: palette.primary.main, display: item.display, minWidth: item.minWidth }} align={item.align}>{item.name}</TableCell>
                                                            ) )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {sortedMaterials.filter( item => ( item.study_material_name.toLowerCase().includes( searchText ) || item.study_material_created_by.toLowerCase().includes( searchText ) || item.subject_name.toLowerCase().includes( searchText ) || item.subject_code.toLowerCase().includes( searchText ) ) ).slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                            .map( ( material, index ) => (
                                                                <TableRow sx={{ background: "white", cursor: "pointer", "&:hover": { background: "#ccc !important" } }} onClick={() => openMaterial( material.study_material_id )} key={material.study_material_id}>
                                                                    <TableCell sx={{ fontWeight: "bold", width: "100%", display: "table-cell", alignItems: "center", gap: "10px" }} align='left'> <Box display="flex" gap="5px" alignItems="center">
                                                                        {Icons.FolderIcon} {material.study_material_name}
                                                                    </Box> </TableCell>
                                                                    <TableCell align='left'>{material.study_material_created_by}</TableCell>
                                                                    <TableCell align='left'>{material.subject_name}-{material.subject_code}</TableCell>
                                                                    <TableCell align='left'>{docsCount[material.study_material_id] ? docsCount[material.study_material_id] : 0}</TableCell>
                                                                </TableRow>
                                                            ) )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                sx={{ background: "#eee" }}
                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                component="div"
                                                count={sortedMaterials.filter( item => ( item.study_material_name.toLowerCase().includes( searchText ) || item.study_material_created_by.toLowerCase().includes( searchText ) || item.subject_name.toLowerCase().includes( searchText ) || item.subject_code.toLowerCase().includes( searchText ) ) ).length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Box>
                                    }
                                </Box>}

                                {mode === "view" && <Button sx={{ textTransform: "capitalize", marginBottom: "10px" }} onClick={() => setMode( "list" )} startIcon={Icons.ChevronLeftIcon} >Back To Materials</Button>}
                                {
                                    mode === "view" && StudyMaterialStore.getMaterial.studyMaterial &&
                                    <Box bgcolor="white" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)">
                                        <Box bgcolor={palette.primary.main} borderRadius="5px 5px 0 0" padding="20px" color="white">
                                            <Box width="100%" display="flex" gap="10px" flexDirection={{ sm: "row", xs: "column" }} justifyContent="space-between" alignItems={{ sm: "center", xs: "flex-start" }}>
                                                <Typography variant="h5">{StudyMaterialStore.getMaterial.studyMaterial.study_material_name}</Typography>
                                                <Box borderBottom="10px" display="flex" gap="10px" alignItems="center">
                                                    {attachmentsSelectedForDetails && <DetailsViewer attachment={attachmentsSelectedForDetails} setShowDetails={setShowDetails} open={showDetails} />}
                                                    {StudyMaterialStore.getMaterial.attachments.length > 0 && <Tooltip title={isDownloading ? "Downloading material..." : "Download all materials"} placement="top">
                                                        <IconButton onClick={downloadStudyMaterial} sx={{ padding: "10px", background: "white", transition: "0.2s ease", color: "textSecondary", "&:disabled": { background: "whitesmoke" }, "&:hover": { background: "white", transform: "scale(1.1)" } }}>
                                                            {isDownloading ? Icons.DownloadingIcon : Icons.DownloadIcon}
                                                        </IconButton>
                                                    </Tooltip>}
                                                </Box>
                                            </Box>
                                            {StudyMaterialStore.getMaterial.studyMaterial.study_material_desc !== "" && <Typography fontSize="14px" textAlign="justify">{StudyMaterialStore.getMaterial.studyMaterial.study_material_desc}</Typography>}
                                            <Box display="flex" gap="10px" marginY="10px" flexWrap="wrap">
                                                {StudyMaterialStore.getMaterial.studyMaterial.study_material_tags && StudyMaterialStore.getMaterial.studyMaterial.study_material_tags.split( "," ).length > 0 && StudyMaterialStore.getMaterial.studyMaterial.study_material_tags.split( "," ).map( ( tag, index ) => (
                                                    <Chip key={index} sx={{ background: "white" }} label={tag} size="small" />
                                                ) )}
                                            </Box>
                                            <Typography fontSize="14px" > {StudyMaterialStore.getMaterial.attachments.length} attachments </Typography>
                                            <Typography fontSize="14px" >Total {StudyMaterialStore.getMaterial.studyMaterial.download_count} Downloads </Typography>
                                        </Box>
                                        <Box>
                                            <Box padding="20px 20px" borderBottom="1px solid grey">
                                                <Box flexDirection={{ sm: "row", xs: "column" }} gap="10px" display="flex" alignItems={{ sm: "center", xs: "flex-start" }} justifyContent="space-between">
                                                    <Typography width="100%" variant="h6" display="flex" alignItems="center" gap="10px" fontWeight="normal" >
                                                        All Documents
                                                    </Typography>
                                                    <Box display="flex" width="100%" alignItems={{ sm: "center", xs: "flex-start" }} flexDirection={{ sm: "row", xs: "column" }} justifyContent={{ sm: "flex-end", xs: "space-between" }}>
                                                        {/* <TextField onChange={searchAttachments} value={searchParameter} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px" }, startAdornment: <SearchIcon sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", minWidth: "150px", width: "100%", maxWidth: { sm: "300px", xs: "100%" }, color: "black" }} placeholder="Search attachments..." variant="standard" type="text" /> */}
                                                        <Box display="flex" alignItems="center">
                                                            <Tooltip title="List view" placement="top">
                                                                <IconButton onClick={() => { localStorage.setItem( "studyMaterialView", "list" ); setViewType( "list" ) }} sx={{ color: viewType !== "grid" ? "primary.main" : "#666" }}>
                                                                    <TableRowsIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Grid view" placement="top">
                                                                <IconButton onClick={() => { localStorage.setItem( "studyMaterialView", "grid" ); setViewType( "grid" ) }} sx={{ color: viewType === "grid" ? "primary.main" : "#666" }}>
                                                                    <GridViewIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {viewType === "grid" ? <GridView removefilter={() => { }} setShowDetails={setShowDetails} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} material={StudyMaterialStore.getMaterial.studyMaterial} attachments={StudyMaterialStore.getMaterial.attachments} allAttachments={StudyMaterialStore.getMaterial.attachments} deleteAttachment={() => { }} /> : <ListView removefilter={() => { }} setShowDetails={setShowDetails} material={StudyMaterialStore.getMaterial.studyMaterial} setAttachmentsSelectedForDetails={setAttachmentsSelectedForDetails} attachments={StudyMaterialStore.getMaterial.attachments} allAttachments={StudyMaterialStore.getMaterial.attachments} deleteAttachment={() => { }} />}
                                        </Box>

                                    </Box>
                                }
                                {( instituteCode === "0" ) && <Box padding="40px 0" bgcolor="#eee" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                                    <img src={selectIntituteImg} width="200px" alt="Select institute and department" />
                                    <Typography marginTop="20px" textAlign="center" variant='subtitle2'>
                                        Please select an institute and a department to browse study materials
                                    </Typography>
                                </Box>}
                            </Box>}
                            {isMaterialsLoading && instituteCode !== "0" &&
                                <Typography variant='h6' padding="20px" color="textSecondary" display="flex" alignItems="center" gap="10px"> <CircularProgress size={20} /> Loading...</Typography>
                            }
                        </TabPanel>
                        <TabPanel sx={{ padding: "10px" }} value={"0"}>
                            <Box display="flex" gap={{ md: "10px", xs: "0" }} alignItems="center" flexDirection={{ md: "row", xs: "column" }}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select Institute</InputLabel>
                                    <Select variant="outlined" disabled={isLoading} onChange={e => handleStatsInstituteChange( e.target.value )} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={statsInstituteCode} label="Select Institute---">
                                        <MenuItem sx={{ fontSize: "14px" }} value="0">{isLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                                        <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={"ALL"} key={'all'}> All </MenuItem>
                                        {
                                            OrganizationStore.getOrganization.map( ( obj, i ) => {
                                                return <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={obj.school_name_short} key={'key-' + i}> {( obj.school_name ).toLowerCase()} </MenuItem>
                                            } )
                                        }
                                    </Select>
                                </FormControl>
                                {stats.length > 0 && <Button onClick={() => exportStudyMaterialsReport( stats, deptFacultyMap, statsInstituteCode )} sx={{ marginTop: { sm: "8px", xs: "0px" }, textTransform: "capitalize", height: "53px", width: { md: "200px", xs: "100%" } }} disableElevation variant='contained'>
                                    Export as excel file
                                </Button>}
                            </Box>
                            {stats.length > 0 && !isLoading && <Box display="flex" marginTop="10px" flexDirection={{ sm: "row", xs: "column" }} sx={{ color: "white" }} gap="20px" alignItems={{ md: "flex-start", xs: "center" }} bgcolor={palette.primary.main} borderRadius="5px" padding="20px">
                                <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                    <Typography variant='h6'>Faculties</Typography>
                                    <Typography variant="h3">{stats.length}</Typography>
                                    <Typography fontSize="12px">Total number of faculties uploaded notes {statsInstituteCode !== "ALL" ? `in ${statsInstituteCode}` : ""}</Typography>
                                </Box>
                                <Box width="180px" textAlign={{ xs: "center", sm: "left" }}>
                                    <Typography variant='h6'>Study Materials</Typography>
                                    <Typography variant="h3">{stats.reduce( ( sum, item ) => sum + parseInt( item.material_count ), 0 )}</Typography>
                                    <Typography fontSize="12px">Total number of study materials {statsInstituteCode !== "ALL" ? `in ${statsInstituteCode}` : ""}</Typography>
                                </Box>
                                <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                    <Typography variant='h6'>Attachments</Typography>
                                    <Typography variant='h3'>{stats.reduce( ( sum, item ) => sum + parseInt( item.attachment_count ), 0 )}</Typography>
                                    <Typography fontSize="12px">Total Number of attachments {statsInstituteCode !== "ALL" ? `in ${statsInstituteCode}` : ""}</Typography>
                                </Box>
                                <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                    <Typography variant='h6'>Downloads</Typography>
                                    <Typography variant='h3'>{stats.reduce( ( sum, item ) => sum + parseInt( item.sum ), 0 )}</Typography>
                                    <Typography fontSize="12px">Total downloads by students {statsInstituteCode !== "ALL" ? `in ${statsInstituteCode}` : ""}</Typography>
                                </Box>
                            </Box>}
                            {stats.length > 0 && !isLoading && <Box marginTop="10px">
                                <TextField onChange={( e ) => setSearchText( e.target.value )} value={searchText} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ margin: "10px 0 20px 0", border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search by faculty" variant="standard" type="text" />
                                <TableContainer sx={{ borderRadius: "0 0 5px 5px", minHeight: "300px", maxHeight: "450px", borderLeft: "1px solid rgba(0,0,0,0.1)", borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Table stickyHeader>
                                        <TableHead sx={{ background: palette.primary.main }}>
                                            <TableRow sx={{ background: palette.primary.main }}>
                                                {statsColumns.map( ( item, index ) => (
                                                    <TableCell key={`row-${index}`} sx={{ color: "#fff", background: palette.primary.main, display: item.display, minWidth: item.minWidth }} align={item.align}>{item.name}</TableCell>
                                                ) )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {stats.filter( item => ( item.study_material_created_by.toLowerCase().includes( searchText ) ) ).slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                .map( ( item, index ) => (
                                                    <TableRow sx={{ background: "white", "&:hover": { background: "#ccc !important" } }} key={`row-${item.empcode}`}>
                                                        <TableCell sx={{ fontWeight: "bold", width: "100%", display: "table-cell", alignItems: "center", gap: "10px" }} align='left'>
                                                            <Box display="flex" gap="5px" alignItems="center">
                                                                {item.study_material_created_by}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align='left'>{item.empcode}</TableCell>
                                                        <TableCell align='left'>{`${statsInstituteCode}-${deptFacultyMap[item.empcode]}`}</TableCell>
                                                        <TableCell align='left'>{item.material_count}</TableCell>
                                                        <TableCell align='left'>{item.attachment_count}</TableCell>
                                                        <TableCell align='left'>{item.sum}</TableCell>
                                                    </TableRow>
                                                ) )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    sx={{ background: "#eee" }}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                    component="div"
                                    count={stats.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>}

                            {( statsInstituteCode === "0" ) && <Box padding="40px 0" bgcolor="#eee" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                                <img src={selectIntituteImg} width="200px" alt="Select institute and department" />
                                <Typography marginTop="20px" textAlign="center" variant='subtitle2'>
                                    Please select an institute to view faculty stats
                                </Typography>
                            </Box>}

                            {stats.length === 0 && !isLoading && statsInstituteCode !== "0" &&
                                <Box padding="40px 0" bgcolor="#eee" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                                    <img src={nodataImg} width="200px" alt="Select institute and department" />
                                    <Typography marginTop="20px" textAlign="center" variant='subtitle2'>
                                        No stats to show for selected institute
                                    </Typography>
                                </Box>
                            }
                            {isLoading && statsInstituteCode !== "0" &&
                                <Typography variant='h6' display="flex" padding="20px" color="textSecondary" alignItems="center" gap="10px"> <CircularProgress size={20} /> Loading...</Typography>
                            }
                        </TabPanel>
                    </Box>
                </TabContext>
            </Paper >}
            {!hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box >
    )
} )

export default StudyMaterial
