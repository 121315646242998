import { Box, Button, Typography, Paper } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import aliveLogo from '../assets/alivelogo.png'


const Notfound = () => {

    const navigate = useNavigate()

    return (
        // <Layout>
        // <Box display="flex" height="100%" alignItems="center" justifyContent="center">
        //     <Box bgcolor="#eee" padding="20px" borderRadius="5px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" minWidth="220px" width="300px" height="300px">
        //         <Typography textAlign="center" variant="h4" color="secondary">Material Not Found!</Typography>
        //         <Button onClick={() => navigate( -1 )} variant="outlined" sx={{ textTransform: "capitalize", marginTop: "20px" }}>Back</Button>
        //     </Box>
        // </Box>
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box height="100%" sx={{ background: "url('/imgs/bg-pattern1.png')", zIndex: "0" }} width="100%" display="flex" position="fixed" top="0" left="0" right="0" bottom="0" flexWrap="wrap" overflow="hidden">
            </Box>
            <Paper sx={{ position: "relative", zIndex: 2, maxWidth: "300px", width: "90%" }}>
                <Box padding="20px" alignItems="center" display="flex" flexDirection="column" gap="10px">
                    <img width="100px" src={aliveLogo} alt="Alive Logo" />
                    <Typography variant="h6">Material Not Found!</Typography>
                    <Button onClick={() => navigate( -1, { replace: true } )} variant="outlined" sx={{ textTransform: "capitalize" }}>Go Back</Button>
                </Box>
            </Paper>
        </Box>
        // </Layout>
    )
}

export default Notfound
