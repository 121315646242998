import React, { useState } from 'react'
import { Dialog, TextField, DialogTitle, DialogActions, DialogContent, Button, Box, Typography, CircularProgress } from '@mui/material'
import { useAssignmentStore } from '../../store/assignment.store'
import { Icons } from '../../utils/utilities'

const AssignmentSubmissionDownloadDialog = ( { onClose, selectedValue, open, dialogType } ) => {

    const [score, setScore] = React.useState( 0 )
    const [downloading, setDownloading] = useState( false )

    const AssignmentStore = useAssignmentStore()

    const handleClose = () => {
        onClose( selectedValue )
    }

    const handleListItemClick = async ( value ) => {
        //onClose(value);
        setDownloading( true )
        await AssignmentStore.downloadAssignment( value )
        setDownloading( false )
    }

    const submit = () => {

        let payload = { "assignment_submission_id": selectedValue[0], "assignment_marks": score }
        console.log( payload )
        AssignmentStore.setAssignmentScore( payload )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90vw" } }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title"> {( dialogType === 'download' ) ? 'Download Assignment' : 'Set Score'} </DialogTitle>

            {
                ( dialogType === 'download' ) ?
                    <Box display="flex" flexDirection="column" padding="20px" paddingTop="0">
                        {selectedValue.map( ( item ) => (
                            <Typography display="flex" padding="10px 0" gap="10px" alignItems="center" sx={{ textTransform: "capitalize", color: "GrayText", cursor: "pointer", fontSize: "14px", "&:hover": { color: "black" } }} onClick={() => handleListItemClick( item )} key={item}>
                                {downloading ? <CircularProgress size="14px" /> : Icons.DownloadIcon} {item.split( "." )[item.split( "." ).length - 2]?.replace( /(%\d+)/g, " " )}
                            </Typography>
                        ) )}

                    </Box> : ''
            }
            {
                ( dialogType === 'setmarks' ) ?
                    <DialogContent>
                        <TextField id="standard-basic" onChange={( e ) => setScore( e.target.value )} label="Score" style={{ width: '100%', marginTop: "10px" }} />
                    </DialogContent>
                    : ''
            }
            {
                ( dialogType === 'setmarks' ) ? <DialogActions>
                    <Button variant="contained" color="secondary" onClick={submit}>
                        Submit
                    </Button>
                </DialogActions> : ''
            }

        </Dialog>
    )
}

export default AssignmentSubmissionDownloadDialog
