import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useApiProviderStore } from '../../store/apiprovider.store'
import { useOrganizationStore } from '../../store/organization.store'
import { Icons } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'
import warningImg from '../../assets/warning.svg'

const ApiSettings = () => {
    const [open, setOpen] = React.useState( false )
    const [apiId, setApiId] = useState( "" )
    const [apiProviderName, setApiProviderName] = useState( "" )
    const [apiEndpoint, setApiEndpoint] = useState( "" )
    const [apiSecret, setApiSecret] = useState( "" )
    const [apiOrgCodes, setApiOrgCodes] = useState( [] )
    const [institutioncode, setInstitutionCode] = React.useState( '' )
    const [apiUserThreshold, setApiUserThreshold] = React.useState( 0 )
    const [action, setAction] = useState( "create" )
    const [hasPermission, setHasPermission] = useState( true )
    const [isLoading, setIsLoading] = useState( true )

    const ApiProviderStore = useApiProviderStore()
    const OrganizationStore = useOrganizationStore()
    const UserStore = useUserStore()

    const handleClickOpen = () => {
        setOpen( true )
    }

    const handleClose = () => {

        setOpen( false )
    }

    useEffect( () => {
        //props.ApiProviderStore.fetchApiProviders()
        //setApiprovider(props.ApiProviderStore.getApiProvider)
        const getData = async () => {
            await ApiProviderStore.fetchApiProviders()
            await OrganizationStore.fetchOrganization()
            setIsLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.api_settings ) {
            setHasPermission( false )
        } else
            getData()
    }, [ApiProviderStore, OrganizationStore, UserStore] )

    const createApiProvider = () => {
        let payload = {
            "api_provider": apiProviderName,
            "api_endpoint": apiEndpoint,
            "api_secret": apiSecret,
            "api_org_codes": apiOrgCodes,
            "api_user_threshold": apiUserThreshold
        }
        ApiProviderStore.createApiProvider( payload )
    }

    const updateApiProvider = () => {
        let payload = {
            "api_provider": apiProviderName,
            "api_endpoint": apiEndpoint,
            "api_secret": apiSecret,
            "api_org_codes": apiOrgCodes,
            "api_user_threshold": apiUserThreshold
        }
        ApiProviderStore.updateApiProvider( payload, apiId )
    }

    const viewData = ( currentData ) => {
        setAction( "view" )
        setApiProviderName( currentData["api_provider"] )
        setApiEndpoint( currentData["api_endpoint"] )
        setApiSecret( currentData["api_secret"] )
        setApiOrgCodes( currentData["api_org_codes"] ? currentData["api_org_codes"] : [] )
        setApiUserThreshold( currentData["api_user_threshold"] )
        handleClickOpen()
    }
    const editData = ( currentData ) => {
        setAction( "edit" )
        setApiProviderName( currentData["api_provider"] )
        setApiEndpoint( currentData["api_endpoint"] )
        setApiSecret( currentData["api_secret"] )
        setApiOrgCodes( currentData["api_org_codes"] ? currentData["api_org_codes"] : [] )
        setApiUserThreshold( currentData["api_user_threshold"] )
        setApiId( currentData["api_id"] )
        handleClickOpen()
    }

    const createData = () => {
        setAction( "create" )
        setApiProviderName( "" )
        setApiEndpoint( "" )
        setApiSecret( "" )
        setApiUserThreshold( "" )
        setApiOrgCodes( [] )
        handleClickOpen()
    }

    const deleteData = ( api_id ) => {
        ApiProviderStore.deleteApiProvider( api_id )
    }

    const chipDelete = ( k ) => {
        let tempChipArray = apiOrgCodes
        tempChipArray.splice( k, 1 )
        setApiOrgCodes( [...tempChipArray] )
    }

    const addChip = ( e ) => {
        e.preventDefault()
        let tempChipArray = apiOrgCodes
        tempChipArray.push( institutioncode )
        setApiOrgCodes( [...tempChipArray] )
    }
    return (
        <Box>
            {hasPermission && <Box>
                <Box padding="20px" borderRadius="5px" bgcolor="white" boxShadow="0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1)">
                    <Typography variant='h5'>API settings</Typography>
                    <Typography color="textSecondary" variant='subtitle2'>Manage your third party API's</Typography>
                    {ApiProviderStore.getApiProvider.length > 0 && !isLoading && <TableContainer >
                        <Table sx={{ marginTop: "20px" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ borderTop: "1px solid rgba(0,0,0,0.2) !important", minWidth: "100px" }}>API Name</TableCell>
                                    <TableCell sx={{ borderTop: "1px solid rgba(0,0,0,0.2) !important", minWidth: "230px" }}>Institute</TableCell>
                                    <TableCell sx={{ borderTop: "1px solid rgba(0,0,0,0.2) !important", minWidth: "100px" }} align="left">End Point</TableCell>
                                    <TableCell sx={{ borderTop: "1px solid rgba(0,0,0,0.2) !important", minWidth: "100px" }} align="left">API Secret</TableCell>
                                    <TableCell sx={{ borderTop: "1px solid rgba(0,0,0,0.2) !important", minWidth: "40px" }} align="left">Threshold</TableCell>
                                    <TableCell sx={{ borderTop: "1px solid rgba(0,0,0,0.2) !important", minWidth: "120px" }} align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ApiProviderStore.getApiProvider.map( ( row, k ) => (
                                    <TableRow key={'key-' + k}>
                                        <TableCell component="th" scope="row">
                                            {row.api_provider}
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                row.api_org_codes ?
                                                    row.api_org_codes.map( ( item, k ) => {
                                                        return <Chip sx={{ margin: "0 5px 5px 0" }} key={'k-' + k}
                                                            label={item}
                                                            className="mr-3"
                                                        />
                                                    } ) : 'No Institution Allocated'
                                            }
                                        </TableCell>
                                        <TableCell align="left">{row.api_endpoint}</TableCell>
                                        <TableCell align="left">xxxxxxxxxxxxxxxxxx</TableCell>
                                        <TableCell align="left">{row.api_user_threshold}</TableCell>
                                        <TableCell align="right">


                                            <IconButton color="primary" aria-label="Edit" onClick={() => editData( row )}>
                                                {Icons.RateReviewIcon}
                                            </IconButton>

                                            <IconButton aria-label="View" onClick={() => viewData( row )}>
                                                {Icons.VisibilityIcon}
                                            </IconButton>

                                            <IconButton color="secondary" aria-label="delete" onClick={() => deleteData( row.api_id )} >
                                                {Icons.DeleteIcon}
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    {ApiProviderStore.getApiProvider.length === 0 && !isLoading && <Typography bgcolor="#eee" borderRadius="5px" marginTop="20px" padding="20px">
                        No API providers
                    </Typography>}
                    {isLoading &&
                        <Typography display="flex" gap="10px" alignItems="center" variant='h6' color="textSecondary" padding="20px" borderRadius="5px" bgcolor="#eee" marginTop="20px"> <CircularProgress size={20} /> Loading api providers... </Typography>
                    }
                </Box>

                {/* DIALOG */}
                <Dialog maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle className="border-bottom" color="primary" id="form-dialog-title">API Service</DialogTitle>
                    <DialogContent >
                        <DialogContentText color="textSecondary" fontSize="14px">
                            {
                                action === "create" ? 'Add a new ' : ''
                            }
                            {
                                action === "edit" ? 'Update ' : ''
                            }
                            API service provider for your live classes.
                        </DialogContentText>
                        <form style={{ marginTop: "20px", display: "flex", gap: "20px", flexDirection: "column" }} noValidate autoComplete="off">

                            <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="w-100 mb-4" id="outlined-basic" onChange={( e ) => setApiProviderName( e.target.value )}
                                label="API Name" variant="outlined" value={apiProviderName} disabled={( action === "view" ) ? true : false} />

                            <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="w-100 mb-4" id="outlined-basic" onChange={( e ) => setApiEndpoint( e.target.value )}
                                label="API Endpoint" variant="outlined" value={apiEndpoint} disabled={( action === "view" ) ? true : false} />

                            <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="w-100 mb-4" id="outlined-basic" onChange={( e ) => setApiSecret( e.target.value )}
                                label="API Secret" variant="outlined" value={apiSecret} disabled={( action === "view" ) ? true : false} />

                            <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="w-100 mb-4" id="outlined-basic" onChange={( e ) => setApiUserThreshold( e.target.value )}
                                label="API Threshold" variant="outlined" value={apiUserThreshold} disabled={( action === "view" ) ? true : false} />

                            <FormControl variant="outlined" className="w-100 mb-4">
                                <InputLabel id="institutioncode-select-outlined-label">Allocated Institution</InputLabel>
                                <Select
                                    sx={{ fontSize: "14px" }}
                                    labelId="institutioncode-select-outlined-label"
                                    id="institutioncode-select-outlined"
                                    value={institutioncode}
                                    onChange={( e ) => { setInstitutionCode( e.target.value ) }}
                                    label="Allocated Institution">
                                    <MenuItem sx={{ fontSize: "14px" }} value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        OrganizationStore.getOrganization.filter( ( itm, i ) => {
                                            return itm.departments !== null
                                        } ).map( ( row, k ) => {
                                            return <MenuItem sx={{ fontSize: "14px" }} value={row.school_name_short} key={k}>{row.school_name}</MenuItem>
                                        } )
                                    }

                                </Select>
                            </FormControl>
                            <Button type="button" disableElevation variant='contained' onClick={addChip}>Add</Button>
                            <div>
                                {
                                    apiOrgCodes.map( ( item, k ) => {
                                        return <Chip key={'k-' + k}
                                            //icon={<FaceIcon />}
                                            label={item}
                                            className="mr-3"
                                            onDelete={() => chipDelete( k )}
                                            color="secondary"
                                        />
                                    } )
                                }
                            </div>

                        </form>

                    </DialogContent>
                    <DialogActions className="px-3">
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        {
                            ( action === "create" ) ?
                                <Button onClick={createApiProvider} color="primary">
                                    Create
                                </Button> : ""
                        }
                        {
                            ( action === "edit" ) ?
                                <Button onClick={updateApiProvider} color="primary">
                                    Update
                                </Button> : ""
                        }

                    </DialogActions>
                </Dialog>

                <Fab sx={{ position: "fixed", right: "40px", bottom: "40px" }} size='medium' onClick={createData} color="primary" aria-label="add">
                    {Icons.AddIcon}
                </Fab>
            </Box>}
            {!hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box>
    )
}

export default ApiSettings
