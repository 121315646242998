import { Box, Divider, List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Fab, TextField, Dialog, Button, DialogActions, DialogTitle, DialogContent, CardActions, Typography, Avatar, CardHeader, Card, FormControl, CardContent } from '@mui/material'
import React, { useEffect } from 'react'
import { Icons } from '../../utils/utilities'
import { useOrganizationStore } from '../../store/organization.store'
import SearchIcon from '@mui/icons-material/Search'

const Institution = () => {

    const OrganizationStore = useOrganizationStore()

    const [open, setOpen] = React.useState( false )
    const [openDepartment, setOpenDepartment] = React.useState( false )

    const [departmentName, setDepartmentName] = React.useState( '' )
    const [departmentCode, setDepartmentCode] = React.useState( '' )


    const [action, setAction] = React.useState( "create" )
    const [search, setSearch] = React.useState( '' )
    const [orgcode, setOrgCode] = React.useState( '' )
    const [existingorgcode, setExistingOrgCode] = React.useState( '' )
    const [orgname, setOrgName] = React.useState( '' )

    const handleClickOpen = () => {
        setOpen( true )
    }
    const handleClickOpenDepartment = ( dept ) => {
        setOpenDepartment( true )
        setExistingOrgCode( dept["org_code"] )
    }

    const handleClose = () => {
        setOpen( false )
        clearCreateForm()
    }
    const handleCloseDepartment = () => {
        setOpenDepartment( false )
        clearDepartmentForm()
    }

    const clearCreateForm = () => {
        setOrgCode( '' )
        setOrgName( '' )
        setExistingOrgCode( '' )
    }

    const clearDepartmentForm = () => {
        setDepartmentName( '' )
        setDepartmentCode( '' )
        setExistingOrgCode( '' )
    }

    const openCreateForm = () => {
        setAction( 'create' )
        handleClickOpen()
    }

    const openEditForm = ( org ) => {
        setAction( 'edit' )
        setOrgCode( org.institute_name_short )
        setOrgName( org.institute_name )
        setExistingOrgCode( org.org_code )
        handleClickOpen()
    }

    const handleChange = ( event ) => {
        setSearch( event.target.value )
    }

    const createInsitution = () => {
        // alert
        var payload = {
            "org_code": orgcode,
            "org_name": orgname
        }
        OrganizationStore.createOrganization( payload )
    }
    const updateInsitution = () => {
        // alert
        var payload = {
            "org_code": orgcode,
            "org_name": orgname,
            "org_code_current": existingorgcode
        }
        OrganizationStore.updateOrganization( payload )

    }

    const deleteInsitution = () => {
        // alert
        var payload = {
            "org_code": orgcode,
        }
        OrganizationStore.deleteOrganization( payload )

    }

    const createDepartment = () => {
        // alert
        var payload = {
            "dept_code": departmentCode,
            "dept_name": departmentName,
            "dept_org_code": existingorgcode
        }
        OrganizationStore.createDepartment( payload )
    }

    const deleteDepartment = ( id ) => {
        // alert 
        OrganizationStore.deleteDepartment( id )
    }

    useEffect( () => {
        OrganizationStore.fetchOrganization()
        OrganizationStore.fetchDepartment()
    }, [OrganizationStore] )

    return (
        <Box>
            <Box>
                <Box>
                    <Box>
                        <Box padding="20px" bgcolor="#fff">
                            <Typography variant='h5'>Institutions</Typography>
                            <Typography marginBottom="20px" color="textSecondary" variant='subtitle2'>Manage your institutions</Typography>
                            <FormControl fullWidth>
                                <TextField onChange={handleChange} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search users by Username / AUID / Employee ID..." variant="standard" type="text" />
                                {/* <InputLabel htmlFor="search-box">Search</InputLabel> */}
                            </FormControl>
                        </Box>
                        <Box >
                            {/* {JSON.stringify(OrganizationStore.getOrganization)} */}
                            {OrganizationStore.getOrganization.filter( ( item ) => ( item.school_name_short.toLowerCase().indexOf( search.toLowerCase() ) > -1 || item.school_name.toLowerCase().indexOf( search.toLowerCase() ) > -1 ) ).map( ( list, k ) => (
                                <Box marginY="10px" key={k}>
                                    <Card >
                                        <CardHeader
                                            avatar={
                                                <Avatar aria-label="recipe">
                                                    {Icons.AccountBalanceIcon}
                                                </Avatar>
                                            }
                                            action={
                                                <IconButton aria-label="edit" onClick={() => openEditForm( list )}>
                                                    {Icons.EditIcon}
                                                </IconButton>
                                            }
                                            title={list.school_name_short}
                                            subheader={'Institute Code'}
                                        />
                                        <CardContent>
                                            {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
    {list.org_name}
    </Typography> */}
                                            <Typography component="p">
                                                {list.school_name}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" onClick={() => handleClickOpenDepartment( list )}>Department Settings</Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                            ) )
                            }
                        </Box>

                    </Box>
                </Box>

                <Dialog maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle className="border-bottom" color="primary" id="form-dialog-title">
                        {
                            action === "create" ? 'Add a new ' : ''
                        }
                        {
                            action === "edit" ? 'Edit ' : ''
                        }
                        Institution
                    </DialogTitle>
                    <DialogContent >

                        <form style={{ display: "flex", flexDirection: "column", marginTop: "10px", gap: "20px" }} noValidate autoComplete="off">
                            <TextField sx={{ minWidth: "220px", width: "350px" }} id="outlined-basic" value={orgname}
                                onChange={( e ) => setOrgName( e.target.value )}
                                label="Institute Name" variant="outlined" />

                            <TextField sx={{ minWidth: "220px", width: "350px" }} id="outlined-basic" value={orgcode}
                                onChange={( e ) => setOrgCode( e.target.value )}
                                label="Institute Code" variant="outlined" />

                        </form>

                    </DialogContent>
                    <DialogActions >
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                        {
                            ( action === "create" ) ?
                                <Button
                                    onClick={createInsitution}
                                    color="primary">
                                    Create
                                </Button> : ""
                        }
                        {
                            ( action === "edit" ) ?
                                <Button
                                    onClick={deleteInsitution}
                                >
                                    Delete
                                </Button> : ""
                        }
                        {
                            ( action === "edit" ) ?
                                <Button
                                    onClick={updateInsitution}
                                    color="primary">
                                    Save
                                </Button> : ""
                        }

                    </DialogActions>
                </Dialog>



                <Dialog sx={{}} maxWidth={'md'} open={openDepartment} onClose={handleCloseDepartment} aria-labelledby="form-dialog-department">
                    <DialogTitle color="primary" id="form-dialog-department">
                        {existingorgcode && existingorgcode.toUpperCase()} Departments
                    </DialogTitle>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" gap="10px">
                            <Box minWidth="200px" width="400px">
                                <TextField fullWidth id="outlined-basic"
                                    value={departmentName}
                                    onChange={( e ) => setDepartmentName( e.target.value )}
                                    label="Department Name" variant="outlined" />
                            </Box>
                            <Box minWidth="200px" width="400px">
                                <TextField fullWidth id="outlined-basic"
                                    value={departmentCode}
                                    onChange={( e ) => setDepartmentCode( e.target.value )}
                                    label="Department Code" variant="outlined" />
                            </Box>
                            <Box minWidth="200px" width="400px">
                                <Button
                                    disableElevation
                                    variant='contained'
                                    onClick={createDepartment}
                                    color="primary">
                                    Add
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                    <Divider />
                    <List
                        subheader={
                            <ListSubheader id="nested-list-subheader">
                                List of Departments
                            </ListSubheader>
                        }>
                        {
                            OrganizationStore.getDepartment
                                .filter( ( list, k ) => {
                                    console.log( list )
                                    return list.dept_org_code === existingorgcode
                                } )
                                .map( ( itm, k ) => {
                                    return ( <ListItem key={k}>
                                        {/* <ListItemAvatar>
                        <Avatar>
                          <BeachAccessIcon />
                        </Avatar>
                      </ListItemAvatar> */}
                                        <ListItemText primary={itm.dept_name} secondary={itm.dept_code} />
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={() => deleteDepartment( itm.dept_id )} color="secondary" edge="end" aria-label="delete">
                                                {Icons.DeleteIcon}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem> )
                                } )
                        }
                    </List>
                </Dialog>
                <Fab sx={{ position: "fixed", right: "40px", bottom: "40px" }} onClick={openCreateForm} color="primary" aria-label="add">
                    {Icons.AddIcon}
                </Fab>
            </Box>
        </Box>
    )
}

export default Institution
