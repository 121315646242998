import { Box, Collapse, Icon, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import fileIcon from '../../assets/file-icon.svg'
import CancelIcon from '@mui/icons-material/Cancel'
import { studyMaterialFormats, flatColors } from '../../utils/utilities'
import Progress from '../Progress'
import { useState } from 'react'

const FileInput = ( { setFieldValue, size, selectedAttachments, setSelectedAttachments, isUploading, setAttachmentsNames, attachmentsNames, progress, acceptableFormats } ) => {

    const [dragOverStatus, setDragOverStatus] = useState( false )

    const handleFilesInsert = ( e ) => {
        const files = e.target.files
        let selectedFileList = []
        let selectedFileNameList = []
        for ( let i = 0; i < files.length; i++ ) {
            selectedFileList.push( files[i] )
            selectedFileNameList.push( files[i].name.split( "." ).slice( 0, -1 ).join( "" ) )
        }
        setSelectedAttachments( [...selectedAttachments, ...selectedFileList] )
        setAttachmentsNames( [...attachmentsNames, ...selectedFileNameList] )
        setFieldValue( 'attachments', [...selectedAttachments, ...selectedFileList] )
        e.target.value = ''
    }

    const handleFileNameChange = ( index, value ) => {
        let values = [...attachmentsNames]
        values[index] = value
        setAttachmentsNames( values )
    }

    const removeAttachment = ( index ) => {
        const files = [...selectedAttachments]
        const filesNames = [...attachmentsNames]
        files.splice( index, 1 )
        filesNames.splice( index, 1 )
        setSelectedAttachments( files )
        setAttachmentsNames( filesNames )
        setFieldValue( 'attachments', [...files] )
    }

    return (
        <Box>
            <Box onDragLeave={() => setDragOverStatus( false )} onDragEnter={() => setDragOverStatus( true )} position="relative" gap="10px" display="flex" padding="20px" flexDirection="column" alignItems="center" justifyContent="center" minHeight="150px" marginTop="20px" border={`${dragOverStatus ? '4px' : '2px'} dashed ${dragOverStatus ? '#f18f08' : 'rgba(0,0,0,0.3)'}`} >
                <input accept={acceptableFormats} disabled={isUploading} style={{ position: "absolute", cursor: isUploading ? "not-allowed" : "pointer", opacity: 0, top: 0, left: 0, width: "100%", height: "100%" }} title="" type="file" multiple name="attachments" id="attachments" onInput={( e ) => { handleFilesInsert( e ) }} />
                <Icon sx={{ height: "60px", width: "60px" }}>
                    {!isUploading && <UploadFileIcon sx={{ fontSize: "60px", color: "rgba(0,0,0,0.3)" }} />}
                    {isUploading && <Progress progress={progress} size={size} />}
                </Icon>
                {isUploading && <Typography variant="body2" fontWeight="bold" color="textSecondary">Uploading selected attachments. This could take a moment...</Typography>}
                {!isUploading && <Typography variant="body2" fontWeight="bold" color="textSecondary">{selectedAttachments.length > 0 ? "Drag and drop more files" : "Drag and drop to upload attachments"}</Typography>}
                {!isUploading && <Typography variant="body2" color="textSecondary" textAlign="center">Supported file formats: {studyMaterialFormats.join( ", " )} </Typography>}
                {!isUploading && <Typography variant="body2" color="secondary" fontWeight="bold" sx={{ textDecoration: "underline" }}>Or click to browse</Typography>}
            </Box>
            <Collapse in={selectedAttachments.length > 0} sx={{ background: "#eee", marginTop: "10px" }} >
                <Typography padding="20px" paddingBottom="0" fontWeight="bold" color="secondary">Selected Attachments</Typography>
                <Box gap="10px" display="flex" alignItems="center" flexWrap="wrap" padding="20px">
                    {selectedAttachments.length > 0 && selectedAttachments.map( ( attachment, index ) => (
                        <Box key={index} bgcolor={flatColors[index % flatColors.length]} padding="5px" sx={{ position: "relative", "&::after": { content: "''", height: "4px", position: "absolute", background: "rgba(255,255,255,0.2)", width: "100%", left: 0, bottom: 0 } }}>
                            <Box display="flex" gap="10px" padding="10px" justifyContent="space-between" color="white">
                                <Box display="flex" alignItems="center" gap="10px">
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <img height="30px" src={fileIcon} alt="File" />
                                    </Box>
                                    <Box>
                                        <Typography fontSize="12px">{attachment.name}</Typography>
                                        <Typography fontSize="12px">{attachment.size / 1024 < 1024 ? `${Math.ceil( attachment.size / 1024 )}KB` : `${Math.ceil( attachment.size / ( 1024 * 1024 ) )}MB`}</Typography>
                                    </Box>
                                </Box>
                                <Tooltip title={`Remove ${attachment.name}`} placement="top">
                                    <IconButton disabled={isUploading} onClick={() => { removeAttachment( index ) }} size='small'>
                                        <CancelIcon sx={{ color: "#ccc", "&:hover": { color: "white" } }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box bgcolor="white" borderRadius="5px">
                                <TextField placeholder='Enter file name here...' onChange={( e ) => handleFileNameChange( index, e.target.value )} value={attachmentsNames[index]} inputProps={{ sx: { padding: "5px", fontSize: "14px" } }} sx={{ padding: "5px", width: "95%" }} variant='standard' />
                            </Box>
                        </Box>
                    ) )}
                </Box>
            </Collapse>
        </Box>
    )
}

export default FileInput
