import { Box, Button, Card, CardContent, Chip, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useOrganizationStore } from '../../store/organization.store'
import { useState } from 'react'
import api from '../../service/api'
import { useEffect } from 'react'
import { useExamStore } from '../../store/exam.store'
import { Icons, flatColors } from '../../utils/utilities'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import ExamViewer from '../../components/admin/ExamViewer'
import noExamsImg from '../../assets/noexams.svg'
import selectInstituteImg from '../../assets/selectinstitute.svg'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import selectIntituteImg from '../../assets/selectinstitute.svg'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import nodataImg from '../../assets/nodata.svg'
import SearchIcon from '@mui/icons-material/Search'
import { useUserStore } from '../../store/user.store'
import warningImg from '../../assets/warning.svg'
import { observer } from 'mobx-react'


const columns = [
    { id: 0, name: "Sl.no", paddingLeft: "20px", minWidth: "30px", width: "50px" },
    { id: 1, name: "Title", minWidth: "100px", width: "100px" },
    { id: 3, name: "Details", minWidth: "200px", width: "150px" },
    { id: 2, name: "Exam Date", minWidth: "100px", width: "100px" },
]

const ExamsReport = observer( () => {

    const OrganizationStore = useOrganizationStore()
    const ExamStore = useExamStore()
    const UserStore = useUserStore()

    const [isLoading, setIsLoading] = useState( true )
    const [isExamsLoading, setIsExamsLoading] = useState( false )
    const [instituteCode, setInstituteCode] = useState( "0" )
    const [view, setView] = useState( 'grid' )
    const [mode, setMode] = useState( "list" )
    const [questions, setQuestions] = useState( [] )
    const [tabValue, setTabValue] = useState( "0" )
    const [statsInstituteCode, setStatsInstituteCode] = useState( "0" )
    const [participations, setParticipations] = useState( [] )
    const [filteredParticipations, setFilteredParticipations] = useState( [] )
    const [totalMarks, setTotalMarks] = useState( 0 )
    const [stats, setStats] = useState( [] )
    const [examParticipationsMap, setExamParticipationsMap] = useState( {} )
    const [sortState, setSortState] = useState( {} )
    const [rowsPerPage, setRowsPerPage] = useState( 10 )
    const [page, setPage] = useState( 0 )
    const [searchText, setSearchText] = useState( "" )
    const [hasPermission, setHasPermission] = useState( true )


    const statsColumns = [
        {
            name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
                Faculty name
            </Box>, align: "left", minWidth: "200px"
        },
        { name: "Employee ID", align: "left", minWidth: "100px" },
        { name: "Institute Department", align: "left", minWidth: "200px" },
        {
            name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
                Exams conducted
                <IconButton onClick={() => { sortByProperty( stats, setStats, "count", "number" ) }} sx={{ color: "grey", width: "20px", height: "20px", background: "whitesmoke", "&:hover": { background: "whitesmoke", color: "grey", width: "20px", height: "20px" } }} size='small'>
                    {sortState.count ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                </IconButton>
            </Box>, align: "right", minWidth: "150px"
        },
        {
            name: "Total participation", align: "right", minWidth: "150px"
        }
    ]

    const sortByProperty = ( data, setNewData, property, type ) => {
        let newFilteredData = data
        newFilteredData.sort( ( a, b ) => {
            if ( type === "number" ) {
                if ( !sortState[property] )
                    return ( parseInt( a[property] ) - parseInt( b[property] ) )
                else
                    return ( parseInt( b[property] ) - parseInt( a[property] ) )
            } else {
                if ( !sortState[property] )
                    return ( ( a[property] < b[property] ) ? -1 : 0 )
                else
                    return ( ( a[property] > b[property] ) ? -1 : 0 )
            }
        } )
        setSortState( { ...sortState, [property]: sortState[property] ? false : true } )
        setNewData( newFilteredData )
    }

    const openExam = async ( id ) => {
        setIsExamsLoading( true )
        await ExamStore.fetchExam( id )
        console.log( ExamStore.getExam )
        const questionsRes = await api.fetchQuestions( id )
        setQuestions( questionsRes.data )
        setMode( "view" )
        setIsExamsLoading( false )
    }

    const handleStatsInstituteChange = async ( e ) => {
        setIsLoading( true )
        setStatsInstituteCode( e.target.value )
        const res = await api.getInstituteExamStats( e.target.value )
        console.log( res.data )
        setStats( res.data.stats )
        const examParticipationsMap = {}
        res.data.participations.forEach( data => {
            examParticipationsMap[data.created_by] = data.count
        } )
        setExamParticipationsMap( examParticipationsMap )
        setIsLoading( false )
    }

    const handleInstituteChange = async ( event ) => {
        setInstituteCode( event.target.value )
        setIsExamsLoading( true )
        try {
            await ExamStore.fetchExamsByInstitute( event.target.value )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsExamsLoading( false )
        }
        setIsLoading( false )
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    useEffect( () => {
        const getOrganizations = async () => {
            await OrganizationStore.fetchOrganization()
            setIsLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.exam_audit ) {
            setHasPermission( false )
        } else
            getOrganizations()
    }, [OrganizationStore, UserStore] )

    return (
        <Box>
            {hasPermission && <Paper sx={{ padding: "20px" }}>
                <Typography variant='h5'>Exams Audit</Typography>
                <Typography marginBottom="20px" variant="subtitle2" color="textSecondary">Access exam data report</Typography>
                <TabContext value={tabValue}>
                    <Box sx={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                        <TabList sx={{ borderBottom: "1px solid grey" }} onChange={( e, newValue ) => { setTabValue( newValue ) }}>
                            <Tab icon={Icons.AnalyticsIcon} iconPosition='start' sx={{ "&:hover": { background: "#eee" }, background: tabValue === "0" ? "#eee" : "#fff" }} value={"0"} label="Exams Stats" />
                            <Tab icon={Icons.QuizIcon} iconPosition='start' sx={{ "&:hover": { background: "#eee" }, background: tabValue !== "0" ? "#eee" : "#fff" }} value={"1"} label="Exams" />
                        </TabList>
                        <TabPanel sx={{ padding: "10px" }} value='0'>
                            <Box display="flex" gap={{ md: "10px", xs: "0" }} alignItems="center" flexDirection={{ md: "row", xs: "column" }}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select Institute</InputLabel>
                                    <Select variant="outlined" disabled={isLoading} onChange={handleStatsInstituteChange} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={statsInstituteCode} label="Select Institute---">
                                        <MenuItem sx={{ fontSize: "14px" }} value="0">{isLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                                        <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={"ALL"} key={'all'}> All </MenuItem>
                                        {
                                            OrganizationStore.getOrganization.map( ( obj, i ) => {
                                                return <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={obj.school_name_short} key={'key-' + i}> {( obj.school_name )?.toLowerCase()} </MenuItem>
                                            } )
                                        }
                                    </Select>
                                </FormControl>
                                {stats.length > 0 && <Button onClick={() => { }} sx={{ marginTop: { sm: "8px", xs: "0px" }, textTransform: "capitalize", height: "53px", width: { md: "200px", xs: "100%" } }} disableElevation variant='contained'>
                                    Export as excel file
                                </Button>}
                            </Box>
                            {stats.length > 0 && !isLoading && <Box display="flex" marginTop="10px" flexDirection={{ sm: "row", xs: "column" }} sx={{ color: "white" }} gap="20px" bgcolor="#3164f4" borderRadius="5px" padding="20px">
                                <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                    <Typography variant='h6'>Faculties</Typography>
                                    <Typography variant="h3">{stats.length}</Typography>
                                    <Typography fontSize="12px">Total number of faculties who have uploaded notes {statsInstituteCode !== "ALL" ? `in ${statsInstituteCode}` : ""}</Typography>
                                </Box>
                                <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                    <Typography variant='h6'>Exams</Typography>
                                    <Typography variant="h3">{stats.reduce( ( sum, item ) => sum + parseInt( item.count ), 0 )}</Typography>
                                    <Typography fontSize="12px">Total number of exams published {statsInstituteCode !== "ALL" ? `in ${statsInstituteCode}` : ""}</Typography>
                                </Box>
                                <Box width="150px" textAlign={{ xs: "center", sm: "left" }}>
                                    <Typography variant='h6'>Participations</Typography>
                                    <Typography variant='h3'>{Object.values( examParticipationsMap ).reduce( ( sum, item ) => sum + parseInt( item ), 0 )}</Typography>
                                    <Typography fontSize="12px">Total Number of participation(Considering only first attempts) {statsInstituteCode !== "ALL" ? `in ${statsInstituteCode}` : ""}</Typography>
                                </Box>
                            </Box>}
                            {stats.length > 0 && !isLoading && <Box marginTop="10px">
                                <TextField onChange={( e ) => setSearchText( e.target.value )} value={searchText} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ margin: "10px 0 20px 0", border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search by faculty" variant="standard" type="text" />
                                <TableContainer sx={{ borderRadius: "0 0 5px 5px", minHeight: "300px", maxHeight: "450px", borderLeft: "1px solid rgba(0,0,0,0.1)", borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Table stickyHeader>
                                        <TableHead sx={{ background: "#3164f4" }}>
                                            <TableRow sx={{ background: "#3164f4" }}>
                                                {statsColumns.map( ( item, index ) => (
                                                    <TableCell key={`row-${index}`} sx={{ color: "#fff", background: "#3164f4", display: item.display, minWidth: item.minWidth }} align={item.align}>{item.name}</TableCell>
                                                ) )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {stats.filter( item => !item.employee_data[0] || item.employee_data.find( item => item !== null )?.employee_name?.toLowerCase().includes( searchText ) ).slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                .map( ( item, index ) => (
                                                    <TableRow sx={{ background: "white", "&:hover": { background: "#ccc !important" } }} key={`row-${item.created_by}`}>
                                                        <TableCell sx={{ fontWeight: "bold", width: "100%", display: "table-cell", alignItems: "center", gap: "10px" }} align='left'>
                                                            <Box display="flex" gap="5px" alignItems="center">
                                                                {item.employee_data.find( item => item !== null )?.employee_name || item.created_by}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align='left'>{item.created_by}</TableCell>
                                                        <TableCell align='left'>{`${item.employee_data.find( item => item !== null )?.institute_name_short || ""}-${item.employee_data.find( item => item !== null )?.branch_name || ""}`}</TableCell>
                                                        <TableCell align='right'>{item.count}</TableCell>
                                                        <TableCell align='right'>{examParticipationsMap[item.created_by] ? examParticipationsMap[item.created_by] : 0}</TableCell>
                                                    </TableRow>
                                                ) )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    sx={{ background: "#eee" }}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                    component="div"
                                    count={stats.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>}
                            {( statsInstituteCode === "0" ) && !isLoading && <Box padding="40px 0" bgcolor="#eee" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                                <img src={selectIntituteImg} width="150px" alt="Select institute and department" />
                                <Typography marginTop="20px" textAlign="center" variant='subtitle2'>
                                    Please select an institute to view faculty stats
                                </Typography>
                            </Box>}
                            {stats.length === 0 && !isLoading && statsInstituteCode !== "0" &&
                                <Box padding="40px 0" bgcolor="#eee" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                                    <img src={nodataImg} width="200px" alt="Select institute and department" />
                                    <Typography marginTop="20px" textAlign="center" variant='subtitle2'>
                                        No stats to show for selected institute
                                    </Typography>
                                </Box>
                            }
                            {isLoading && statsInstituteCode !== "0" &&
                                < Typography variant='h6' display="flex" padding="20px" color="textSecondary" alignItems="center" gap="10px"> <CircularProgress size={20} /> Loading...</Typography>
                            }
                        </TabPanel>
                        <TabPanel sx={{ padding: "10px" }} value='1'>
                            {mode === "list" && <Box display="flex" gap="10px" marginBottom="10px" alignItems="center" flexDirection={{ md: "row", xs: "column" }}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select Institute</InputLabel>
                                    <Select variant="outlined" disabled={isLoading} onChange={handleInstituteChange} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={instituteCode} label="Select Institute---">
                                        <MenuItem sx={{ fontSize: "14px" }} value="0">{isLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                                        {
                                            OrganizationStore.getOrganization.map( ( obj, i ) => {
                                                return <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={obj.school_name_short} key={'key-' + i}> {( obj.school_name )?.toLowerCase()} </MenuItem>
                                            } )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>}
                            {!isExamsLoading && ExamStore.getExams.length > 0 && <Box>
                                <TextField onChange={( e ) => setSearchText( e.target.value )} value={searchText} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ margin: "10px 0 20px 0", border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search by exam name" variant="standard" type="text" />
                                {mode === "list" && <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant='h6'>
                                        <Typography color="textSecondary" sx={{ display: "inline-block", fontWeight: 500, marginLeft: "10px" }}> {ExamStore.getExams.length} exams found</Typography>
                                    </Typography>
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Tooltip title="Grid View">
                                            <IconButton color={view === "grid" ? "primary" : "textSecondary"} onClick={() => setView( "grid" )}>
                                                {Icons.GridViewIcon}
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="List view">
                                            <IconButton color={view !== "grid" ? "primary" : "textSecondary"} onClick={() => setView( "list" )}>
                                                {Icons.ViewListIcon}
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>}
                                {view === 'grid' && mode === "list" &&
                                    <Box marginTop="10px" display="flex" gap="10px" flexWrap="wrap">
                                        {ExamStore.getExams.filter( item => item.exam_name?.toLowerCase().includes( searchText ) ).map( ( exam, index ) => <Card onClick={() => openExam( exam.exam_id )} key={exam.exam_id} sx={{ position: "relative", height: "fit-content", maxWidth: "270px", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" } }} elevation={1}>
                                            <CardContent sx={{ display: "grid", cursor: "pointer", height: "100%", gridTemplateRows: "40% 60%", padding: "0 !important", "&:hover": { gridTemplateRows: "fit-content auto" } }}>
                                                <div style={{ color: "white", height: "100px", padding: "10px 15px", gap: "10px", display: "flex", alignItems: "flex-end", justifyContent: "space-between", background: flatColors[index % flatColors.length] }}>
                                                    <Typography variant='h6' fontSize="16px" title={exam.title} maxWidth="160px" textTransform="capitalize">{exam.exam_name} </Typography>
                                                    <Tooltip title={exam.exam_status === 'Published' ? "Published" : "Draft"}>
                                                        <Box bgcolor="#fff" display="flex" alignItems="center" justifyContent="center" width="20px" borderRadius="50%" height="20px" top="10px" right="10px" fontWeight="bold" color={exam.exam_status !== 'Published' ? "black" : "green"} fontSize="14px" textTransform="uppercase">{exam.exam_status[0]}</Box>
                                                    </Tooltip>
                                                </div>
                                                <Box display="flex" gap="5px" flexWrap="nowrap" flexDirection="column" justifyContent="center" padding="20px 15px">
                                                    <Box display="flex" maxWidth="100px" flexWrap="nowrap" gap="5px">
                                                        {exam.exam_topics.slice( 0, 2 ).map( ( topic, i ) => (
                                                            <Chip key={topic} size="small" title={topic} sx={{ width: "fit-content", maxWidth: "100px", background: flatColors[( i + 1 ) % flatColors.length - 1], color: "white" }} label={topic} />
                                                        ) )}
                                                        {exam.exam_topics.length > 2 && <Chip key={"morechips"} size="small" sx={{ width: "fit-content", background: flatColors[0], color: "white" }} label={`+${exam.exam_topics.length - 2} more`} />}
                                                    </Box>
                                                    <Typography variant='subtitle2' textAlign="justify" fontSize="12px" color="textSecondary"> {`${exam.exam_description.substring( 0, 60 )}${exam.exam_description.length > 60 ? "..." : ""}`}</Typography>
                                                    <Typography variant='subtitle2' textAlign="justify" fontSize="12px" color="primary">Exam Date: {formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</Typography>
                                                    <Typography variant='subtitle2' textAlign="justify" fontSize="12px">By: {exam.faculty_name ? exam.faculty_name : exam.created_by_data.username ? exam.created_by_data.employee_name : exam.created_by}</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card> )}
                                    </Box>}

                                {view === 'list' && mode === "list" &&
                                    <TableContainer sx={{ background: "white", marginTop: "10px", borderRight: "1px solid rgba(0,0,0,0.1)", borderLeft: "1px solid rgba(0,0,0,0.1)", maxHeight: "450px" }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map( ( column ) => (
                                                        <TableCell key={column.id} align="left" sx={{ paddingLeft: column.paddingLeft, background: flatColors[3], color: "white", minWidth: `${column.minWidth} !important`, width: column.width, }}>{column.name}</TableCell>
                                                    ) )}
                                                    <TableCell key={4} align="right" sx={{ background: flatColors[3], color: "white", minWidth: "100px", width: "100px", }}>Created By</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ExamStore.getExams.filter( item => item.exam_name?.toLowerCase().includes( searchText ) ).map( ( exam, index ) => (
                                                    <TableRow onClick={() => openExam( exam.exam_id )} key={exam.exam_id} sx={{ "&:nth-of-type(even)": { background: "#eee" }, cursor: "pointer", "&:hover": { background: "#ccc" } }}>
                                                        <TableCell sx={{ paddingLeft: "20px" }}>{index + 1}</TableCell>
                                                        <TableCell><Typography noWrap fontSize="14px" fontWeight='bold'>{exam.exam_name} <Tooltip title={exam.exam_status === 'Published' ? "Published" : "Draft"}>
                                                            <Chip sx={{ color: exam.exam_status === 'Published' ? "green" : "gray", fontSize: "12px" }} label={exam.exam_status.toUpperCase().substring( 0, 1 )}></Chip>
                                                        </Tooltip> </Typography></TableCell>
                                                        <TableCell style={{ fontSize: "12px", textTransform: "capitalize" }}>{`${exam.exam_description.substring( 0, 50 )}${exam.exam_description.length > 50 ? "..." : ""}`}</TableCell>
                                                        <TableCell style={{ fontSize: "12px", textTransform: "capitalize" }}>{formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</TableCell>
                                                        <TableCell key={4} align="right" sx={{ minWidth: "50px", width: "50px", background: "transparent" }} >{exam.created_by_data.employee_name ? exam.created_by_data.employee_name : exam.created_by}</TableCell>
                                                    </TableRow>
                                                ) )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                {( mode === "view" || mode === "details" ) &&
                                    <ExamViewer participations={participations} setParticipations={setParticipations} totalMarks={totalMarks} setTotalMarks={setTotalMarks} setFilteredParticipations={setFilteredParticipations} filteredParticipations={filteredParticipations} setMode={setMode} isExamsLoading={isExamsLoading} setIsExamsLoading={setIsExamsLoading} mode={mode} examQuestion={questions} />
                                }
                            </Box>}
                            {!isExamsLoading && ExamStore.getExams.length === 0 && instituteCode !== "0" && <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="20px" bgcolor="#eee">
                                <img src={noExamsImg} width="200px" alt="No Exams" />
                                <Typography variant='subtitle2' marginTop="20px">No exams created for selected department!</Typography>
                            </Box>}
                            {!isExamsLoading && instituteCode === "0" && <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="20px" bgcolor="#eee">
                                <img src={selectInstituteImg} width="150px" alt="Select institute and department" />
                                <Typography marginTop="20px" variant='subtitle2'>Select an institute to browse exams.</Typography>
                            </Box>}
                            {isExamsLoading &&
                                <Typography display="flex" variant='h6' marginBottom="10px" marginLeft="20px" color="textSecondary" alignItems="center" marginTop="20px" gap="10px"> <CircularProgress size={20} /> Loading... </Typography>
                            }
                        </TabPanel>
                    </Box>
                </TabContext>
            </Paper>}
            {
                !hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                    <img src={warningImg} width="40px" alt="No permission" />
                    <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
                </Paper>
            }
        </Box >
    )
}
)
export default ExamsReport
