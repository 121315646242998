import { Box, Collapse, Typography } from '@mui/material'
import React from 'react'
import parse from 'html-react-parser'

const questionNbrStyles = {
    display: 'flex',
    padding: '10px',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: '#aaa',
    fontSize: '12px',
    fontWeight: 'bold'
}

const answerdStyles = {
    position: 'relative',
    background: 'green !important',
    color: 'white',
}

const notAnsweredStyles = {
    position: 'relative',
    background: '#888',
    color: 'white',
}

export default function QuestionList( { questions, selectQuestion, currentQuestionIndex, submissions, showQuestions } ) {

    const isAnswered = ( id ) => {
        for ( let i = 0; i < submissions.length; i++ ) {
            if ( submissions[i].question_id === id ) {
                return true
            }
        }
        return false
    }

    return (
        <Collapse in={showQuestions} orientation="horizontal" sx={{ minWidth: "50px", overflow: "auto !important" }}>
            <Box display="flex" position="relative" flexDirection="column" minWidth={{ lg: "300px", md: "250px", sm: "50px", xs: "50px" }} overflow="auto" bgcolor="white">
                {questions.map( ( question, index ) => (
                    <Box key={question.question.question_id} display="flex" bgcolor={currentQuestionIndex === index ? "#bbb" : "#eee"} padding={{ lg: "0 5px", md: "0 5px", sm: "10px", xs: "10px" }} borderBottom="1px solid #aaa" alignItems="center" sx={{ cursor: "pointer", display: "flex !important", justifyContent: "flex-start", alignItems: "center !important", "&:hover": { background: "rgba(0,0,0,0.2)" }, '@media(max-width:350px)': { padding: "2px !important" } }} onClick={() => selectQuestion( question.question.question_id )}>
                        <Box sx={
                            isAnswered( question.question.question_id ) ?
                                { ...questionNbrStyles, ...answerdStyles, }
                                : { ...questionNbrStyles, ...notAnsweredStyles }}
                            marginLeft={{ lg: "10px", md: "10px", sm: "0", xs: "0" }}>{index + 1}</Box>
                        <Box padding="10px" sx={{ display: { lg: "block", md: "block", sm: "none", xs: "none" } }}>
                            <Box variant='subtitle1' width="160px" sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", "& p": { margin: '0' } }}>{parse( question.question.question_text.replace( "LATEX-", '' ).replace( /\\/g, '' ) )}</Box>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography color="textSecondary" fontWeight="bold" fontSize={"12px"}>{question.question.question_type}</Typography>
                                <Typography color="textSecondary" fontWeight="bold" fontSize={"12px"}>{question.question.question_answer_selection}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ) )}
            </Box>
        </Collapse>
    )
}
