import { Box, Button, CircularProgress, Dialog, FormControl, FormControlLabel, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SectionBlock from './SectionBlock'
import { Icons } from '../../utils/utilities'
import { validateQuestionsImportFormatForQuestionBank } from '../../utils/exam-utilities'
import api from '../../service/api'
import { convert } from 'html-to-text'
import { toast } from 'react-toastify'
import AddQuestionDialog from './AddQuestionDialog'
import QuestionsPreviewDialog from '../common/QuestionsPreviewDialog'
import noQuestionImg from '../../assets/no-questions.svg'
import { Percent } from '@mui/icons-material'
import CustomSwitch from '../common/CustomSwitch'
// import Latex from 'react-latex-next'


const AddSectionDialog = ( { setSectionMaxQuestionCounts, state, setState, setSections } ) => {

    const [fieldValue, setFieldValue] = useState( "" )
    const [adding, setAdding] = useState( false )

    const { palette, border } = useTheme()


    const handleClose = () => {
        setFieldValue( "" )
        setState( false )
    }

    const createSection = e => {
        if ( ( e.type === "keydown" && e.key === "Enter" ) || e.type === "click" ) {
            setAdding( true )
            if ( fieldValue && fieldValue?.trim().length > 0 ) {
                setSections( prev => [...prev, fieldValue?.trim()] )
                setSectionMaxQuestionCounts( prev => {
                    const newValue = { ...prev }
                    newValue.totalQuestions[fieldValue?.trim()] = 0
                    newValue.maxQuestionsForExam[fieldValue?.trim()] = 0
                    return newValue
                } )
                e.preventDefault()
                setState( false )
                setFieldValue( "" )
            }
            setAdding( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "60%", minWidth: "180px", maxWidth: "450px", background: palette.form.formCardBg } }} onClose={handleClose} open={state}>
            <Typography variant='h6' padding="10px 20px" borderBottom={border[1]}>
                Add new section
            </Typography>
            <Box margin="20px" borderRadius="5px" border={border[1]} marginTop="15px" padding="20px" bgcolor={palette.form.formBg}>
                <Box>
                    <Typography color={palette.labelColor} gutterBottom variant="subtitle2">Topic name *</Typography>
                    <TextField size="small" onKeyDown={createSection} value={fieldValue} onChange={e => setFieldValue( e.target.value )} autoFocus fullWidth placeholder="Eg: Aptitude" />
                </Box>
                <Box marginTop="30px" display="flex" alignItems="center" gap="20px">
                    <Button disabled={adding} onClick={createSection} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} variant="contained" disableElevation >Add Section</Button>
                    <Button onClick={handleClose} variant="contained" disableElevation color="error" >Cancel</Button>
                </Box>
            </Box>
        </Dialog>
    )
}

const SectionUseToggleConfirm = ( { useSections, setUseSections, setQuestionFiles, setQuestions, setSections, state, setState } ) => {

    const [fieldValue, setFieldValue] = useState( "" )
    const [adding, setAdding] = useState( false )

    const { palette, border } = useTheme()


    const createSection = e => {
        if ( ( e.type === "keydown" && e.key === "Enter" ) || e.type === "click" ) {
            setAdding( true )
            if ( fieldValue && fieldValue?.trim().length > 0 ) {
                const sectionName = fieldValue?.trim()
                setSections( [sectionName] )
                setQuestions( prev => {
                    return prev.map( q => {
                        q.question_section = sectionName
                        return q
                    } )
                } )
                setQuestionFiles( prev => {
                    return prev.map( q => {
                        q.section = sectionName
                        return q
                    } )
                } )
                e.preventDefault()
                setState( false )
                setFieldValue( "" )
                setUseSections( true )
            }
            setAdding( false )
        }
    }

    const removeAllQuestions = () => {
        setQuestions( [] )
        setQuestionFiles( [] )
        setUseSections( true )
        setState( false )
    }

    const removeSections = () => {
        setSections( [] )
        setQuestions( prev => {
            return prev.map( q => {
                q.question_section = undefined
                return q
            } )
        } )
        setQuestionFiles( prev => {
            return prev.map( q => {
                q.section = undefined
                return q
            } )
        } )
        setUseSections( false )
        setState( false )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "60%", minWidth: "180px", maxWidth: "650px", background: palette.form.formCardBg } }} open={state} onClose={() => setState( false )}>
            <Typography variant='h6' padding="10px 20px" borderBottom={border[1]}>
                {!useSections ? "What do you want to with existing questions?" : "Are you sure?"}
            </Typography>
            {!useSections && <Box margin="20px" borderRadius="5px" border={border[1]} marginTop="15px" padding="20px" bgcolor={palette.form.formBg}>
                <Box>
                    <Typography color={palette.labelColor} gutterBottom variant="subtitle2">Create Section and add existing questions here *</Typography>
                    <Box flexWrap="wrap" display="flex" gap="10px">
                        <TextField size="small" onKeyDown={createSection} value={fieldValue} onChange={e => setFieldValue( e.target.value )} fullWidth autoFocus placeholder="Eg: Aptitude" />
                    </Box>
                    <Box marginTop="20px" gap="20px" alignItems="center" flexWrap="wrap" display="flex" >
                        <Button disabled={adding} onClick={createSection} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} sx={{ minWidth: "max-content" }} variant="contained" disableElevation >Add questions to section</Button>
                        <Typography variant='subtitle2' color="textSecondary" >OR</Typography>
                        <Button onClick={removeAllQuestions} variant="contained" disableElevation startIcon={Icons.default.DeleteIconOutlined} color="errorMessage">Remove Questions</Button>
                    </Box>
                </Box>
            </Box>}
            {useSections && <Box margin="20px" borderRadius="5px" border={border[1]} marginTop="15px" padding="20px" bgcolor={palette.form.formBg}>
                <Typography variant='subtitle2' color="textSecondary">All the question in the sections will be dumped out of the sections! and sections will be lost</Typography>
                <Button onClick={removeSections} variant="contained" disableElevation startIcon={Icons.default.DeleteIconOutlined} sx={{ marginTop: "20px" }} color="errorMessage">Okay</Button>
            </Box>}
        </Dialog >
    )
}

const AddQuestionsComponent = ( { setStepsStatus, setActiveStep, examCreationData, setExamCreationData } ) => {

    const { palette, border } = useTheme()


    const [sections, setSections] = useState( examCreationData?.sections || ['Primary'] )
    const [useSections, setUseSections] = useState( true )
    const [selectedSection, setSelectedSection] = useState( 'Primary' )
    const [questionsFile, setQuestionsFile] = useState( null )
    const [questionsPreview, setQuestionsPreview] = useState( false )
    const [sectionsCutoffEnabled, setSectionsCutoffEnabled] = useState( examCreationData?.enableSectionsCutoff ? true : false )
    const [addSectionDialogState, setAddSectionDialogState] = useState( false )
    const [addQuestionDialogState, setAddQuestionDialogState] = useState( false )
    const [questions, setQuestions] = useState( examCreationData.questions || [] )
    const [readOnlyModeForFileView, setReadOnlyModeForFileView] = useState( false )
    const [questionFiles, setQuestionFiles] = useState( examCreationData.questionFiles || [] )
    const [showUseSectionToggleInfoDialog, setShowUseSectionToggleInfoDialog] = useState( false )
    const [sectionMaxQuestionCounts, setSectionMaxQuestionCounts] = useState( examCreationData?.sectionQuestionsCountMap || { maxQuestionsForExam: { Primary: 0 }, totalQuestions: { Primary: 0 } } )
    const [sectionsCutoff, setSectionsCutoff] = useState( examCreationData?.sectionsCutoff || { totalCutoff: 0 } )


    const importFileInputRef = useRef()
    const previousSectionsCutoffValue = useRef( examCreationData?.sectionsCutoff || { totalCutoff: 0 } )

    const deleteSection = ( index ) => {
        const sectionName = sections[index]
        setQuestions( prev => prev.filter( q => q.question_section !== sectionName ) )
        const newSections = [...sections]
        newSections.splice( index, 1 )
        setSections( newSections )
    }

    const onImportChange = async ( e, section ) => {
        if ( e.target.files[0].name.includes( ".txt" ) ) {
            setQuestionsPreview( { status: "loading" } )
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( fileReader.result, "txt", undefined, section )
                setQuestionsFile( e.target.files[0] )
                setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "txt", plainData: fileReader.result, errors, questions, errorLines } )
                e.target.value = ""
            }
            fileReader.readAsText( e.target.files[0] )
        } else {
            try {
                const fileReader = new FileReader()
                fileReader.onload = async () => {
                    setQuestionsPreview( { status: "loading" } )
                    const formData = new FormData()
                    formData.append( 'file', e.target.files[0] )
                    try {
                        const res = await api.convertDocxToHtml( formData )
                        const { questions, errors, preview, errorLines } = validateQuestionsImportFormatForQuestionBank( res.data, "docx", undefined, section )
                        if ( e.type )
                            setQuestionsFile( e.target.files[0] )
                        setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "doc", plainData: convert( fileReader.result ), errors, questions, errorLines } )
                    } catch ( err ) {
                        console.log( err )
                        toast( "Something went wrong! try again later", { containerId: "main" } )
                        setQuestionsPreview( false )
                    } finally {
                        e.target.value = ''
                    }
                }
                fileReader.readAsText( e.target.files[0] )
            } catch ( err ) {
                console.log( err )
                setQuestionsPreview( false )
            }
        }
    }

    const removeQuestionFile = ( id ) => {
        setQuestionFiles( prev => prev.filter( f => f.id !== id ) )
    }

    const deleteQuestion = async ( id ) => {
        setQuestions( prev => prev.filter( q => q.id !== id ) )
    }

    const toggleUseSection = () => {
        if ( questions.length > 0 || questionFiles.length > 0 ) {
            setShowUseSectionToggleInfoDialog( true )
        } else
            setUseSections( prev => !prev )
    }

    const move = ( step ) => {

        const fileSections = []
        const formData = examCreationData.formData
        formData.delete( 'questionfiles[]' )
        formData.delete( 'sectionQuestionsCountMap' )
        formData.delete( 'cutoff' )
        for ( let i = 0; i < questionFiles.length; i++ ) {
            const file = questionFiles[i]
            fileSections.push( file.section )
            formData?.append( 'questionfiles[]', file.file )
        }
        formData.delete( 'fileSections' )
        formData.delete( 'questions' )
        formData?.append( 'cutoff', JSON.stringify( sectionsCutoff ) )
        formData?.append( 'fileSections', JSON.stringify( fileSections ) )
        formData?.append( 'questions', JSON.stringify( questions ) )
        formData?.append( 'sectionQuestionsCountMap', JSON.stringify( sectionMaxQuestionCounts ) )
        setExamCreationData( prev => {
            prev.formData = formData
            prev.questions = questions
            prev.questionFiles = questionFiles
            prev.usingSections = useSections
            prev.sections = sections
            prev.sectionQuestionsCountMap = sectionMaxQuestionCounts
            prev.enableSectionsCutoff = sectionsCutoffEnabled
            prev.sectionsCutoff = sectionsCutoff
            return prev
        } )
        let zeroQuestioSections = []
        for ( let i = 0; i < sections.length; i++ ) {
            const section = sections[i]
            if ( section === 'Primary' && ( !sectionMaxQuestionCounts.maxQuestionsForExam[section] || +sectionMaxQuestionCounts.maxQuestionsForExam[section] === 0 ) ) continue
            if ( !sectionMaxQuestionCounts.maxQuestionsForExam[section] || +sectionMaxQuestionCounts.maxQuestionsForExam[section] === 0 )
                zeroQuestioSections.push( section )
        }
        if ( zeroQuestioSections?.length > 0 ) {
            toast( <p>Please specify the number of questions needed from section(s): <font color={palette.errorMessage.dark}><em>{zeroQuestioSections?.join( ", " )}</em></font>.</p> )
            return step === -1 && setActiveStep( prev => prev + step )
        }
        if ( step === 1 ) {
            setStepsStatus( prev => {
                prev[1] = "completed"
                return prev
            } )
        }
        setActiveStep( prev => prev + step )
    }

    useEffect( () => {
        if ( sectionsCutoffEnabled ) {
            setSectionsCutoff( prev => {
                const newSectionsCutOff = {}
                for ( let i = 0; i < sections.length; i++ ) {
                    const section = sections[i]
                    newSectionsCutOff[section] = previousSectionsCutoffValue?.current[section] || 0
                }
                return newSectionsCutOff
            } )
        } else {
            setSectionsCutoff( prev => {
                if ( !prev.totalCutoff ) {
                    previousSectionsCutoffValue.current = prev
                    return { totalCutoff: 0 }
                }
                return prev
            } )

        }
    }, [sectionsCutoffEnabled, sections] )

    const handleTotalCutoffChange = e => {
        const newValue = { totalCutoff: e.target.value }
        setSectionsCutoff( newValue )
    }

    return (
        <Box padding="20px">

            <AddSectionDialog setSectionMaxQuestionCounts={setSectionMaxQuestionCounts} state={addSectionDialogState} setState={setAddSectionDialogState} setSections={setSections} />
            <QuestionsPreviewDialog setReadOnlyModeForFileView={setReadOnlyModeForFileView} setSelectedSection={setSelectedSection} readOnly={!Boolean( questionsFile ) || readOnlyModeForFileView} setQuestionFiles={setQuestionFiles} file={questionsFile} setFile={setQuestionsFile} isImporting={false} section={selectedSection || "Primary"} setIsImporting={() => { }} getQuestions={() => { }} setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />
            <AddQuestionDialog setSelectedSection={setSelectedSection} setQuestions={setQuestions} section={selectedSection || 'Primary'} fromComponent="questionbank" state={addQuestionDialogState} setState={setAddQuestionDialogState} />
            <SectionUseToggleConfirm setQuestions={setQuestions} setQuestionFiles={setQuestionFiles} useSections={useSections} setUseSections={setUseSections} setSections={setSections} questions={questions} setState={setShowUseSectionToggleInfoDialog} state={showUseSectionToggleInfoDialog} />

            <Box>
                <Typography variant='h6' color="primaryDark" >Exam Questions</Typography>
                <Typography color="primaryDark" variant='body2' >Add questions or import questions from a file</Typography>
            </Box>
            <Box marginTop="20px" bgcolor={palette.form.formBg} padding="20px" borderRadius="5px" border={border[1]}>
                <Box display="flex" flexDirection="column">
                    <FormControl margin="normal" sx={{ maxWidth: "450px" }} fullWidth>
                        <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Exam cut-off %</Typography></label>
                        <TextField onChange={handleTotalCutoffChange} value={sectionsCutoff?.totalCutoff || 0} disabled={sectionsCutoffEnabled} type="number" InputProps={{ endAdornment: <Percent sx={{ paddingX: "10px" }} fontSize='small' /> }} inputProps={{ min: 0, step: 0.001, max: 100 }} id="cutoff" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='cutoff' />
                        <Typography variant='subtitle2' fontSize="12px" >Candidate will only be qualified if cut-off has been reached!</Typography>
                    </FormControl>
                    {( sections?.length > 2 || ( sections?.length === 2 && ( questionFiles?.filter( q => q.section === 'Primary' ).length > 0 || questions?.filter( q => q.section === 'Primary' ).length > 0 ) ) ) && <FormControlLabel control={<CustomSwitch color='primaryDark' checked={sectionsCutoffEnabled} onChange={e => setSectionsCutoffEnabled( prev => !prev )} />} label="Use section-wise cut-off." />}
                </Box>
                <input accept='text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document' onChange={e => onImportChange( e, undefined )} style={{ width: 0, height: 0, opacity: 0 }} type="file" ref={importFileInputRef} />
                <Box marginBottom="20px" display="flex" justifyContent="space-between" alignItems="center">
                    <Box marginTop="-20px" sx={{ cursor: "pointer" }} onClick={toggleUseSection} display="flex" gap='10px' alignItems="center">
                        <Typography variant='h6' color="secondary" fontSize="16px" >Sections and questions</Typography>
                    </Box>
                    <Box display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                        {<Button onClick={() => setAddSectionDialogState( true )} startIcon={Icons.default.AddIcon} size='small' sx={{ textTransform: "capitalize" }} disableElevation variant='contained' color="primary" >Add Section</Button>}
                        {( questions.length > 0 || questionFiles.length > 0 ) && <Button onClick={() => importFileInputRef.current.click()} startIcon={Icons.default.DownloadIcon} size='small' sx={{ textTransform: "capitalize" }} disableElevation variant='contained' color="secondary" >Add Question file</Button>}
                        {( questions.length > 0 || questionFiles.length > 0 ) && <Button onClick={() => setAddQuestionDialogState( true )} startIcon={Icons.default.AddIcon} size='small' sx={{ textTransform: "capitalize" }} disableElevation variant='contained' color="success" >Add Question</Button>}
                    </Box>
                </Box>

                {/* SECTIONS */}
                {sections.length > 0 && <Box marginTop="20px" display="flex" flexDirection="column" gap="10px">
                    {/* <SectionBlock sectionMaxQuestionCount={sectionMaxQuestionCounts['Primary']} setSectionMaxQuestionCounts={setSectionMaxQuestionCounts} setReadOnlyModeForFileView={setReadOnlyModeForFileView} parent='create' deleteQuestion={deleteQuestion} removeQuestionFile={removeQuestionFile} files={questionFiles?.filter( q => !q.section )} setSelectedSection={setSelectedSection} setAddQuestionDialogState={setAddQuestionDialogState} questionsPreview={questionsPreview} setQuestionsPreview={setQuestionsPreview} onImportChange={onImportChange} index={-1} deleteSection={deleteSection} section={"Primary"} questions={questions?.filter( q => !q.section )} key={-1} /> */}
                    {sections.map( ( section, index ) => {
                        const sectionFiles = questionFiles?.filter( q => q.section === section )
                        const sectionQuestions = questions?.filter( q => q.section === section )
                        return section === 'Primary' && ( sectionFiles.length === 0 && sectionQuestions.length === 0 ) ? "" : (
                            <SectionBlock cutoffInput={sectionsCutoffEnabled} setSectionsCutoff={setSectionsCutoff} sectionsCutoff={sectionsCutoff} sectionMaxQuestionCount={sectionMaxQuestionCounts} setSectionMaxQuestionCounts={setSectionMaxQuestionCounts} setReadOnlyModeForFileView={setReadOnlyModeForFileView} parent='create' deleteQuestion={deleteQuestion} removeQuestionFile={removeQuestionFile} files={sectionFiles} setSelectedSection={setSelectedSection} setAddQuestionDialogState={setAddQuestionDialogState} questionsPreview={questionsPreview} setQuestionsPreview={setQuestionsPreview} onImportChange={onImportChange} index={index} deleteSection={deleteSection} section={section} questions={sectionQuestions} key={index} />
                        )
                    } )}
                </Box>}


                {questions.length === 0 && questionFiles.length === 0 && <Box textAlign="center" display="flex" flexDirection="column" gap="20px" padding="20px" alignItems="center" justifyContent="center" >
                    <img width="160px" src={noQuestionImg} alt="No Questions" />
                    <Typography variant='subtitle2' fontWeight="bold" color="textSecondary" >No questions added yet!</Typography>
                    <Box display="flex" gap="10px" justifyContent="center" alignItems="center">
                        <Button variant='contained' color='primary' startIcon={Icons.default.DownloadIcon} onClick={() => importFileInputRef.current.click()} disableElevation sx={{ textTransform: "capitalize", marginTop: "10px" }}>Import Questions</Button>
                        <Button variant='contained' color='secondary' startIcon={Icons.default.AddIcon} disableElevation onClick={() => setAddQuestionDialogState( true )} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Add Questions</Button>
                        <Button variant='contained' color='success' startIcon={Icons.default.AddBox} disableElevation onClick={() => setAddSectionDialogState( true )} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Add Section</Button>
                    </Box>
                </Box>}
            </Box>
            <Box marginTop="20px" justifyContent="flex-end" display="flex" gap="20px" alignItems="center" flexWrap="wrap">
                <Button variant='contained' disableElevation startIcon={Icons.default.KeyBoardArrowLeft} onClick={() => move( -1 )} color='error' sx={{ textTransform: "capitalize" }} >Back</Button>
                <Button variant='contained' disableElevation disabled={questions.length === 0 && questionFiles.length === 0} endIcon={Icons.default.KeyBoardArrowRight} onClick={() => move( 1 )} color="primaryDark" sx={{ textTransform: "capitalize" }}  >Next</Button>
            </Box>
        </Box>
    )
}

export default AddQuestionsComponent

