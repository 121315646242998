import { Box, InputLabel, Select, MenuItem, DialogActions, Button, Fab, TextField, FormControl, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, Table, TableBody, TableHead, TableContainer, Chip, TableCell, Paper, TableRow, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useOrganizationStore } from '../../store/organization.store'
import { useUserStore } from '../../store/user.store'
import { Icons } from '../../utils/utilities'
import SearchIcon from '@mui/icons-material/Search'

function UserAccount() {

    const UserStore = useUserStore()
    const OrganizationStore = useOrganizationStore()

    const [search, setSearch] = React.useState( '' )
    const [del, setDel] = React.useState( false )

    //
    const [open, setOpen] = React.useState( false )
    const [action, setAction] = useState( "create" )

    // new form
    // const [userid, userID] = React.useState( '' )
    const [regid, setRegID] = React.useState( '' )
    const [username, setUserName] = React.useState( '' )
    const [email, setEmail] = React.useState( '' )
    const [institutioncode, setInstitutionCode] = React.useState( '' )
    const [departmentcode, setDepartmentCode] = React.useState( '' )
    const [role, setRole] = React.useState( '' )
    const [status, setStatus] = React.useState( '' )

    const handleClickOpen = () => {
        setOpen( true )
    }

    const handleClose = () => {
        setOpen( false )
        clearForm()
    }

    useEffect( () => {
        UserStore.fetchUsers()
        OrganizationStore.fetchOrganization()
        OrganizationStore.fetchDepartment()
    }, [OrganizationStore, UserStore] )

    const handleChange = ( event ) => {
        setSearch( event.target.value )
    }

    const clearForm = () => {
        setRegID( '' )
        setUserName( '' )
        setEmail( '' )
        setInstitutionCode( '' )
        setDepartmentCode( '' )
        setRole( '' )
        setStatus( '' )
    }

    const createAccountOpenForm = () => {
        setAction( "create" )
        handleClickOpen()
    }

    const editAccounOpenForm = ( row ) => {
        setAction( "edit" )
        setRegID( row.user_reg_id )
        setUserName( row.user_name )
        setEmail( row.user_email )
        setInstitutionCode( row.user_org_code )
        setDepartmentCode( row.user_dept_code )
        setRole( row.user_role )
        setStatus( row.user_status )
        handleClickOpen()

    }

    const createAccount = () => {

        let payload = {
            "user_reg_id": regid,
            "user_email": email,
            "user_name": username,
            "user_org_code": institutioncode,
            "user_dept_code": departmentcode,
            "user_role": role,
            "user_status": status
        }

        UserStore.createUser( payload )

    }

    const updateAccount = () => {

    }

    const approveUser = ( user ) => {
        let payload = {
            "user_id": user["user_id"],
            "user_status": "active"
        }
        UserStore.updateUserStatus( payload )
    }

    const blockUser = ( user ) => {
        let payload = {
            "user_id": user["user_id"],
            "user_status": "suspended"
        }
        UserStore.updateUserStatus( payload )
    }

    return (
        <Box>
            <Paper sx={{ justifyContent: { md: "center" } }}>
                <Box padding="20px">
                    <Typography variant='h5'>User Accounts </Typography>
                </Box>
                <hr />
                <Box padding="20px" className="col-md-8 mt-4">

                    <Box className="row justify-content-md-center">
                        <Box>
                            <FormControl fullWidth>
                                <TextField value={search} onChange={handleChange} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search" variant="standard" type="text" />
                                {/* <InputLabel htmlFor="search-box">Search</InputLabel> */}
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box padding="20px">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "650px" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Institution</TableCell>
                                    <TableCell>Department</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UserStore.userAccounts.map( ( row, k ) => (
                                    <TableRow key={k}>
                                        <TableCell component="th" scope="row">
                                            {row.user_reg_id}
                                        </TableCell>
                                        <TableCell >{row.user_name}</TableCell>
                                        <TableCell>{row.user_email}</TableCell>
                                        <TableCell >{row.user_org_code ? row.user_org_code : 'ALL'}</TableCell>
                                        <TableCell >{row.user_dept_code}</TableCell>
                                        <TableCell >{row.user_role.toUpperCase()}</TableCell>
                                        <TableCell align="right">
                                            <Chip
                                                size="small"
                                                avatar={( row.user_status.toUpperCase() === 'ACTIVE' ) ? Icons.DoneIcon : Icons.ErrorIcon}
                                                label={row.user_status.toUpperCase()}
                                                color={( row.user_status.toUpperCase() === 'ACTIVE' ) ? 'primary' : 'secondary'}
                                            />
                                        </TableCell>
                                        {
                                            ( del === false ) ?
                                                <TableCell align="right" >

                                                    {
                                                        ( row.user_status.toUpperCase() !== 'ACTIVE' ) ?
                                                            <Tooltip title="Approve">
                                                                <span>
                                                                    <IconButton color="primary" onClick={() => approveUser( row )} aria-label="Approve" className={'mr-1'} >
                                                                        {Icons.CheckCircleIcon}
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title="Block">
                                                                <span>
                                                                    <IconButton color="primary" onClick={() => blockUser( row )} aria-label="Block" className={'mr-1'} >
                                                                        {Icons.BlockIcon}
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                    }


                                                    <Tooltip title="Edit Account">
                                                        <span>
                                                            <IconButton color="primary" onClick={() => editAccounOpenForm( row )} aria-label="Edit" className={'mr-1'} >
                                                                {Icons.RateReviewIcon}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>

                                                    <Tooltip title="Delete Account">
                                                        <span>
                                                            <IconButton onClick={() => setDel( true )} color="secondary" aria-label="delete" >
                                                                {Icons.DeleteIcon}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                :
                                                <TableCell align="right">
                                                    <Tooltip title="Yes">
                                                        <span>
                                                            <IconButton color="primary" aria-label="Yes" className={'mr-1'} >
                                                                {Icons.DoneIcon}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>

                                                    <Tooltip title="Cancel">
                                                        <span>
                                                            <IconButton onClick={() => setDel( false )} color="secondary" aria-label="Cancel" >
                                                                {Icons.ClearIcon}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                        }
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            </Paper>


            <Dialog maxWidth={'sm'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle className="border-bottom" color="primary" id="form-dialog-title">
                    {
                        action === "create" ? 'New ' : ''
                    }
                    {
                        action === "edit" ? 'Update ' : ''
                    }
                    User Account</DialogTitle>
                <DialogContent>
                    <form style={{ display: "flex", gap: "10px", flexDirection: "column" }} className="mt-3" noValidate autoComplete="off">
                        <TextField sx={{ width: "500px", minWidth: "200px" }} className="w-100 mb-4" id="outlined-basic" onChange={( e ) => setRegID( e.target.value )}
                            label="User Registration ID" variant="outlined" value={regid} />

                        <TextField sx={{ width: "500px", minWidth: "200px" }} className="w-100 mb-4" id="outlined-basic" onChange={( e ) => setUserName( e.target.value )}
                            label="User Name" variant="outlined" value={username} />

                        <TextField sx={{ width: "500px", minWidth: "200px" }} className="w-100 mb-4" id="outlined-basic" onChange={( e ) => setEmail( e.target.value )}
                            label="Email" variant="outlined" value={email} />

                        <FormControl variant="outlined" className="w-100 mb-4">
                            <InputLabel id="institutioncode-select-outlined-label">Institution Name</InputLabel>
                            <Select
                                labelId="institutioncode-select-outlined-label"
                                id="institutioncode-select-outlined"
                                value={institutioncode}
                                onChange={( e ) => { setInstitutionCode( e.target.value ) }}
                                label="Institution Name">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    OrganizationStore.getOrganization.filter( ( itm, i ) => {
                                        return itm.departments !== null
                                    } ).map( ( row, k ) => {
                                        return <MenuItem value={row.school_name_short}>{row.school_name}</MenuItem>
                                    } )
                                }

                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className="w-100 mb-4">
                            <InputLabel id="departmentcode-select-outlined-label">Department Name</InputLabel>
                            <Select
                                labelId="departmentcode-select-outlined-label"
                                id="departmentcode-select-outlined"
                                value={departmentcode}
                                onChange={( e ) => setDepartmentCode( e.target.value )}
                                label="Institution Name"
                                disabled={( institutioncode !== "" ) ? false : true} >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    OrganizationStore.getDepartment
                                        .filter( ( list, k ) => {
                                            return list.dept_org_code === institutioncode
                                        } )
                                        .map( ( row, k ) => {
                                            return <MenuItem value={row.dept_code}>{row.dept_name}</MenuItem>
                                        } )
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className="w-100 mb-4">
                            <InputLabel id="role-select-outlined-label">User Role</InputLabel>
                            <Select
                                labelId="role-select-outlined-label"
                                id="role-select-outlined"
                                value={role}
                                onChange={( e ) => setRole( e.target.value )}
                                label="User Role" >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="moderator">Moderator</MenuItem>
                                <MenuItem value="presenter">Presenter</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                            </Select>
                        </FormControl>

                        {/* <TextField className="w-100 mb-4" id="outlined-basic" onChange={(e)=> setStatus(e.target.value)}
            label="Status" variant="outlined" value={status} /> */}


                        <FormControl variant="outlined" className="w-100 mb-4">
                            <InputLabel id="status-select-outlined-label">Status</InputLabel>
                            <Select
                                labelId="status-select-outlined-label"
                                id="status-select-outlined"
                                value={status}
                                onChange={( e ) => setStatus( e.target.value )}
                                label="Status" >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                                <MenuItem value="suspend">Suspend</MenuItem>
                            </Select>
                        </FormControl>
                    </form>

                </DialogContent>
                <DialogActions className="px-3">
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    {
                        ( action === "create" ) ?
                            <Button color="primary" onClick={createAccount}>
                                Create
                            </Button> : ""
                    }
                    {
                        ( action === "edit" ) ?
                            <Button color="primary" onClick={updateAccount}>
                                Update
                            </Button> : ""
                    }

                </DialogActions>
            </Dialog>



            <Fab sx={{ position: 'fixed', right: 20, bottom: 20 }} onClick={createAccountOpenForm} color="primary" aria-label="add">
                {Icons.AddIcon}
            </Fab>
        </Box>
    )
}

export default UserAccount