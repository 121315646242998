import React from 'react'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { useEffect } from 'react'
import { useState } from 'react'
import { Box, Button, CircularProgress, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import noFormImg from '../../../assets/no-resume-templates.svg'
import AddResumeTemplateDialog from '../../../components/admin/resume-builder/AddResumeTemplateDialog'
import { useNavigate } from 'react-router-dom'
import { Icons } from '../../../utils/utilities'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useUserStore } from '../../../store/user.store'
import warningImg from '../../../assets/warning.svg'

const ResumeTemplates = observer( () => {

    const [loading, setLoading] = useState( true )
    const [addDialogState, setAddDialogState] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    const ResumeBuilderStore = useResumeBuilderStore()
    const UserStore = useUserStore()

    const { palette } = useTheme()

    const navigate = useNavigate()

    const deleteTemplate = async ( id ) => {
        try {
            await api.deleteResumeTemplate( id )
            await ResumeBuilderStore.fetchTemplates()
        } catch ( err ) {
            console.log( err )
            toast( ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    useEffect( () => {
        const getData = async () => {
            await ResumeBuilderStore.fetchTemplates()
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        } else
            getData()
    }, [ResumeBuilderStore, UserStore] )

    return (
        <Box id="resume-form-container" height="100%">
            {hasPermission && <Box>
                <AddResumeTemplateDialog state={addDialogState} setAddDialogState={setAddDialogState} />
                <Paper sx={{ display: "flex", flexDirection: "column" }}>
                    <Box padding="20px">
                        <Typography variant='h5'>Resume templates</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>Manage resume templates here</Typography>
                    </Box>
                    {!loading && <Fab sx={{ position: "fixed", bottom: "20px", right: "20px" }} color="secondary" onClick={() => setAddDialogState( true )}>
                        {Icons.AddIcon}
                    </Fab>}
                    {!loading && <Box>
                        {ResumeBuilderStore.getTemplates.length > 0 && <Box>
                            <TableContainer sx={{ height: "500px", overflow: "auto" }}>
                                <Table stickyHeader sx={{ overflow: "auto" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ background: palette.primary.main, color: "white", maxWidth: "70px", minWidth: "70px" }}>Sl.No</TableCell>
                                            <TableCell sx={{ background: palette.primary.main, color: "white", maxWidth: "200px", minWidth: "200px" }}>Template name</TableCell>
                                            <TableCell sx={{ background: palette.primary.main, color: "white", maxWidth: "300px", minWidth: "300px" }}>Form name</TableCell>
                                            <TableCell sx={{ background: palette.primary.main, color: "white", maxWidth: "200px", minWidth: "200px" }}>Institutes linked</TableCell>
                                            <TableCell sx={{ background: palette.primary.main, color: "white", maxWidth: "100px" }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ResumeBuilderStore.getTemplates.map( ( row, rowId ) => (
                                            <TableRow sx={{ padding: "5px", cursor: "pointer", "&:hover": { background: "#eee" } }} key={row.id}>
                                                <TableCell onClick={() => navigate( `/admin/resume-templates/${row.id}` )} sx={{ maxWidth: "70px", minWidth: "70px", padding: "5px 10px" }}>{rowId + 1}</TableCell>
                                                <TableCell onClick={() => navigate( `/admin/resume-templates/${row.id}` )} sx={{ maxWidth: "200px", minWidth: "200px", padding: "5px 10px" }}> <Typography fontSize="14px" fontWeight="bold">{row.name}</Typography> </TableCell>
                                                <TableCell onClick={() => navigate( `/admin/resume-templates/${row.id}` )} sx={{ maxWidth: "300px", minWidth: "300px", padding: "5px 10px" }}>{row.form_name}</TableCell>
                                                <TableCell onClick={() => navigate( `/admin/resume-templates/${row.id}` )} sx={{ maxWidth: "200px", minWidth: "200px", padding: "5px 10px" }}>{row.access_list.join( "," )}</TableCell>
                                                <TableCell sx={{ maxWidth: "100px", padding: "5px 10px" }}>
                                                    <IconButton size="small" onClick={() => deleteTemplate( row.id )}>
                                                        {Icons.DeleteIcon}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ) )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>}
                        {ResumeBuilderStore.getTemplates.length === 0 && <Box bgcolor="#eee" padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="center">
                            <img src={noFormImg} height="150px" style={{ marginBottom: "20px" }} alt="No Form" />
                            <Typography color="textSecondary" variant='subtitle2'>Seems like there are no templates to show!</Typography>
                            <Button sx={{ textTransform: "capitalize" }} startIcon={Icons.AddIcon} onClick={() => setAddDialogState( true )} variant='outlined'>Add Template</Button>
                        </Box>}
                    </Box>}
                    {loading && <Typography variant='subtitle2' padding="20px" alignItems="center" gap="10px" display="flex">
                        <CircularProgress size={20} /> Loading templates...
                    </Typography>}
                </Paper>
            </Box>}
            {
                !hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                    <img src={warningImg} width="40px" alt="No permission" />
                    <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
                </Paper>
            }
        </Box >
    )
} )

export default ResumeTemplates
