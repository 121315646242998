import { Typography, Box, Paper, Button } from '@mui/material'
import sessionEndedImg from '../../assets/warning.svg'
import { useUserStore } from '../../store/user.store'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'


const EmbeddBbb = () => {

    const UserStore = useUserStore()
    const navigate = useNavigate()

    const navigateToHome = () => {
        if ( UserStore.getUser['user_role']?.toUpperCase() === "STUDENT" )
            navigate( '/student/home' )
        else if ( UserStore.getUser['user_role']?.toUpperCase() === "ADMIN" || UserStore.getUser['user_role']?.toUpperCase() === "SUBADMIN" )
            navigate( '/admin/home' )
        else
            navigate( '/faculty/home' )
    }

    useEffect( () => {
        const getData = () => {
            UserStore.fetchUser()
        }
        getData()
    }, [UserStore] )


    return (
        <Box>
            <Box id="embed-frame-xclass">
                {/* {JSON.stringify(props.RoomStore.getStreamDetails)} */}
                {
                    //props.RoomStore.getStreamDetails.is_stream?
                    localStorage.getItem( 'active_session' ) ?
                        <iframe title='streem window' type="text/html" id="embedd-bbb" allow="microphone;camera;display-capture;" allowFullScreen
                            src={atob( localStorage.getItem( 'active_session' ) )}
                        /> :
                        <Box >
                            <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '70vh' }}>
                                <Box textAlign="center">
                                    {/* <img src={sessionEndedImg} alt="Session ended" style={{ marginBottom: "30px" }} width="60"></img>
                                    <br></br>
                                    <Typography variant='h5'>Your session has ended!</Typography>
                                    <Typography>Restart your session</Typography> */}
                                    <Paper sx={{ position: "relative", zIndex: 2, maxWidth: "300px", width: "90%" }}>
                                        <Box padding="20px" alignItems="center" display="flex" flexDirection="column" gap="10px">
                                            <img width="50px" src={sessionEndedImg} alt="Alive Logo" />
                                            <Typography variant="h6" fontSize="20px">Your session has ended!</Typography>
                                            <Typography variant="subtitle2" color="textSecondary">Restart your session</Typography>
                                            <Button variant="outlined" onClick={navigateToHome} sx={{ textTransform: "capitalize" }}>Back to home</Button>
                                        </Box>
                                    </Paper>
                                    {/* <button className="btn btn-primary"> Go To Home</button> */}
                                </Box>

                            </Box>
                        </Box>
                }

            </Box>
        </Box>
    )
}

export default EmbeddBbb
