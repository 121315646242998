import { Box, Chip, Typography, useTheme } from '@mui/material'
import React from 'react'

const EditQuestionList = ( { questions, selectedQuestion, selectQuestion } ) => {

    const { palette } = useTheme()

    return (
        <Box borderRight="2px solid #d3d3d3" className="custom-scrollbar" width={{ lg: "350px", sm: "350px", xs: "fit-content" }} maxWidth="230px" sx={{ overflowX: "hidden", overflowY: "auto" }} height="100%" bgcolor="white">
            {/* <Typography variant='h5' marginBottom="20px" gutterBottom>Questions</Typography> */}
            <Box display="flex" flexDirection="column" gap="1px" height="100%">
                {questions.map( ( question, index ) => (
                    <Box
                        onClick={() => selectQuestion( question.question.question_id )} padding="10px"
                        display="flex"
                        alignItems="center"
                        key={question.question.question_id}
                        borderBottom="1px solid #d3d3d3"
                        sx={{ "&:hover .question-number": { background: String( selectedQuestion.question.question_id ) === String( question.question.question_id ) ? palette.primary.main : palette.primary.light, color: "white" }, cursor: "pointer" }}
                        borderRight={String( selectedQuestion.question.question_id ) === String( question.question.question_id ) ? `${palette.primary.main} 5px solid` : "#eee 5px solid"}
                    >
                        <Box
                            className="question-number"
                            marginRight="10px"
                            display="flex"
                            bgcolor="white"
                            alignItems="center"
                            borderRadius="50%"
                            justifyContent="center"
                            minWidth={{ sm: "25px", xs: "25px" }}
                            width={{ sm: "25px", xs: "25px" }}
                            height={{ sm: "25px", xs: "25px" }}
                            sx={{
                                background: String( selectedQuestion.question.question_id ) === String( question.question.question_id ) ? palette.primary.main : "#fff",
                                color: String( selectedQuestion.question.question_id ) === String( question.question.question_id ) ? "white" : "#000",
                            }}
                        >
                            <Typography fontSize="10px" fontWeight="bold">{index + 1}</Typography>
                        </Box>
                        <Box fontSize="12px" flexDirection="column" display={{ lg: "flex", sm: "flex", xs: "none" }} gap="5px">
                            {question.question.question_text.trim().includes( 'LATEX-' ) ? <Typography variant='subtitle2' maxWidth="150px" noWrap> {question.question.question_text.trim().split( "LATEX-" )[1].substring( 0, 20 ).replace( /\\/g, "" )}</Typography> : <Typography maxWidth="150px" noWrap variant='subtitle2' width="100px">Question-{question.question.question_id}</Typography>}
                            <Box display="flex" gap="5px">
                                <Chip size='small' sx={{ fontSize: "12px" }} label={question.question.question_type} />
                                <Chip size='small' sx={{ fontSize: "12px" }} label={question.question.question_answer_selection} />
                                {/* <b>{question.question.question_type}</b>
                                <b>{question.question.question_answer_selection}</b> */}
                            </Box>
                        </Box>
                    </Box>
                ) )
                }
            </Box>
        </Box >
    )
}

export default EditQuestionList
