import { Button, Typography, Box, Icon, CircularProgress, Paper } from '@mui/material'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
// import SchedulesAndActivities from '../../components/dashboard-components/SchedulesAndActivities'
// import MobileSchedulesAndActivities from '../../components/dashboard-components/MobileSchedulesAndActivities'
import { useAssignmentStore } from '../../store/assignment.store'
import { useUserStore } from '../../store/user.store'
import { useRoomStore } from '../../store/room.store'
import { useEffect, useState } from 'react'
import { Icons } from '../../utils/utilities'
import noAssignmentsImg from '../../assets/noassignment.svg'
import noClassesImg from '../../assets/noclasses.svg'
import noExamsImg from '../../assets/noexams.svg'
import SubmitAssignmentDialog from '../../components/user/SubmitAssignmentDialog'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useExamStore } from '../../store/exam.store'
import { formatDateTimeForDisplay, formatDateTimeForInputField } from '../../utils/exam-utilities'
import { observer } from 'mobx-react'
import { useLayoutContext } from '../../contexts/layout.context'

const Dashboard = observer( () => {

    const AssignmentStore = useAssignmentStore()
    const UserStore = useUserStore()
    const RoomStore = useRoomStore()
    const ExamStore = useExamStore()

    const { setCurrentLoc } = useLayoutContext()

    const navigate = useNavigate()

    const [isAssignmentsloaded, setIsAssignmentsLoaded] = useState( false )
    const [isClassesLoaded, setIsClassesLoaded] = useState( false )
    const [isExamsLoaded, setIsExamsLoaded] = useState( false )
    const [openAssignmentSubmission, setOpenAssignmentSubmission] = useState( false )
    const [selectedAssignmentData, setSelectedAssignmentData] = useState( {} )

    const handleClickOpenAssignmentSubmission = ( value ) => {
        setSelectedAssignmentData( value )
        setOpenAssignmentSubmission( true )
    }

    const handleCloseAssignmentSubmission = ( value ) => {
        setOpenAssignmentSubmission( false )
        setSelectedAssignmentData( value )
    }

    const startSession = async ( data, flag ) => {

        if ( flag === true ) {
            data["record"] = true
        } else {
            data["record"] = false
        }
        if ( data["sTime"] === undefined || data["sTime"] === null || data["eTime"] === undefined || data["eTime"] === null ) {
            toast.error( "ERP not sending Start Time / End Time" )
            return
        } else if ( checkTime( data["sTime"], data["eTime"] ) === false ) {
            toast.error( `You can only start session from ${data["sTime"].substring( 0, 5 )} to ${data["eTime"].substring( 0, 5 )}` )
            return
        }

        try {
            var res = await RoomStore.startSession( data )
            if ( res["status"] === true ) {
                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /ipad/i ) || userAgent.match( /iphone/i ) ) {
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    navigate( '/faculty/stream' )
                }
            }
        } catch ( err ) {
            console.log( err )
        }
    }

    const joinSession = async ( data ) => {
        try {
            let res = await RoomStore.joinSessionAsModerator( data )
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');

                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) ) {
                    // iPad or iPhone
                    //alert("iphone", window.innerWidth)
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    navigate( '/faculty/stream' )
                }
            }
        } catch ( err ) {
            console.log( err )
        }
    } //endSession

    const joinSessionAsViewer = async ( data ) => {
        try {
            let res = await RoomStore.joinSession( data )
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');

                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) ) {
                    // iPad or iPhone
                    //alert("iphone", window.innerWidth)
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    // navigate( '/app/stream' )
                    if ( UserStore.getUser['user_role'] === "STUDENT" )
                        navigate( '/student/stream' )
                    else
                        navigate( '/faculty/stream' )
                }
            }
        } catch ( err ) {
            console.log( err )
        }
    } //endSession

    // const getSessionClassAttendence = ( data ) => {
    //     RoomStore.getSessionClassAttendence( data )
    // }
    const endSession = ( data ) => {
        RoomStore.endSession( data )
    }


    const checkTime = ( sTime, eTime ) => {
        var currentDate = new Date()
        var currentTime = currentDate.toString().split( " " )[4]
        var sTimeSplit = sTime.split( ":" )
        // console.log( sTime )
        var eTimeSplit = eTime.split( ":" )
        var cTimeSplit = currentTime.split( ":" )
        var sTimeHr = parseInt( sTimeSplit[0] ), sTimeMin = parseInt( sTimeSplit[1] )
        var eTimeHr = parseInt( eTimeSplit[0] ), eTimeMin = parseInt( eTimeSplit[1] )
        var cTimeHr = parseInt( cTimeSplit[0] ), cTimeMin = parseInt( cTimeSplit[1] )
        var startMin = 0, endMin = 0, currentMin = 0
        if ( sTimeHr === 0 ) {
            startMin = sTimeMin
        } else if ( sTimeHr > 0 ) {
            startMin = ( ( 60 * sTimeHr ) + sTimeMin )
        }
        console.log( `Start Min`, startMin )
        if ( eTimeHr === 0 ) {
            endMin = eTimeMin
        } else if ( eTimeHr > 0 ) {
            endMin = ( ( 60 * eTimeHr ) + eTimeMin )
        }
        console.log( `End Min`, endMin )
        if ( cTimeHr === 0 ) {
            currentMin = cTimeMin
        } else if ( cTimeHr > 0 ) {
            currentMin = ( ( 60 * cTimeHr ) + cTimeMin )
        }
        console.log( `Current Min`, currentMin )

        if ( currentMin >= ( startMin - 5 ) && currentMin <= endMin ) {
            return true
        }
        else {
            return false
        }
    }

    const navigateToExams = ( id ) => {
        if ( UserStore.getUser['user_role'] === "STUDENT" ) {
            navigate( `/student/exams` )
        } else {
            navigate( `/faculty/exams/${id}` )
            setCurrentLoc( `/faculty/exams` )
        }
    }


    useEffect( () => {
        const getData = async () => {
            // await UserStore.fetchUser()
            if ( UserStore.getUser["user_role"] === "presenter" || UserStore.getUser["user_role"] === "FACULTY" || UserStore.getUser["user_role"] === "HOD" || UserStore.getUser["user_role"] === "PRINCIPAL" ) {
                await AssignmentStore.fetchAssignmentByAuthor()
                setIsAssignmentsLoaded( true )
                await RoomStore.fetchViewerAndPresenterRooms()
                await RoomStore.getMyActiveSessions()
                await RoomStore.resetStreamDetails()
                if ( !await ExamStore.fetchExams() ) {
                    toast.error( "Something went wrong!" )
                } setIsClassesLoaded( true )
            } else if ( UserStore.getUser["user_role"] === "observer" || UserStore.getUser["user_role"] === "STUDENT" ) {
                await AssignmentStore.fetchAssignmentByStudent()
                setIsAssignmentsLoaded( true )
                await RoomStore.fetchViewerAndPresenterRooms()
                await RoomStore.getMyActiveSessions()
                if ( !await ExamStore.fetchExams() ) {
                    toast.error( "Something went wrong! while fetching exams" )
                } setIsClassesLoaded( true )
            }
            setIsExamsLoaded( true )
        }
        getData()
    }, [UserStore, AssignmentStore, RoomStore, ExamStore] )

    return (
        <Box padding="0 0 20px 20px" display="grid" marginTop="-28px" gridTemplateColumns="100% 0" sx={{ transition: "0.5s ease-in-out", '@media(max-width:1100px)': { gridTemplateColumns: "100% 0" } }} bgcolor="#f3f6fd" height="100%" overflow="auto">
            <SubmitAssignmentDialog selectedValue={selectedAssignmentData} open={openAssignmentSubmission} onClose={handleCloseAssignmentSubmission} />
            <Box padding="20px 20px 20px 0" >
                <Box>
                    <Box display="flex" marginBottom="10px" justifyContent="space-between" alignItems="center">
                        <Typography fontWeight="bold">Online Classes</Typography>
                        {/* <Button sx={{ textTransform: "capitalize" }} endIcon={<NavigateNextIcon />}>View All</Button> */}
                    </Box>
                    {isClassesLoaded && RoomStore.getRoom.length > 0 && <Paper>
                        {RoomStore.getRoom.map( ( room, k ) => {
                            let exist = RoomStore.getActiveRoom.filter( ( item, k ) => {
                                return item.room_session_id === room.time_table_id
                            } ).length
                            return (
                                <Box borderBottom={"2px solid rgba(0,0,0,0.2)"} key={room.time_table_id} marginBottom="10px" alignItems="center" bgcolor="white" gap={{ sm: "20px", xs: "5px" }} sx={{ padding: "20px" }} display="grid" gridTemplateColumns={{ sm: "30px auto auto", xs: "1fr" }} borderRadius="5px">
                                    <PlayCircleFilledIcon color='primary' sx={{ display: { sm: "block", xs: "none" }, fontSize: "30px" }} />
                                    <Box display="flex" marginRight="30px" alignItems={{ sm: "center", xs: "flex-start" }} justifyContent="space-between" flexDirection={{ sm: "row", xs: "column" }}>
                                        <Box display="flex" width="150px" alignItems="center" gap="5px" marginTop={{ sm: "0", xs: "5px" }} >
                                            <Typography variant='h6' textTransform="capitalize" noWrap fontWeight="bold">{room.subject_short_name}</Typography>
                                            {/* <Typography variant='h6' textTransform="capitalize" noWrap fontWeight="bold">{room.subject_short_name}</Typography> */}
                                            <Typography textTransform="capitalize" fontSize="12px">
                                                {String( room["interval_type_id"] ) === "11" ? ' | Internal' :
                                                    String( room["interval_type_id"] ) === "10" ? ' | Online Class' :
                                                        String( room["interval_type_id"] ) === "9" ? ' | Internship' :
                                                            String( room["interval_type_id"] ) === "8" ? ' | Studio' :
                                                                String( room["interval_type_id"] ) === "5" ? ' | Elective Lecture Class' :
                                                                    String( room["interval_type_id"] ) === "4" ? ' | Lab' :
                                                                        String( room["interval_type_id"] ) === "3" ? ' | Lecture Class' : ' | Class'}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="flex-start" justifyContent="space-between" gap={{ sm: "0", xs: "5px" }} flexDirection={{ sm: "column", xs: "row" }}>
                                            <Typography fontSize="12px">Time</Typography>
                                            <Typography color="textSecondary" fontSize="12px">{room.intervals}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent={{ sm: "initial", xs: "flex-start" }} alignItems="center">

                                        {room.status === 'Completed' ? <Typography fontSize="14px" color='error' textAlign="right" fontWeight="bold">Session Completed</Typography>
                                            :
                                            <Box display="flex" justifyContent="flex-start" alignItems="center" gap="10px">
                                                {
                                                    // room.room_status === 'online'
                                                    ( exist > 0 ) ?
                                                        <Button variant='contained' sx={{ textTransform: "capitalize" }} size="medium" onClick={( e ) => endSession( room )} color="secondary">
                                                            End
                                                        </Button> : ''
                                                }
                                                {
                                                    // room.room_status === 'online'
                                                    ( exist > 0 && room.empID === UserStore.getUser["user_id"] ) ?
                                                        <Button variant='contained' disableElevation sx={{ textTransform: "capitalize" }} size="medium" onClick={( e ) => joinSession( room )}
                                                            endIcon={Icons.ChevronRightIcon}
                                                            color="primary">
                                                            Join
                                                        </Button> : ''
                                                }
                                                {/* : joinSessionAsViewer(room) */}
                                                {
                                                    // room.room_status === 'online'
                                                    ( room.empID !== UserStore.getUser["user_id"] ) ?
                                                        <Button variant='contained' disableElevation sx={{ textTransform: "capitalize" }} size="medium" onClick={( e ) => joinSessionAsViewer( room )}
                                                            endIcon={Icons.ChevronRightIcon}
                                                            color="primary">
                                                            Join
                                                        </Button> : ''
                                                }
                                                {
                                                    // room.room_status === 'online'
                                                    ( exist === 0 && ( room.empID === UserStore.getUser["user_id"] || room.empID === null ) ) ?
                                                        <Button variant='contained' disableElevation sx={{ textTransform: "capitalize" }} size="medium" color="primary" onClick={( e ) => startSession( room, false )}
                                                            //disabled={RoomStore.getActiveRoom.length > 0}
                                                            endIcon={Icons.ChevronRightIcon}>
                                                            start
                                                        </Button> : ''
                                                }
                                                {/* {
                                                    // room.room_status === 'online'
                                                    ( exist === 0 && room.empID === UserStore.getUser["user_id"] ) ?
                                                        <IconButton color="primary" onClick={( e ) => startSession( room, true )} aria-label="Start & Recording Session">
                                                            {Icons.PlayCircleFilledWhiteIcon}
                                                        </IconButton> : ''
                                                } */}
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            )
                        } )}
                    </Paper>}
                    {isClassesLoaded && RoomStore.getRoom.length === 0 &&
                        <Paper sx={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <img width="150px" src={noClassesImg} alt="No classes today!" />
                            <Typography variant='subtitle2' color="textSecondary" marginTop="10px">No classes for today!</Typography>
                        </Paper>
                    }
                    {!isClassesLoaded && <Paper sx={{ borderRadius: "5px" }}>
                        <Typography height="100%" padding="40px 20px" bgcolor="#fff" display="flex" gap="10px" justifyContent="center" alignItems="center" variant='body2' color="textSecondary"> <CircularProgress size={20} color='customThemeColor' />Loading classes...</Typography>
                    </Paper>}
                </Box>
                {/* <MobileSchedulesAndActivities /> */}
                <Box marginTop="20px" display="grid" gridTemplateColumns={{ sm: "1fr 1fr", xs: "1fr" }} gap="20px">
                    <Box>
                        <Typography marginY="10px" fontWeight="bold">
                            <Link style={{ color: "black" }} to={UserStore.getUser['user_role'] === "STUDENT" ? `/student/exams` : `/faculty/exams`}>Upcoming Exams</Link>
                        </Typography>
                        <Paper sx={{ textAlign: "center", minHeight: ( isExamsLoaded && ExamStore.getExams.filter( e => new Date() < new Date( formatDateTimeForInputField( e.exam_end_date, e.exam_end_time ) ) ).length === 0 ) ? "200px" : "0px", padding: ( isExamsLoaded && ExamStore.getExams.filter( e => new Date() < new Date( formatDateTimeForInputField( e.exam_end_date, e.exam_end_time ) ) ).length === 0 ) ? "20px 0" : "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {isExamsLoaded && <Box height="100%" width="100%" display="flex" justifyContent="flex-start" flexDirection="column">
                                {ExamStore.getExams.length > 0 && ExamStore.getExams.filter( e => new Date() < new Date( formatDateTimeForInputField( e.exam_end_date, e.exam_end_time ) ) ).map( ( exam, index ) => (
                                    <Paper onClick={() => navigateToExams( exam.exam_id )} key={index} sx={{ borderRadius: "0", alignItems: "center", display: "flex", gap: "10px", padding: "20px", cursor: "pointer", transition: "0.1s ease", "&:hover": { background: "#eee" }, borderBottom: "1px solid rgba(0,0,0,0.3)" }}>
                                        <Icon sx={{ height: "40px" }}>
                                            {Icons.QuizIcon}
                                        </Icon>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            <Typography textTransform="capitalize" fontSize="16px" fontWeight="bold">{exam.exam_name}</Typography>
                                            <Box display="flex" alignItems="center" gap={{ sm: "10px", xs: "0" }} flexDirection={{ sm: "row", xs: "column" }}>
                                                <Typography fontSize="12px" > <b>Start Date & time:</b>  {formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</Typography>
                                                <Typography fontSize="12px" > <b>End Date & time:</b> {formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                ) )
                                }
                                {
                                    ExamStore.getExams.filter( e => new Date() < new Date( formatDateTimeForInputField( e.exam_end_date, e.exam_end_time ) ) ).length === 0 && <Box height="100%" flexDirection="column" display="flex" gap="10px" justifyContent="center" alignItems="center" variant='body2' color="textSecondary">
                                        <img src={noExamsImg} width="150px" alt="No assignments pending" />
                                        <Typography variant='subtitle2' color="textSecondary">No Exams!</Typography>
                                    </Box>
                                }
                            </Box>}
                            {
                                !isExamsLoaded && <Typography height="100%" padding="20px" display="flex" gap="10px" justifyContent="center" alignItems="center" variant='body2' color="textSecondary"> <CircularProgress size={20} color='customThemeColor' />Loading...</Typography>
                            }
                        </Paper>
                    </Box>
                    <Box>
                        <Typography marginY="10px" fontWeight="bold">
                            <Link style={{ color: "black" }} to={UserStore.getUser['user_role'] === "STUDENT" ? `/student/assignment` : `/faculty/assignment`}>Assignments</Link>
                        </Typography>
                        <Paper sx={{ textAlign: "center", minHeight: ( AssignmentStore.getAssignment.filter( item => new Date( new Date().toISOString().split( "T" )[0] ) <= new Date( String( item.assignment_due_date ).split( "T" )[0] ) ).length === 0 && isAssignmentsloaded ) ? "200px" : "0px", padding: ( AssignmentStore.getAssignment.filter( item => new Date( new Date().toISOString().split( "T" )[0] ) <= new Date( String( item.assignment_due_date ).split( "T" )[0] ) ).length === 0 && isAssignmentsloaded ) ? "20px 0" : "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {isAssignmentsloaded && <Box height="100%" width="100%" display="flex" justifyContent="flex-start" flexDirection="column">
                                {AssignmentStore.getAssignment.length > 0 && AssignmentStore.getAssignment.filter( item => new Date( new Date().toISOString().split( "T" )[0] ) <= new Date( String( item.assignment_due_date ).split( "T" )[0] ) ).slice( 0, 3 ).map( ( item, index ) => (
                                    <Paper elevation={0} onClick={( e ) => {
                                        if ( UserStore.getUser["user_role"] === "STUDENT" )
                                            handleClickOpenAssignmentSubmission( item )
                                        else navigate( `/faculty/assignment` )
                                    }} key={index} sx={{ borderRadius: "0", alignItems: "center", display: "flex", gap: "10px", padding: "20px", cursor: "pointer", transition: "0.1s ease", "&:hover": { background: "#eee" }, borderBottom: "1px solid rgba(0,0,0,0.3)" }}>
                                        <Icon>
                                            {Icons.AssignmentIcon}
                                        </Icon>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            <Typography fontSize="16px" textTransform="capitalize" fontWeight="bold">{item.assignment_name}</Typography>
                                            <Box display="flex" alignItems="center" gap="10px">
                                                <Typography fontSize="12px" >{item.author_name}</Typography>
                                                <Typography fontSize="12px" fontWeight="bold" >Due: {( new Date( item.assignment_due_date ) ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                ) )}
                                {
                                    AssignmentStore.getAssignment.filter( item => new Date( new Date().toISOString().split( "T" )[0] ) <= new Date( String( item.assignment_due_date ).split( "T" )[0] ) ).length === 0 && isAssignmentsloaded && <Box height="100%" flexDirection="column" display="flex" gap="10px" justifyContent="center" alignItems="center" variant='body2' color="textSecondary">
                                        <img src={noAssignmentsImg} width="150px" alt="No assignments pending" />
                                        <Typography variant='subtitle2' color="textSecondary">No assignments!</Typography>
                                    </Box>
                                }
                            </Box>}
                            {
                                !isAssignmentsloaded && <Typography height="100%" display="flex" gap="10px" justifyContent="center" alignItems="center" variant='body2' padding="20px" color="textSecondary"> <CircularProgress size={20} color='customThemeColor' />Loading...</Typography>
                            }
                        </Paper>
                    </Box>
                </Box>
            </Box>
            {/* <SchedulesAndActivities /> */}
        </Box >
    )
} )

export default Dashboard
