import { createContext, useState, useContext } from "react"

const LayoutContext = createContext()
const minSidebarCollapseWidth = 900

export const LayoutContextProvider = ( { children } ) => {

    const [currentLoc, setCurrentLoc] = useState( '' )
    const [sidedrawerState, setSidedrawerState] = useState( false )
    const [isSmallScreen, setIsSmallScreen] = useState( false )
    const [menuState, setMenuState] = useState( window.innerWidth < minSidebarCollapseWidth ? false : true )
    const [active, setActive] = useState( window.location.pathname )
    const [sidebarWidth, setSidebarWidth] = useState( 300 )
    const [navHeight, setNavHeight] = useState( 60 )
    const [focusedMenuItem, setFocusedMenuItem] = useState( null )
    const [secondarySidebarStatus, setSecondarySidebarStatus] = useState( false )
    const [sidebarOpenState, setSidebarOpenState] = useState( false )
    const [navHeadText, setNavHeadText] = useState( "" )

    return <LayoutContext.Provider value={{
        currentLoc, setCurrentLoc, sidedrawerState, setSidedrawerState, isSmallScreen, setIsSmallScreen, navHeadText, setNavHeadText, sidebarOpenState, setSidebarOpenState, secondarySidebarStatus, setSecondarySidebarStatus, menuState, setMenuState, minSidebarCollapseWidth: minSidebarCollapseWidth, active, setActive, sidebarWidth, setSidebarWidth, navHeight, setNavHeight, focusedMenuItem, setFocusedMenuItem
    }}>
        {children}
    </LayoutContext.Provider>
}

export const useLayoutContext = () => useContext( LayoutContext )