import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAssignmentStore } from '../../store/assignment.store'
import { Box, Button, CircularProgress, IconButton, Paper, Typography } from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import { DataGrid } from '@mui/x-data-grid'
import AssignmentSubmissionDownloadDialog from '../../components/presenter/AssignmentSubmissionDownloadDialog'
import noSubmissionImg from '../../assets/nosubmissions.svg'
import { Icons, exportAssignmentReport } from '../../utils/utilities'
import { observer } from 'mobx-react'

const EvaluateAssignment = observer( () => {
    let { assignment_id } = useParams()

    const AssignmentStore = useAssignmentStore()

    const [url, setUrl] = React.useState( [] )
    const [loaded, setLoaded] = React.useState( false )
    const [open, setOpen] = React.useState( false )
    const [dialogType, setDialogType] = React.useState( 'download' ) // download || setmarks

    const columnsTable = [
        { field: 'student_name', headerName: 'Student', width: 260 },
        { field: 'assignment_submitted_by', headerName: 'AUID', width: 160 },
        { field: 'assignment_submitted_on', headerName: 'Submitted on', width: 160 },
        {
            field: 'assignment_submission_file_paths',
            headerName: 'Attachments',
            width: 160,
            disableClickEventBubbling: true,
            renderCell: ( params ) => {
                const onClick = () => {
                    // console.log(params.value)
                    setDialogType( 'download' )
                    setUrl( params.value )
                    setOpen( true )
                }
                return <Button size={'small'} variant="contained" color="primary" disableElevation onClick={onClick}>Download</Button>
            }
        },
        {
            field: 'assignment_marks',
            headerName: 'Score',
            width: 90
        },
        {
            field: '',
            headerName: 'Assign Marks',
            width: 160,
            disableClickEventBubbling: true,

            renderCell: ( params ) => {
                const onClick = () => {
                    //console.log(params)
                    setDialogType( 'setmarks' )
                    setUrl( [params.row.assignment_submission_id] )
                    setOpen( true )
                }
                return <Button size={'small'} variant="contained" color="primary" disableElevation onClick={onClick}>Assign Marks</Button>
            }
        },
        {
            field: 'button',
            headerName: 'Action',
            width: 100,
            disableClickEventBubbling: true,

            renderCell: ( params ) => {
                const onClick = () => {
                    //alert(JSON.stringify(params.row))
                    var r = window.confirm( `Do you want to delete ${params.row.student_name}'S Assignment ?` )
                    if ( r === true ) {
                        //let payload = { "assignment_id":id }
                        AssignmentStore.deleteAssignmentSubmission( params.row )
                    }
                }
                return <IconButton size={'small'} aria-label="delete" color="secondary" onClick={onClick}>
                    <Delete fontSize="small" />
                </IconButton>
            }
        },
    ]

    const handleClose = () => {
        setOpen( false )
    }

    useEffect( () => {
        const getData = async () => {
            await AssignmentStore.fetchAssignmentSubmission( assignment_id )
            setLoaded( true )
        }
        getData()
    }, [AssignmentStore, assignment_id] )
    return (
        <Box padding="0 20px">
            {/* <Button startIcon={Icons.ChevronLeftIcon} sx={{ marginBottom: "10px", textTransform: "capitalize" }} onClick={() => navigate( '/faculty/assignment' )}>Back to assignments</Button> */}
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link className="breadcrumb-link" to='/faculty/assignment'>Assignments</Link>{Icons.SmallChevronRightIcon} Submissions</Typography>
            <AssignmentSubmissionDownloadDialog dialogType={dialogType} open={open} selectedValue={url} onClose={handleClose} />
            <Paper sx={{ maxHeight: 600 }}>
                <Box padding="20px" borderBottom="1px solid #d3d3d3" display="flex" alignItems={{ sm: "center", xs: "flex-start" }} justifyContent="space-between" flexDirection={{ sm: "row", xs: "column" }}>
                    <Box>
                        <Typography variant='h5'>Submissions</Typography>
                        <Typography variant="subtitle2" color="textSecondary">Manage assignment submissions.</Typography>
                    </Box>
                    {loaded && AssignmentStore.getSubmission.length > 0 && <Button onClick={() => exportAssignmentReport( AssignmentStore.getSubmission )} sx={{ width: { sm: "fit-content", xs: "100%" } }} variant='contained' disableElevation startIcon={Icons.DownloadForOfflineIcon}>Export as Excel</Button>}
                </Box>
                {loaded && AssignmentStore.getSubmission.length > 0 && <Box padding="20px">
                    <DataGrid sx={{ height: "450px" }} rows={AssignmentStore.getSubmission} columns={columnsTable} pageSize={20} />
                </Box>}
                {!loaded && <Typography display="flex" alignItems="center" gap="10px" padding="20px" color="textSecondary" variant='h6' > <CircularProgress size={20} /> Loading submissions... </Typography>}
                {loaded && AssignmentStore.getSubmission.length === 0 && <Box display="flex" flexDirection="column" alignItems="center" padding="20px" gap="10px" color="textSecondary" variant='h6' justifyContent="center" bgcolor="#eee" textAlign="center" >
                    <img src={noSubmissionImg} width="200px" alt="No submissions!" />
                    <Typography variant='subtitle2' marginTop="10px" color="textSecondary" >No submissions!</Typography>
                </Box>}
            </Paper>
        </Box>
    )
} )

export default EvaluateAssignment
