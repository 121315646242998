import Routes from './components/Routes'
import { ThemeProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthStore, { AuthStoreProvider } from "./store/auth.store"
import UserStore, { UserStoreProvider } from "./store/user.store"
import OrganizationStore, { OrganizationStoreProvider } from "./store/organization.store"
import RoomStore, { RoomStoreProvider } from "./store/room.store"
import StudyMaterialStore, { StudyMaterialStoreProvider } from "./store/studymaterial.store"
import AssignmentStore, { AssignmentStoreProvider } from "./store/assignment.store"
import NotificationStore, { NotificationStoreProvider } from "./store/notification.store"
import ExamStore, { ExamStoreProvider } from './store/exam.store'
import ChatStore, { ChatStoreProvider } from './store/chat.store'
import OnlineCourseStore, { OnlineCourseProvider } from './store/onlinecourse.store'
import ResumeBuilderStore, { ResumeBuilderStoreProvider } from './store/resumebuilder.store'
import { LayoutContextProvider } from './contexts/layout.context'
import DownloadStatusListStore, { DownloadStatusListProvider } from './store/downloadstatuslistdialog.store'
import ThemeContextProvider, { useThemeContext } from './contexts/theme.context'

import themes from './themes/themes'


// 10 mins poll //60000 //600000


function MainComponent() {

  const { theme } = useThemeContext()

  return (
    <ThemeProvider theme={themes[theme]}>
      <LayoutContextProvider>
        <AuthStoreProvider store={AuthStore}>
          <UserStoreProvider store={UserStore}>
            <OrganizationStoreProvider store={OrganizationStore}>
              <RoomStoreProvider store={RoomStore}>
                <StudyMaterialStoreProvider store={StudyMaterialStore}>
                  <OnlineCourseProvider store={OnlineCourseStore}>
                    <AssignmentStoreProvider store={AssignmentStore}>
                      <NotificationStoreProvider store={NotificationStore}>
                        <ExamStoreProvider store={ExamStore}>
                          <ResumeBuilderStoreProvider store={ResumeBuilderStore}>
                            <ChatStoreProvider store={ChatStore}>
                              <DownloadStatusListProvider store={DownloadStatusListStore}>
                                < Routes />
                                <ToastContainer position='bottom-left' autoClose={3000} hideProgressBar closeOnClick theme='dark' style={{ fontSize: "12px" }} />
                              </DownloadStatusListProvider>
                            </ChatStoreProvider>
                          </ResumeBuilderStoreProvider>
                        </ExamStoreProvider>
                      </NotificationStoreProvider>
                    </AssignmentStoreProvider>
                  </OnlineCourseProvider>
                </StudyMaterialStoreProvider>
              </RoomStoreProvider>
            </OrganizationStoreProvider>
          </UserStoreProvider>
        </AuthStoreProvider>
      </LayoutContextProvider>
    </ThemeProvider>
  )
}



const App = () => {
  return <ThemeContextProvider>
    <MainComponent />
  </ThemeContextProvider>
}

export default App

