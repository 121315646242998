import { AppBar, Avatar, Box, Button, MenuItem, Popover, Select, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { useAuthStore } from '../../store/auth.store'
import { useThemeContext } from '../../contexts/theme.context'
import { useLayoutContext } from '../../contexts/layout.context'
import aliveLogo from '../../assets/alivelogo.png'
import { toast } from 'react-toastify'
import { Logout } from '@mui/icons-material'
import AdmissionExamStoreInstance, { AdmissionExamStoreProvider } from '../../store/admission-exam.store'

const themeOptions = [
    'light', 'dark', 'system'
]

const Navbar = () => {

    const [anchorEl, setAnchorEl] = useState( null )
    const [settingsAnchorEl, setSettingsAnchorEl] = useState( null )

    const UserStore = useUserStore()
    const AuthStore = useAuthStore()

    const open = Boolean( anchorEl )

    const navigate = useNavigate()
    const { navHeight } = useLayoutContext()

    const { palette } = useTheme()
    const { setSelectedTheme, selectedTheme } = useThemeContext()

    const handleThemeChange = e => {
        setSelectedTheme( e.target.value )
        localStorage.setItem( 'preferedTheme', e.target.value )
        setSettingsAnchorEl( null )
    }

    const logout = async () => {
        await AuthStore.doLogout()
        localStorage.clear()
        toast( "Logged out successfully!", { containerId: "main" } )
        navigate( '/' )
    }

    return (
        <AppBar
            id="appbar"
            elevation={0}
            sx={{
                borderBottom: palette.grey[600] + " 1px solid",
                borderRadius: "0",
                background: palette.layoutBackground,
                transition: "0.2s ease-out",
                position: "absolute",
                color: "black",
                height: "fit-content",
                zIndex: "1220"
            }}
        >
            {/* <Typography fontSize="12px" sx={{ position: "absolute", top: 0, left: 0, right: 0, background: palette.warning.main, color: palette.warning.contrastText }}>Under maintenance</Typography> */}
            <Box alignItems="center" justifyContent="space-between" height={`${navHeight}px`} paddingRight={"20px"} display="flex" maxWidth="100%" id="app-bar" sx={{ transition: "0.2s ease-out", paddingLeft: "20px" }}>
                <Box display="flex" gap="10px" alignItems="center">
                    <img width="30px" src={aliveLogo} alt="Logo" />
                    <Typography variant="h6" fontSize="18px">Alive Admission Exams</Typography>
                </Box>
                {<Box display="flex" alignItems="center" gap="10px">
                    <Box display={{ sm: "block", xs: "none" }}>
                        <Typography textAlign="right" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["employee_name"] || ""}</Typography>
                    </Box>

                    <Popover transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'top' }} elevation={1} open={Boolean( settingsAnchorEl )} onClose={() => setSettingsAnchorEl( null )} slotProps={{ paper: { sx: { maxWidth: "300px" } } }} anchorEl={settingsAnchorEl}>
                        <Box padding="10px" display="flex" gap="20px" alignItems="center">
                            <Typography width="max-content" variant="variant2">Theme</Typography>
                            <Select sx={{ height: "25px", textTransform: "capitalize" }} size="small" value={selectedTheme} onChange={handleThemeChange} >
                                {themeOptions.map( theme => (
                                    <MenuItem key={theme} value={theme} dense sx={{ fontSize: "14px", textTransform: "capitalize" }}>{theme}</MenuItem>
                                ) )}
                            </Select>
                        </Box>
                    </Popover>

                    <Popover slotProps={{ paper: { sx: { border: `1px solid ${palette.borderColor}` } } }} sx={{ maxWidth: "300px" }} onClose={e => { setAnchorEl( null ) }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'top' }} anchorEl={anchorEl} open={open}>
                        <Box display="flex" alignItems="center" flexDirection="column" gap="20px" padding="20px">

                            <Avatar sx={{ textTransform: "uppercase", cursor: "pointer", border: `4px solid ${palette.borderColor}` }} alt={UserStore.getUser["user_name"] ? UserStore.getUser["user_name"] : ""} >
                                {( UserStore.getUser["user_role"] ? UserStore.getUser["user_role"] : "" ).substring( 0, 1 )}
                            </Avatar>
                            <Box>
                                <Typography marginBottom="-5px" textAlign="center" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["user_role"]?.toUpperCase()}</Typography>
                            </Box>
                            <Button color="error" onClick={logout} startIcon={<Logout sx={{ color: "error.main" }} fontSize="small" />}>
                                Logout
                            </Button>
                        </Box>
                    </Popover>
                </Box>}
            </Box>
        </AppBar>
    )
}

const AdmissionExamLayout = () => {

    const { palette } = useTheme()

    const { navHeight, navHeadText, setMenuState } = useLayoutContext()

    return (
        <AdmissionExamStoreProvider store={AdmissionExamStoreInstance}>

            <Box bgcolor={palette.layoutBackground} display="flex" height="100%" overflow="auto">
                <Box
                    flexGrow={1}
                    display="flex"
                    minWidth="0"
                    position="relative"
                >
                    <Navbar navHeadText={navHeadText} closeSideMenu={() => setMenuState( false )} openSideMenu={() => setMenuState( true )} />
                    <Box
                        flexGrow={1}
                        display="flex"
                        maxHeight="100%"
                        id="page-content"
                        paddingTop={`${navHeight}px`}
                        minWidth="0"
                    >
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </AdmissionExamStoreProvider>
    )
}

export default AdmissionExamLayout
