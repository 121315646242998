import React from 'react'
import dayjs from 'dayjs'
import { Box, Typography, Dialog, Button, Paper, CircularProgress, Divider, Slider, FormControl, DialogContent, TextField } from '@mui/material'
import api from '../../service/api'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DataGrid } from '@mui/x-data-grid'
import noAttendenceImg from '../../assets/noattendence.svg'
import { useSearchParams } from 'react-router-dom'
import { Search } from '@mui/icons-material'
import { toast } from 'react-toastify'

const columns = [
    { field: 'selected_date', headerName: 'Date', width: 110 },
    { field: 'subject_name_short', headerName: 'Subject', width: 160 },
    { field: 'duration', headerName: 'Duration', width: 190 }, //subject_name_short
    { field: 'attendance', headerName: 'Attendance', width: 190 }, //selected_date
]


const Attendence = () => {
    const [loading, setLoading] = React.useState( false )
    const [allAttendance, setAllAttendance] = React.useState( [] )
    const [fromDate, setFromDate] = React.useState( new Date().toISOString() )
    const [toDate, setToDate] = React.useState( new Date().toISOString() )
    const [sliderPercentage, setSliderPercentage] = React.useState( 50 )
    const [selectedRow, setSelectedRow] = React.useState( null )
    const [searchParams, setSearchParams] = useSearchParams( { searchText: "" } )
    const searchText = searchParams.get( 'searchText' )

    const submit = async () => {
        let payload = {
            "date_from": fromDate.toString().split( "T" )[0],
            "date_to": toDate.toString().split( "T" )[0],
            "percentage": sliderPercentage
        }
        setLoading( true )
        try {
            const res = await api.searchAttendence( payload )
            console.log( res.data )
            setAllAttendance( res["data"]["data"] ? res["data"]["data"] : [] )
        } catch ( error ) {
            toast.error( error )
        } finally {
            setLoading( false )
        }

    }

    const showAuid = ( e ) => {
        setSelectedRow( e.row )
    }

    const handleClose = () => {
        setSelectedRow( null )
        setSearchParams( prev => {
            prev.set( 'searchText', '' )
            return prev
        }, { replace: true } )
    }

    return (
        <Box padding="0 20px">
            <Box display="flex" flexDirection="column" gap="20px">
                <Box>
                    <Paper sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Box padding="20px" display="flex" flexDirection="column" gap="10px" borderBottom="1px solid #d3d3d3">
                                <Box>
                                    <Typography variant='h5'>Attendance Audit</Typography>
                                    <Typography variant='subtitle2' color="textSecondary">View all the online classes attendance details</Typography>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: { md: "row", xs: "column" }, gap: "20px", paddingTop: "10px" }} noValidate autoComplete="off">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="DD-MM-YYYY"
                                            id="fromdate-picker-inline"
                                            label="From Date"
                                            value={dayjs( fromDate )}
                                            // onAccept={( e ) => setFromDate( e.toISOString() )}
                                            onChange={( e ) => setFromDate( e.toISOString() )}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disableToolbar
                                            maxDate={dayjs( new Date() )}
                                            variant="inline"
                                            format="DD-MM-YYYY"
                                            id="todate-picker-inline"
                                            label="To Date"
                                            value={dayjs( toDate )}
                                            // onAccept={( e ) => setFromDate( e.toISOString() )}
                                            onChange={( e ) => setToDate( e.toISOString() )}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}


                                        />
                                    </LocalizationProvider>
                                    <FormControl>
                                        <Typography variant="subtitle2" fontSize="12px">Select attendance threshold</Typography>
                                        <Slider defaultValue={50}
                                            aria-label="Attendance"
                                            valueLabelDisplay="auto" onChange={( e, val ) =>
                                                setSliderPercentage( val )
                                            } />
                                    </FormControl>
                                    <Button disableElevation variant='contained' sx={{ height: "54px" }} color="primary" onClick={submit}>
                                        Search
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        {allAttendance.length > 0 && !loading && <Box flexGrow={1} bgcolor="white">
                            <Dialog PaperProps={{ sx: { minWidth: "85vw" } }} fullWidth={true} onClose={handleClose} aria-labelledby="simple-dialog-title" open={selectedRow ? true : false}>
                                <Box padding="10px 20px" display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h6"> Attendance List </Typography>
                                    <TextField value={searchText} onChange={
                                        ( e ) => setSearchParams( prev => {
                                            prev.set( 'searchText', e.target.value )
                                            return prev
                                        }, { replace: true } )
                                    }
                                        InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", "@media(max-width:900px)": { width: "100%" } }, startAdornment: <Search sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.4)", background: "#fff", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "&:hover": { border: "2px solid #000" } }} placeholder="Search by AUID..." variant="standard" type="text" />
                                </Box>
                                <Divider />
                                <DialogContent sx={{ paddingTop: "5px", paddingBottom: "20px" }}>
                                    {selectedRow && <Box bgcolor="#eee" padding="10px">
                                        <Typography variant="subtitle2" gutterBottom> <i>Total number of times attendance taken for this class: {selectedRow?.snapshot_timestamps?.length}</i> </Typography>
                                        {selectedRow.snapshot_timestamps && <Typography display="flex" gap="10px" flexWrap="wrap" fontSize="10px" >{selectedRow.snapshot_timestamps.map( ( time, index ) =>
                                            <span key={`time-${index}`}> <span style={{ paddingRight: "10px" }}>|</span> {`${new Date( time ).toLocaleTimeString( 'en-IN' )}`}</span>
                                        )}</Typography>}
                                    </Box>}
                                    {selectedRow && selectedRow.student_attendance.filter( list => list?.toLowerCase().includes( searchText ) ).map( ( list ) => <Box key={list} display="flex" gap="10px" alignItems="center" flexWrap="wrap" padding="10px" borderBottom="1px solid #d3d3d3">
                                        <Typography fontSize="14px" width="fit-content" variant='subtitle2'>{list}</Typography>
                                        {selectedRow && selectedRow.present_at[list] && <Typography display="flex" gap="10px" flexWrap="wrap" fontSize="10px" >{selectedRow.present_at[list].map( ( time, index ) =>
                                            <span key={`time-${index}`}> <span style={{ paddingRight: "10px" }}>|</span> {`${new Date( selectedRow.snapshot_timestamps[time] ).toLocaleTimeString( 'en-IN' )}`}</span>
                                        )}</Typography>}
                                    </Box> )}
                                </DialogContent>
                            </Dialog>
                            <DataGrid autoHeight={true} rows={allAttendance} onRowClick={showAuid} columns={columns} pageSize={( allAttendance.length > 10 ) ? 10 : 0} />
                        </Box>}
                        {allAttendance.length === 0 && !loading &&
                            <Paper sx={{ flexGrow: 1, padding: "20px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                                <img src={noAttendenceImg} width="200px" alt="No attendence details for selected date range!" />
                                <Typography color="textSecondary" variant='subtitle2' marginY="20px">No attendence details for selected date range!</Typography>
                            </Paper>
                        }
                        {loading &&
                            <Paper sx={{ flexGrow: 1, gap: "10px", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <CircularProgress size={20} />
                                <Typography variant='h6' color="textSecondary" marginY="20px">Loading attendence...</Typography>
                            </Paper>
                        }
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}

export default Attendence
