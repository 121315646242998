import { Typography, Box, CircularProgress, Paper, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import EditQuestionList from '../../components/exams-components/EditQuestionList'
import QuestionDetail from '../../components/exams-components/QuestionDetail'
import { formatDateTimeForInputField } from '../../utils/exam-utilities'
import api from '../../service/api'
import { useExamStore } from '../../store/exam.store'
import { Icons } from '../../utils/utilities'

const EditQuestions = () => {
    const [questions, setQuestions] = useState( null )
    const [exam, setExam] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [selectedQuestion, setSelectedQuestion] = useState( null )
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState( 1 )

    const ExamStore = useExamStore()

    const { id } = useParams()
    const navigate = useNavigate()

    const selectQuestion = ( id ) => {
        if ( questions ) {
            const newSelectedQuestion = questions.find( question => question.question.question_id === id )
            setSelectedQuestion( { ...newSelectedQuestion } )
            const index = questions.indexOf( newSelectedQuestion ) + 1
            setCurrentQuestionNumber( index )
        }
    }

    const update = async ( data, qid ) => {
        try {
            const res = await api.updateQuestion( id, qid, data )
            toast.success( res.data.message )
            const questionsData = await api.fetchQuestions( id )
            setQuestions( questionsData.data )
            return { updatedOptions: res.data.updatedOptions, success: true }
        } catch ( e ) {
            console.log( e )
            if ( e.message === "Network Error" ) {
                navigate( '/networkerror' )
            } else toast.error( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! try again later" )
            return { success: false }
        }
    }

    const deleteQuestion = async ( qid ) => {
        try {
            const res = await api.deleteQuestion( id, qid )
            const newQuestions = questions.filter( question => question.question.question_id !== qid )
            setQuestions( newQuestions )
            if ( newQuestions.length > 0 ) {
                if ( newQuestions.length > currentQuestionNumber ) {
                    setSelectedQuestion( newQuestions[currentQuestionNumber - 1] )
                } else {
                    setSelectedQuestion( newQuestions[newQuestions.length - 1] )
                    setCurrentQuestionNumber( newQuestions.length )
                }
            } else {
                setSelectedQuestion( null )
                setCurrentQuestionNumber( 0 )
            }
            toast.success( res.data )
        } catch ( e ) {
            if ( e.message === "Network Error" ) {
                navigate( '/networkerror' )
            } else toast.error( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! try again later" )
        }
    }

    useEffect( () => {
        selectQuestion( selectedQuestion?.question?.question_id )
        // eslint-disable-next-line
    }, [questions] )

    useEffect( () => {
        const getData = async () => {
            try {
                await ExamStore.fetchExam( id )
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' ) {
                    toast.error( "Exam already started or published!" )
                    navigate( -1, { replace: true } )
                }
                const questionsData = await api.fetchQuestions( id )
                setQuestions( questionsData.data )
                setExam( ExamStore.getExam.exam )
                setSelectedQuestion( questionsData.data.length > 0 ? questionsData.data[0] : null )
            } catch ( e ) {

            } finally {
                setInProgress( false )
            }
        }
        getData()
    }, [id, ExamStore, navigate] )

    return (
        // <Layout>
        <Box padding="0 20px 20px 20px">
            {exam && <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to={`/faculty/exams`} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} <Link to={`/faculty/exams/${id}`} className="breadcrumb-link">{exam ? exam.exam_name : <CircularProgress size={12} />}</Link> {Icons.SmallChevronRightIcon} Edit questions </Typography>}

            <Paper sx={{ display: "flex", overflow: "hidden", flexDirection: "column" }}>
                <Box borderBottom="1px solid #d3d3d3" padding="20px" display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
                    <Box>
                        <Typography variant='h5'>Edit Questions</Typography>
                        <Typography variant='body2' color="textSecondary">{exam?.exam_name}</Typography>
                    </Box>
                </Box>
                {( !questions || questions.length === 0 ) && <Box padding="20px" borderBottom="1px solid #d3d3d3">
                    <Typography variant='h6' margin="0 20px 0 0" padding=" 0" borderRadius="5px" >No questions for the exam! <Button sx={{ fontSize: "12px", textTransform: "capitalize", textDecoration: "underline !important" }} onClick={() => navigate( `/faculty/exams/${id}` )}>Add or import questions</Button> </Typography>
                </Box>}
                {exam && selectedQuestion?.question && <Box flexGrow={1} display="flex" alignItems="flex-start" height="calc(100vh - 200px)">
                    <EditQuestionList questions={questions} selectedQuestion={selectedQuestion} selectQuestion={selectQuestion} />
                    <QuestionDetail selectedQuestion={selectedQuestion} exam={exam} questionNumber={currentQuestionNumber} update={update} deleteQuestion={deleteQuestion} />
                </Box>}
                {inProgress && <Typography margin="20px" color="textSecondary"> <CircularProgress size={20} /> Loading...</Typography>}
            </Paper>
        </Box>
        // </Layout>
    )
}

export default EditQuestions
