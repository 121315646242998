import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import UserStore from "./user.store"
import React from 'react'
import { toast } from 'react-toastify'
// import UserStore from "./user.store";

class AdmissionExamStore {
    exams
    selectedExam

    constructor () {
        this.exams = []
        this.selectedExam = {}
        this.api = api
        makeObservable( this, {
            exams: observable,
            selectedExam: observable,
            getExam: computed,
            getExams: computed,
            createExam: action,
            updateExam: action,
            setExams: action,
            setSelectedExam: action,
            fetchExam: action,
            fetchExams: action,
            fetchExamsBySubject: action,
            fetchPendingExams: action
        } )
    }

    get getExam() {
        return toJS( this.selectedExam )
    }

    get getExams() {
        return toJS( this.exams )
    }

    setExams( exams ) {
        runInAction( () => {
            this.exams = exams
        } )
    }

    setSelectedExam( exam ) {
        this.selectedExam = exam
    }

    async createExam( payload ) {
        try {
            await this.api.admissionExams.createExam( payload )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong!", { containerId: "main" } )
        }
    }

    async fetchExams( params ) {
        try {
            const res = await this.api.admissionExams.getAllExams( params )
            if ( res.data.success === true || res.data.data ) {
                this.setExams( res.data.data )
                return { success: true }
            } else
                return { success: false }
        } catch ( err ) {
            console.log( err, err.status, err?.response?.status )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
                return { success: false, error: { code: err?.response?.status, message: "Session ended please signin" } }
            } else
                return { success: false, error: { code: err?.response?.status, message: err?.response?.data?.message || err?.response?.data || "Something went wrong while fetching the exams" } }
        }
    }

    async fetchExamsByDept( deptCode ) {
        try {
            const res = await api.admissionExams.fetchExamsByDept( deptCode )
            if ( res.data.success === true || res.data.exams ) {
                this.setExams( res.data.exams )
                return true
            } else
                return false
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                toast( err?.response?.data?.message || "Something went wrong!", { containerId: "main" } )
        }
    }

    async fetchExamsByInstitute( institute ) {
        try {
            const { data } = await api.admissionExams.fetchExamsByInstitute( institute )
            if ( data.success === true || data.exams ) {
                this.setExams( data.exams )
                return true
            } else {
                console.log( data )
                return false
            }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                toast( err?.response?.data?.message || "Something went wrong!", { containerId: "main" } )
        }
    }

    async fetchExamsBySubject( subject ) {
        try {
            const { data } = await api.admissionExams.fetchExamsBySubject( subject )
            if ( data.success === true || data.data ) {
                this.setExams( data.data )
                return true
            } else {
                console.log( data )
                return false
            }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                toast( err?.response?.data?.message || "Something went wrong!", { containerId: "main" } )
        }
    }


    async fetchPendingExams( subjectId ) {
        try {
            const { data } = await api.admissionExams.fetchPendingExams( { subjectId } )
            if ( data.success === true || data.data ) {
                this.setExams( data.data )
                return true
            } else {
                console.log( data )
                return false
            }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else
                toast( err?.response?.data?.message || "Something went wrong!", { containerId: "main" } )
        }
    }


    async updateExam( id, payload ) {
        try {
            const res = await this.api.admissionExams.updateExam( id, payload )
            if ( res.status === 200 ) {
                toast( "Exam updated", { containerId: "main" } )
            }
            await this.fetchExam( id )

        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong!", { containerId: "main" } )
        }
    }

    async fetchExam( id ) {
        try {
            const res = await this.api.admissionExams.getExam( id )
            this.setSelectedExam( res.data )
            return { success: true, code: 200 }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
                return { success: false, error: { code: err?.response?.status, message: "Session ended please signin" } }
            } else
                return { success: false, error: { code: err?.response?.status || 500, message: err?.response?.data?.message || err?.response?.data || "Something went wrong while fetching the exam" } }
        }
    }
}


const AdmissionExamStoreInstance = new AdmissionExamStore()

export default AdmissionExamStoreInstance

const AdmissionExamStoreContext = React.createContext()

export const AdmissionExamStoreProvider = ( { children, store } ) => {
    return (
        <AdmissionExamStoreContext.Provider value={store}>{children}</AdmissionExamStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useAdmissionExamStore = () => React.useContext( AdmissionExamStoreContext )