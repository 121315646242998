import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAuthStore } from '../store/auth.store'
import { useUserStore } from '../store/user.store'
import aliveLogo from '../assets/alivelogo.png'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { erpDashboardLink } from '../utils/utilities'

const AliveLogin = () => {

    const [loggingIn, setLoggingIn] = useState( false )
    const [failed, setFailed] = useState( false )
    const navigate = useNavigate()

    const AuthStore = useAuthStore()
    const UserStore = useUserStore()

    const { access_token: token } = useParams()
    const [searchParams] = useSearchParams( { module: 'home' } )
    const module = searchParams.get( 'module' )

    const navigateToModule = ( module, role ) => {
        navigate( `/${role}/${module}`, { replace: true } )
    }

    useEffect( () => {
        const loginUser = async () => {
            if ( token ) {
                setLoggingIn( true )
                if ( await AuthStore.doAuthViaERPAuthToken( token ) ) {
                    await UserStore.fetchUser()
                    if ( UserStore.getUser['user_role'] === "STUDENT" )
                        if ( ['exams', 'assignment', 'material'].includes( module ) )
                            navigateToModule( module, 'student' )
                        else
                            navigate( '/student/home', { replace: true } )
                    else
                        if ( ['exams', 'assignment', 'material'].includes( module ) )
                            navigateToModule( module, 'faculty' )
                        else
                            navigate( '/faculty/home', { replace: true } )
                    setFailed( false )
                    setLoggingIn( false )
                } else {
                    setLoggingIn( false )
                    setFailed( true )
                }
            } else {
                navigate( `/`, { replace: true } )
            }
        }
        loginUser()
        // eslint-disable-next-line
    }, [UserStore, AuthStore, token, navigate] )

    return (
        <Box display="flex" height="100%" alignItems="center" width="100%" justifyContent="center" flexWrap="wrap" sx={{ overflowX: "hidden", backgroundImage: "url('/imgs/bg-pattern1.png')", backgroundRepeat: "repeat" }}>
            {loggingIn && <Paper sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                <img width="50px" src={aliveLogo} alt="Alive Logo" />
                <Typography display="flex" alignItems="center" color="GrayText" gap="10px" variant="subtitle2">
                    <CircularProgress size={20} />
                    Authenticating
                </Typography>
            </Paper>}
            {failed && <Paper sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                <img width="50px" src={aliveLogo} alt="Alive Logo" />
                <Typography display="flex" flexDirection="column" alignItems="center" color="GrayText" gap="10px" variant="subtitle2">
                    Failed to login in Alive Digital classrooms!
                    {/* <small>Please try to refresh again</small> */}
                    <Button sx={{ textTransform: "capitalize" }} color="error" variant="outlined" onClick={() => window.location.replace( erpDashboardLink )} >Back to ERP</Button>
                </Typography>
            </Paper>}
        </Box >

    )
}

export default AliveLogin
