import React, { useEffect, useState } from 'react'
import { Typography, Box, CircularProgress, Button, Tooltip, Paper, Divider } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { downloadParticipationReport, flatColors } from '../../utils/exam-utilities'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import parse from 'html-react-parser'
import CheckCircle from '@mui/icons-material/CheckCircle'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import api from '../../service/api'
import { EditableMathField } from 'react-mathquill'
import { Icons } from '../../utils/utilities'
import { useLayoutContext } from '../../contexts/layout.context'

export default function ParticipationDetails() {
    const { participation_id, exam_id } = useParams()
    const [answers, setAnswers] = useState( null )
    const [questions, setQuestions] = useState( null )
    const [participation, setParticipation] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const { setCurrentLoc } = useLayoutContext()


    const getAnswerText = ( id, options ) => {
        for ( let i = 0; i < options.length; i++ ) {
            if ( id === options[i].answer_id )
                return options[i].answer_text.includes( "LATEX-" ) ? <EditableMathField id="static-latex" style={{ color: "inherit !important", padding: "0" }} latex={options[i].answer_text.split( "LATEX-" )[1]} /> : parse( options[i].answer_text )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const participationData = await api.fetchUserParticipation( participation_id )
                const submissionData = await api.fetchParticipationSubmissions( exam_id, participation_id )
                const questionsData = await api.fetchQuestions( exam_id )
                let subs = {}
                submissionData.data.forEach( sub => {
                    subs[sub.question_id] ? subs[sub.question_id].push( sub ) : subs[sub.question_id] = [sub]
                } )
                setAnswers( subs )
                setQuestions( questionsData.data )
                setParticipation( participationData.data )
                console.log( participationData.data )
                setInProgress( false )
            } catch ( e ) {
                toast.error( e )
            }
        }

        getData()
    }, [participation_id, exam_id] )

    return (
        <Box sx={{ padding: "20px", paddingTop: 0 }}>
            <Typography fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to="/student/exams" onClick={() => setCurrentLoc( '/student/exams' )} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} <Link to="/student/scores" onClick={() => setCurrentLoc( '/student/scores' )} className="breadcrumb-link">Scores</Link> {Icons.SmallChevronRightIcon} {participation?.participation?.exam_title} </Typography>
            <Box>
                {participation && !inProgress &&
                    <Box marginTop="20px" display="flex" flexDirection={{ lg: "row-reverse", md: "row-reverse", sm: "column", xs: "column" }} gap="20px">
                        <Box height="fit-content" display="flex" justifyContent="center" padding="20px" borderRadius="5px" bgcolor={flatColors[2]} boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)">
                            {participation && !inProgress && <Box maxWidth="350px" minWidth="300px" sx={{ color: "white" }} display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" flexDirection="column" textAlign="center" width="100%">
                                    <Typography width="100%" variant='h4' textTransform="capitalize">{participation.participation.exam_title}</Typography>
                                    <Typography width="100%" variant='subtitle1' fontWeight="bold" sx={{ color: "white" }} textTransform="capitalize"> {`${new Date( participation.participation.exam_participation_start_time ).toLocaleDateString( 'en-IN', { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}, ${new Date( participation.participation.exam_participation_start_time ).toLocaleTimeString()}`}</Typography>
                                    <Typography width="100%" variant='body2' sx={{ color: "white" }} textTransform="capitalize"> Attempt: {participation.participation.exam_participation_attempt}</Typography>
                                    <Typography width="100%" fontWeight="bold" variant='h4'>{participation.participation.exam_participation_score ? participation.participation.exam_participation_score : 0} <span style={{ fontSize: "14px", margin: "0 5px" }}>OUT OF</span> {participation.marks.sum}</Typography>
                                    <Button onClick={() => downloadParticipationReport( participation.participation, questions, answers )} sx={{ color: "white", marginTop: "10px", border: "1px solid white", textTransform: "capitalize", "&:hover": { color: flatColors[2], background: "white" } }} startIcon={<DownloadForOfflineIcon />} variant='outlined' >
                                        Export Report
                                    </Button>
                                </Box>
                            </Box>}
                        </Box>
                        <Paper sx={{ flexGrow: "1", bgcolor: "white", borderRadius: "5px", padding: "20px" }} >
                            <Box marginBottom="20px">
                                <Typography width="100%" variant='h5' textTransform="capitalize">  {participation && participation.participation.exam_title}</Typography>
                                <Typography width="100%" variant='subtitle1' color="textSecondary" textTransform="capitalize"> participation Details</Typography>
                                <Divider sx={{ marginY: "10px" }} />
                            </Box>
                            {questions && answers && questions.map( ( data, index ) => (
                                <Box key={index} borderBottom="2px #eee solid" paddingBottom="10px" marginTop="10px">
                                    <Box sx={{ fontWeight: "600", "& p": { margin: 0, display: "inline-block" } }} display="flex" flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }} justifyContent="space-between" alignItems={{ lg: "center", md: "center", sm: "flex-start", xs: "flex-start" }}>
                                        <Box display="flex" fontWeight={400} textAlign="justify" alignItems="center" gap="20px">
                                            <span style={{ borderRadius: "50%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", background: "rgb(48,48,48)", color: "white", minWidth: "30px", minHeight: "30px", width: "30px", height: "30px" }}>{index + 1}</span>
                                            {/* {parse( data.question.question_text )} */}
                                            {data.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={data.question.question_text.trim().split( "LATEX-" )[1]} id='static-latex'> </EditableMathField> : parse( data.question.question_text.trim() )}
                                        </Box>
                                        {answers[data.question.question_id] &&
                                            <Box display="flex" justifyContent="center" >
                                                {( answers[data.question.question_id].length > 1 || ( answers[data.question.question_id][0].submission_score !== null && answers[data.question.question_id][0].submission_score >= 0 ) )
                                                    ? <Typography fontWeight="bold" sx={{ color: "green", display: "flex !important", gap: "10px", alignItems: "center", marginX: "10px !important", marginY: { lg: "0 !important", md: "0 !important", sm: "10px !important", xs: "10px !important" } }} fontSize="12px">
                                                        <span style={{ color: "black", display: "block", minWidth: "85px", fontSize: "20px" }}> {( answers[data.question.question_id] && answers[data.question.question_id][0].submission_score ) ? answers[data.question.question_id][0].submission_score : 0} <span style={{ fontSize: "14px" }}>OUT OF </span>{data.question.question_point}</span>
                                                        <Tooltip title="Evaluated">
                                                            <CheckCircle fontSize='small' />
                                                        </Tooltip>
                                                    </Typography>
                                                    : <Tooltip title="Yet to be evaulated" sx={{ marginLeft: "10px !important" }}>
                                                        <AccessTimeFilledIcon color='error' fontSize='small' />
                                                    </Tooltip>
                                                }
                                            </Box>}
                                    </Box>
                                    <Box alignItems="center">
                                        <Box sx={{ padding: "20px", background: "#eee", display: "block", margin: "10px 0", fontSize: "14px", borderRadius: "5px", "& p": { margin: 0 } }}>
                                            {answers[data.question.question_id] && <Typography variant='subtitle1' fontWeight="bold" color="secondary">Your answer</Typography>}
                                            {answers[data.question.question_id] && answers[data.question.question_id].map( ( ans, index ) => (
                                                ans.answer_id ? <Box key={ans.answer_id} padding="20px" bgcolor="white" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)" marginTop="10px" borderRadius="5px" fontSize="14px" sx={{ color: ans.submission_score === 0 ? "red" : "green", fontWeight: "bold" }}>{getAnswerText( ans.answer_id, data.options )}</Box> : parse( ans.answer_descriptive )
                                            )
                                            )
                                            }
                                            {!answers[data.question.question_id] && <Typography fontSize="14px" color="error" fontWeight="bold">Not answered</Typography>}
                                        </Box>
                                    </Box>
                                </Box>
                            ) )}
                            {questions && answers && <Box display="flex" alignItems="center" marginTop="10px" paddingTop="10px">
                                <div style={{ flexGrow: 1, fontSize: "14px", padding: "10px", background: "#eee", marginRight: "10px", borderRadius: "5px" }}>
                                    <Typography variant='h6' color="primary" gutterBottom>Remark</Typography>
                                    {participation.participation.exam_participation_remark ? <Typography variant='body2'>{participation.participation.exam_participation_remark}</Typography> : <Typography variant='body2' color="textSecondary">No remark given!</Typography>}
                                </div>
                            </Box>}

                        </Paper>
                    </Box>}
                {inProgress && <Typography variant='h6'><CircularProgress size={20} sx={{ marginRight: "10px" }} /> Loading... </Typography>}
            </Box>
        </Box >
    )
}
