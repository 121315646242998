import { Paper, Typography, Box, TextField, Button, CircularProgress, Tooltip, IconButton, Divider, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAssignmentStore } from '../../store/assignment.store'
import AssignmentViewer from './AssignmentViewer'
import SearchIcon from '@mui/icons-material/Search'
import { flatColors, Icons } from '../../utils/utilities'
import noASsignmentImg from '../../assets/noassignment.svg'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import ConfirmDialog from '../ConfirmDialog'


const AssignmentsList = observer( ( { editAssignment, setView } ) => {

    const AssignmentStore = useAssignmentStore()

    const [searchText, setSearchText] = useState( '' )

    const [open, setOpen] = useState( false )
    const [selectedValue, setSelectedValue] = useState( {} )
    const [loaded, setLoaded] = useState( false )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [confirmAction, setConfirmAction] = useState( null )
    const [confirmMessage, setConfirmMessage] = useState( null )

    const navigate = useNavigate()

    useEffect( () => {
        const getAssignments = async () => {
            await AssignmentStore.fetchAssignmentByAuthor()
            setLoaded( true )
        }
        getAssignments()
    }, [AssignmentStore, loaded] )

    const handleClickOpen = ( value ) => {
        setSelectedValue( value )
        setOpen( true )
    }

    const handleClose = ( value ) => {
        setOpen( false )
        setSelectedValue( value )
    }

    const deleteAssignment = async ( id ) => {
        let payload = { "assignment_id": id }
        await AssignmentStore.deleteAssignment( payload )
    }

    const openDeleteDialog = ( id, name ) => {
        setConfirmAction( () => async () => {
            await deleteAssignment( id )
            setConfirmDialogStatus( false )
        } )
        setConfirmMessage(
            <Box>
                <Typography>
                    Do you really want to delete <b style={{ color: "#f18f08" }}>{name}</b>?
                </Typography>
            </Box>
        )
        setConfirmDialogStatus( true )
    }

    return (
        <Paper>
            <ConfirmDialog status={confirmDialogStatus} confirmAction={confirmAction} cancelAction={() => setConfirmDialogStatus( false )} message={confirmMessage} actionName="Delete" />
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" padding="20px" borderBottom="1px solid #d3d3d3">
                <Box>
                    <Typography variant="h5">Assignments</Typography>
                    <Typography variant="body2" color="textSecondary">Manage all your assignments here.</Typography>
                </Box>
                <Button onClick={() => setView( 'create' )} variant="contained" size="small" startIcon={Icons.AddIcon}>
                    Create
                </Button>
            </Box>
            <Box>
                <Box>
                    <Box padding="20px" paddingBottom="20px">
                        <TextField onChange={( e ) => setSearchText( e.target.value )} value={searchText} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search Assignments" variant="standard" type="text" />
                    </Box>
                    <AssignmentViewer selectedValue={selectedValue} open={open} onClose={handleClose} />
                </Box>
                <Box display="flex" flexWrap="wrap" gap="20px">
                    {loaded && AssignmentStore.getAssignment.length > 0 && <Box display="flex" flexWrap="wrap" gap="20px" padding="20px" paddingTop="0">
                        {AssignmentStore.getAssignment.filter( ( current_assignment ) => {
                            return current_assignment["assignment_name"].toLowerCase().indexOf( searchText.toLowerCase() ) > -1
                        } ).map( ( assignment, k ) => {
                            return (
                                <Paper elevation={2} sx={{ marginBottom: "10px", minWidth: "200px", width: "250px", "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" } }} key={assignment["assignment_id"]}>
                                    <Box className="card-body">
                                        <Box onClick={( e ) => handleClickOpen( assignment )} borderRadius="5px 5px 0 0" sx={{ cursor: "pointer" }} color="white" bgcolor={flatColors[k % flatColors.length]} padding="20px">
                                            <Tooltip title={assignment["assignment_name"]} PopperProps={{ sx: { "& .MuiTooltip-tooltip": { background: "#000", padding: "10px" } } }} placement='top'>
                                                <Typography variant="h5" noWrap >{assignment["assignment_name"]}</Typography>
                                            </Tooltip>
                                            <Typography variant="body2">Due Date : {assignment["assignment_due_date"].split( "T" )[0]}</Typography>
                                        </Box>
                                        {/* <Typography variant="body2" className="card-text">{assignment["assignment_details"]}</Typography> */}
                                        <Box onClick={( e ) => handleClickOpen( assignment )} sx={{ cursor: "pointer" }} marginTop="10px" padding="10px 20px 20px 20px" fontSize="12px" fontWeight="bold">
                                            {assignment["author_name"] + ' '}
                                            <br />
                                            <Typography color="textSecondary" fontSize="12px">Created On: {new Date( assignment["assignment_created_at"] ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                                            {/* <span> Score : {!assignment["assignment_marks"] ? 'Not Evaluated' : assignment["assignment_marks"]}</span> */}
                                        </Box>
                                        <Divider sx={{ background: "rgba(0,0,0,0.15)", height: "2px" }} />
                                        <Box display="flex" flexGrow={1} alignItems="flex-end" justifyContent="flex-start" padding="0 20px 10px 20px" gap="10px" marginTop="10px">
                                            {/* <Tooltip title="View">
                                                <IconButton onClick={( e ) => handleClickOpen( assignment )} >
                                                    {Icons.OpenInNewIcon}
                                                </IconButton>
                                            </Tooltip> */}
                                            <Tooltip title="Evaluate">
                                                <Button variant='contained' color='success' disableElevation size="small" onClick={() => navigate( `/faculty/assignment/${assignment["assignment_id"]}` )}>
                                                    {/* {Icons.CheckIcon} */}
                                                    Evaluate
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton sx={{ color: "secondary.main" }} size="small" onClick={() => editAssignment( assignment )}  >
                                                    {Icons.EditIcon}
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    sx={{ color: "error.main" }}
                                                    size="small"
                                                    onClick={() => openDeleteDialog( assignment["assignment_id"], assignment["assignment_name"] )}
                                                >
                                                    {Icons.DeleteIcon}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Paper>
                            )
                        } )
                        }
                    </Box>}

                    {
                        loaded && AssignmentStore.getAssignment.filter( ( current_assignment ) => {
                            return current_assignment["assignment_name"].toLowerCase().indexOf( searchText.toLowerCase() ) > -1 || searchText === ""
                        } ).length === 0 &&
                        <Box display="flex" flexDirection="column" bgcolor="#eee" padding="20px" width="100%" alignItems="center" justifyContent="center">
                            <img src={noASsignmentImg} width="200px" alt="No Assignments!" />
                            <Typography marginY="20px" variant='subtitle2' color="textSecondary">No assignments!</Typography>
                            <Box display="flex" gap="20px" alignItems="center" flexWrap="wrap">
                                {searchText !== "" && <Button onClick={() => setSearchText( "" )} variant='outlined' sx={{ fontSize: "12px", textTransform: "capitalize" }} color="error" startIcon={Icons.BlockIcon}>Clear filter</Button>}
                                <Button onClick={() => setView( "create" )} variant='outlined' sx={{ fontSize: "12px", textTransform: "capitalize" }} color="primary" startIcon={Icons.AddIcon}>Add new assignment</Button>
                            </Box>
                        </Box>
                    }
                    {
                        !loaded && <Typography display="flex" alignItems="center" padding="20px" gap="10px" variant='h6' color="textSecondary"> <CircularProgress size={20} /> Loading assignments... </Typography>
                    }
                </Box>
            </Box>
        </Paper>
    )
} )

export default AssignmentsList
