import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import React from 'react'
import StudyMaterialStore from './studymaterial.store'
import axios from 'axios'
import { downloadZip } from 'client-zip'

class DownloadStatusList {
    dialogStatus
    progressStatusList
    statusList
    tempProgressValues
    downloadingCount

    constructor () {
        this.dialogStatus = false
        this.progressStatusList = {}
        this.tempProgressValues = {}
        this.downloadingCount = 0

        makeObservable( this, {
            downloadingCount: observable,
            dialogStatus: observable,
            progressStatusList: observable,
            setProgressStatusList: action,
            downloadAttachment: action,
            downloadMaterial: action,
            closeDialog: action,
            getProgressStatusList: computed,
            getDialogStatus: computed,
            openDialog: action
        } )
    }

    get getProgressStatusList() {
        return toJS( this.progressStatusList )
    }

    get getDialogStatus() {
        return this.dialogStatus
    }

    get getDownloadingCount() {
        return toJS( this.downloadingCount )
    }

    setDialogStatus( status ) {
        runInAction( () => {
            this.dialogStatus = status
        } )
    }

    setProgressStatusList( list ) {
        const dowloadingCount = Object.values( list ).filter( item => item.inProgress ).length
        runInAction( () => {
            this.progressStatusList = list
            this.downloadingCount = dowloadingCount
        } )
    }

    async downloadMaterial( material_id, data ) {
        this.setProgressStatusList( { ...this.tempProgressValues, [`material-${material_id}`]: { name: data.study_material_name, type: "material", progress: 0, inProgress: true } } )
        this.tempProgressValues[`material-${material_id}`] = { name: data.study_material_name, type: "material", progress: 0, inProgress: true }
        this.setDialogStatus( true )
        const attachmentsUrl = await StudyMaterialStore.downloadMaterial( material_id )
        this.setProgressStatusList( { ...this.tempProgressValues, [`material-${material_id}`]: { name: data.study_material_name, type: "material", progress: 10, inProgress: true } } )
        this.tempProgressValues[`material-${material_id}`]["progress"] = 10
        let filesUrl = []
        for ( let i = 0; i < attachmentsUrl.length; i++ ) {
            const url = attachmentsUrl[i]
            filesUrl.push( await fetch( url ) )
            this.setProgressStatusList( { ...this.tempProgressValues, [`material-${material_id}`]: { name: data.study_material_name, type: "material", progress: Math.ceil( 10 + ( ( i + 1 ) * 80 / attachmentsUrl.length ) ), inProgress: true } } )
            this.tempProgressValues[`material-${material_id}`]["progress"] = Math.ceil( 10 + ( ( i + 1 ) * 80 / attachmentsUrl.length ) )
            if ( i === attachmentsUrl.length - 1 ) {
                const blob = await downloadZip( filesUrl ).blob()
                const link = URL.createObjectURL( blob )
                const a = document.createElement( "a" )
                a.href = link
                a.download = data.study_material_name
                a.click()
                this.setProgressStatusList( { ...this.tempProgressValues, [`material-${material_id}`]: { name: data.study_material_name, type: "material", progress: Math.ceil( 100 ), inProgress: false } } )
                this.tempProgressValues[`material-${material_id}`]["progress"] = 100
                this.tempProgressValues[`material-${material_id}`]["inProgress"] = false
            }
        }
    }

    async downloadAttachment( id, url, name, ext ) {
        this.setProgressStatusList( { ...this.tempProgressValues, [id]: { name, type: "attachment", progress: 0, inProgress: true } } )
        this.tempProgressValues[id] = { name, type: "attachment", progress: 0, inProgress: true }
        this.setDialogStatus( true )
        const DownloadLink = await StudyMaterialStore.downloadAttachment( id, { url } )
        const a = document.createElement( "a" )
        const res = await axios( DownloadLink, {
            onDownloadProgress: ( e ) => {
                this.setProgressStatusList( { ...this.tempProgressValues, [id]: { name, type: "attachment", progress: Math.ceil( e.loaded * 100 / e.total ), inProgress: true } } )
                this.tempProgressValues[id] = { name, type: "attachment", progress: Math.ceil( e.loaded * 100 / e.total ), inProgress: true }
            }, responseType: 'blob'
        } )
        const link = URL.createObjectURL( res.data )
        a.href = link
        a.download = `${name}.${ext}`
        a.click()
        this.setProgressStatusList( { ...this.tempProgressValues, [id]: { name, type: "attachment", progress: 100, inProgress: false } } )
        this.tempProgressValues[id]["progress"] = 100
        this.tempProgressValues[id]["inProgress"] = false
    }

    openDialog() {
        this.setDialogStatus( true )
    }

    closeDialog() {
        if ( this.dialogStatus ) {
            const newStatuses = { ...this.progressStatusList }
            const keys = Object.keys( this.progressStatusList )
            for ( let i = 0; i < keys.length; i++ ) {
                const key = keys[i]
                if ( newStatuses[key].progress >= 100 && !newStatuses[key].inprogress ) {
                    delete newStatuses[key]
                    delete this.tempProgressValues[key]
                }
            }
            this.setProgressStatusList( newStatuses )
            this.setDialogStatus( false )
        } else {
            this.setDialogStatus( true )
        }
    }
}


const DownloadStatusListInstance = new DownloadStatusList()

export default DownloadStatusListInstance

const DownloadStatusListContext = React.createContext()

export const DownloadStatusListProvider = ( { children, store } ) => {
    return (
        <DownloadStatusListContext.Provider value={store}>{children}</DownloadStatusListContext.Provider>
    )
}

export const useDownloadStatusListStore = () => React.useContext( DownloadStatusListContext )