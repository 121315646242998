import MoreVert from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import InfoIcon from '@mui/icons-material/Info'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useUserStore } from '../../store/user.store'
import { Edit } from '@mui/icons-material'
import ConfirmDialog from '../ConfirmDialog'

const columns = [
    { name: "Attachment name", minWidth: "200px", align: "left", borderRight: "1px solid rgba(0,0,0,0.2)" },
    { name: "Attachment type", minWidth: "150px", align: "left", borderRight: "1px solid rgba(0,0,0,0.2)", styles: { "@media(max-width:700px)": { display: "none" } } },
    { name: "Uploaded on", minWidth: "170px", align: "left", borderRight: "1px solid rgba(0,0,0,0.2)", styles: { "@media(max-width:500px)": { display: "none" } } }
]
// eslint-disable-next-line
const ListView = ( { attachments, allAttachments, removefilter, downloadAttachment, material, renameAttachment, setAttachmentsSelectedForDetails, setShowDetails, deleteAttachment } ) => {

    const [anchorEls, setAnchorEls] = useState( {} )
    const [openState, setOpenState] = useState( {} )
    const [renameValue, setRenameValue] = useState( "" )
    const [renameDialogState, setRenameDialogState] = useState( false )
    const [selectedAttachment, setSelectedAttachment] = useState( null )
    const [confirmDialogStatus, setConfirmDialogStatus] = useState( false )
    const [confirmAction, setConfirmAction] = useState( null )
    const [confirmMessage, setConfirmMessage] = useState( null )

    const UserStore = useUserStore()
    const { palette } = useTheme()

    const handleOptionsClick = ( e, index ) => {
        setAnchorEls( { [index]: e.currentTarget } )
        setOpenState( { [index]: Boolean( e.currentTarget ) } )
    }

    const openDeleteDialog = ( index, id, name ) => {
        setAnchorEls( { [index]: null } )
        setOpenState( { [index]: Boolean( null ) } )
        setConfirmAction( () => async () => {
            await deleteAttachment( id )
            setConfirmDialogStatus( false )
        } )
        setConfirmMessage(
            <Box>
                <Typography>
                    Do you really want to delete <b style={{ color: "#f18f08" }}>{name}</b>?
                </Typography>
            </Box>
        )
        setConfirmDialogStatus( true )
    }

    return (
        <Box>
            <Dialog PaperProps={{ sx: { width: { md: "60%", xs: "85%" } } }} open={renameDialogState}>
                <DialogTitle>Rename Attachment</DialogTitle>
                <DialogContent>
                    <TextField sx={{ marginTop: "2px" }} value={renameValue} onChange={( e ) => setRenameValue( e.target.value )} variant='outlined' fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant='contained' onClick={async () => { await renameAttachment( selectedAttachment, renameValue ); setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Update</Button>
                    <Button color="error" variant='outlined' onClick={() => { setRenameValue( "" ); setRenameDialogState( false ) }} sx={{ textTransform: "capitalize" }}>Close</Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog status={confirmDialogStatus} confirmAction={confirmAction} cancelAction={() => setConfirmDialogStatus( false )} message={confirmMessage} actionName="Delete" />
            {attachments && attachments.length > 0 && <TableContainer sx={{ marginTop: "10px", maxHeight: "500px" }}>
                <Table stickyHeader>
                    <TableHead sx={{ background: palette.primary.main }}>
                        <TableRow sx={{ background: palette.primary.main }}>
                            {columns.map( column => (
                                <TableCell key={column.name} align={column.align} sx={{ background: palette.primary.main, color: "white", minWidth: column.minWidth, maxWidth: column.maxWidth, fontWeight: "bold", ...column.styles }}>
                                    {column.name}
                                </TableCell>
                            ) )}
                            <TableCell align='right' sx={{ background: palette.primary.main, color: "white", minWidth: "fit-content", position: "sticky" }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attachments.map( ( attachment, index ) => (
                            <TableRow sx={{ "&:nth-of-type(odd)": { background: "#eee" } }} key={index}>
                                <TableCell><Typography noWrap fontWeight="bold" fontSize="12px">{attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name}</Typography></TableCell>
                                <TableCell sx={{ "@media(max-width:700px)": { display: "none" } }}><Typography fontSize="12px">{attachment.study_material_attachment_type}</Typography></TableCell>
                                <TableCell sx={{ "@media(max-width:500px)": { display: "none" } }}><Typography fontSize="12px">{new Date( attachment.study_material_attachment_published_date ).toLocaleDateString( 'en-IN', { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" } )}</Typography></TableCell>
                                <TableCell align='right'>
                                    <Tooltip>
                                        <IconButton onClick={( e ) => handleOptionsClick( e, index )} size="small">
                                            <MoreVert />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "center", horizontal: "right" }} anchorEl={anchorEls[index]} open={openState[index] ? true : false} onClose={() => { setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} >
                                        <MenuItem sx={{ padding: "0", width: "200px" }}>
                                            <Button onClick={( e ) => { downloadAttachment( attachment.study_material_attachment_id, attachment.study_material_attachment_url, attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name, attachment.study_material_attachment_name.split( "." ).pop() ); setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} startIcon={<DownloadIcon />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>Download</Button>
                                        </MenuItem>
                                        <MenuItem onClick={() => { setAttachmentsSelectedForDetails( attachment ); setShowDetails( true ); setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} sx={{ padding: "0", width: "200px" }}>
                                            <Button startIcon={<InfoIcon color='secondary' />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>View Details</Button>
                                        </MenuItem>
                                        {UserStore.getUser.user_id === material.empcode && < MenuItem sx={{ padding: "0", width: "200px" }}>
                                            <Button onClick={e => { setSelectedAttachment( attachment.study_material_attachment_id ); setRenameValue( attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name ); setRenameDialogState( true ); setAnchorEls( { [index]: null } ); setOpenState( { [index]: Boolean( null ) } ) }} startIcon={<Edit color='primary' />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>Rename</Button>
                                        </MenuItem>}
                                        {UserStore.getUser.user_id === material.empcode && < MenuItem sx={{ padding: "0", width: "200px" }}>
                                            <Button onClick={() => openDeleteDialog( index, attachment.study_material_attachment_id, attachment.study_material_attachment_title ? attachment.study_material_attachment_title : attachment.study_material_attachment_name )} startIcon={<DeleteIcon color='error' />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", color: "rgba(0,0,0,0.7)", justifyContent: "flex-start" }}>Delete</Button>
                                        </MenuItem>}
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </TableContainer>}
            {( !attachments || attachments.length === 0 ) && <Box textAlign="center" padding="20px">
                <Icon sx={{ height: "fit-content", width: "fit-content" }}>
                    <AttachmentIcon sx={{ fontSize: "50px", color: "#00000099" }} />
                </Icon>
                <Typography variant='h6' fontWeight="bold" color="textSecondary" >No attachments!</Typography>
                {allAttachments.length > 0 && <Button variant='contained' color='secondary' disableElevation onClick={removefilter} sx={{ textTransform: "capitalize", marginTop: "10px" }}>Remove filter</Button>}
            </Box>}
        </Box>
    )
}

export default ListView
