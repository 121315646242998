import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Typography, Paper, useTheme } from '@mui/material'
//eslint-disable-next-line
import { useState, useEffect } from 'react'
import { usePubNub } from 'pubnub-react'
import { observer } from 'mobx-react'

const channels = ['server_status']

const ServerStatusBarChart = observer( () => {
    const pubnub = usePubNub()
    const [dataPoints, setDataPoints] = useState( [] )
    const [timeStamp, setTimeStamp] = useState( Date() )

    const { palette } = useTheme()

    useEffect( () => {
        //setDataPoints([...props.datapoints])
        pubnub.addListener( {
            message: messageEvent => {
                const messageData = messageEvent.message
                setDataPoints( messageData )
                setTimeStamp( Date() )
            },
        } )
        pubnub.subscribe( { channels } )
    }, [pubnub] )

    const totalCount = ( dataPoints ) => {
        let tot = dataPoints.reduce( function ( total, num ) {
            return total + ( num.api_current_user )
        }, 0 )
        return tot
    }

    return (
        <Paper sx={{ height: "90%", padding: "20px", width: "94%" }}>
            <Typography variant='h5' >Server Status | Total users{` ${totalCount( dataPoints )}`}</Typography>
            <Typography variant='body2' color="textSecondary">Last updated on: {timeStamp}</Typography>
            {/* <hr style={{ margin: '20px 0' }} /> */}
            <ResponsiveContainer width="100%" height="85%">
                <BarChart
                    data={dataPoints.sort( ( a, b ) => ( a.api_provider > b.api_provider ) ? 1 : ( ( b.api_provider > a.api_provider ) ? -1 : 0 ) )}
                    margin={{
                        top: 40, right: 30, left: 0, bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis style={{ fontSize: "12px" }} dataKey="api_provider" />
                    <YAxis style={{ fontSize: "12px" }} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Capacity" fill={palette.primary.main} />
                    <Bar dataKey="Load" fill="#FF6E01" />
                </BarChart>
            </ResponsiveContainer>
        </Paper >
    )
} )

export default ServerStatusBarChart
