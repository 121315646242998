import { Avatar, Box, Typography, IconButton, Menu, MenuItem, ListItemIcon, AppBar, useTheme, Divider } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuthStore } from "../../store/auth.store"
import { useUserStore } from "../../store/user.store"
import aliveTextLogo from '../../assets/alivelogo.png'
import { useLayoutContext } from "../../contexts/layout.context"

const Navbar = ( { openSideMenu } ) => {

    const [anchorEl, setAnchorEl] = useState( null )
    const AuthStore = useAuthStore()
    const UserStore = useUserStore()
    const open = Boolean( anchorEl )
    const navigate = useNavigate()
    const { isSmallScreen, setSidedrawerState, sidedrawerState } = useLayoutContext()
    const theme = useTheme()

    const logout = async () => {
        await AuthStore.doLogout()
        localStorage.clear()
        toast.success( "Logged out successfully!" )
        navigate( '/admin' )
    }


    return (
        <AppBar id="appbar" elevation={0} sx={{ borderRadius: "0", background: "white", borderBottom: "1px solid #26272988", zIndex: theme.zIndex.drawer + 1, transition: "0.2s ease-out", position: "absolute", color: "black", width: "100%", height: "fit-content" }}>
            <Box alignItems="center" justifyContent="space-between" padding="10px" display="flex" maxWidth="100%" id="app-bar" sx={{ transition: "0.2s ease-out", '@media(max-width:1100px)': { padding: "10px" }, paddingLeft: "20px" }}>
                <Box display="flex" alignItems="center">
                    <IconButton onClick={() => setSidedrawerState( !sidedrawerState )} sx={{ marginRight: "10px", marginBottom: "-5px", color: "#262729", display: !isSmallScreen ? "none" : "block" }}>
                        <MenuIcon />
                    </IconButton>
                    <Box display="flex" alignItems="center" gap="20px">
                        <img src={aliveTextLogo} width="30px" alt="alive-logo" />
                        <Box>
                            <Typography variant='h6' margin="0" fontSize={{ sm: "16px", xs: "16px" }}>Alive</Typography>
                            <Typography variant='subtitle2' marginTop="-2px" fontWeight="400" fontSize="12px">Digital Classrooms</Typography>
                        </Box>
                    </Box>
                </Box>
                {<Box display="flex" paddingRight="20px" alignItems="center" gap="10px">
                    <IconButton onClick={( e ) => setAnchorEl( e.currentTarget )}>
                        <Avatar sx={{ height: "32px", width: "32px" }}></Avatar>
                    </IconButton>
                    <Box display={{ sm: "block", xs: "none" }}>
                        <Typography color="GrayText" textAlign="right" variant='subtitle1' textTransform="capitalize">{UserStore.getUser?.user_role?.toLowerCase()}</Typography>
                    </Box>
                    <Menu slotProps={{ paper: { sx: { minWidth: "150px" } } }} onClose={e => { setAnchorEl( null ) }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} anchorEl={anchorEl} open={open}>
                        <Typography textAlign="center">{UserStore.getUser?.user_name}</Typography>
                        <Typography paddingBottom="10px" gutterBottom color="GrayText" fontSize="12px" textAlign="center">{UserStore.getUser?.user_email}</Typography>
                        <Divider />
                        <MenuItem dense color="error" sx={{ color: "error.main", display: "flex", justifyContent: "center", marginTop: "5px" }} onClick={logout}>
                            <ListItemIcon>
                                <LogoutIcon sx={{ color: "error.main" }} fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Box>}
            </Box>
        </AppBar>
    )
}

export default Navbar
