import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useRoomStore } from '../../../src/store/room.store'
import { useOrganizationStore } from '../../../src/store/organization.store'
import SelectInstitute from '../../components/admin/SelectInstitute'
import noClassesImg from '../../assets/noclasses.svg'
import selectInstituteImg from '../../assets/selectinstitute.svg'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { observer } from 'mobx-react'
import warningImg from '../../assets/warning.svg'

const user = 'admin'

const LiveClass = observer( () => {
    const RoomStore = useRoomStore()
    const OrganizationStore = useOrganizationStore()
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const [instituteCode, setInstituteCode] = useState( "0" )
    const [isLoading, setIsLoading] = useState( false )
    const [hasAccess, setHasAccess] = useState( true )

    const handleInstituteChange = async ( e ) => {
        setIsLoading( true )
        setInstituteCode( e )
        await RoomStore.fetchAuditRoom( e )
        console.log( RoomStore.getAuditRoom )
        setIsLoading( false )
    }

    const joinSession = async ( data ) => {
        try {
            let res = await RoomStore.joinSession( data )
            //console.log(res)
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');
                RoomStore.setStreamDetails( true, res["data"], data )
                navigate( '/admin/stream' )
            }
        } catch ( err ) {
            console.log( err )

        }
    }
    const endSession = async ( data ) => {
        try {
            let res = await RoomStore.endSessionByAdmin( data )
            if ( res === true ) {
                RoomStore.fetchAuditRoom( instituteCode )
                //setInstituteCode(res)
            }
        } catch ( err ) {

        }
    }

    useEffect( () => {
        const getData = async () => {
            if ( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) {
                if ( UserStore.getUser.user_role === 'subadmin' ) {
                    if ( UserStore.getUser.user_permissions.live_class_audit )
                        await OrganizationStore.fetchOrganization()
                    else {
                        setHasAccess( false )
                    }
                } else
                    await OrganizationStore.fetchOrganization()
            } else {
                await RoomStore.fetchAuditRoom()
            }
        }
        getData()
        // console.log(instituteCode)

        // return () => {
        //     // clean up
        // }

    }, [OrganizationStore, RoomStore, UserStore] )


    return (
        <Box >
            {hasAccess && <Box borderRadius="5px" padding={!( UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) ? "0 20px" : "0px"} gap="20px" display="flex" flexDirection={{ md: "row", xs: "column" }}>
                {( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) && <Paper sx={{ height: "fit-content", maxWidth: { md: "240px", xs: "100%" } }} elevation={1}>
                    <Box padding="20px" paddingBottom="0">
                        <Typography variant='h5'>Live classes</Typography>
                        <Typography marginBottom="20px" color="textSecondary" variant='subtitle2'>Check all the currently running sessions.</Typography>
                    </Box>
                    <hr />
                    <SelectInstitute variant="outlined" onSelect={handleInstituteChange} />
                </Paper>}
                <Paper sx={{ flexGrow: "1" }}>
                    {!isLoading && RoomStore.getAuditRoom.length > 0 && <TableContainer sx={{ borderRadius: "5px", maxHeight: "450px" }}>
                        <Table sx={{ minWidth: "650px" }} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Course Details</TableCell>
                                    <TableCell align="right">Branch</TableCell>
                                    <TableCell align="right">Presenter</TableCell>
                                    <TableCell align="right">Subject</TableCell>
                                    <TableCell align="right">Session Time</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {RoomStore.getAuditRoom.map( ( row, i ) => (
                                    <TableRow key={'row_' + i}>
                                        <TableCell scope="row">
                                            Course : {row.room_session_data["course_short_name"]} <br></br>
                                            <small>

                                                Semester {row.room_session_data["current_sem"]} -
                                                Section {row.room_session_data["section_short_name"]}</small>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["branch_name"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["presenter_name"] ? row.room_session_data["presenter_name"] : "Not Available"}</TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["subject_short_name"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["intervals"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px", display: "flex", flexWrap: "wrap", gap: "10px" }} align="right">
                                            {
                                                user === "admin" ?
                                                    <Button variant="contained" color="secondary"
                                                        onClick={() => endSession( row.room_session_data )}
                                                        disableElevation className="mr-2">
                                                        END
                                                    </Button> : ''
                                            }
                                            <Button variant="contained" color="primary"
                                                onClick={() => joinSession( row.room_session_data )}
                                                disableElevation>
                                                Join
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>}

                    {RoomStore.getAuditRoom.length === 0 && ( instituteCode !== "0" || !( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) ) && !isLoading && <Box sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "#999", variant: 'h5', padding: "20px", borderRadius: "0 0 5px 5px", bgcolor: "#fff" }}>
                        <img src={noClassesImg} width="200px" alt="No live classes going on!" />
                        <Typography variant='subtitle2' color="textSecondary" marginTop="10px">No live classes going on!</Typography>
                    </Box>}
                    {RoomStore.getAuditRoom.length === 0 && instituteCode === "0" && ( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) && <Box sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "#999", variant: 'h5', padding: "20px", borderRadius: "0 0 5px 5px", bgcolor: "#fff" }}>
                        <img src={selectInstituteImg} style={{ maxHeight: "180px" }} height="100%" alt="Select an institute" />
                        <Typography marginTop="10px" color="textSecondary" variant='subtitle2'>Select an institute</Typography>
                    </Box>}
                    {isLoading && <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "#999", variant: 'h5', padding: "20px", borderRadius: "5px", bgcolor: "#fff" }}>
                        <Typography display="flex" alignItems="center" gap="10px" variant='h6'><CircularProgress size={20} />Loading rooms... </Typography>
                    </Box>}
                </Paper>
            </Box>}
            {!hasAccess && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box >
    )
} )

export default LiveClass
