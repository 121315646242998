export const EMAIL_PATTERN = /^[^!#$%&~]+@([\w-]+\.)+[\w-]{2,4}$/
// eslint-disable-next-line
export const URL_PATTERN = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/


export const displayPresets = {
    custom: { fontSize: 1, fontWeight: "400" },
    sectionHeader: { fontSize: 2, fontweight: "600" },
    header1: { fontSize: 1.5, fontweight: "500" },
    header2: { fontSize: 1.3, fontweight: "500" },
    body1: { fontSize: 1, fontWeight: "400" },
    body2: { fontSize: 0.8, fontWeight: "400" },
    label1: { fontSize: 1, fontweight: "500" },
    label2: { fontSize: 0.9, fontweight: "500" }
}

export const templateColorThemes = {
    default: {
        black: "#000",
        primary: "#273679",
        secondary: "#f18f08",
        lightGrey: "#eee",
        darkGrey: "grey"
    },
    "Natural and Earthly": {
        black: "#000",
        primary: "#8D8741",
        secondary: "#659DBD",
        color1: "#DAAD86",
        color2: "#BC986A",
        lightGrey: "#eee",
        darkGrey: "grey"
    },
    "Cool and Fresh": {
        black: "#000",
        primary: "#05386B",
        secondary: "#379683",
        color1: "#5CDB95",
        color2: "#8EE4AF",
        lightGrey: "#eee",
        darkGrey: "grey"
    }, "Vermillion and Russian Green": {
        black: "#000",
        primary: "#A33327",
        secondary: "#5CDB95",
        color1: "#907163",
        color2: "#379683",
        lightGrey: "#eee",
        darkGrey: "grey"
    }, "Striking and Simple": {
        black: "#000",
        primary: "#0B0C10",
        secondary: "#1F2833",
        color1: "#C5C6C7",
        color2: "#66FCF1",
        lightGrey: "#eee",
        darkGrey: "grey"
    }, "Artsy and Creative": {
        black: "#000",
        primary: "#D79922",
        secondary: "#EFE2BA",
        color1: "#F13C20",
        color2: "#4056A1",
        lightGrey: "#eee",
        darkGrey: "grey"
    }

}

export const fontWeights = [
    { val: "100", label: "100" },
    { val: "200", label: "200" },
    { val: "300", label: "300" },
    { val: "400", label: "Regular" },
    { val: "500", label: "500" },
    { val: "600", label: "Bold" },
    { val: "700", label: "700" },
    { val: "800", label: "Bolder" },
    { val: "900", label: "900" },
]

export const defaultColumnRatios = {
    1: "1fr",
    2: "1fr 1fr",
    3: "1fr 1fr 1fr"
}

export const allColumnRatios = {
    1: [{ label: "100%", val: "1fr" }],
    2: [{ label: "50% 50%", val: "1fr 1fr" }, { label: "60% 40%", val: "3fr 2fr" }, { label: "40% 60%", val: "2fr 3fr" }],
    3: [{ label: "33% 33% 33%", val: "1fr 1fr 1fr" }]
}

export const handleGroupItemDragStart = () => {
    console.log( "Group Item Drag Start" )
}