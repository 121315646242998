import { Box, TextField, Paper, Typography, CircularProgress, Tooltip, Divider, Button } from "@mui/material"
import React, { useEffect } from "react"
import SimpleDialog from "../../components/user/AssignmentDialog"
import SubmitAssignmentDialog from "../../components/user/SubmitAssignmentDialog"
import { useAssignmentStore } from "../../store/assignment.store"
import SearchIcon from '@mui/icons-material/Search'
import noAssignmentImg from '../../assets/noassignment.svg'
import { flatColors } from "../../utils/utilities"

const Assignment = () => {

    const AssignmentStore = useAssignmentStore()

    const [searchText, setSearchText] = React.useState( '' )
    const [loaded, setLoaded] = React.useState( false )

    const [open, setOpen] = React.useState( false )
    const [openAssignmentSubmission, setOpenAssignmentSubmission] = React.useState( false )

    const [selectedValue, setSelectedValue] = React.useState( {} )
    const [selectedAssignmentData, setSelectedAssignmentData] = React.useState( {} )

    useEffect( () => {
        const getAssignments = async () => {
            await AssignmentStore.fetchAssignmentByStudent()
            console.log( AssignmentStore.getAssignment )
            setLoaded( true )
        }
        getAssignments()
    }, [AssignmentStore] )

    const handleClickOpen = ( value ) => {
        console.log( value )
        setSelectedValue( value )
        setOpen( true )
    }

    const handleClose = ( value ) => {
        setOpen( false )
        setSelectedValue( value )
    }

    const handleClickOpenAssignmentSubmission = ( value ) => {
        setSelectedAssignmentData( value )
        setOpenAssignmentSubmission( true )
    }

    const handleCloseAssignmentSubmission = ( value ) => {
        setOpenAssignmentSubmission( false )
        setSelectedAssignmentData( value )
    }


    return (
        <Box padding="0 20px" className="main">
            <Paper>
                <Box padding="20px">
                    <Box>
                        <Typography variant="h5">Assignments</Typography>
                        <Typography variant="subtitle2" color="textSecondary">View all your assignments here</Typography>
                    </Box>
                    <Box>
                        {( AssignmentStore.getAssignment.length !== 0 && loaded ) && <Box marginTop="20px" display="flex" alignItems="center" gap="10px">
                            <TextField onChange={( e ) => setSearchText( e.target.value )} value={searchText} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search Assignments" variant="standard" type="text" />
                        </Box>}
                        <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
                        <SubmitAssignmentDialog selectedValue={selectedAssignmentData} open={openAssignmentSubmission} onClose={handleCloseAssignmentSubmission} />
                    </Box>
                    {( AssignmentStore.getAssignment.length !== 0 && loaded ) && <Box marginTop="20px" display="flex" flexWrap="wrap" gap="20px">
                        {
                            loaded && AssignmentStore.getAssignment.filter( ( current_assignment ) => {
                                return current_assignment["assignment_name"].toLowerCase().indexOf( searchText.toLowerCase() ) > -1
                            } ).map( ( assignment, k ) => {
                                return (
                                    <Paper sx={{ marginBottom: "10px", minWidth: "200px", width: "250px", "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" } }} key={assignment["assignment_id"]}>
                                        <Box className="card-body">
                                            <Box onClick={( e ) => handleClickOpen( assignment )} sx={{ cursor: "pointer" }}>
                                                <Box borderRadius="5px 5px 0 0 " color="white" bgcolor={flatColors[k % flatColors.length]} padding="20px">
                                                    <Typography variant="h5" noWrap width="200px" >{assignment["assignment_name"]}</Typography>
                                                    <Typography variant="body2">Due Date : {assignment["assignment_due_date"].split( "T" )[0]}</Typography>
                                                </Box>
                                                <Box marginTop="10px" padding="10px 20px 20px 20px" fontSize="12px" fontWeight="bold">
                                                    {assignment["author_name"] + ' '}
                                                    <br />
                                                    <Typography color="textSecondary" fontSize="12px">Created On: {new Date( assignment["assignment_created_at"] ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                                                    <span> Score : {!assignment["assignment_marks"] ? 'Not Evaluated' : assignment["assignment_marks"]}</span>
                                                </Box>
                                            </Box>
                                            <Divider sx={{ background: "rgba(0,0,0,0.15)", height: "2px" }} />
                                            <Box display="flex" justifyContent="flex-start" alignItems="center" padding="0 20px 10px 20px" gap="10px" marginTop="10px">
                                                <Tooltip title={( assignment["submission_exists"] === "0" ) ? 'Submit Assignment' : 'Assignment Submitted'}>
                                                    <Typography display="flex" justifyContent="center" width="100%" textAlign="center"><Button sx={{ width: "100%" }} disabled={assignment["submission_exists"] !== "0" || new Date( new Date().toISOString().split( "T" )[0] ) > new Date( String( assignment.assignment_due_date ).split( "T" )[0] )} onClick={( e ) => handleClickOpenAssignmentSubmission( assignment )} color="secondary">{assignment["submission_exists"] !== "0" ? "Assignment Submitted" : new Date( new Date().toISOString().split( "T" )[0] ) > new Date( String( assignment.assignment_due_date ).split( "T" )[0] ) ? "Due Date Over" : "Submit Answers"}</Button></Typography>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Paper>
                                )
                            } )
                        }
                    </Box>}
                    {
                        !loaded && <Typography display="flex" color="textSecondary" gap="10px" alignItems="center" variant="h6" height="100px" justifyContent="center"> <CircularProgress size={20} /> Loading assignments... </Typography>
                    }
                </Box>
                {
                    ( ( AssignmentStore.getAssignment.length === 0 && loaded ) ? <Box padding="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" bgcolor="#eee"><img src={noAssignmentImg} width="200px" className="img-fluid" alt="No Assignments Just Relax" /> <Typography variant="subtitle2" color="textSecondary" marginTop="10px">No Assignment Just Relax!</Typography></Box> : '' )
                }
            </Paper>
        </Box>
    )
}



export default Assignment