import React, { useEffect, useState } from 'react'
import { Box, Typography, Divider, Button, CircularProgress, Chip, Dialog, DialogContent, IconButton } from '@mui/material'
import AttachmentIcon from '@mui/icons-material/Attachment'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import GetAppIcon from '@mui/icons-material/GetApp'
import { formatDateTimeForInputField, formatDateTimeForDisplay, ATTACHMENTS_BASE_URL, flatColors, chipColors, validateQuestionsImportFormat, calculateDurationBetween } from '../../utils/exam-utilities'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import { convert } from 'html-to-text'
import DownloadIcon from '@mui/icons-material/Download'
import RefreshIcon from '@mui/icons-material/Refresh'
import InfoIcon from '@mui/icons-material/Info'
import Edit from '@mui/icons-material/Edit'
import PublishIcon from '@mui/icons-material/Publish'
import { useUserStore } from '../../store/user.store'
import { useExamStore } from '../../store/exam.store'
import api from '../../service/api'
import { observer } from 'mobx-react'
import { EditableMathField } from 'react-mathquill'
import ConfirmDialog from '../../components/ConfirmDialog'
import QuestionsPreviewDialog from '../../components/exams-components/QuestionsPreviewDialog'
import QuestionImportFileInputDialog from '../../components/exams-components/QuestionImportFileInputDialog'
import { Icons } from '../../utils/utilities'
import { useLayoutContext } from '../../contexts/layout.context'

const Exam = observer( () => {
    let { id } = useParams()
    const [batches, setBatches] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [questions, setQuestions] = useState( null )
    const [isImporting, setIsImporting] = useState( false )
    const [notFound, setNotFound] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [questionsPreview, setQuestionsPreview] = useState( null )
    const [importDialogStatus, setImportDialogStatus] = useState( false )
    const { setCurrentLoc } = useLayoutContext()

    const navigate = useNavigate()
    const UserStore = useUserStore()
    const ExamStore = useExamStore()

    // addStyles()

    const getExam = async () => {
        try {
            await ExamStore.fetchExam( id )
            setInProgress( false )
        } catch ( e ) {
            toast.error( "Something went wrong! try again later" )
            navigate( UserStore.getUser['user_role'] === "STUDENT" ? "/student/exams" : "/faculty/exams" )
        }
    }

    const publish = async () => {
        try {
            await api.publish( ExamStore.getExam.exam.exam_id )
            await getExam()
            toast.success( "Exam published!" )
        } catch ( e ) {
            console.log( e )
            toast.error( e.response && e.response.data )
        }
    }

    const deleteExam = async () => {
        try {
            await api.delete( ExamStore.getExam.exam.exam_id )
            toast.success( <div><span style={{ color: "#f18f08" }}>{ExamStore.getExam.exam.exam_name}</span> successfully deleted</div> )
            navigate( "/faculty/exams" )
        } catch ( e ) {
            toast( e?.response?.data ? e?.response.data : "Something went wrong! try again later" )
        } finally {
            setDeleteDialogState( false )
        }
    }

    const attempt = async () => {
        try {
            const res = await api.attempt( ExamStore.getExam.exam.exam_id )
            if ( res.status === 200 ) {
                if ( !res.data.isNewParticipation ) toast.success( "Proceeding with the previously unfinished exam! finish this to start new attampt" )
                navigate( `/student/exams/${ExamStore.getExam.exam.exam_id}/attempt/${res.data.attempt}/${res.data.participation_id}` )
            }
        } catch ( e ) {
            console.log( e.response.data )
            if ( e.response.data ) toast.error( e.response.data )
            else toast.error( "Something went wrong! try again later" )
        }
    }

    const onImportChange = async ( e ) => {
        if ( e.target.files[0].name.includes( ".txt" ) ) {
            setQuestionsPreview( { status: "loading" } )
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const { questions, errors, preview, errorLines } = validateQuestionsImportFormat( fileReader.result, "txt" )
                setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "txt", plainData: fileReader.result, errors, questions, errorLines } )
                e.target.value = ""
            }
            fileReader.readAsText( e.target.files[0] )
        } else {
            try {
                const fileReader = new FileReader()
                fileReader.onload = async () => {
                    const formData = new FormData()
                    formData.append( 'file', e.target.files[0] )
                    try {
                        const res = await api.convertDocxToHtml( formData )
                        const { questions, errors, preview, errorLines } = validateQuestionsImportFormat( res.data, "docx" )
                        setQuestionsPreview( { status: "loaded", data: preview, file: e.target.files[0], type: "doc", plainData: convert( fileReader.result ), errors, questions, errorLines } )
                        e.target.value = ''
                    } catch ( err ) {
                        console.log( err )
                        toast( "Something went wrong! try again later" )
                    }
                }
                fileReader.readAsText( e.target.files[0] )
            } catch ( err ) {
                console.log( err )
            }
        }
        setImportDialogStatus( false )
    }

    const importQuestions = async ( questions ) => {
        setIsImporting( true )
        let done = 0
        for ( let i = 0; i < questions.length; i++ ) {
            const question = questions[i]
            try {
                await api.addQuestion( id, question )
                done += 1
                if ( i === questions.length - 1 ) {
                    toast( <span>Successfully imported <b>{done}</b> questions from {questionsPreview.file.name}</span> )
                    const questionsRes = await api.fetchQuestions( id )
                    setQuestions( questionsRes.data )
                }
            } catch ( err ) {
                // console.log();
            }
        }
        setIsImporting( false )
    }

    useEffect( () => {
        const getExam = async () => {
            try {
                if ( !await ExamStore.fetchExam( id ) )
                    setNotFound( true )
                if ( UserStore.getUser["user_role"] === 'FACULTY' || UserStore.getUser["user_role"] === 'HOD' || UserStore.getUser["user_role"] === 'PRINCIPAL' || UserStore.getUser["user_role"] === 'presenter' ) {
                    const questionsRes = await api.fetchQuestions( id )
                    setQuestions( questionsRes.data )
                }
                console.log( ExamStore.getExam.exam.exam_attachments )
                setBatches( ExamStore.getExam.batches )
                setInProgress( false )
            } catch ( e ) {
                console.log( e )
                if ( e.response && e.response.data ) {
                    if ( e.response.status === 404 && e.response.data === "Exam not found!" ) {
                        setNotFound( true )
                    } else
                        toast.error( e.response.data )
                }
                else toast.error( "Something went wrong!" )
                // navigate( '/exams' )
            }
        }
        getExam()
    }, [ExamStore, UserStore, id, navigate] )

    return (
        // <Layout>
        <Box height="100%" padding="20px" paddingTop="0" >
            <Dialog open={notFound}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant='h3'>404</Typography>
                        <Typography marginTop="10px">Exam you are looking for doen't exists!</Typography>
                        <Button onClick={() => navigate( UserStore.getUser['user_role'] === "STUDENT" ? "/student/exams" : "/faculty/exams", { replace: true } )} color='error'>Back to exams</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <QuestionImportFileInputDialog questionsPreview={questionsPreview} isImporting={isImporting} importDialogStatus={importDialogStatus} setImportDialogStatus={setImportDialogStatus} onImportChange={onImportChange} />
            <QuestionsPreviewDialog importQuestions={importQuestions} setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />
            <ConfirmDialog status={deleteDialogState} confirmAction={deleteExam} cancelAction={() => setDeleteDialogState( false )} actionName="Delete" message={<Typography>Do you really want to delete the exam <b>{ExamStore.getExam?.exam?.exam_name}</b> </Typography>} />
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to={UserStore.getUser['user_role'] === "STUDENT" ? "/student/exams" : "/faculty/exams"} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} {ExamStore.getExam.exam && ExamStore.getExam.exam.exam_name ? ExamStore.getExam.exam.exam_name : <CircularProgress sx={{ color: "textSecondary" }} size={12} />}</Typography>
            {!inProgress && ExamStore.getExam.exam && <>
                <Box bgcolor="white" borderRadius="5px" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)">
                    <Box style={{ minHeight: "200px", display: "flex", alignItems: "flex-end", padding: "20px", color: "white", borderRadius: "5px 5px 0 0" }} sx={{ background: flatColors[Math.floor( Math.random() * flatColors.length )] }} >
                        <Box>
                            <Typography variant='h4' >
                                {ExamStore.getExam.exam.exam_name}<small style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px", letterSpacing: "1px", color: "white", textTransform: "uppercase" }}>{ExamStore.getExam.exam.exam_status}</small>
                            </Typography>
                            <Typography gutterBottom variant='body2' color='white' >{`${formatDateTimeForDisplay( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time )} - ${formatDateTimeForDisplay( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time )}`}</Typography>
                            {ExamStore.getExam.exam.exam_duration !== "null" && ExamStore.getExam.exam.exam_duration !== null && <Typography variant='body2' color='white' > {`Duration: ${ExamStore.getExam.exam.exam_duration.split( ":" )[0]} Hours ${ExamStore.getExam.exam.exam_duration.split( ":" )[1]} Minutes`}</Typography>}
                            {( ExamStore.getExam.exam.exam_duration === "null" || ExamStore.getExam.exam.exam_duration === null ) && <Typography variant='body2' color='white' > {`Duration: ${calculateDurationBetween( { date: ExamStore.getExam.exam.exam_start_date, time: ExamStore.getExam.exam.exam_start_time }, { date: ExamStore.getExam.exam.exam_end_date, time: ExamStore.getExam.exam.exam_end_time } )}`}</Typography>}
                            <Typography variant='body2' color='white' >Maximum Attempts: {ExamStore.getExam.exam.maximum_attempts}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px", "@media(max-width:800px)": { flexDirection: "column", alignItems: "flex-start", flexWrap: "wrap" } }} padding="20px 10px" paddingBottom={UserStore.getUser["user_role"] !== 'STUDENT' ? "0" : "20px"}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
                            {UserStore.getUser["user_role"] === 'STUDENT' && <Box display="flex" gap="10px" title={new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() ? "Exam already ended" : ""}>
                                <Button disableElevation variant='contained' onClick={attempt} disabled={new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date()}>
                                    Attempt test
                                </Button>
                                {new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() && <Button disableElevation sx={{ "&:hover": { color: "white !important" } }} onClick={() => { navigate( '/student/scores' ); setCurrentLoc( `/student/scores` ) }} variant='contained'>
                                    View scores
                                </Button>}
                            </Box>}
                            {UserStore.getUser["user_role"] !== 'STUDENT' && <Box flexWrap="wrap" display="flex" gap="10px" alignItems="center">
                                <Button disableElevation color='primary' type="button" disabled={( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || isImporting || ExamStore.getExam.exam.exam_status === 'Published' )} startIcon={<GetAppIcon />} onClick={() => setImportDialogStatus( true )} variant='contained'>Import Questions</Button>
                                <Button disableElevation disabled={( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' )} onClick={() => navigate( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/addquestions` )} sx={{ "&:hover": { color: "white" } }} variant='contained' startIcon={<AddCircleOutlineIcon />}>Add questions </Button>
                                <Button disableElevation disabled={( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' )} sx={{ "&:hover": { color: "white" } }} title="Edit ExamStore.getExam.exam" variant='contained' startIcon={<EditIcon />} onClick={() => navigate( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/edit` )}> Edit</Button>
                                <Button disableElevation startIcon={<InfoIcon />} onClick={() => { navigate( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/participation` ); setCurrentLoc( `/faculty/participations` ) }} sx={{ "&:hover": { color: "white" } }} variant='contained'>Details</Button>
                                {ExamStore.getExam.exam.exam_status.toLowerCase() !== "published" &&
                                    <Button startIcon={<PublishIcon />} disableElevation variant="contained" onClick={publish} color='secondary'>Publish</Button>}
                                <Button disableElevation variant='contained' startIcon={<DeleteForeverIcon />} onClick={() => setDeleteDialogState( true )} color='error'>Delete </Button>
                            </Box>}
                        </Box>
                    </Box>
                    {UserStore.getUser["user_role"] !== 'STUDENT' && <Box>
                        <i style={{ fontSize: "14px", display: "block", margin: "10px" }} >To know about the import file format <Link to='https://docs.moodle.org/401/en/Aiken_format' target="_blank" style={{ color: "purple", fontWeight: "bold", cursor: "pointer", textDecoration: "underline !important" }} >Aiken click here.</Link> </i>
                    </Box>}
                    <Box padding="0 20px 20px 20px">
                        <Divider sx={{ marginBottom: "10px", marginTop: "10px", background: "#273679", height: "1px" }} />
                        <p style={{ textAlign: "justify", fontSize: "14px" }}>
                            {ExamStore.getExam.exam.exam_description}
                        </p>
                        <Typography variant='h6'>Topics</Typography>
                        <Box sx={{ padding: "10px 0", display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="5px">
                            {ExamStore.getExam.exam.exam_topics && ExamStore.getExam.exam.exam_topics.map( ( topic, index ) => (
                                <Chip key={topic} label={topic} sx={{ background: chipColors[index % flatColors.length], color: "white" }} />
                            ) )}
                        </Box>
                        {UserStore.getUser["user_role"] !== 'STUDENT' && <Box marginY="10px">
                            <Typography marginBottom="5px" variant='h6'>Exam for batch(es):</Typography>
                            <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                {batches && batches.map( ( batch, index ) => (
                                    <Chip key={batch} label={batch} sx={{ background: chipColors[index % flatColors.length], color: "white" }} />
                                ) )}
                                {( !batches || batches.length === 0 ) && <Typography variant='body2' color="textSecondary">Exam is not yet assigned to batches!</Typography>}
                            </Box>
                        </Box>}
                        <Typography variant='h6'>Attachments<AttachmentIcon sx={{ margin: "-2px 0 0 5px" }} fontSize="small" /> </Typography>
                        <Box sx={{ padding: "10px 0", display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="10px">
                            {ExamStore.getExam.exam.exam_attachments && ExamStore.getExam.exam.exam_attachments.map( ( attachment ) => (
                                <Chip href={`${ATTACHMENTS_BASE_URL}${attachment}`} clickable deleteIcon={<DownloadIcon />} onDelete={e => window.open( `${ATTACHMENTS_BASE_URL}${attachment}` )} sx={{ fontWeight: "bold", fontSize: "12px", padding: "5px", border: "1px solid grey", marginRight: "5px", display: "flex" }} label={attachment.split( "exam/" )[1]} key={attachment} />
                            ) )}
                            {!ExamStore.getExam.exam.exam_attachments &&
                                <Typography variant='body2' color="textSecondary" mt={-1}>No attachments provided!</Typography>
                            }
                        </Box>
                        {UserStore.getUser["user_role"] !== 'STUDENT' && <Box>
                            <Box display="flex" justifyContent="space-between" marginTop="10px" flexWrap="wrap" gap="10px">
                                <Typography variant='h6'>Exam questions</Typography>
                                <Box display="flex" gap="20px">
                                    <IconButton onClick={async () => {
                                        const questionsRes = await api.fetchQuestions( id )
                                        setQuestions( questionsRes.data )
                                    }} size="small" color='primary'>
                                        <RefreshIcon sx={{ fontSize: "20px" }} />
                                    </IconButton>
                                    {!( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' ) && questions && questions.length > 0 && <Button onClick={() => navigate( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/questions` )} sx={{ fontSize: "14px", textTransform: "capitalize" }} startIcon={<Edit sx={{ fontSize: "16px !important" }} />} >Edit questions</Button>}
                                </Box>
                            </Box>
                            {questions && questions.length > 0 && <Box display="flex" marginTop="10px" flexDirection="column" gap="10px">
                                {questions.map( ( question, index ) => (
                                    <Box sx={{ display: "flex", alignItems: "center", padding: "20px", background: "white", boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)", gap: "10px", fontSize: "14px", borderRadius: "5px", "& p": { margin: "0 " } }} key={question.question.question_id}>
                                        <span style={{ display: "flex", borderRadius: "50%", alignItems: "center", justifyContent: "center", minWidth: "30px", maxWidth: "30px", minHeight: "30px", maxHeight: "30px", background: "rgb(48,48,48)", color: "white" }}>{index + 1}</span>
                                        <Box>
                                            <Box>
                                                {question.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={question.question.question_text.trim().split( "LATEX-" )[1]} id='static-latex' style={{ padding: "0px", fontFamily: "'Rubik','Roboto', sans-serif !important" }}> </EditableMathField> : parse( question.question.question_text.trim() )}
                                            </Box>
                                            <Box marginTop="5px">
                                                <Chip size='small' color='secondary' sx={{ marginLeft: "5px" }} label={question.question.question_type}></Chip>
                                                {question.question.question_type === 'MCQ' && <Chip size='small' color='secondary' sx={{ marginLeft: "5px" }} label={question.question.question_answer_selection}></Chip>}
                                            </Box>
                                        </Box>
                                    </Box>
                                ) )}
                            </Box>}
                            {( !questions || questions.length === 0 ) && <Typography color="textSecondary" variant='body2'>Questions not yet added!</Typography>}
                        </Box>
                        }
                    </Box>
                </Box>
            </>
            }
            {inProgress &&
                <Typography height="100%" borderRadius="5px" padding="20px" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)" variant="h6" bgcolor="white" color="textSecondary"><CircularProgress size={20} sx={{ marginRight: "10px" }} />Loading...  </Typography>
            }
        </Box>
        // </Layout >
    )
} )

export default Exam