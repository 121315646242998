import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, Typography } from '@mui/material'
import React, { useRef } from 'react'
import ResumeTemplatesList from '../../../components/user/resume-builder-components/ResumeTemplatesList'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ResumeTemplate from '../../../components/user/resume-builder-components/ResumeTemplate'
import { Download, ViewComfy } from '@mui/icons-material'
import { Icons } from '../../../utils/utilities'
import { useReactToPrint } from 'react-to-print'
// import generatePDF, { Resolution } from 'react-to-pdf'
// import ReactToPrint, { useReactToPrint } from 'react-to-print'

// const options = {
//     filename: 'Resume.pdf',
//     method: "open",
//     resolution: Resolution.HIGH
// }

const ResumePreview = () => {
    const [loading, setLoading] = useState( true )
    const [allRequiredInfoAvailable, setAllRequiredInfoAvailable] = useState( false )
    const ResumeBuilderStore = useResumeBuilderStore()
    const [selectedTemplate, setSelectedTemplate] = useState( null )
    const [templatesDialogState, setTemplatesDialogState] = useState( false )
    const [downloading, setDownloading] = useState( false )

    const resumeRef = useRef()
    const navigate = useNavigate()
    const handlePrint = useReactToPrint( {
        content: () => resumeRef.current,
    } )

    useEffect( () => {
        const getData = async () => {
            if ( await ResumeBuilderStore.fetchStudentResumeInfo() && await ResumeBuilderStore.fetchStudentForm() && await ResumeBuilderStore.fetchTemplates() ) {
                setAllRequiredInfoAvailable( true )
                setSelectedTemplate( ResumeBuilderStore.getTemplates[0] )
            } else {
                setAllRequiredInfoAvailable( false )
            }
            setLoading( false )
        }
        getData()
    }, [ResumeBuilderStore] )
    return (
        <Box display="flex" alignItems="stretch" height="100%">
            {!templatesDialogState && ResumeBuilderStore.getTemplates.length > 1 && <Fab onClick={() => setTemplatesDialogState( true )} variant='extended' sx={{ textTransform: "capitalize", position: "fixed", bottom: "20px", right: "20px" }}>
                <ViewComfy sx={{ mr: 1 }} />
                Templates
            </Fab>}


            <Dialog PaperProps={{ sx: { minWidth: "250px" } }} onClose={() => setTemplatesDialogState( false )} open={templatesDialogState}>
                <DialogTitle><Typography sx={{ textOrientation: "mixed" }} fontSize="16px">Templates</Typography></DialogTitle>
                <DialogContent>
                    <ResumeTemplatesList setTemplatesDialogState={setTemplatesDialogState} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} templates={ResumeBuilderStore.getTemplates} />
                </DialogContent>
            </Dialog>
            <Box padding="0 40px" margin="0 auto">
                <Box>
                    {allRequiredInfoAvailable && <Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Button size="small" sx={{ textTransform: "capitalize", color: "GrayText" }} onClick={() => navigate( `/student/resume-builder` )} startIcon={Icons.ChevronLeftIcon}>Back</Button>
                            {/* <ReactToPrint
                                content={resumeRef}
                                trigger={
                                    <Button
                                                onClick={
                                                async () => {
                                            setDownloading( true )
                                            await generatePDF( resumeRef, options )
                                            setDownloading( false )
                                        }}
                                        color='secondary' disabled={downloading} startIcon={downloading ? <CircularProgress size={14} /> : <Download />} size='small' sx={{ textTransform: "capitalize" }}>{downloading ? "Preparing your resume..." : "Save this template"}</Button>
                                } /> */}
                            <Button
                                onClick={
                                    // async () => {
                                    //     setDownloading( true )
                                    //     await generatePDF( resumeRef, options )
                                    //     setDownloading( false )
                                    // }
                                    async ( e ) => {
                                        setDownloading( true )
                                        await handlePrint( e )
                                        setDownloading( false )
                                    }
                                }
                                color='secondary' disabled={downloading} startIcon={downloading ? <CircularProgress size={14} /> : <Download />} size='small' sx={{ textTransform: "capitalize" }}>{downloading ? "Preparing your resume..." : "Save this template"}</Button>
                        </Box>
                        <ResumeTemplate ref={resumeRef} loading={loading} layout={selectedTemplate.template_json} data={ResumeBuilderStore.getStudentResumeInfo.resume_data} />
                    </Box>}
                    {!loading && !allRequiredInfoAvailable && <Box textAlign="center" padding="20px" bgcolor="#eee">
                        <Typography color="error" borderRadius="5px" variant='subtitle2'>Please make sure you first fill your details! If you have already filled your details then the templates are not available for your institute yet. Please wait for templates...</Typography>
                        <Button variant='outlined' sx={{ marginTop: "20px" }}>
                            <Link to="/student/resume-builder">Fill your details now</Link>
                        </Button>
                    </Box>}
                </Box>
            </Box>
        </Box>
    )
}

export default ResumePreview
