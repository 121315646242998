import { Avatar, Box, Typography, IconButton, Menu, MenuItem, ListItemIcon, AppBar, useTheme, Divider, Tooltip } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import { useEffect, useState } from "react"
import { useUserStore } from "../../store/user.store"
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuthStore } from "../../store/auth.store"
import aliveTextLogo from '../../assets/alivelogo.png'
import { useLayoutContext } from "../../contexts/layout.context"
import { erpDashboardLink } from "../../utils/utilities"
import api from "../../service/api"
import { useNavigate } from "react-router-dom"

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState( null )
    const [profile, setProfile] = useState( null )
    const UserStore = useUserStore()
    const AuthStore = useAuthStore()
    const open = Boolean( anchorEl )
    const theme = useTheme()
    const navigate = useNavigate()

    const { isSmallScreen, setSidedrawerState, sidedrawerState, setCurrentLoc } = useLayoutContext()

    const logout = async () => {
        await AuthStore.doLogout()
        window.location.replace( erpDashboardLink )
    }

    const navigateHome = () => {
        navigate( '/student/home' )
        setCurrentLoc( '/student/home' )
    }

    useEffect( () => {
        const getProfileImage = async () => {
            if ( UserStore.getUser?.image_path && UserStore.getUser?.image_path?.trim() !== "" ) {
                try {
                    const { data: image } = await api.fetchProfileImage( UserStore.getUser?.image_path )
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        setProfile( fileReader.result )
                    }
                    fileReader.readAsDataURL( image )
                } catch ( err ) {

                }
            }
        }
        getProfileImage()
    }, [UserStore] )

    return (
        <AppBar id="appbar" elevation={0} sx={{ borderBottom: "#26272988 2px solid", zIndex: theme.zIndex.drawer + 1, background: "white", transition: "0.2s ease-out", position: "absolute", color: "black", width: "100%", height: "fit-content" }}>
            <Box alignItems="center" justifyContent="space-between" padding="10px" display="flex" maxWidth="100%" id="app-bar" sx={{ transition: "0.2s ease-out", '@media(max-width:1100px)': { padding: "10px" }, paddingLeft: "20px" }}>
                <Box display="flex" alignItems="center">
                    <IconButton onClick={() => setSidedrawerState( !sidedrawerState )} sx={{ marginRight: "10px", marginBottom: "-5px", color: "#262729", display: !isSmallScreen ? "none" : "block" }}>
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ cursor: "pointer" }} onClick={navigateHome} display="flex" alignItems="center" gap="20px">
                        <img src={aliveTextLogo} width="30px" alt="alive-logo" />
                        <Box>
                            <Typography variant='h6' margin="0" fontSize={{ sm: "16px", xs: "16px" }}>Alive</Typography>
                            <Typography variant='subtitle2' marginTop="-2px" fontWeight="400" fontSize="12px">Digital Classrooms</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box paddingRight="20px" display="flex" alignItems="center" gap="10px">
                    <Tooltip placement="bottom" title={UserStore.getUser["user_name"] ? UserStore.getUser["user_id"] : ""}>
                        <IconButton onClick={( e ) => setAnchorEl( e.currentTarget )}>
                            <Avatar imgProps={{ sx: { objectFit: "contain" } }} src={profile ? profile : undefined} sx={{ height: "32px", width: "32px" }}>{UserStore.getUser["user_name"] ? UserStore.getUser["user_name"][0] : ""}</Avatar>
                        </IconButton>
                    </Tooltip>
                    <Box display={{ sm: "block", xs: "none" }}>
                        <Typography color="GrayText" fontWeight="500" textAlign="right" fontSize="14px" textTransform="capitalize">{UserStore.getUser["user_name"] ? UserStore.getUser["user_name"].split( " " )[0] : ""}</Typography>
                    </Box>
                    <Menu slotProps={{ paper: { sx: { minWidth: "170px" } } }} sx={{ maxWidth: "300px" }} onClose={e => { setAnchorEl( null ) }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} anchorEl={anchorEl} open={open}>
                        <Box paddingBottom="10px" display="block">
                            <Typography fontSize="14px" textAlign="center" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["user_name"] ? UserStore.getUser["user_name"] : ""}</Typography>
                            <Typography fontSize="12px" textAlign="center" color="GrayText" textTransform="capitalize">{UserStore.getUser["user_id"] ? UserStore.getUser["user_id"] : ""}</Typography>
                            <Typography color="GrayText" fontSize="10px" textAlign="center" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["user_role"] ? UserStore.getUser["user_role"] : ""}</Typography>
                        </Box>
                        <Divider />
                        <MenuItem dense sx={{ color: "error.dark", justifyContent: "center", marginTop: "5px", display: "flex" }} onClick={logout}>
                            <ListItemIcon >
                                <LogoutIcon color="error" fontSize="small" />
                            </ListItemIcon>
                            Back To ERP
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
        </AppBar>
    )
}

export default Navbar
