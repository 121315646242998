import { Box, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography } from '@mui/material'
import React from 'react'
import addFileImg from '../../assets/add-file-img.svg'
import { Icons } from '../../utils/utilities'

const QuestionImportFileInputDialog = ( { importDialogStatus, questionsPreview, setImportDialogStatus, isImporting, onImportChange } ) => {
    const handleDragEnter = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.add( 'drag' )
        } catch ( err ) { }
    }

    const handleDragLeave = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.remove( 'drag' )
        } catch ( err ) { }
    }

    const handleDrop = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.remove( 'drag' )
        } catch ( err ) { }
    }

    return (
        <Dialog open={importDialogStatus && !questionsPreview} PaperProps={{ sx: { width: "75vw", maxWidth: "600px" } }}>
            <DialogTitle sx={{ display: "flex", fontSize: "16px", justifyContent: "space-between", alignItems: "center" }}>Upload the question file
                <IconButton size='small' onClick={() => setImportDialogStatus( false )} color='error' sx={{ textTransform: "capitalize" }}>{Icons.CloseIcon}</IconButton>
            </DialogTitle>
            <DialogContent>
                <Box id="file-upload-container" border="3px rgba(0,0,0,0.3) dashed" height="200px" borderRadius="10px" position="relative">
                    <Box display="flex" justifyContent="center" gap="10px" flexDirection="column" height="100%" alignItems="center">
                        <input onDrop={handleDrop} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} accept="text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document" disabled={isImporting} type="file" onChange={onImportChange} style={{ position: "absolute", cursor: "pointer", opacity: "0", top: "0", left: "0", width: "100%", height: "100%" }} />
                        <img id='file-img' width="60" height="60" src={addFileImg} alt="document" />
                        <Typography variant='subtitle2'>Drag and drop your file here, or <Typography fontWeight="bold" display="inline-block" sx={{ color: "customThemeColor.main" }}>Browse</Typography> </Typography>
                        <Typography fontSize="14px" fontWeight="500" color="textSecondary">Supports: <i>.docx and .txt</i> </Typography>
                    </Box>
                </Box>
                <i style={{ fontSize: "14px", display: "block", margin: "10px 0" }} >To know about the import file format <Link to='https://docs.moodle.org/401/en/Aiken_format' target="_blank" style={{ color: "purple", fontWeight: "bold", cursor: "pointer", textDecoration: "underline !important" }} >click here.</Link> </i>
            </DialogContent>
        </Dialog>
    )
}

export default QuestionImportFileInputDialog
