import { useOrganizationStore } from '../../store/organization.store'
import { useState, useEffect } from 'react'
import { Box, FormControl, MenuItem, Select, FormHelperText, Typography, CircularProgress, InputLabel } from '@mui/material'
import PropTypes from 'prop-types'

const SelectInstitute = ( props ) => {
    const OrganizationStore = useOrganizationStore()

    const [instituteCode, setInstituteCode] = useState( '0' )
    const [isLoading, setIsLoading] = useState( true )
    const { onSelect } = props

    const handleChange = ( event ) => {
        setInstituteCode( event.target.value )
        onSelect( event.target.value )
    }

    useEffect( () => {
        const getOrganizations = async () => {
            await OrganizationStore.fetchOrganization()
            setIsLoading( false )
        }
        getOrganizations()
    }, [OrganizationStore] )

    return (
        <Box sx={{ padding: "0 15px", display: "flex", flexGrow: 1, justifyContent: "center" }}>
            <FormControl sx={{ marginBottom: "20px" }} fullWidth margin="normal">
                {props.variant && <InputLabel sx={{ fontSize: "16px" }}>Select Institute</InputLabel>}
                <Select disabled={isLoading} onChange={handleChange} sx={{ fontSize: "14px" }} variant={props.variant ? props.variant : 'standard'} value={instituteCode} label="Select Institute---">
                    <MenuItem sx={{ fontSize: "14px" }} value="0">{isLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                    {
                        OrganizationStore.getOrganization.map( ( obj, i ) => {
                            return <MenuItem sx={{ fontSize: "12px" }} value={obj.school_name_short} key={'key-' + i}> {obj.school_name} </MenuItem>
                        } )
                    }
                </Select>
                <FormHelperText sx={{ marginLeft: "0" }}>Select the instituteCode to check analytics...</FormHelperText>
            </FormControl>
        </Box>
    )
}

SelectInstitute.propTypes = {
    onSelect: PropTypes.func.isRequired,
}

export default SelectInstitute