import React from 'react'
import { observable, action, toJS, computed, makeObservable } from 'mobx'
import api from '../service/api'
import AuthStore from './auth.store'

class UserStore {

    user // Logged In Json Info
    userAccounts
    userDebug

    constructor () {
        this.user = { "user_name": "", "user_id": "", "user_email": "", "user_org_code": "", "user_dept_code": "", "user_role": "", "user_auditor": false }
        this.api = api
        this.userAccounts = []
        this.userDebug = {}
        this.userSubjects = []
        //this.fetchUser();
        makeObservable( this, {
            user: observable,
            userAccounts: observable,
            userDebug: observable,
            userSubjects: observable,
            getUser: computed,
            getUserSubjects: computed,
            getUserDebug: computed,
            setUser: action,
            setERPSessionState: action,
            setUserDebug: action,
            getUserAccounts: computed,
            setUserAccounts: action,
            fetchUser: action,
            fetchUsers: action,
            capitalizeWords: action
        } )
    }
    capitalizeWords = ( str ) => {
        return str.replace( /\w\S*/g, function ( txt ) { return txt.charAt( 0 ).toUpperCase() + txt.substr( 1 ).toLowerCase() } )
    }


    fetchUser = async ( options = {} ) => {
        const { userType } = options
        //console.log(AuthStore.getToken)
        if ( AuthStore.getToken === null || AuthStore.isTokenExpired() ) {
            console.log( "Session Do not exist!" )
            return { unauth: true, message: "Session does not exists!" }
        } else {
            try {
                let res
                if ( userType === 'candidate' )
                    res = await this.api.admissionExams.getUser()
                else
                    res = await this.api.getUser()
                console.log( "USER RES", res )

                if ( res["data"] && res["data"]["status"] ) {
                    if ( res["data"]["data"]["user_source"] === "erp" && res["data"]["data"]["session_data"] &&
                        (
                            res["data"]["data"]["session_data"]["lms_role"] === "FACULTY"
                            || res["data"]["data"]["session_data"]["lms_role"] === "HOD"
                            || res["data"]["data"]["session_data"]["lms_role"] === "PRINCIPAL"
                        ) ) {
                        let erp_data = {
                            "user_name": this.capitalizeWords( res["data"]["data"]["session_data"]["employee_name"] ),
                            "user_id": res["data"]["data"]["session_data"]["user_id"],
                            "user_email": "",
                            "user_org_code": res["data"]["data"]["session_data"]["institute_name_short"], //institute_name_short
                            "user_dept_code": res["data"]["data"]["session_data"]["branch_name"],
                            "user_role": res["data"]["data"]["user_role"],
                            "user_status": "active",
                            "user_auditor": res["data"]["data"]["session_data"]["is_auditor"],
                            "image_path": res["data"]["data"]["session_data"]["image_path"]
                        }
                        this.setUser( res["data"]["data"] ? erp_data : { "user_name": "", "user_email": "", "user_org_code": "", "user_dept_code": "" } )
                    } else if ( res["data"]["data"]["user_source"] === "erp" && res["data"]["data"]["session_data"] && res["data"]["data"]["session_data"]["lms_role"] === "STUDENT" ) {
                        let erp_data = {
                            "user_name": this.capitalizeWords( res["data"]["data"]["session_data"]["student_name"] ),
                            "user_id": res["data"]["data"]["session_data"]["auid"],
                            "user_email": "",
                            "user_auid": res["data"]["data"]["session_data"]["auid"] ? res["data"]["data"]["session_data"]["auid"] : '',
                            "user_org_code": res["data"]["data"]["session_data"]["institute_name_short"], //institute_name_short
                            "user_dept_code": res["data"]["data"]["session_data"]["branch_name"],
                            "user_role": res["data"]["data"]["user_role"],
                            "user_eligible_status": res["data"]["data"]["session_data"]["eligible_status"],
                            "user_due_status": res["data"]["data"]["session_data"]["due_status"],
                            "image_path": res["data"]["data"]["session_data"]["image_path"],
                            "user_status": "active"
                        }
                        this.setUser( res["data"]["data"] ? erp_data : { "user_name": "", "user_email": "", "user_org_code": "", "user_dept_code": "" } )
                    } else if ( res["data"]["data"]["session_data"] && res["data"]["data"]["session_data"]["user_source"] === "erp" && res["data"]["data"]["session_data"]["user_role"] === "CANDIDATE" ) {
                        let erp_data = {
                            "user_name": this.capitalizeWords( res["data"]["data"]["session_data"]["candidate_name"] ),
                            "user_role": "CANDIDATE",
                            "user_id": res["data"]["data"]["session_data"]["pinfl"],
                            "application_id": res["data"]["data"]["session_data"]["applicationNo"]
                        }
                        this.setUser( res["data"]["data"] ? erp_data : { "user_name": "", "user_email": "", "user_org_code": "", "user_dept_code": "" } )
                    } else if ( res["data"]["data"]["user_source"] === "standalone" ) {
                        this.setUser( res["data"]["data"] ? res["data"]["data"] : { "user_name": "", "user_email": "", "user_org_code": "", "user_dept_code": "" } )
                    }
                }
                return { success: true, message: "User fetched successfully!" }
            } catch ( err ) {
                console.log( err )
                if ( err.response )
                    return { netError: false, success: false, message: err?.message }
                return { netError: true, success: false, message: "User fetched successfully!" }

            }
        }

    }

    setERPSessionState = ( state ) => {
        AuthStore.setERPSessionState( state )
    }

    fetchUserSubjects = async () => {
        try {
            const subjects = await this.api.getAllSubjects()
            if ( subjects.data.success ) {
                this.userSubjects = subjects.data.data
                return true
            } else {
                this.userSubjects = []
                return false
            }
        } catch ( err ) {
            console.log( err )
        }
    }

    fetchUsers = async () => {
        ///console.log(AuthStore.isTokenExpired())
        if ( AuthStore.getToken === null || AuthStore.isTokenExpired() ) {
            console.log( "Session Do not exist!" )
        } else {
            try {
                const res = await this.api.getUsers()
                //console.log(res)
                this.setUserAccounts( res["data"]["data"] )
            } catch ( err ) {
                console.log( err )
            }
        }

    }

    // get compute_func()
    get getUser() {
        return toJS( this.user )
    }

    setUser( user ) {
        this.user = user
    }

    get getUserAccounts() {
        return toJS( this.userAccounts )
    }

    get getUserSubjects() {
        return toJS( this.userSubjects )
    }

    setUserAccounts( userAccounts ) {
        this.userAccounts = userAccounts
    }

    createUserAccount = async ( payload ) => {
        try {
            const res = await this.api.createUser( payload )
            console.log( res )
        } catch ( error ) {

        }
    }

    updateUserStatus = async ( payload ) => {

        await this.api.updateUserStatus( payload )
        this.fetchUsers()

    }

    updatePassword = async ( payload ) => {
        try {
            const res = await this.api.updatePassword( payload )
            console.log( res["data"]["message"] )
        } catch ( err ) {

        }
    }

    get getUserDebug() {
        return toJS( this.userDebug )
    }

    setUserDebug = ( data ) => {
        this.userDebug = data
    }

    userDebugTimetable = async ( payload ) => {
        return await this.api.findUserDebugTimetable( payload )
        // .then((res)=>{
        //     console.log(`User Debug : `, res["data"])
        // }).catch((err)=>{

        // })
    }

}

// decorate(UserStore, {
//     user:observable,
//     userAccounts:observable,
//     userDebug:observable,
//     getUser:computed,
//     getUserDebug:computed,
//     setUser:action,
//     setUserDebug:action,
//     getUserAccounts:computed,
//     setUserAccounts:action,
//     fetchUser:action,
//     fetchUsers:action,
//     capitalizeWords:action
// })


const UserStoreInstance = new UserStore()

export default UserStoreInstance

const UserStoreContext = React.createContext()

export const UserStoreProvider = ( { children, store } ) => {
    return (
        <UserStoreContext.Provider value={store}>{children}</UserStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useUserStore = () => React.useContext( UserStoreContext )