import React from 'react'
import * as Yup from 'yup'
import { useState } from 'react'
import { Box, Button, Checkbox, Chip, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { toast } from 'react-toastify'
import { Icons } from '../../../utils/utilities'

const allEntityTypes = [
    { value: "string", label: "String" },
    { value: "number", label: "Number" },
    { value: "boolean", label: "Boolean" },
    { value: "array", label: "Array" },
    { value: "email", label: "E-mail" },
    { value: "url", label: "URL" },
    { value: "file", label: "File" },
    { value: "date", label: "Date" },
    { value: "dateorpresent", label: "Date or present" },
    { value: "linkwithlabel", label: "Label with link" },
    { value: "select", label: "Select" },
    { value: "cgpaorpercentage", label: "CGPA or Percentage" },
]

const AddNewEntity = ( { addDialogstate, setAddDialogState, entities } ) => {

    const [entityType, setEntityType] = useState( "string" )
    const [showLabel, setShowLabel] = useState( true )
    const [longText, setLongText] = useState( false )
    const [textfieldValue, setTextfieldValue] = useState( '' )
    const [optionValues, setOptionValues] = useState( [] )

    const ResumeBuilderStore = useResumeBuilderStore()

    const handleChange = ( e ) => {
        setTextfieldValue( e.target.value )
    }

    const removeItem = ( i ) => {
        let newValues = [...optionValues]
        newValues.splice( i, 1 )
        setOptionValues( newValues )
    }

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            e.preventDefault()
            let newValues = [...optionValues]
            newValues.push( e.target.value )
            setOptionValues( newValues )
            setTextfieldValue( '' )
        }
    }

    const validationSchema = Yup.object().shape( {
        entityName: Yup.string().required( "Template name is required!" ),
        entityDescription: Yup.string().required( "Description is required!" ),
        key: Yup.string().required( "Key is required!" ),
        entityLabel: Yup.string().required( "Entity label is required!" ),
    } )

    const initialValues = {
        entityName: '',
        entityDescription: '',
        entityLabel: '',
        key: '',
        entityType: 'string',
        linkLabel: ""
    }

    const addEntity = async ( { entityName, entityDescription, key, entityLabel, entityType, linkLabel }, { resetForm } ) => {
        if ( entityType === "select" || entityType === "selectandanswer" ) {
            if ( optionValues.length < 2 ) {
                toast( "Two or more options are required for this type of entity" )
            } else {
                if ( !entities.find( i => i.entity_detail.key === key.replace( /[^\w\d]/g, "_" ).toLowerCase() ) ) {
                    const payload = { entityName, entityDescription, entityDetail: { key: key.replace( /[^\w\d]/g, "_" ).toLowerCase(), label: entityLabel, label_show: showLabel, data_type: entityType, longText, link_label: linkLabel, children: optionValues } }
                    await ResumeBuilderStore.createEntity( payload )
                    await ResumeBuilderStore.fetchEntities()
                    setAddDialogState( false )
                    resetForm()
                    setEntityType( 'string' )
                } else {
                    toast( "Entity with that key already exists!" )
                }
            }
        }
        else {
            if ( !entities.find( i => i.entity_detail.key === key.replace( /[^\w\d]/g, "_" ).toLowerCase() ) ) {
                const payload = { entityName, entityDescription, entityDetail: { key: key.replace( /[^\w\d]/g, "_" ).toLowerCase(), label: entityLabel, label_show: showLabel, data_type: entityType, longText, link_label: linkLabel, children: [] } }
                await ResumeBuilderStore.createEntity( payload )
                await ResumeBuilderStore.fetchEntities()
                setAddDialogState( false )
                resetForm()
                setEntityType( 'string' )
            } else {
                toast( "Entity with that key already exists!" )
            }
        }
    }

    const closeDialog = () => {
        setAddDialogState( false )
        setShowLabel( true )
        setLongText( false )
        setEntityType( 'string' )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "1050px" } }} open={addDialogstate}>
            <DialogTitle>
                Add new resume entity
            </DialogTitle>
            <DialogContent>
                <Formik onSubmit={addEntity} validationSchema={validationSchema} initialValues={initialValues}>
                    {( { values, setFieldValue, resetForm } ) => (
                        <Form>
                            <FormControl sx={{ marginBottom: "20px", marginTop: "10px" }} fullWidth>
                                <Field as={TextField} name='entityName' label="Entity name" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityName' /></Typography>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                <Field as={TextField} multiline rows={3} name='entityDescription' label="Entity description" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityDescription' /></Typography>
                            </FormControl>
                            <Typography marginBottom="10px" variant='subtitle2' color="textSecondary">Entity details</Typography>
                            <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                <Field as={TextField} name='key' label="Entity Key" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="GrayText">{values.key.replace( /[^\w\d]/g, "_" ).toLowerCase()}</Typography>
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='key' /></Typography>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                <Field as={TextField} name='entityLabel' label="Entity label" type='text' />
                                <FormControlLabel control={<Checkbox size='small' checked={showLabel} onChange={() => setShowLabel( !showLabel )} />} label="Show Label" />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityLabel' /></Typography>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                <InputLabel>Entity type</InputLabel>
                                <Field as={Select} value={entityType} onChange={( e ) => { setEntityType( e.target.value ); setFieldValue( 'entityType', e.target.value ) }} name='entityType' label="Entity type" type='text' >
                                    {allEntityTypes.map( type => (
                                        <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                    ) )}
                                </Field>
                                {entityType === 'string' && <FormControlLabel control={<Checkbox size='small' checked={longText} onChange={() => setLongText( !longText )} />} label="Long text(paragraph)" />}
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityType' /></Typography>
                            </FormControl>
                            {entityType === 'linkwithlabel' &&
                                <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                    <Field as={TextField} name='linkLabel' label="Link label" type='text' />
                                </FormControl>
                            }
                            {( entityType === 'select' ) &&
                                <Box marginBottom="20px" padding="5px 0 0 5px" borderRadius="5px" border="1px solid #d3d3d3">
                                    <FormControl sx={{ flexGrow: 1 }} fullWidth>
                                        <TextField value={textfieldValue} onChange={handleChange} onKeyDown={handleKeyDown} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px", "@media(max-width:900px)": { width: "100%" } } }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder={`To add options, type and press 'Enter'`} variant="standard" type="text" />
                                    </FormControl>
                                    <Box padding="10px">
                                        <Typography variant='subtitle2' gutterBottom={optionValues.length !== 0} color="GrayText">{optionValues.length === 0 ? `No options Yet` : ``}</Typography>
                                        {optionValues.length > 0 && <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
                                            {optionValues.map( ( val, i ) => (
                                                <Chip key={i} label={val} size='large' deleteIcon={Icons.CloseIcon} onDelete={() => { removeItem( i ) }} sx={{ border: "1px solid #d3d3d3", fontSize: "14px", fontWeight: "600" }} />
                                            ) )}
                                        </Box>}
                                    </Box>
                                </Box>
                            }
                            <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                <Button type='submit' disableElevation variant='contained' sx={{ textTransform: "capitalize" }}>
                                    Add entity
                                </Button>
                                <Button onClick={() => { closeDialog(); resetForm() }} color="error" variant='outlined' sx={{ textTransform: "capitalize" }}>
                                    Cancel
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default AddNewEntity
