import React from 'react'
import ResumeTemplateBuilder from '../../../components/admin/resume-builder/ResumeTemplateBuilder'
import { Box, Paper, Typography } from '@mui/material'
import ResumeTemplateEditStore, { ResumeTemplateEditStoreProvider } from '../../../store/resumetemplateEdit.store'
import { useEffect } from 'react'
import { useUserStore } from '../../../store/user.store'
import warningImg from '../../../assets/warning.svg'
import { useState } from 'react'

const ResumeTemplate = () => {

    const [hasPermission, setHasPermission] = useState( true )
    const UserStore = useUserStore()

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        <Box overflow="auto" height="100%" width="100%" display="flex">
            {hasPermission && <ResumeTemplateEditStoreProvider store={ResumeTemplateEditStore}>
                <ResumeTemplateBuilder />
            </ResumeTemplateEditStoreProvider>}
            {
                !hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                    <img src={warningImg} width="40px" alt="No permission" />
                    <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
                </Paper>
            }
        </Box>
    )
}

export default ResumeTemplate
