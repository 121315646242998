import { Box, Button, FormControl, FormControlLabel, FormHelperText, InputLabel, OutlinedInput, Select, TextField, Typography, MenuItem, Checkbox, Card, CircularProgress, Paper, Dialog, DialogContent, Tooltip, IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { formatDateTimeForDB } from '../../utils/exam-utilities'
import { Link, useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import api from '../../service/api'
import sessionExpiredImg from '../../assets/warning.svg'
import { Icons, erpDashboardLink } from '../../utils/utilities'
import { ArrowBack } from '@mui/icons-material'

const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
const minutes = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]

export default function AddExam() {
    const initialDate = new Date().toISOString().split( 'T' )[0] + "T00:00"
    const supportedFormats = ["png", "jpg", "jpeg"]
    const attachmentsSupportedFormats = ["pdf", "docx", "doc", "xlsx"]
    // const [status, setStatus] = useState( 'Draft' )
    const [isCreatingExam, setIsCreatingExam] = useState( false )
    const [selectedBatches, setSelectedBatches] = useState( [] )
    const [selectedBatchesNames, setSelectedBatchesNames] = useState( [] )
    const [startTimeData, setStartTimeData] = useState( new Date() )
    const [endDateTime, setEndDateTime] = useState( new Date() )
    const [academicYear, setAcademicYear] = useState( "-1" )
    const [academicYears, setAcademicYears] = useState( [] )
    const [isFetchingBatches, setIsFetchingBatches] = useState( false )
    const [isOpenExam, setIsOpenExam] = useState( false )
    const [randomize, setRandomize] = useState( false )
    const [openExamInfo, setOpenExamInfo] = useState( false )
    const [randomizeInfo, setRandomizeInfo] = useState( false )
    const [durationHours, setDurationHours] = useState( "" )
    const [durationMinutes, setDurationMinutes] = useState( "" )
    const [batches, setBatches] = useState( {} )
    const [sections, setSections] = useState( {} )
    const [sessionExpired, setSessionExpired] = useState( false )
    // const [selectedBatches, setselectedBatches] = useState(initialState);

    const navigate = useNavigate()

    const initialValues = {
        title: "",
        description: "",
        topics: '',
        thumbnail: '',
        attachments: [],
        status: 'Draft',
        startDateTime: initialDate,
        endDateTime: initialDate,
        selectedBatches: '',
        duration: null,
        maximumAttempt: 1
    }

    // const setPreviewImg = ( file ) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL( file )
    //     reader.onload = () => {
    //         setPreviewUrl( reader.result )
    //     }
    // }

    const handleBatchChange = ( e ) => {
        if ( e.target.value ) {
            const batchesAndSections = e.target.value
            setSelectedBatches( batchesAndSections )
            let selectedBatchesAndSectionsNames = []
            batchesAndSections.forEach( item => {
                selectedBatchesAndSectionsNames.push( batches[item] ? batches[item] : sections[item] )
            } )
            setSelectedBatchesNames( selectedBatchesAndSectionsNames )
        }
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Exam title is required." ),
        description: Yup.string().required( "Exam description is required." ).min( 50, "Description as to be least 50 characters." ),
        topics: Yup.array().required( "Please provide the exam topics." ),
        thumbnail: Yup.mixed().nullable().notRequired().test( "FILE_FORMAT", "Unsupported file format, accepted formats: JPG,JPEG,PNG.", ( value ) => {
            if ( value )
                return supportedFormats.includes( value.name.split( "." ).pop().toLowerCase() )
            return true
        } ),
        startDateTime: Yup.date().required().min( new Date(), "Date is invalid" ),
        endDateTime: Yup.date().required().when( "startDateTime", ( startDate ) => {
            if ( startDate ) return Yup.date().min( startDate, "End date must be after start date" )
        } ),
        attachments: Yup.mixed().nullable().notRequired().test( "ATTACHMENTS_FILE_FORMAT", "Unsupported attachment format! accepted formats: PDF.", ( value ) => {
            if ( value ) {
                const files = Object.values( value )
                for ( let i = 0; i < files.length; i++ ) {
                    const file = files[i]
                    if ( !attachmentsSupportedFormats.includes( file.name.split( "." ).pop().toLowerCase() ) ) {
                        return false
                    }
                }
                return true
            }
            else return true
        } ),
        duration: Yup.string().nullable().test( "CHECK_DURATION", "Invalid time format hours: HH and minutes: MM", ( value ) => {
            if ( isOpenExam ) {
                if ( value && value !== '00:00' ) return true
                return false
            } else return true
        } ),
        maximumAttempt: Yup.number().required( "This field is required!" ).min( 1, "At least 1 attempt must be given" )
    } )

    const submit = async ( values, props ) => {
        // console.log( values )
        setIsCreatingExam( true )
        const formData = new FormData()
        formData.append( 'title', values.title )
        if ( values.thumbnail )
            formData.append( "thumbnail", values.thumbnail, values.thumbnail ? `${values.title}-thumb.${values.thumbnail.name.split( "." )[values.thumbnail.name.split( "." ).length - 1]}` : '' )
        formData.append( 'description', values.description )
        formData.append( 'topics', values.topics )
        formData.append( 'startDateTime', JSON.stringify( formatDateTimeForDB( values.startDateTime, 'en-IN', '-' ) ) )
        formData.append( 'endDateTime', JSON.stringify( formatDateTimeForDB( values.endDateTime, 'en-IN', '-' ) ) )
        formData.append( "status", values.status )
        formData.append( 'selectedBatches[]', values.selectedBatches )
        formData.append( 'duration', isOpenExam ? values.duration : null )
        formData.append( 'maximumAttempt', values.maximumAttempt )
        formData.append( "randomize", randomize )
        for ( let i = 0; i < values.attachments.length; i++ ) {
            formData.append( "attachments[]", values.attachments[i], `${values.title}-${Date.now()}${String( i + 1 )}.${values.attachments[i].name.split( "." )[values.attachments[i].name.split( "." ).length - 1]}` )
        }
        try {
            await api.createExam( formData )
            navigate( "/faculty/exams" )
            toast.success( "New exam created successfully!" )
        } catch ( e ) {
            console.log( e )
            toast.error( "Something went wrong! try again later" )
        } finally {
            setIsCreatingExam( false )
        }

    }

    const getBatches = async ( year = academicYear ) => {
        setIsFetchingBatches( true )
        try {
            const batches = await api.getAllBatch( year )
            if ( !batches.data.success && ( batches.data.unauth || batches.data.message === "Invalid Token" ) ) {
                setSessionExpired( true )
            }
            if ( ( batches.data.data && batches.data.data.batches && batches.data.data.batches.length > 0 ) ) {
                const batchesForSelect = {}
                batches.data.data.batches.forEach( batch => {
                    batchesForSelect[`${batch.batch_assignment_id}`] = batch.batch_name + "-" + batch.course_name_short + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name
                } )
                setBatches( batchesForSelect )
            } else {
                setBatches( {} )
            }
            if ( ( batches.data.data && batches.data.data.sections && batches.data.data.sections.length > 0 ) ) {
                const sectionsForSelect = {}
                batches.data.data.sections.forEach( section => {
                    sectionsForSelect[`${section.section_assignment_id}-${section.section_short_name}`] = section.course_branch_short_name + "-" + section.course_short_name + " Y" + section.current_year + " S" + section.current_sem + " (" + section.section_short_name + ")"
                } )
                setSections( sectionsForSelect )
            } else {
                setSections( {} )
            }
        } catch ( e ) {
            console.log( e )
            toast.error( "Something went wrong!" )
        } finally {
            setIsFetchingBatches( false )
        }
    }

    useEffect( () => {
        const getAcademicYears = async () => {
            try {
                const res = await api.getAllBatchAcademicYear()
                if ( res.data.success === false && res.data.message === "Invalid Token" ) {
                    setSessionExpired( true )
                    console.log( "Session Expired" )
                }
                setAcademicYears( res.data.data ? res.data.data : [] )
            } catch ( e ) {
                console.log( e )
                if ( e.message === "Network Error" )
                    navigate( '/networkerror' )
                else toast.error( "Something went wrong! try again later" )
            }
        }
        getAcademicYears()
    }, [navigate] )

    // const attachmentsRef = useRef()

    return (
        <Box padding="0 20px 20px 20px" >
            <Dialog sx={{}} onClose={() => setSessionExpired( true )} open={sessionExpired}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img width="150px" src={sessionExpiredImg} alt="Session Expired!" />
                        <Typography marginTop="10px">Session has expired! please login again</Typography>
                        <Button onClick={() => window.location.replace( erpDashboardLink )} color='error'>Login</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Typography fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to='/faculty/exams' className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} Create </Typography>
            <Paper sx={{ marginTop: "10px", bgcolor: "white", borderRadius: "5px" }}>
                <Box padding="20px" display="flex" alignItems="center" gap="10px" borderBottom="1px solid #d3d3d3">
                    <Tooltip placement='top' title="Back to assignments">
                        <IconButton onClick={() => navigate( '/faculty/exams' )} size='small'>
                            <ArrowBack fontSize='small' color='GrayText' />
                        </IconButton>
                    </Tooltip>
                    <Box>
                        <Typography variant='h5' >New exam</Typography>
                        <Typography color="textSecondary" variant='subtitle2' >Create exam by providing all required details</Typography>
                    </Box>
                </Box>
                <Box padding="0 20px 20px 20px">
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue } ) => (
                            <Form method='POST' id='add-form' autoComplete='off' style={{ height: "100%" }} encType="multipart/form-data">
                                <FormControl variant='filled' fullWidth>
                                    <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='title' margin="normal" label="Exam title*" />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", fontWeight: "bold" }}><span style={{ color: "#de1738", dispaly: "block" }}><ErrorMessage name='title' /></span></FormHelperText>
                                </FormControl>
                                <FormControl variant='outlined' fullWidth>
                                    <Field as={TextField} multiline rows={4} InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='description' margin="normal" label="Exam description*" />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", fontWeight: "bold", color: "#de1738" }}><ErrorMessage name='description' /></FormHelperText>
                                </FormControl>
                                <FormControl variant='outlined' fullWidth>
                                    <Field as={TextField} onChange={( e ) => { setFieldValue( 'topics', e.target.value.split( "," ) ) }}
                                        InputLabelProps={{ sx: { fontSize: "14px !important" } }}
                                        fullWidth
                                        name='topics'
                                        margin="normal"

                                        label="Topics*"
                                    />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", fontWeight: "bold" }}>Enter topics as comma separated values.<br />
                                        <span style={{ color: "#de1738" }}><ErrorMessage name='topics' /></span>
                                    </FormHelperText>
                                </FormControl>
                                <Box marginTop="20px" bgcolor="#eee" padding="20px" borderRadius="5px" display="flex" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} gap="20px" alignItems="center">
                                    <FormControl fullWidth>
                                        <InputLabel sx={{ fontSize: "14px" }} >Select year of academic batch</InputLabel>
                                        {academicYears && <Select sx={{ fontSize: "14px" }} inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "10px !important", height: "28px !important" } }} value={academicYear} onChange={( e ) => { setAcademicYear( e.target.value ); getBatches( e.target.value ) }} input={<OutlinedInput label="Select year of academic batch" />}>
                                            <MenuItem sx={{ fontSize: "14px" }} value="-1" disabled>{academicYears.length === 0 ? "No academic years!" : "Select academic year"}</MenuItem>
                                            {academicYears.length > 0 && academicYears.map( year => (
                                                <MenuItem sx={{ fontSize: "14px" }} key={year.ac_year_id} value={year.ac_year_id}>{year.ac_year}</MenuItem>
                                            ) )}
                                        </Select>}
                                    </FormControl>
                                    <FormControl sx={{ position: "relative" }} fullWidth>
                                        <InputLabel sx={{ fontSize: "14px" }} >Select student batch</InputLabel>
                                        <Select sx={{ fontSize: "14px" }} disabled={isFetchingBatches || academicYear === "-1"} name='selectedBatches' multiple value={selectedBatches} onChange={( e ) => { handleBatchChange( e ); setFieldValue( 'selectedBatches', typeof e.target.value === 'string' ? e.target.value.split( "," ) : e.target.value ) }} input={<OutlinedInput label="Select student batch" />}
                                            renderValue={( selected ) => (
                                                <Typography fontSize="14px" noWrap sx={{ margin: "5px" }}>
                                                    {selectedBatchesNames.length > 0 && selectedBatchesNames.join( ", " )}
                                                </Typography>
                                            )}>
                                            <Typography paddingX="15px" color="secondary">Batches</Typography>
                                            {batches && Object.keys( batches ).length > 0 && Object.keys( batches ).map( batch => (
                                                <MenuItem sx={{ fontSize: "14px" }} key={batch} value={batch}>{batches[batch]}</MenuItem>
                                            ) )}
                                            {( !batches || Object.keys( batches ).length === 0 ) && <MenuItem value={"No batches"} sx={{ fontSize: "14px", fontWeight: "bold" }} disabled>No batches for selected year!</MenuItem>}
                                            <hr />
                                            <Typography paddingX="15px" color="secondary">Sections</Typography>
                                            {sections && Object.keys( sections ).length > 0 && Object.keys( sections ).map( section => (
                                                <MenuItem sx={{ fontSize: "14px" }} key={section} value={section}>{sections[section]}</MenuItem>
                                            ) )}
                                            {( !sections || Object.keys( sections ).length === 0 ) && <MenuItem value={"No sections"} sx={{ fontSize: "14px", fontWeight: "bold" }} disabled>No sections for selected year!</MenuItem>}

                                        </Select>

                                        {isFetchingBatches && <Typography position="absolute" top="100%" marginLeft="5px" variant='body2' color="textSecondary"> <CircularProgress size={10} sx={{ marginRight: "5px" }} /> Loading batches...</Typography>}
                                    </FormControl>
                                    {!batches && <Typography marginTop="10px" variant='body2' color="textSecondary">No batches for selected academic year...</Typography>}
                                </Box>
                                {/* <FormControl margin='normal' variant="standard" sx={{ width: "100%" }}>
                                <FormLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>Exam related attachments(optional)</FormLabel>
                                <Box>
                                    <Box style={{ position: "relative" }}>
                                        <Button sx={{
                                            width: { lg: "246px", md: "246px", sm: "246px", xs: "100%" }, textTransform: "capitalize !important",
                                            border: "rgba(0,0,0,0.3) 1px solid",
                                            borderRadius: "5px",
                                            height: "53px",
                                            cursor: "pointer !important",
                                            transition: "none",
                                            "&:hover": { background: "white !important", border: "rgba(0,0,0,1) 1px solid" }
                                        }} onClick={() => attachmentsRef.current.click()} type='button' variant='primary' startIcon={<CloudUploadIcon />} color="primary">
                                            Upload attachments
                                        </Button>
                                        <input hidden accept='application/pdf' onChange={( e ) => { setFieldValue( 'attachments', e.target.files ) }} id='attachments' type="file" name="attachments[]" ref={attachmentsRef} multiple />
                                    </Box>
                                    {values.attachments.length > 0 && (
                                        <>
                                            <Typography variant='body2' marginY={"5px"}>Selected Attachments</Typography>
                                            <Box style={{ marginBottom: "10px", flexWrap: "wrap", display: "flex", justifyContent: "sapace-between", alignItems: "center", gap: "10px" }} id='attachments'>
                                                {Object.values( values.attachments ).map( ( attachment, index ) => (
                                                    <Chip sx={{ fontWeight: "bold", border: "1px solid black", padding: "5px" }} label={attachment.name} key={index} />
                                                ) )}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                                <FormHelperText sx={{ margin: "-10px 0 10px 2px", fontWeight: "bold" }}><span style={{ color: "#de1738" }}><ErrorMessage name="attachments" /></span></FormHelperText>
                            </FormControl> */}
                                <FormControl variant='filled' fullWidth sx={{ width: { lg: "246px", md: "246px", sm: "246px", xs: "100%" } }}>
                                    <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='maximumAttempt' margin="normal" label="Maximum attempts*" />
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", fontWeight: "bold" }}><span style={{ color: "#de1738", dispaly: "block" }}><ErrorMessage name='maximumAttempt' /></span></FormHelperText>
                                </FormControl>
                                <FormControl variant='outlined' sx={{ display: "flex" }}>
                                    <Box sx={{ display: "flex", gap: "10px", margin: "10px 0" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                sx={{ fontSize: "14px" }}
                                                label="Start date and time"
                                                minDateTime={dayjs( new Date() )}
                                                value={dayjs( startTimeData )}
                                                format="DD-MM-YYYY hh:mm A"
                                                onChange={( e ) => { if ( e.$d !== "Invalid Date" ) { setStartTimeData( e.$d ); setFieldValue( 'startDateTime', e.$d ) } }}
                                            // onAccept={( e ) => { console.log( e ); setFieldValue( 'startDateTime', e.$d ) }}
                                            // renderInput={( params ) => <TextField {...params} sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" } }} />}
                                            // slotProps={}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", fontWeight: "bold" }}>Enter start date and time.<br />
                                        <span style={{ color: "#de1738" }}><ErrorMessage name='startDateTime' /></span><br />
                                    </FormHelperText>

                                </FormControl>
                                <FormControl variant='outlined' sx={{ display: "flex" }}>
                                    <Box sx={{ display: "flex", gap: "10px", margin: "10px 0" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                sx={{ fontSize: "14px" }}
                                                label="End date and time"
                                                value={dayjs( endDateTime )}
                                                format="DD-MM-YYYY hh:mm A"
                                                onChange={( e ) => { setEndDateTime( e.$d ); setFieldValue( 'endDateTime', e.$d ) }}
                                            // onAccept={( e ) => { setFieldValue( 'endDateTime', e.$d ) }}
                                            // renderInput={( params ) => <TextField {...params} sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" } }} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <FormHelperText sx={{ margin: "-5px 0 0 2px", fontWeight: "bold" }}>Enter end date and time.<br />
                                        <span style={{ color: "#de1738" }}><ErrorMessage name='endDateTime' /></span>
                                    </FormHelperText>
                                </FormControl>
                                {<Box position="relative">
                                    {openExamInfo && <Card sx={{ width: "300px", borderRadius: "5px", padding: "20px", position: "absolute", bottom: "100%", background: "white", zIndex: "100" }} raised>
                                        <Typography textAlign="justify" variant="h6" color="secondary">Open Exams</Typography>
                                        <Typography textAlign="justify" variant='body2'>In open exams students can take exams whenever they want with in the specified interval of time.</Typography>
                                        <Button onClick={e => setOpenExamInfo( false )} variant='outlined' sx={{ textTransform: "capitalize", marginTop: "10px" }}>Okay</Button>
                                    </Card>}
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox value={isOpenExam} onChange={e => { setIsOpenExam( e.target.checked ) }} />} label="Is open exam?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setOpenExamInfo( true )} />
                                    </Box>
                                </Box>}

                                {isOpenExam && <FormControl variant='outlined' sx={{ display: "flex" }}>
                                    <Typography fontSize="14px" margin="5px 0 10px 0" gutterBottom>Exam duration</Typography>
                                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                        <FormControl fullWidth>
                                            <InputLabel sx={{ fontSize: "14px" }} >Hours</InputLabel>
                                            <Select inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "12px !important", height: "28px !important" } }} label="Hours" sx={{ width: { lg: "246px", md: "246px", sm: "246px", xs: "100%" } }} onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours}>
                                                {hours.map( hour => (
                                                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                                ) )}
                                            </Select>
                                            {/* <TextField type="number" sx={{ width: "246px" }} InputLabelProps={{ sx:{fontSize:"14px !important"} }} label="Hours" onWheel={e => e.target.blur()} onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours} /> */}
                                        </FormControl >
                                        <FormControl fullWidth>
                                            <InputLabel sx={{ fontSize: "14px" }} >Minutes</InputLabel>
                                            <Select inputProps={{ sx: { dispaly: "flex !important", alignItems: "center !important", padding: "12px !important", height: "28px !important" } }} label="Minutes" sx={{ width: { lg: "246px", md: "246px", sm: "246px", xs: "100%" } }} onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes}>
                                                {minutes.map( min => (
                                                    <MenuItem key={min} value={min}>{min}</MenuItem>
                                                ) )}
                                            </Select>
                                            {/* <TextField type="number" sx={{ width: "214px" }} InputLabelProps={{ sx:{fontSize:"14px !important"} }} label="Minutes" onWheel={e => e.target.blur()} onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes} /> */}
                                        </FormControl>
                                    </Box>
                                    <FormHelperText sx={{ margin: "5px 0 0 2px", fontWeight: "bold", color: "black" }}>Exam duration: {`${durationHours ? durationHours : 0} hours ${durationMinutes ? durationMinutes : 0} minutes`}<br />
                                        <span style={{ color: "#de1738" }}><ErrorMessage name='duration' /></span>
                                    </FormHelperText>
                                </FormControl>}
                                {<Box position="relative">
                                    {randomizeInfo && <Card sx={{ width: "300px", borderRadius: "5px", padding: "20px", position: "absolute", bottom: "100%", background: "white", zIndex: "100" }} raised>
                                        <Typography textAlign="justify" variant="h6" color="secondary">Randamize questions and options?</Typography>
                                        <Typography textAlign="justify" variant='body2'>If selected the questions and their options will be in random order for each individual taking the exam.</Typography>
                                        <Button onClick={e => setRandomizeInfo( false )} variant='outlined' sx={{ textTransform: "capitalize", marginTop: "10px" }}>Okay</Button>
                                    </Card>}
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox value={randomize} onChange={e => { setRandomize( e.target.checked ) }} />} label="Randamize questions and options?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setRandomizeInfo( true )} />
                                    </Box>
                                </Box>}
                                <Box display="flex" gap="10px" alignItems="center">
                                    <Button disableElevation disabled={isCreatingExam} type='submit' variant='contained' sx={{ marginTop: "20px", textTransform: "capitalize" }}>{isCreatingExam ? "Creating exam..." : "Create Exam"}</Button>
                                    <Button disabled={isCreatingExam} type='button' onClick={() => navigate( `/faculty/exams` )} variant='outlined' color="error" sx={{ marginTop: "20px", textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Paper>
        </Box>
    )
}

