import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import * as Yup from 'yup'
import api from '../../service/api'
import { toast } from 'react-toastify'

const defaultPermissions = {
    live_class_audit: false,
    study_material_audit: false,
    attendance_audit: false,
    feedback: false,
    api_settings: false,
    debug_user: false,
    analytics: false,
    recordings: false,
    exams: false,
    exam_audit: false,
    online_course: false,
    resume_builder: false
}

const AddEditAdmin = ( { setMode, mode, user } ) => {
    const [permissions, setPermissions] = useState( mode === "add" ? defaultPermissions : user.user_permissions || user.permissions )
    const [isIndeterminate, setIsIndeterminate] = useState( false )
    const [isAllChecked, setIsAllChecked] = useState( false )

    const handleAllChange = ( e ) => {
        setIsAllChecked( e.target.checked )
        if ( e.target.checked ) {
            setPermissions( { live_class_audit: true, study_material_audit: true, feedback: true, api_settings: true, password: true, debug_user: true, analytics: true, recordings: true, attendance_audit: true, online_course: true } )
        } else {
            setPermissions( defaultPermissions )
        }
    }

    const addNewAdmin = async ( values ) => {
        try {
            if ( values.password.trim() !== "" && values.password.match( `^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,32}$` ) ) {
                const res = await api.createSubAdminAccount( { ...values, permissions } )
                toast( res.data.message )
                setMode( "manage" )
            }
        } catch ( err ) {
            console.log( err )
        }
    }

    const updateAdminUser = async ( values ) => {
        try {
            const res = await api.updateSubAdminAccount( { ...values, permissions }, user.user_id )
            toast( res.data.message )
        } catch ( err ) {
            console.log( err )
        }
    }

    const initialValues = {
        email: mode === "edit" ? user.user_email : "",
        username: mode === "edit" ? user.user_name : "",
        password: mode === "edit" ? user.user_password : ""
    }

    const validationSchema = Yup.object().shape( {
        email: Yup.string().email( "Please provide valid email" ).required( "E-mail is required!" ),
        username: Yup.string().required( "Username is required!" ),
        // password: Yup.string().required( "Password is required!" ).matches( `^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,32}$`, "Provide a strong password!" )
    } )

    useEffect( () => {
        let previous = undefined
        let isDiff = false
        for ( const permission in permissions ) {
            if ( previous === true || previous === false ) {
                if ( previous !== permissions[permission] ) {
                    setIsIndeterminate( true )
                    isDiff = true
                    break
                }
            } else
                previous = permissions[permission]
        }
        if ( !isDiff ) {
            setIsIndeterminate( false )
            setIsAllChecked( previous )
        }
    }, [permissions] )

    return (
        <Box marginTop="20px" border="1px solid rgba(0,0,0,0.2)" padding="20px" borderRadius="5px">
            <Typography variant='h6' gutterBottom>Add new admin</Typography>
            <Formik onSubmit={mode === "add" ? addNewAdmin : updateAdminUser} initialValues={initialValues} validationSchema={validationSchema}>
                {() => (
                    <Form autoComplete='off'>
                        <FormControl fullWidth sx={{ marginBottom: "10px", marginTop: "10px" }}>
                            <Field as={TextField} label="E-mail" name="email" variant='outlined' />
                            <FormHelperText sx={{ color: "error.main" }}> <ErrorMessage name="email" /> </FormHelperText>
                        </FormControl>
                        <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                            <Field as={TextField} label="Username" type='text' name="username" variant='outlined' />
                            <FormHelperText sx={{ color: "error.main" }}> <ErrorMessage name="username" /> </FormHelperText>
                        </FormControl>
                        {mode === "add" && <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                            <Field as={TextField} label="Password" type="password" name="password" variant='outlined' />
                            <FormHelperText sx={{ color: "error.main" }}> <ErrorMessage name="password" /> </FormHelperText>
                        </FormControl>}
                        <Box>
                            <Typography variant='subtitle2' color="secondary">Grant permissions</Typography>
                            <FormControlLabel label="All" control={<Checkbox checked={isAllChecked} onChange={handleAllChange} indeterminate={isIndeterminate} />} />
                            <Box marginLeft="20px" display="flex" flexDirection="column">
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Live Class Audit" control={<Checkbox size='small' checked={permissions.live_class_audit} onChange={() => setPermissions( { ...permissions, live_class_audit: !permissions.live_class_audit } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Study Material Audit" control={<Checkbox size='small' checked={permissions.study_material_audit} onChange={() => setPermissions( { ...permissions, study_material_audit: !permissions.study_material_audit } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Online Course" control={<Checkbox size='small' checked={permissions.online_course} onChange={() => setPermissions( { ...permissions, online_course: !permissions.online_course } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Resume Builder" control={<Checkbox size='small' checked={permissions.resume_builder} onChange={() => setPermissions( { ...permissions, resume_builder: !permissions.resume_builder } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Attendance Audit" control={<Checkbox size='small' checked={permissions.attendance_audit} onChange={() => setPermissions( { ...permissions, attendance_audit: !permissions.attendance_audit } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Admission Exams" control={<Checkbox size='small' checked={permissions.exams} onChange={() => setPermissions( { ...permissions, exams: !permissions.exams } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Exam Audit" control={<Checkbox size='small' checked={permissions.exam_audit} onChange={() => setPermissions( { ...permissions, exam_audit: !permissions.exam_audit } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="feedback" control={<Checkbox size='small' checked={permissions.feedback} onChange={() => setPermissions( { ...permissions, feedback: !permissions.feedback } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="API settings" control={<Checkbox size='small' checked={permissions.api_settings} onChange={() => setPermissions( { ...permissions, api_settings: !permissions.api_settings } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Debug User" control={<Checkbox size='small' checked={permissions.debug_user} onChange={() => setPermissions( { ...permissions, debug_user: !permissions.debug_user } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Analytics" control={<Checkbox size='small' checked={permissions.analytics} onChange={() => setPermissions( { ...permissions, analytics: !permissions.analytics } )} />} />
                                <FormControlLabel sx={{ "& span": { fontSize: "14px" } }} label="Recordings" control={<Checkbox size='small' checked={permissions.recordings} onChange={() => setPermissions( { ...permissions, recordings: !permissions.recordings } )} />} />
                            </Box>
                        </Box>
                        <Box display="flex" marginTop="20px" gap="10px">
                            <Button type='submit' variant='contained' disableElevation>{mode === "edit" ? "Update" : "Add admin"}</Button>
                            <Button onClick={() => { setMode( "manage" ); setPermissions( defaultPermissions ) }} color="error" variant='contained' disableElevation>Cancel</Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default AddEditAdmin
