import { useAssignmentStore } from "../../store/assignment.store"
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, Chip, DialogContent, Typography, Divider } from '@mui/material'
import Download from "@mui/icons-material/Download"
import { chipColors } from "../../utils/utilities"
import { toast } from "react-toastify"

const AssignmentViewer = ( { onClose, selectedValue, open } ) => {

    const AssignmentStore = useAssignmentStore()

    const handleClose = () => {
        onClose( selectedValue )
    }

    const handleListItemClick = ( value ) => {
        console.log( value )
        AssignmentStore.downloadAssignment( value )
        toast( `Downloading ${value.split( "." )[value.split( "." ).length - 2]}`, { position: "bottom-left" } )
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle sx={{ minWidth: "200px", width: "400px" }} id="simple-dialog-title">
                {selectedValue.assignment_name}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ marginBottom: "20px" }}>
                <Typography variant="subtitle2">Created on: {new Date( selectedValue.assignment_created_at ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                <Typography variant="subtitle2">Due: {new Date( selectedValue.assignment_due_date ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                <Typography variant="subtitle1" marginTop="20px" fontWeight="bold" >Details</Typography>
                <Typography variant="body2">{selectedValue.assignment_details}</Typography>
                <Typography variant="subtitle1" marginTop="20px" fontWeight="bold" >Attachments</Typography>
                {/* <Typography> {JSON.stringify( selectedValue, null, 4 )} </Typography> */}
                {selectedValue.assignment_file_paths && selectedValue.assignment_file_paths.length > 0 && selectedValue.assignment_file_paths.map( ( item ) => (
                    <Chip key={item} deleteIcon={<Download sx={{ fontSize: "16px !important", color: "white !important" }} />} onDelete={() => handleListItemClick( item )} sx={{ color: "white", background: chipColors[2], margin: "5px 5px 0 0" }} label={item.split( "." )[item.split( "." ).length - 2].replace( /%20/g, " " )} />
                ) )}

            </DialogContent>
        </Dialog>
    )
}

AssignmentViewer.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.object.isRequired,
}

export default AssignmentViewer