import { Box, Typography } from '@mui/material'

const Progress = ( { progress, size } ) => {

    return (
        <div>
            <Box id="circular-progress" sx={{ transition: "background 1s ease", background: `conic-gradient(#273679 ${progress * 3.6}deg,#ededed 0deg)` }} position="relative" width={`${size}px`} height={`${size}px`} bgcolor="#7d2ae8" borderRadius="50%" display="flex" alignItems="center" justifyContent="center">
                {!isNaN( progress ) && <Typography bgcolor="#fff" width={`${size * 0.8}px`} borderRadius="50%" fontSize={`${size * 0.3}px`} display="flex" alignItems="center" justifyContent="center" height={`${size * 0.8}px`} fontWeight="bold">{`${progress}%`}</Typography>}
            </Box>
        </div >
    )
}

export default Progress
