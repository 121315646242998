import { Avatar, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AnswerSection from '../../components/exams-components/AnswerSection'
import QuestionList from '../../components/exams-components/QuestionList'
import { useParams, useNavigate } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { formatDateTimeForDisplay, formatDateTimeForInputField } from '../../utils/exam-utilities'
import { toast } from 'react-toastify'
import userIcon from '../../assets/usericon.png'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../service/api'
import { useExamStore } from '../../store/exam.store'
import { useUserStore } from '../../store/user.store'
import { shuffleArray } from '../../utils/utilities'
const examWindowHeaderStyle = {
    padding: "10px 20px",
    color: "white",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    position: "fixed",
    top: 0,
    left: 0,
    // width: "100%",
    right: 0,
    height: "70px",
    zIndex: 1000,
    '@media(max-width:900px)': {
        flexDirection: "column",
        height: "fit-content"
    }
}

export default function ExamAttempt() {
    const [currentIndex, setCurrentIndex] = useState( 0 )
    const [submissions, setSubmissions] = useState( null )
    const [currentQuestion, setCurrentQuestion] = useState( null )
    const [questions, setQuestions] = useState( null )
    const [exam, setExam] = useState( null )
    const [user, setUser] = useState( null )
    const [isExamEnded, setIsExamEnded] = useState( false )
    const [examDuration, setExamDuration] = useState( null )
    const [countdown, setCountdown] = useState( '00H:00M:00S' )
    const [participation, setParticipation] = useState( null )
    const [isExamStarted, setIsExamStarted] = useState( false )
    const [showQuestions, setShowQuestions] = useState( true )
    const [isStarting, setIsStarting] = useState( false )
    const navigate = useNavigate()

    const ExamStore = useExamStore()
    const UserStore = useUserStore()
    const { palette } = useTheme()

    const { id, pid, attempt } = useParams()

    const examAttemptFullScreenHandle = useFullScreenHandle()

    const selectQuestion = id => {
        for ( let i = 0; i < questions.length; i++ ) {
            if ( questions[i].question.question_id === id ) {
                setCurrentQuestion( questions[i] )
                setCurrentIndex( i )
                break
            }
        }
    }
    const saveAndNext = async ( answer, type, selectionType ) => {
        let nextIndex = currentIndex + 1
        if ( nextIndex >= questions.length )
            nextIndex = 0
        setCurrentIndex( nextIndex )
        setCurrentQuestion( questions[nextIndex] )
        if ( answer ) {
            if ( String( answer ).trim() !== '' ) {
                try {
                    const res = await api.submitAnswer( id, pid, { answer, qid: currentQuestion.question.question_id, type, selectionType } )
                    if ( res.status === 200 ) {
                        const sData = await api.fetchParticipationSubmissions( id, pid )
                        setSubmissions( sData.data )
                        toast( res.data, { style: { fontSize: "14px" } } )
                    }
                } catch ( e ) {
                    console.log( e )
                    toast( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! submit answer again", { style: { fontSize: "14px" } } )
                }
            } else toast( "No answer given for previous question!" )
        } else toast( "No answer given for previous question!" )
    }

    // const countdownInterval = setInterval( () => {
    //     if ( exam && questions && submissions && examDuration ) {
    //         const now = new Date().getTime()
    //         const distance = endTime - now

    //         const hours = Math.floor( ( distance % ( 1000 * 60 * 60 * 24 ) ) / ( 1000 * 60 * 60 ) )
    //         const minutes = Math.floor( ( distance % ( 1000 * 60 * 60 ) ) / ( 1000 * 60 ) )
    //         const seconds = Math.floor( ( distance % ( 1000 * 60 ) ) / 1000 )

    //         const time = hours + "H:" + minutes + "M:" + seconds + "S"
    //         if ( distance < 0 ) {
    //             clearInterval( countdownInterval )
    //             finishExam()
    //         }
    //         setCountdown( time )
    //     }
    // }, 1000 )

    const checkForReload = ( e ) => {
        if ( e.code === 'F5' )
            e.preventDefault()
        e = e || e.window.event
        if ( e.ctrlKey ) {
            var c = e.which || e.keyCode
            if ( c === 82 ) {
                e.preventDefault()
                e.stopPropagation()
            }
        }
    }

    const handleFullScreenChange = ( state, handle ) => {
        if ( isExamStarted && state === false ) {
            finishExam()
        }
    }

    const handleScreenChange = ( e ) => {
        if ( isExamStarted ) {
            setIsExamStarted( ps => false )
            finishExam()
        }
    }

    const startExam = async () => {
        setIsStarting( true )
        try {
            const res = await api.startExam( id, pid )
            setExamDuration( res.data.duration )
            setIsExamStarted( true )
            return true
        } catch ( e ) {
            console.log( e )
        } finally {
            setIsStarting( false )
        }
    }

    const finishExam = async () => {
        try {
            if ( !isExamEnded ) {
                const res = await api.finishExam( id, pid )
                setIsExamEnded( true )
                toast.success( res?.data?.message || res.data )
            }
            navigate( `/student/exams/${id}`, { replace: true } )
        }
        catch ( e ) {
            toast.error( e.response.data )
        }
    }

    const toggleQuestions = () => {
        setShowQuestions( ps => !ps )
    }

    useEffect( () => {
        const interval = setInterval( () => {
            if ( examDuration || examDuration === 0 ) {
                if ( examDuration <= 0 ) {
                    finishExam()
                    clearInterval( interval )
                } else setExamDuration( pd => pd - 1 )
                let hours = Math.floor( examDuration / 3600 )
                let minutes = Math.floor( ( examDuration % 3600 ) / 60 )
                let seconds = parseInt( ( ( examDuration % 3600 ) % 60 ) )

                hours = hours < 10 ? "0" + hours : hours
                minutes = minutes < 10 ? "0" + minutes : minutes
                seconds = seconds < 10 ? "0" + seconds : seconds
                setCountdown( `${hours}H:${minutes}M:${seconds}S` )
            }
        }, 1000 )
        return () => clearInterval( interval )
        // eslint-disable-next-line
    }, [countdown, examDuration] )

    useEffect( () => {
        window.addEventListener( 'keydown', checkForReload )
        window.addEventListener( 'blur', handleScreenChange )

        return () => {
            window.removeEventListener( 'keydown', checkForReload )
            window.removeEventListener( 'blur', handleScreenChange )
        }
        // eslint-disable-next-line
    }, [isExamStarted] )

    useEffect( () => {
        const getData = async () => {
            try {
                const participation = await api.fetchParticipationData( id, pid )
                if ( String( participation.data.participation.exam_participation_end_time ) !== String( null ) || participation.data.participation.exam_participation_status === 'Completed' ) {
                    toast.error( "The attempt you tried to acess already ended!" )
                    navigate( `/student/exams/${id}`, { replace: true } )
                }
                await ExamStore.fetchExam( id )
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() ) {
                    finishExam()
                    toast.error( "Exam already ended!" )
                    navigate( `/student/exams/${id}`, { replace: true } )
                }
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) > new Date() ) {
                    toast.error( "Exam not yet started!" )
                    navigate( `/student/exams/${id}`, { replace: true } )
                }
                // await UserStore.fetchUser()
                const sData = await api.fetchParticipationSubmissions( id, pid )
                const qData = await api.fetchQuestions( id )
                setUser( UserStore.getUser )
                setParticipation( participation.data )
                if ( ExamStore.getExam.exam.enable_randomize )
                    shuffleArray( qData.data )
                setQuestions( qData.data )
                setCurrentQuestion( qData.data[0] )
                setSubmissions( sData.data )
                setExam( ExamStore.getExam.exam )
            } catch ( e ) {
                console.log( e )
                if ( e.response && e.response.status && e.response.status === 404 )
                    navigate( '/notfound', { replace: true } )
                else toast.error( e.response ? e.response.data : "Something went wrong! try again later" )
            }
        }
        getData()
        // eslint-disable-next-line
    }, [id, pid, navigate, ExamStore, UserStore] )

    return (
        <FullScreen handle={examAttemptFullScreenHandle} onChange={handleFullScreenChange} className='full-screen-element'>
            {!isExamStarted && exam && <Box paddingBottom="20px" bgcolor="#f4f8f9">

                <Box margin="20px" marginTop="0" borderRadius="5px" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)" bgcolor="#f4f8f9">
                    <Box padding="20px" bgcolor="#f18f08" borderRadius="5px 5px 0 0" color="white">
                        <Typography variant='h5'>{exam.exam_name}</Typography>
                        <Typography variant='body1'>{`${formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} - ${formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}`}</Typography>
                        <Typography variant='body1'>{exam.exam_duration !== null && exam.exam_duration !== "null" ? exam.exam_duration : ""}</Typography>
                        <Typography variant='body2'>Attempt: {attempt}</Typography>
                    </Box>
                    <Box padding="20px">
                        <Typography variant='h6'>Exam Description</Typography>
                        <Typography variant='body2' textAlign="justify">{exam.exam_description}</Typography>
                        <Typography marginTop="20px" variant='h6'>Muhim ko`rsatmalar</Typography>
                        <ul>
                            <li><Typography fontSize="14px">The exam will be held in fullscreen mode.</Typography> </li>
                            <li><Typography fontSize="14px">If a participant change tabs or window the exam will be ended.</Typography></li>
                            <li><Typography fontSize="14px">Exam will be ended if participant exits fullscreen mode by any means.</Typography></li>
                            <li><Typography fontSize="14px">So please switch off all the notification.</Typography></li>
                        </ul>
                        <Button onClick={async () => { if ( await startExam() ) examAttemptFullScreenHandle.enter() }} disabled={isStarting} variant='contained' sx={{ textTransform: "capitalize", height: "53px" }}> {isStarting ? <Typography display="flex" alignItems="center" gap="5px" fontSize={14}> <CircularProgress size={14} /> Starting exam...</Typography> : <Typography fontSize={14}>Start Exam</Typography>} </Button>
                    </Box>
                </Box>

            </Box>
            }
            {isExamStarted && <Box id='exam-window' height="100%" overflow="auto !important">
                {participation && <Box sx={{ ...examWindowHeaderStyle, background: palette.primary.dark }}>
                    <Box>
                        <Typography variant='h6' display={{ lg: "flex", md: "flex", sm: "none", xs: "none" }} alignItems="center" justifyContent={{ lg: "flex-start", md: "flex-start", sm: "center", xs: "center" }} noWrap>{exam && exam.exam_name}</Typography>
                        <Typography variant='subtitle2' fontSize="12px" display={{ lg: "flex", md: "flex", sm: "none", xs: "none" }} alignItems="center" justifyContent={{ lg: "flex-start", md: "flex-start", sm: "center", xs: "center" }}>{exam && exam.exam_topics.join( ", " )}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="20px" justifyContent={{ lg: "initial", md: "initial", sm: "space-between", xs: "space-between" }}>
                        <Box display="flex" gap="10px" alignItems="center" flexDirection={{ lg: "row", md: "row", sm: "row-reverse", xs: "row-reverse" }}>
                            <Typography variant='body2' display="block">{user.user_name}</Typography>
                            <Avatar sx={{ height: "20px", width: "20px", borderRadius: "50%", border: "1px solid white" }} src={userIcon} />
                        </Box>
                        <Box textAlign="right">
                            <Typography variant='subtitle1'>Time left</Typography>
                            <Typography variant='subtitle2' sx={{ color: examDuration < 3000 ? "red" : "white" }} width="90px"> {countdown} </Typography>
                        </Box>
                    </Box>
                </Box>}
                {questions && <Box display="grid" gridTemplateColumns={{ lg: `${showQuestions ? "300px" : "0px"} auto`, md: `${showQuestions ? "250px" : "0px"} auto`, sm: `${showQuestions ? "50px" : "0px"} auto`, xs: `${showQuestions ? "50px" : "0px"} auto` }} paddingTop={{ md: "90px", xs: "69px" }} height="100%" sx={{ transition: "0.2s ease-out" }}>
                    <QuestionList showQuestions={showQuestions} questions={questions} currentQuestionIndex={currentIndex} submissions={submissions} selectQuestion={selectQuestion} />
                    <AnswerSection toggleQuestions={toggleQuestions} showQuestions={showQuestions} question={currentQuestion} currentQuestionIndex={currentIndex} nextQuestion={saveAndNext} submissions={submissions} finishExam={finishExam} />
                </Box>}
            </Box >}
        </FullScreen>
    )
}
