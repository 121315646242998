import { Box, Button, CircularProgress, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../utils/utilities'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AddEditAdmin from '../../components/admin/AddEditAdmin'
import api from '../../service/api'

const columns = [
    { name: "Sl.No.", maxWidth: "100px", align: "left" },
    { name: "Username", maxWidth: "200px", align: "left" },
    { name: "E-mail", maxWidth: "200px", align: "left" },
    { name: "", maxWidth: "200px", align: "right" },
]

const ManageAccounts = () => {
    const [mode, setMode] = useState( "manage" )
    const [adminUsers, setAdminUsers] = useState( [] )
    const [adminUser, setAdminUser] = useState( null )
    const [isLoading, setIsLoading] = useState( true )

    const deleteAdminUser = async ( id ) => {
        try {
            await api.deleteSubAdminAccount( id )
            const res = await api.getSubAdminAccounts()
            setAdminUsers( res.data.data )
        } catch ( err ) {
            console.log( err )
        }
    }

    useEffect( () => {
        const getAdmins = async () => {
            try {
                const res = await api.getSubAdminAccounts()
                console.log( res.data )
                setAdminUsers( res.data.data )
            } catch ( err ) {
                console.log( err )
            } finally {
                setIsLoading( false )
            }
        }
        getAdmins()
    }, [mode] )

    return (
        <Box>
            <Paper sx={{ padding: "20px" }}>
                <Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Typography variant='h5'>Admins</Typography>
                            <Typography variant='subtitle2' color="textSecondary">Manage admins permissions</Typography>
                        </Box>
                        <Fab color={( mode !== "add" || mode === "edit" ) ? "secondary" : "primary"} sx={{ position: "fixed", right: "40px", bottom: "40px" }} onClick={() => { if ( mode === "add" || mode === "edit" ) setMode( "manage" ); else setMode( "add" ) }} >
                            {( mode === "add" || mode === "edit" ) ? <ManageAccountsIcon /> : Icons.AddIcon}
                        </Fab>
                    </Box>
                </Box>
                {( mode === "add" || mode === "edit" ) &&
                    <AddEditAdmin mode={mode} setMode={setMode} user={adminUser} />
                }
                {mode === "manage" &&
                    <Box>
                        {adminUsers.length > 0 && !isLoading && <TableContainer sx={{ marginTop: "20px" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map( column =>
                                            <TableCell key={column.name} align={column.align} sx={{ maxWidth: column.maxWidth }}>{column.name}</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {adminUsers.map( ( user, index ) => (
                                        <TableRow key={`row-${index}`}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{user.user_name}</TableCell>
                                            <TableCell>{user.user_email}</TableCell>
                                            <TableCell>
                                                <Box display="flex" justifyContent="flex-end" gap="10px" alignItems="center">
                                                    <Button variant='contained' startIcon={<ManageAccountsIcon />} sx={{ backgroundColor: "rgb(48,48,48)", textTransform: "capitalize", fontSize: "14px", "&:hover": { background: "rgb( 32, 32, 32 )" } }} onClick={() => { setAdminUser( user ); setMode( "edit" ) }} size='small'>
                                                        Manage
                                                    </Button>
                                                    <Button variant='contained' color="error" startIcon={Icons.DeleteIcon} sx={{ textTransform: "capitalize", fontSize: "14px" }} onClick={() => { deleteAdminUser( user.user_id ) }} size='small'>
                                                        Delete
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) )}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        {adminUsers.length === 0 && !isLoading &&
                            <Typography marginTop="20px" variant='h6' padding="20px" borderRadius="5px" bgcolor="#eee" color="textSecondary">No subadmin accounts!</Typography>
                        }
                        {isLoading &&
                            <Typography display="flex" gap="10px" marginTop="20px" alignItems="center" variant='h6' color="textSecondary"> <CircularProgress size={20} /> Loading accounts... </Typography>
                        }
                    </Box>
                }
            </Paper>
        </Box>
    )
}

export default ManageAccounts
