import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import NotificationStore from "./notification.store"
import fileDownload from 'js-file-download'
import React from 'react'
// const notification = NotificationStore;
class AssignmentStore {
    assignment = []
    submission
    batch
    notify
    loading
    doEdit
    constructor () {
        this.assignment = []
        this.submission = []
        this.batch = { "batches": [], "sections": [] }
        this.api = api
        this.loading = false
        this.notify = NotificationStore
        this.doEdit = false
        this.selectedAssignment = {}
        makeObservable( this, {
            assignment: observable,
            selectedAssignment: observable,
            submission: observable,
            batch: observable,
            loading: observable,
            doEdit: observable,
            getAssignment: computed,
            getSubmission: computed,
            getBatch: computed,
            setAssignment: action,
            fetchBatch: action,
            setUpdateStatus: action,
            downloadAssignment: action
        } )
    }

    get getAssignment() {
        return toJS( this.assignment )
    }

    get getSubmission() {
        return toJS( this.submission )
    }

    setAssignment( assignments ) {
        runInAction( () => {
            this.assignment = assignments
        } )
    }

    /****
     * 
     * 
     * 
     * 
    ​
// ac_year_id: "12"
// ​​​
// course_assignment_id: "168"
// ​​​
// course_branch_assignment_id: "204"
// ​​​
// course_branch_short_name: "BPT"
// ​​​
// course_short_name: "BPT"
// current_sem: "0"
// current_year: "1"
// section_assignment_id: "1480"
// section_id: "1"
// section_short_name: "A"

     */

    fetchBatch = async ( ac_year_id ) => {
        try {
            const res = await this.api.getAllBatch( ac_year_id )
            console.log( `Batch API`, res["data"]["data"] )
            if ( res.data.success === false && res.data.message === "Invalid Token" ) {
                return false
            }
            this.batch = res["data"]["data"] ? res["data"]["data"] : { "batches": [], "sections": [] }
            return true
        } catch ( err ) {
            console.log( err )
        }
    }

    get getBatch() {
        return toJS( this.batch )
    }

    uploadAssignment = async ( payload ) => {
        try {
            const res = await this.api.uploadAssignment( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            this.fetchAssignmentByAuthor()
            return true
        } catch ( err ) {
            console.log( `Upload Err `, err )
            this.notify.setNotification( true, err.response?.data?.message )
            return false

        }
    }

    deleteAssignment = async ( payload ) => {
        try {
            const res = await this.api.deleteAssignment( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            this.fetchAssignmentByAuthor()
        } catch ( err ) {
            this.notify.setNotification( true, err.response["data"]["message"] )
        }
    }

    deleteAssignmentSubmission = async ( payload ) => {
        try {
            const res = await this.api.deleteAssignmentSubmitted( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            this.fetchAssignmentSubmission( payload.assignment_id )
        } catch ( err ) {
            this.notify.setNotification( true, err.response["data"]["message"] )
        }
    }

    fetchAssignmentByAuthor = async () => {
        try {
            const res = await this.api.fetchAssignmentByAuthor()
            console.log( `Upload Assignment `, res["data"] )
            runInAction( () => {
                this.assignment = res["data"]["data"] ? res["data"]["data"] : []
            } )
        } catch ( err ) {
            console.log( `Upload Err `, err.response?.data?.message )
        }
    }

    fetchAssignmentByStudent = async () => {
        try {
            const res = await this.api.fetchAssignmentByStudent()
            runInAction( () => {
                this.assignment = res["data"]["assignments"] ? res["data"]["assignments"] : []
            } )
        } catch ( err ) {
            console.log( `Upload Err `, err.response["data"]["message"] )
        }
    }

    fetchAssignmentSubmission = async ( id ) => {
        try {
            const res = await this.api.fetchAssignmentSubmission( id )
            //
            let submissionData = JSON.parse( JSON.stringify( res["data"]["data"] ? res["data"]["data"] : [] ) )
            submissionData.map( ( a, index ) => a['id'] = index + 1 )
            console.log( `Fet Assignment Sub `, submissionData )

            this.submission = submissionData
        } catch ( err ) {
            console.log( `Upload Err `, err.response["data"]["message"] )
        }
    }

    downloadAssignment = async ( url ) => {
        let fname = url.split( "." )[url.split( "." ).length - 2] + "." + url.split( "." )[url.split( "." ).length - 1]
        try {
            const res = await this.api.downloadAssignment( url )
            console.log( res )
            fileDownload( res.data, fname )
        } catch ( err ) {
            console.log( err )
        }
    }

    setAssignmentScore = async ( payload ) => {
        try {
            const res = await this.api.setAssignmentScore( payload )
            //this.fetchAssignmentByAuthor()
            this.updateSubmission( payload )
            this.notify.setNotification( true, res["data"]["message"] )
        } catch ( err ) {
            this.notify.setNotification( true, err.response["data"]["message"] )
        }
    }

    updateSubmission = ( payload ) => {
        let currentValue = JSON.parse( JSON.stringify( this.submission ) )
        currentValue.forEach( p => {
            if ( p.assignment_submission_id === payload.assignment_submission_id )
                p.assignment_marks = payload.assignment_marks
        } )
        //console.log(currentValue)
        this.submission = currentValue
    }

    uploadAssignmentSubmission = async ( payload ) => {
        this.loading = true
        try {
            const res = await this.api.uploadAssignmentSubmission( payload )
            //console.log(`Upload Assignment Submission `, res["data"])
            this.notify.setNotification( true, res["data"]["message"] )
            this.fetchAssignmentByStudent()
            this.loading = false
            return true
        } catch ( err ) {
            this.notify.setNotification( true, ( err.response["message"] ? err.response["message"] : "Something went wrong!" ) )
            this.loading = false
            return false
        }
    }

    setUpdateStatus = ( payload, status ) => {
        this.selectedAssignment = payload
        this.doEdit = status
    }

    updateAssignmentDetail = async ( payload ) => {
        payload["assignment_id"] = this.selectedAssignment["assignment_id"]
        try {
            const res = await api.updateAssignment( payload )
            this.notify.setNotification( true, res["data"]["message"] )
            return true
        } catch ( err ) {
            this.notify.setNotification( true, err.response["data"]["message"] )
            return false
        }
    }
}

const AssignmentStoreInstance = new AssignmentStore()

export default AssignmentStoreInstance

const AssignmentStoreContext = React.createContext()

export const AssignmentStoreProvider = ( { children, store } ) => {
    return (
        <AssignmentStoreContext.Provider value={store}>{children}</AssignmentStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useAssignmentStore = () => React.useContext( AssignmentStoreContext )