import { Collapse, Typography, IconButton, Box } from '@mui/material'
import { attachmentThumbnails } from '../../utils/utilities'
import CloseIcon from '@mui/icons-material/Close'

const DetailsViewer = ( { attachment, open, setShowDetails } ) => {
    return (
        <Collapse sx={{ position: "fixed", top: 0, right: 0, background: "#fff", boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.19)", maxWidth: "350px", height: "100vh", overflow: "auto", zIndex: "2000", padding: "20px" }} orientation='horizontal' in={open}>
            <Box color="black" marginBottom="20px" display="flex" gap="20px" alignItems="center" justifyContent="space-between">
                <Box display="flex" gap="20px" maxWidth="250px" flexDirection={{ sm: "row", xs: "column" }}>
                    <img width="40px" src={attachmentThumbnails[attachment.study_material_attachment_type] ? attachmentThumbnails[attachment.study_material_attachment_type] : attachmentThumbnails["default"]} alt={attachment.study_material_attachment_type} />
                    <Typography variant='subtitle1' sx={{ overflow: "hidden", display: "inline-block", wordWrap: "break-word" }}>{attachment.study_material_attachment_name}</Typography>
                </Box>
                <IconButton onClick={() => setShowDetails( false )}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <hr />
            {attachment.study_material_attachment_title && <Typography sx={{ color: "black" }} variant='subtitle2' gutterBottom display="flex" flexDirection="column" marginBottom="10px" marginTop="20px"><b>Title: </b>{attachment.study_material_attachment_title}</Typography>}
            <Typography sx={{ color: "black" }} variant='subtitle2' gutterBottom display="flex" flexDirection="column" marginBottom="10px" marginTop="20px"><b>Type: </b>{attachment.study_material_attachment_type}</Typography>
            <Typography sx={{ color: "black" }} variant='subtitle2' gutterBottom display="flex" flexDirection="column" marginBottom="10px" ><b>Size: </b>{( attachment.study_material_attachment_meta.size ) / ( 1024 * 1024 ) < 1024 ? `${Math.round( attachment.study_material_attachment_meta.size / ( 2 * 1024 ) )}KB` : `${Math.round( attachment.study_material_attachment_meta.size / ( 3 * 1024 ) )}MB`}</Typography>
            <Typography sx={{ color: "black" }} variant='subtitle2' gutterBottom display="flex" flexDirection="column" marginBottom="10px" ><b>MIME: </b>{attachment.study_material_attachment_meta.mimetype}</Typography>
            <Typography sx={{ color: "black" }} variant='subtitle2' gutterBottom display="flex" flexDirection="column" marginBottom="10px" ><b>Date Uploaded: </b>{new Date( attachment.study_material_attachment_published_date ).toLocaleDateString( 'en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "numeric", minute: "numeric" } ).replace( /\//g, "-" )}</Typography>

        </Collapse>
    )
}

export default DetailsViewer
