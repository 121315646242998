import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import Progress from '../Progress'
import { ArrowDropDown, Schedule, Pending, CheckCircleOutline, ErrorOutline, Error } from '@mui/icons-material'
import { Icons } from '../../utils/utilities'
import { useUploadStatusListStore } from '../../store/uploadstatuslistdialog.store'
import { observer } from 'mobx-react'

const UploadsStatusList = observer( () => {

    const UploadStatusList = useUploadStatusListStore()

    return (
        <Box sx={{ position: "fixed", display: UploadStatusList.getAttachmentList.length > 0 ? "block" : "none", zIndex: 2000, borderRadius: "5px", background: "#fff", bottom: "0", right: "20px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", width: "250px", maxWidth: "250px" }}>
            <Box display="flex" padding="5px" alignItems="center" justifyContent="space-between">
                {UploadStatusList.getStatusList.filter( item => ( item.statusText === 'queued' || item.statusText === 'uploading' ) ).length > 0 ? <Typography variant="subtitle2">
                    Uploading {UploadStatusList.getStatusList.filter( item => ( item.statusText === 'queued' || item.statusText === 'uploading' ) ).length} items...
                </Typography> :
                    <Typography variant="subtitle2">
                        Upload completed
                    </Typography>
                }
                <IconButton size="small" onClick={() => UploadStatusList.closeDialog()}>
                    {!UploadStatusList.getDialogStatus ? <ArrowDropDown /> : Icons.CloseIcon}
                </IconButton>
            </Box>
            {UploadStatusList.getDialogStatus && <Box maxHeight="150px" overflow="auto">
                {UploadStatusList.getStatusList.map( ( attachmentStatus, i ) => {
                    return (
                        <Box key={i} borderTop="2px solid rgba(0,0,0,0.1)" fontSize="14px" display="flex" alignItems="center" justifyContent="space-betweeen" gap="5px" padding="10px">
                            <Box flexGrow="1" display="flex" flexDirection="column" alignItems="center">
                                <Typography fontSize="12px" width="150px" noWrap><b>{`${UploadStatusList.getAttachmentList[i]?.name}.${UploadStatusList.getAttachmentList[i]?.ext}`}</b></Typography>
                                <Typography fontSize="10px" width="150px" noWrap> to <b>{UploadStatusList.getAttachmentList[i]?.module.module_name}</b></Typography>
                                <Typography fontSize="12px" display="flex" gap="5px" alignItems="center" sx={{ color: attachmentStatus?.statusText === 'queued' ? "primary.main" : attachmentStatus?.statusText === 'uploading' ? "orange" : attachmentStatus?.statusText === 'failed' ? "error.main" : "green" }} width="150px" textTransform="capitalize" noWrap> {attachmentStatus?.statusText === 'queued' ? <Schedule sx={{ fontSize: "15px" }} /> : attachmentStatus?.statusText === 'uploading' ? <Pending sx={{ fontSize: "15px" }} /> : attachmentStatus?.statusText === 'failed' ? <ErrorOutline sx={{ fontSize: "15px" }} /> : <CheckCircleOutline sx={{ fontSize: "15px" }} />} {attachmentStatus.statusText}</Typography>
                            </Box>
                            {attachmentStatus?.statusText === 'failed' ? <Error color='error' sx={{ fontSize: "30px" }} /> : <Progress size={30} progress={( !attachmentStatus || !attachmentStatus.progress ) ? 0 : attachmentStatus.progress} />}
                        </Box>
                    )
                } )}
            </Box>}
        </Box>
    )
} )

export default UploadsStatusList
