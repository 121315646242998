import { Typography, Button, Box, RadioGroup, FormControlLabel, Tooltip, FormControl, Radio, Checkbox, CircularProgress, } from '@mui/material'
import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import parse from 'html-react-parser'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import { useEffect } from 'react'
import { EditableMathField } from 'react-mathquill'
import { shuffleArray } from '../../utils/utilities'
import { useExamStore } from '../../store/exam.store'
import AudioPlayer from './AudioPlayer'
import { AudioFileOutlined } from '@mui/icons-material'
import api from '../../service/api'

export default function AnswerSection( { question, currentQuestionIndex, nextQuestion, submissions, finishExam, toggleQuestions, showQuestions } ) {
    const [currentSubmissions, setcurrentSubmissions] = useState( null )
    const [finishDialog, setFinishDialog] = useState( false )
    const [isSubmitting, setIsSubmitting] = useState( false )
    const [signedUrls, setSignedUrls] = useState( [] )

    const ExamStore = useExamStore()

    const submit = ( e ) => {
        e.preventDefault()
        // console.log( currentSubmissions[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
        nextQuestion( currentSubmissions[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
    }

    const modules = {
        toolbar: [
            [{ header: 1 }, { header: 2 }, { header: [3, 4, 5, 6] }, { font: [] }],
            [{ size: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ]
    }

    useEffect( () => {
        const getAudioUrls = async () => {
            let signedUrlsList = []
            for ( let i = 0; i < question.question?.audio_attachments_keys.length; i++ ) {
                const key = question.question.audio_attachments_keys[i]
                const { data: signedUrl } = await api.getAudioPresignedUrl( ExamStore.getExam.exam.exam_id, question.question.question_id, { key } )
                signedUrlsList.push( signedUrl )
            }
            setSignedUrls( signedUrlsList )
        }
        getAudioUrls()
        // eslint-disable-next-line
    }, [question] )


    useEffect( () => {
        const subs = {}
        submissions.forEach( sub => {
            if ( sub.answer_id )
                if ( sub.question_answer_selection === 'single' )
                    subs[sub.question_id] = sub.answer_id
                else
                    subs[sub.question_id] = subs[sub.question_id] ? { ...subs[sub.question_id], [sub.answer_id]: 1 } : { [sub.answer_id]: 1 }
            else
                subs[sub.question_id] = sub.answer_descriptive
        } )
        if ( ExamStore.getExam.exam.enable_randomize )
            shuffleArray( question.options )
        setcurrentSubmissions( subs )
        // eslint-disable-next-line
    }, [submissions] )


    return (
        <Box sx={{ overflow: "auto", flexGrow: 1, background: "#f4f8f9", }} bgcolor="white" borderLeft={showQuestions ? "3px solid grey" : "none"} style={{ overflow: "hidden !important" }}>
            <Box overflow="auto" padding="10px" >
                <Tooltip title="Toggle questions">
                    <Button onClick={toggleQuestions} startIcon={showQuestions ? <ToggleOnIcon /> : <ToggleOffIcon />} sx={{ textTransform: "capitalize" }}>
                        {showQuestions ? "Close" : "Open"} Question list
                    </Button>
                </Tooltip>
                {question && <Box>
                    <Typography fontSize={{ lg: '16px', md: "16px", sm: "12px", xs: "12px" }} paddingLeft="10px" color="textSecondary">Question {currentQuestionIndex + 1}</Typography>
                    <Box fontSize="18px" paddingLeft="10px" align='justify'>
                        {/* {parse( question.question.question_text )} */}
                        {question.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField style={{ padding: "0px", fontSize: "18px !important", fontWeight: "400" }} latex={question.question.question_text.trim().split( "LATEX-" )[1]} id='answer-section-latex'> </EditableMathField> : parse( question.question.question_text.trim() )}
                    </Box>
                    {question.question.question_hint && <Typography paddingLeft="10px" marginTop="-15px !important" fontSize="13px" fontStyle="italic" variant='body2' align='justify' color="textSecondary">HINT: {question.question.question_hint}</Typography>}
                    {question.question.audio_attachments.length > 0 && <Box display="flex" margin="10px 0 0 10px" flexWrap="wrap" gap="10px" alignItems="center">
                        {question.question.audio_attachments.map( ( attachment, index ) => (
                            <Box padding="5px" bgcolor="#fff" display="flex" gap="10px" alignItems="center" border="1px solid #d3d3d3">
                                <AudioFileOutlined sx={{ fontSize: "20px" }} />
                                <AudioPlayer audioName={question.question.audio_attachments[index]} audioSrc={signedUrls[index]} />
                            </Box>
                        ) )}
                    </Box>}
                    <hr style={{ margin: "10px 0" }} />
                </Box>}
                {currentSubmissions && question &&
                    < Box sx={{ height: "100%", overflow: "auto" }}>
                        <form onSubmit={submit} method="post" id='answer-form' style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box style={{ flexGrow: 1, marginTop: "20px" }}>
                                <FormControl fullWidth>
                                    {question.question.question_type === 'MCQ' && question.question.question_answer_selection === 'single' &&
                                        <>
                                            <RadioGroup sx={{ padding: "0 0 0 20px", display: "flex", gap: "10px", flexDirection: "column" }} value={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id] : 'NA'} name={String( question.question.question_id )} onChange={e => setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: e.target.value } )}>
                                                {question.options.map( ( option, index ) => (
                                                    <FormControlLabel key={option.answer_id} value={option.answer_id} control={<Radio />}
                                                        // label={parse( option.answer_text )}
                                                        label={
                                                            option.answer_text.trim().includes( 'LATEX-' ) ?
                                                                <EditableMathField id='static-latex' latex={option.answer_text.trim().split( "LATEX-" )[1]} /> :
                                                                option.answer_text.trim().replace( "LATEX-", "" ).toLowerCase().includes( "<img" ) ? parse( option.answer_text.trim().replace( "LATEX-", "" ) ) : option.answer_text.trim().replace( "LATEX-", "" )
                                                        }
                                                        sx={{
                                                            padding: "10px", borderRadius: "5px", background: "white", border: "2px solid #aaaaaa99", display: "flex", alignItems: "center", transition: "0.2s ease", whiteSpace: "pre-wrap", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #fff" }, '& >p': {
                                                                margin: "0", display: "inline-block"
                                                            }
                                                        }} style={( currentSubmissions[question.question.question_id] && parseInt( currentSubmissions[question.question.question_id] ) === parseInt( option.answer_id ) ) ? { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4", color: "#374ba4", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4" }, "& span": { fontWeight: "bolder", } } : null} />
                                                ) )}
                                            </RadioGroup>
                                            <Button variant='outlined' disableElevation color='secondary' sx={{ textTransform: "capitalize", margin: "20px 0 0 10px", width: "fit-content", '@media(max-width:350px)': { width: "calc(100% - 20px)" } }} onClick={e => setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: '-1' } )}>Reset</Button>
                                        </>
                                    }
                                    {question.question.question_type === 'MCQ' && question.question.question_answer_selection !== 'single' &&
                                        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column", padding: "0 0 0 20px" }}>
                                            {question.options.map( ( option, index ) => (
                                                <FormControlLabel key={option.answer_id}
                                                    value={option.answer_id}
                                                    checked={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id][option.answer_id] === 1 ? true : false : false}
                                                    control={<Checkbox onChange={
                                                        e => {
                                                            if ( currentSubmissions[question.question.question_id] )
                                                                setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: { ...currentSubmissions[question.question.question_id], [option.answer_id]: e.target.checked ? 1 : 0 } } )
                                                            else
                                                                setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: { [option.answer_id]: e.target.checked ? 1 : 0 } } )
                                                        }
                                                    } />}
                                                    // label={parse( option.answer_text )}
                                                    label={option.answer_text.trim().includes( 'LATEX-' ) ?
                                                        <EditableMathField id='static-latex' latex={option.answer_text.trim().split( "LATEX-" )[1]} /> :
                                                        option.answer_text.trim().replace( "LATEX-", "" ).toLowerCase().includes( "<img" ) ? parse( option.answer_text.trim().replace( "LATEX-", "" ) ) : option.answer_text.trim().replace( "LATEX-", "" )
                                                    }
                                                    sx={{
                                                        padding: "10px", borderRadius: "5px", background: "white", border: "2px solid #aaaaaa99", display: "flex", alignItems: "center", transition: "0.2s ease", whiteSpace: "pre-wrap", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #fff" }, '& >p': {
                                                            margin: "0", display: "inline-block"
                                                        }
                                                    }} style={( currentSubmissions[question.question.question_id] && parseInt( currentSubmissions[question.question.question_id] ) === parseInt( option.answer_id ) ) ? { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4", color: "#374ba4", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4" }, "& span": { fontWeight: "bolder", } } : null}
                                                />
                                            ) )}
                                            <Button variant='outlined' disableElevation color='secondary' sx={{ textTransform: "capitalize", margin: "20px 0 0 -10px", width: "fit-content", '@media(max-width:350px)': { width: "calc(100% - 20px)" } }} onClick={e => setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: null } )}>Reset</Button>
                                        </Box>
                                    }
                                    {question.question.question_type === 'descriptive' && <ReactQuill modules={modules} value={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id] : 'Answer goes here...'} onChange={( e ) => setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: e } )} />}
                                </FormControl>
                            </Box>
                            <Box bgcolor="rgba(0,0,0,0.3)" sx={{ position: "fixed", zIndex: "20000", display: finishDialog ? "flex" : "none", alignItems: "center", top: 0, right: 0, bottom: 0, left: 0, justifyContent: "center" }} >
                                <Box bgcolor="white" padding="20px" borderRadius="5px">
                                    <Typography variant='h5'>Are you sure?</Typography>
                                    <Typography marginY="10px">There is still time left! Do you want to finish the exam now?</Typography>
                                    <Box display="flex" gap="10px" flexWrap="wrap">
                                        <Button onClick={async () => { setIsSubmitting( true ); await finishExam(); setIsSubmitting( false ) }} disableElevation disabled={isSubmitting} variant='contained' sx={{ textTransform: "capitalize" }}>{isSubmitting ? <Typography display="flex" alignItems="center" gap="5px" fontSize="14px"> <CircularProgress size={14} /> Submitting your answers</Typography> : "Finish now"}</Button>
                                        <Button onClick={() => setFinishDialog( false )} disabled={isSubmitting} variant='outlined' sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" flexWrap="wrap" justifyContent="space-between" paddingRight="10px">
                                <Button type='submit' variant="contained" sx={{ textTransform: "capitalize !important", margin: "10px 0 0 10px !important", width: "fit-content", '@media(max-width:350px)': { width: "100% !important" } }}>Save and next</Button>
                                <Button variant='contained' onClick={() => setFinishDialog( true )} sx={{ textTransform: "capitalize !important", margin: "10px 0 0 10px !important", width: "fit-content", '@media(max-width:350px)': { width: "100% !important" } }}>Finish exam</Button>
                            </Box>
                        </form>
                    </Box >}
                <ToastContainer position='top-center' autoClose={2000} hideProgressBar closeOnClick theme='dark' style={{ height: "50px" }} />
            </Box>
        </Box >
    )
}
