import { Box, TextField, Button, Typography, Paper } from '@mui/material'
import React, { useState } from 'react'
import { useUserStore } from '../../store/user.store'
import ReactJson from 'react-json-view'
import SearchIcon from '@mui/icons-material/Search'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import warningImg from '../../assets/warning.svg'

const DebugUser = () => {

    const UserStore = useUserStore()

    const [username, setUsername] = useState( '' )
    const [token, setToken] = useState( '' )
    const [isSearching, setIsSearching] = useState( false )
    // eslint-disable-next-line
    const [timetable, setTimetable] = useState( [] )
    const [hasPermission, setHasPermission] = useState( true )

    const search = async () => {
        let payload = {
            "user_id": username
        }
        setIsSearching( true )
        try {
            const res = await UserStore.userDebugTimetable( payload )
            await UserStore.fetchUser( payload )
            console.log( res.data )
            if ( res.data.success ) {
                setToken( res["data"]["data"]["token"] )
                setTimetable( res["data"]["data"]["timetable"] )
            } else toast.error( "No user found with the given ID" )
        } catch ( err ) {
            console.log( `Debug : `, err )
        } finally {
            setIsSearching( false )
        }
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.debug_user ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        <Box>
            {hasPermission && <Box borderRadius="5px" bgcolor="#fff" padding="20px" boxShadow="0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1)">
                <Typography variant='h5'>Debug User</Typography>
                <Typography color="textSecondary" variant='subtitle2'>Debug any user by their username or AUID or Employee ID</Typography>
                <Box marginTop="20px" >
                    <Box display="flex" alignItems="center" gap="10px">
                        <TextField onChange={( e ) => setUsername( e.target.value )} value={username} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search users by Username / AUID / Employee ID..." variant="standard" type="text" />
                        <Button disabled={isSearching} sx={{ height: "40px" }} disableElevation variant="contained" color="primary" onClick={search} >
                            Search
                        </Button>
                    </Box>
                </Box>
                <Box marginY="20px" >
                    {token && <Box display="flex" alignItems="center">
                        <Typography borderRight="1px solid rgba(0,0,0,0.3)" padding="10px" variant='h6' color="textSecondary">Token</Typography>
                        <TextField sx={{ marginLeft: "20px" }} fullWidth value={token} disabled />
                    </Box>}
                    {timetable && timetable.length > 0 && <Box>
                        <Box sx={{ "background": "#272822" }}>
                            <Box marginTop="20px" >
                                <ReactJson style={{ padding: "20px" }} theme={'monokai'} src={timetable} />
                            </Box>
                        </Box>
                    </Box>}
                </Box>

            </Box>}
            {!hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box>
    )
}

export default DebugUser
