import { Box, Button, CircularProgress, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import noTemplatesImg from '../../../assets/no-resume-templates.svg'
import { observer } from 'mobx-react'
import EntitiesTableRow from '../../../components/admin/resume-builder/EntitiesTableRow'
import AddNewEntity from '../../../components/admin/resume-builder/AddNewEntity'
import EditEntity from '../../../components/admin/resume-builder/EditEntity'
import { Search } from '@mui/icons-material'
import { useUserStore } from '../../../store/user.store'
import warningImg from '../../../assets/warning.svg'

const tableColumns = [
    { label: "Name", style: { minWidth: "200px", padding: "10px" } },
    { label: "Description", style: { minWidth: "200px", display: { md: "table-cell", xs: "none" }, padding: "10px" } },
    { label: "Key", style: { minWidth: "150px", padding: "10px" } },
    { label: "Label", style: { minWidth: "150px", padding: "10px" } },
    { label: "Show Label", style: { minWidth: "80px", padding: "10px" } },
    { label: "Type", style: { minWidth: "100px", padding: "10px" } },
    { label: "", style: { padding: "10px" } },
]

const ResumeEntities = observer( () => {

    const [addDialogstate, setAddDialogState] = useState( false )
    const [loading, setLoading] = useState( true )
    const [selectedEntity, setSelectedEntity] = useState( null )
    const [searchText, setSearchText] = useState( '' )
    const [hasPermission, setHasPermission] = useState( true )

    // const navigate = useNavigate()
    const ResumeBuilderStore = useResumeBuilderStore()
    const UserStore = useUserStore()

    const { palette } = useTheme()

    useEffect( () => {
        const getData = async () => {
            await ResumeBuilderStore.fetchEntities()
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        } else
            getData()
    }, [ResumeBuilderStore, UserStore] )

    return (
        <Box height="100%">
            {hasPermission && <Box>
                <AddNewEntity addDialogstate={addDialogstate} entities={ResumeBuilderStore.getEntities} setAddDialogState={setAddDialogState} />
                {selectedEntity && <EditEntity setSelectedEntity={setSelectedEntity} entity={selectedEntity} />}
                <Paper>
                    <Box padding="20px">
                        <Typography variant='h5'>Resume Entities</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Manage resume entities from here.</Typography>
                    </Box>
                    {
                        ResumeBuilderStore.getEntities.length > 0 && !loading && <Box padding="0 20px 20px 20px">
                            <TextField fullWidth value={searchText} onChange={e => setSearchText( e.target.value )} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", "@media(max-width:900px)": { width: "100%" } }, startAdornment: <Search sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder="Search by name..." variant="standard" type="text" />
                        </Box>}
                    {
                        ResumeBuilderStore.getEntities.length === 0 && !loading && <Box bgcolor="#eee" padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="center">
                            <img src={noTemplatesImg} height="150px" style={{ marginBottom: "20px" }} alt="No templates" />
                            <Typography color="textSecondary" variant='subtitle2'>Seems like there are no resume entities to show!</Typography>
                            <Button onClick={() => setAddDialogState( true )} startIcon={Icons.AddIcon} sx={{ textTransform: "capitalize" }} variant='outlined'>Add Entity</Button>
                        </Box>
                    }
                    {
                        ResumeBuilderStore.getEntities.length > 0 && !loading &&
                        <TableContainer sx={{ overflow: "auto", maxHeight: "450px" }}>
                            <Table stickyHeader sx={{ minWidth: "880px" }}>
                                <TableHead>
                                    <TableRow>
                                        {tableColumns.map( column => (
                                            <TableCell key={column.label} sx={{ ...column.style, background: palette.primary.main, color: "white" }}>{column.label}</TableCell>
                                        ) )}
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflow: "auto !important" }}>
                                    {ResumeBuilderStore.getEntities.filter( item => item.entity_name.toLowerCase().includes( searchText ) ).map( item => (
                                        <EntitiesTableRow key={item.id} item={item} setSelectedEntity={setSelectedEntity} />
                                    ) )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {loading && <Typography padding="20px" paddingTop="0" display="flex" alignItems="center" gap="10px" color="textSecondary" variant="h6"> <CircularProgress size={20} /> Loading entities... </Typography>}
                    <Tooltip title="Add new entity" placement='top-start'>
                        <Fab onClick={() => setAddDialogState( true )} sx={{ position: "fixed", bottom: "20px", right: "20px" }} color='secondary'>
                            {Icons.AddIcon}
                        </Fab>
                    </Tooltip>
                </Paper >
            </Box>}
            {!hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>}
        </Box>
    )
} )

export default ResumeEntities
