import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import aliveLogo from '../assets/alivelogo.png'

const ExamNotfound = () => {

    const navigate = useNavigate()

    return (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box height="100%" sx={{ background: "url('/imgs/bg-pattern1.png')", zIndex: "0" }} width="100%" display="flex" position="fixed" top="0" left="0" right="0" bottom="0" flexWrap="wrap" overflow="hidden">
            </Box>
            <Paper sx={{ position: "relative", zIndex: 2, maxWidth: "300px", width: "90%" }}>
                <Box padding="20px" alignItems="center" display="flex" flexDirection="column" gap="10px">
                    <img width="100px" src={aliveLogo} alt="Alive Logo" />
                    <Typography variant="h6">Exam not found!</Typography>
                    <Button variant="outlined" onClick={() => navigate( -1 )} sx={{ textTransform: "capitalize" }}>Go back</Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default ExamNotfound
