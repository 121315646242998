import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'
import { useState } from 'react'
import noFeedbacksImg from '../../assets/nofeedback.svg'
import api from '../../service/api'
import FeedbackTable from '../../components/admin/FeedbackTable'
import warningImg from '../../assets/warning.svg'
import { useEffect } from 'react'
import { useUserStore } from '../../store/user.store'

const AdminSessionFeedback = () => {

    const [startDate, setStartDate] = useState( new Date() )
    const [endDate, setEndDate] = useState( new Date() )
    const [feedbacks, setFeedbacks] = useState( [] )
    const [isLoading, setIsLoading] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    const UserStore = useUserStore()

    const searchFeedbacks = async () => {
        setIsLoading( true )
        try {
            const res = await api.fetchFeedbacks( { startDate, endDate } )
            setFeedbacks( res.data.data )
            console.log( res.data.data )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsLoading( false )
        }
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.feedback ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        <Box display="flex" flexDirection={{ md: "column", xs: "column" }} gap="20px" >
            {hasPermission && <Paper>
                <Box padding="20px 20px 10px 20px"><Typography variant='h5'>Feedbacks</Typography>
                    <Typography color="textSecondary" variant='subtitle2'>Select date range to view live classes feedbacks</Typography>
                </Box>
                <Box sx={{ padding: "0 20px 20px 20px", marginTop: "10px" }}>
                    <Box >
                        <Box >
                            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap="20px">
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            margin='normal'
                                            variant='inline'
                                            sx={{ width: '100%', fontSize: "10px !important" }}
                                            label="Start date"
                                            value={dayjs( startDate )}
                                            format='DD-MM-YYYY'
                                            onChange={setStartDate}
                                        // onAccept={( e ) => { setFieldValue( 'startDateTime', e.$d ) }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            margin='normal'
                                            variant='inline'
                                            sx={{ width: '100%', fontSize: "10px !important" }}
                                            label="End date"
                                            value={dayjs( endDate )}
                                            format='DD-MM-YYYY'
                                            onChange={setEndDate}
                                        // onAccept={( e ) => { setFieldValue( 'startDateTime', e.$d ) }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box>
                                    <Button disableElevation fullWidth variant="contained" color="primary" sx={{ height: "53px" }} onClick={searchFeedbacks} >
                                        Search
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {feedbacks.length === 0 && !isLoading &&
                    <Box bgcolor="#eee" sx={{ padding: "20px", flexGrow: 1, textAlign: "center", height: "fit-content" }}>
                        <img width="200px" src={noFeedbacksImg} alt="No feedbacks!" />
                        <Typography color="textSecondary" marginTop="20px" variant='subtitle2'>No feedbacks for selected date range!</Typography>
                    </Box>
                }
                {feedbacks.length > 0 && !isLoading &&
                    <FeedbackTable rows={feedbacks} />
                }
                {isLoading && <Typography display="flex" gap="5px" color="textSecondary" variant="h6" padding="20px" alignItems="center"> <CircularProgress size={20} /> Loading sessions... </Typography>}
            </Paper>}
            {
                !hasPermission && <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                    <img src={warningImg} width="40px" alt="No permission" />
                    <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
                </Paper>
            }
        </Box>
    )
}

export default AdminSessionFeedback
