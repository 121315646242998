import { Box, Collapse, Divider, Paper, Typography } from "@mui/material"
import { useState } from "react"
import { Icons } from "../utils/utilities"
import parse from 'html-react-parser'

const FacultyFaqs = [
    {
        section: "Online classes",
        faq: [
            {
                question: "How to join a class?",
                answer: "Allow permissions such as camera and microphone in the browser. Go to home/dashboard page and under online classes section, join the scheduled class by clicking on join"
            },
            {
                question: "How to take attendance?",
                answer: "Once joined the sessions attendence will be taken automatically in certain interval of time"
            },
            {
                question: "How to schedule online classes?",
                answer: "Add class to your time table using ERP and mark it as <em className='faq-em'>online</em>"
            }
        ]
    },
    {
        section: "Exam",
        faq: [
            {
                question: "How to create an exam?",
                answer: `
                                <div className="faq-steps">
                                    <div className="faq-step-box">
                                        <span className="faq-step">1</span>
                                        <p>Click in the <em>+</em> at the bottom right corner of the screen in exams page.</p>
                                    </div>
                                    <div className="faq-step-box">
                                        <span className="faq-step">2</span>
                                        <p>Fill all the required fields such as exam name, description, topics, exam start and end time and batches.</p>
                                    </div>
                                    <div className="faq-step-box">
                                        <span className="faq-step">3</span>
                                        <p>Click on <em>create</em> button at the bottom.</p>
                                    </div>
                                </div>
                `
            }, {
                question: "How to add questions to an exam?",
                answer: `
                        <div className="faq-steps">
                        <h3 className='faq-answer-header'>From a file</h3>
                            <div className="faq-step-box">
                                <span className="faq-step">1</span>
                                <p>Click on the <em>Import Question</em> button in exam page.</p>
                            </div>
                            <div className="faq-step-box">
                                <span className="faq-step">2</span>
                                <p>Choose a text file in <a target="blank" href="https://docs.moodle.org/401/en/Aiken_format">aiken</a> format to import questions .</p>
                            </div>
                        </div>
                        <div className="faq-steps">
                        <h3 className='faq-answer-header'>From the UI</h3>
                            <div className="faq-step-box">
                                <span className="faq-step">1</span>
                                <p>Click on the <em>Add Question</em> button in exam page.</p>
                            </div>
                            <div className="faq-step-box">
                                <span className="faq-step">2</span>
                                <p>Fill all the required fields.</p>
                            </div>
                            <div className="faq-step-box">
                                <span className="faq-step">3</span>
                                <p>Click on the <em>Add</em> button to add new question.</p>
                            </div>
                        </div>
                `
            },
            {
                question: "Is the question restricted to english language only?",
                answer: "No. question can be framed in any language in text file or through UI."
            }
        ]
    },
    {
        section: "Study Material",
        faq: [
            {
                question: "What are the supported file formats?",
                answer: `.pdf, .docx, .ppsx, .pptx, .odp`
            },
            {
                question: "Can files be added after creation of study material?",
                answer: `Yes`
            },
            {
                question: "Which students have access to my notes?",
                answer: `Any student with Alive access will be able to access any study material on Alive`
            }
        ]
    }
]

const StudentFaqs = [
    {
        section: "Online Classes",
        faq: [
            {
                question: "How to join a class?",
                answer: "Allow permissions such as camera and microphone in the browser. Go to home/dashboard page and under online classes section, join the scheduled class by clicking on join"
            }
        ]
    },
    {
        section: "Exam",
        faq: [
            {
                question: "Can exams be taken on mobile devices?",
                answer: "Yes. But a device with larger sceen is recommended."
            },
            {
                question: "Is fullscreen mandatory while taking the exam?",
                answer: "Yes. If fullscreen mode is exited the exam will be ended."
            },
            {
                question: "What happens if window focus is changes during the exam?",
                answer: "The exam will be ended."
            }
        ]
    },
    {
        section: "Study Material",
        faq: [
            {
                question: "Can study materials of other branch are accessible",
                answer: "Yes."
            },
            {
                question: "Is it possible to dowload entire study material?",
                answer: "Yes. In a compressed zip file"
            }
        ]
    }
]

const FAQs = ( { auth } ) => {

    const [collapseState, setCollapseState] = useState( {} )

    return (
        <Box padding="0 20px 20px 20px">
            <Paper sx={{ padding: "20px" }}>
                <Typography variant="h5">Frequently asked questions</Typography>
                {auth === "faculty" && FacultyFaqs.map( section => (
                    <Box marginTop="20px" border='1px solid #rgba(0,0,0,0.2)' key={section.section}>
                        <Typography color="textSecondary" variant="subtitle1" gutterBottom>{section.section}</Typography>
                        {section.faq.map( ( item, k ) => <Box key={`${section.section}-${k}`} bgcolor="#fff" borderTop="1px solid rgba(0,0,0,0.1)" borderBottom="1px solid rgba(0,0,0,0.1)">
                            <Box onClick={() => { setCollapseState( { [`${section.section}-${k}`]: !collapseState[`${section.section}-${k}`] } ) }} sx={{ cursor: "pointer" }}>
                                <Box display="flex" alignItems="center" gap="10px" padding="10px" sx={{ background: collapseState[`${section.section}-${k}`] ? "#eee" : "#fff", "&:hover": { background: "#eee" } }}>
                                    {collapseState[`${section.section}-${k}`] ? Icons.RemoveIcon : Icons.AddIcon}
                                    <Typography variant="subtitle1" fontWeight="600">{item.question}</Typography>
                                </Box>
                            </Box>
                            <Collapse sx={{ background: collapseState[`${section.section}-${k}`] ? "#f5f5f5" : "#fff" }} in={collapseState[`${section.section}-${k}`]}>
                                <Divider />
                                <Box margin="20px" borderRadius="0 5px 5px 0" padding="10px">
                                    {parse( item.answer )}
                                </Box>
                            </Collapse>
                        </Box> )}
                    </Box>
                ) )}
                {auth === "student" && StudentFaqs.map( section => (
                    <Box marginTop="20px" border='1px solid #rgba(0,0,0,0.2)' key={section.section}>
                        <Typography color="textSecondary" variant="subtitle1" gutterBottom>{section.section}</Typography>
                        {section.faq.map( ( item, k ) => <Box key={`${section.section}-${k}`} bgcolor="#fff" borderTop="1px solid rgba(0,0,0,0.1)" borderBottom="1px solid rgba(0,0,0,0.1)">
                            <Box onClick={() => { setCollapseState( { [`${section.section}-${k}`]: !collapseState[`${section.section}-${k}`] } ) }} sx={{ cursor: "pointer" }}>
                                <Box display="flex" alignItems="center" gap="10px" padding="10px" sx={{ background: collapseState[`${section.section}-${k}`] ? "#eee" : "#fff", "&:hover": { background: "#eee" } }}>
                                    {collapseState[`${section.section}-${k}`] ? Icons.RemoveIcon : Icons.AddIcon}
                                    <Typography variant="subtitle1" fontWeight="600">{item.question}</Typography>
                                </Box>
                            </Box>
                            <Collapse sx={{ background: collapseState[`${section.section}-${k}`] ? "#f5f5f5" : "#fff" }} in={collapseState[`${section.section}-${k}`]}>
                                <Divider />
                                <Box margin="20px" borderRadius="0 5px 5px 0" padding="10px">
                                    {parse( item.answer )}
                                </Box>
                            </Collapse>
                        </Box> )}
                    </Box>
                ) )}
            </Paper>
        </Box>
    )
}

export default FAQs
