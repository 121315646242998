import { Box, Button, Typography, Paper } from '@mui/material'
import React from 'react'
import aliveLogo from '../assets/alivelogo.png'
import warningImg from '../assets/waiting.svg'
import { useAuthStore } from '../store/auth.store'
import { useNavigate } from 'react-router-dom'
import { erpDashboardLink } from '../utils/utilities'

const PageNotFound = () => {

    const AuthStore = useAuthStore()
    const navigate = useNavigate()

    return (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box height="100%" sx={{ background: "url('/imgs/bg-pattern1.png')", zIndex: "0" }} width="100%" display="flex" position="fixed" top="0" left="0" right="0" bottom="0" flexWrap="wrap" overflow="hidden">
            </Box>
            {AuthStore.getAuth && <Paper sx={{ position: "relative", zIndex: 2 }}>
                <Box padding="20px" width="200px" alignItems="center" display="flex" flexDirection="column" gap="10px">
                    <img width="100px" src={aliveLogo} alt="Alive Logo" />
                    <Typography textAlign="center" variant="subtitle2">Resource you are looking not found! please check the url</Typography>
                    <Button onClick={() => navigate( -1, { replace: true } )} variant="outlined" sx={{ textTransform: "capitalize" }}>Go back</Button>
                </Box>
            </Paper>}
            {!AuthStore.getAuth && <Paper sx={{ position: "relative", zIndex: 2 }}>
                <Box padding="20px" width="200px" alignItems="center" display="flex" flexDirection="column" gap="10px">
                    <img width="100%" src={warningImg} alt="Alive Logo" />
                    <Typography textAlign="center" variant="h6">Session Ended</Typography>
                    <Button onClick={() => window.location.replace( erpDashboardLink )} variant="outlined" sx={{ textTransform: "capitalize" }}>Back to ERP</Button>
                </Box>
            </Paper>}
        </Box>
    )
}

export default PageNotFound


// <Box padding="10px 20px 20px 20px">
//     <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px", alignItems: "center" }}>
//         {AuthStore.getAuth ?
//             <img width="200px" src={error404Img} alt={"Resource not found"} /> : <Typography display="flex" flexDirection="column" gap="20px" alignItems="center" variant='h4' color="error">
//                 <img width="100px" src={warningImg} alt="session ended" />
//                 Session Ended!
//             </Typography>}
//         {AuthStore.getAuth ?
//             <Box display="flex" flexDirection="column" alignItems="center">
//                 <Typography variant='subtitle1' color="textSecondary">The resource you are looking for is unavailable! please check the URL</Typography>
//                 <Button onClick={() => navigate( -1, { replace: true } )} sx={{ textTransform: "capitalize" }}>Go Back</Button>
//             </Box>
//             : <Typography variant='subtitle1' color="textSecondary">Your session ended! please <Link to="/" style={{ fontWeight: "bolder", textDecoration: "underline" }}>login</Link> again</Typography>}
//     </Box>
// </Box>
