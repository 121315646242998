import {
  Box,
  Button,
  CircularProgress,
  useTheme,
  Paper,
  // TextField,
  Tooltip,
  Typography,
  // IconButton,
} from "@mui/material"
import DataLoadingSpinner from "../../components/common/DataLoadingSpinner"
import { downloadParticipationReport } from "../../utils/exam-utilities"
import Submission from "../../components/admission-exam-components/Submission"
import BlockMessage from "../../components/common/BlockMessage"
import BreadcrumbList from "../../components/common/BreadcrumbList"
import { useNavigate, useParams } from "react-router-dom"
// import { Formik, ErrorMessage, Form } from "formik"
import React, { useState, useEffect, useCallback } from "react"
import { Icons, showDateInIndianDateFormat } from "../../utils/utilities"
import { toast } from "react-toastify"
// import * as Yup from "yup"
import { UploadOutlined } from "@mui/icons-material"
import api from "../../service/api"
import { useAdmissionExamStore } from "../../store/admission-exam.store"
import { observer } from "mobx-react"
import warningImg from '../../assets/warning.svg'
import { useUserStore } from "../../store/user.store"

const AdmissionExamEvaluate = observer( () => {
  const [participation, setParticipation] = useState( null )
  const [exam, setExam] = useState( null )
  const [submissions, setSubmissions] = useState( null )
  // const [showRemarks, setShowRemarks] = useState( false )
  const [questions, setQuestions] = useState( [] )
  // const [remarks, setRemarks] = useState( "  " )
  const [totalMarks, setTotalMarks] = useState( 0 )
  const [inProgress, setInProgress] = useState( true )
  const [scheme, setScheme] = useState( null )
  const [accessDenied, setAccessDenied] = useState( false )
  const [notFound, setNotFound] = useState( false )
  const [hasPermission, setHasPermission] = useState( true )

  const { id, participation_id } = useParams()

  const ExamStore = useAdmissionExamStore()
  const UserStore = useUserStore()
  const navigate = useNavigate()

  const { palette } = useTheme()

  // const initialValues = {
  //   remarks: "  ",
  // }

  // const validationSchema = Yup.object().shape( {
  //   remarks: Yup.string()
  //     .required( "This field is required!" )
  //     .test( {
  //       name: "TEXT_LENGTH",
  //       message: "Remark must be atleast 5 characters long",
  //       test: ( value ) => {
  //         if ( !value || value?.trim().length < 5 ) {
  //           return false
  //         } else return true
  //       },
  //     } ),
  // } )

  // const remarksSubmit = async ( values, props ) => {
  //   try {
  //     const { data } = await api.admissionExams.submitRemark( id, participation_id, values )
  //     toast( data.message )
  //   } catch ( e ) {
  //     toast.error(
  //       e?.response?.data?.message ||
  //       "Something went wrong while submitting the remark", { containerId: "main" }
  //     )
  //   }
  // }

  const getData = useCallback( async () => {
    try {
      const { success, code } = await ExamStore.fetchExam( id )
      if ( success ) {
        let { data: participationData } = await api.admissionExams.fetchParticipationData( id, participation_id )
        let { data: submissionData } = await api.admissionExams.fetchParticipationSubmissions( id, participation_id )
        let { data: questionsData } = await api.admissionExams.fetchParticipationQuestions( id, participation_id )
        let { data: schemeData } = await api.admissionExams.fetchEvaluationScheme( id )
        console.log( participationData )
        setParticipation( participationData.participation )
        setTotalMarks( participationData.marks.sum )
        setExam( ExamStore.getExam.exam )
        // setRemarks( participationData.participation.exam_participation_remark )
        let subs = {}
        let tempScheme = {}
        for ( let i = 0; i < submissionData.length; i++ ) {
          subs[submissionData[i].question_id]
            ? subs[submissionData[i].question_id].push( submissionData[i] )
            : ( subs[submissionData[i].question_id] = [submissionData[i]] )
        }
        for ( let i = 0; i < schemeData.length; i++ ) {
          tempScheme[schemeData[i].question_id] = schemeData[i]
        }
        setSubmissions( subs )
        setScheme( tempScheme )
        setQuestions( questionsData )
      } else {
        if ( code === 403 || code === 401 ) setAccessDenied( true )
        else if ( code === 404 ) {
          setNotFound( true )
        }
      }
    } catch ( e ) {
      console.log( e )
      toast( e?.response?.data?.message || "Something went wrong!", { containerId: "main" } )
    } finally {
      setInProgress( false )
    }
  }, [participation_id, id, ExamStore] )


  useEffect( () => {
    if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.exams ) {
      setHasPermission( false )
    }
  }, [UserStore] )

  useEffect( () => {
    getData()
  }, [getData] )

  return (
    hasPermission ? <Box
      sx={{
        // padding: "20px",
        flexGrow: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {notFound && (
        <BlockMessage
          actions={[
            {
              label: "Back to Participations",
              action: () => navigate( `/faculty/participations` ),
            },
          ]}
          type="error"
          blockTitle="Exam Not Found"
          message="The exam is you are looking for seems to be deleted."
        />
      )}
      {accessDenied && (
        <BlockMessage
          actions={[
            {
              label: "Back to Participations",
              action: () => navigate( `/faculty/participations` ),
            },
          ]}
          type="error"
          blockTitle="Access Denied"
          message="You do not have permission to view this exam information. Only exam creator and admin can access a particular exam."
        />
      )}

      {!accessDenied && !notFound && (
        <BreadcrumbList
          items={[
            { label: "Exams", link: "/admission-exam" },
            {
              label:
                exam && exam.exam_name ? (
                  exam.exam_name
                ) : (
                  <CircularProgress sx={{ color: "textSecondary" }} size={12} />
                ),
              link: `/admission-exam/exam/${id}`,
            },
            {
              label:
                exam && exam.exam_name ? (
                  "Participations"
                ) : (
                  <CircularProgress sx={{ color: "textSecondary" }} size={12} />
                ),
              link: `/admission-exam/${id}/participation`,
            },
            "Evaluate",
            participation?.student_name || "",
          ]}
        />
      )}

      {!accessDenied && !notFound && exam && participation && !inProgress && (
        <Box
          sx={{
            minHeight: "450px",
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            height: "100%",
          }}
        >
          <Paper
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                background: `linear-gradient(90deg, #151D48 0%, rgba(21, 29, 72, 0.8) 100%)`,
                color: "white",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                alignItems: { xs: "flex-start", md: "center" },
                padding: "20px",
                gap: "20px",
              }}
            >
              <Box>
                <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                  {exam.exam_name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textTransform: "capitalize" }}
                >
                  Submissions of {participation.student_name.toLowerCase()}
                </Typography>
              </Box>
              <Typography
                variant="subtitle2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  textTransform: "capitalize",
                }}
              >
                {Icons.default.Calendar}{" "}
                {`${showDateInIndianDateFormat(
                  participation.exam_participation_start_time
                )}, ${new Date(
                  participation.exam_participation_start_time
                ).toLocaleTimeString()}`}
              </Typography>
              <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                Attempt: {participation.exam_participation_attempt}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  bgcolor: "rgba(217, 217, 217, 0.08)",
                  border: "1px solid #fff",
                  borderRadius: "5px",
                  padding: "5px 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {participation.exam_participation_score
                  ? participation.exam_participation_score
                  : "0"}{" "}
                Out Of {totalMarks}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Download Report as CSV">
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    endIcon={<UploadOutlined />}
                    onClick={() =>
                      downloadParticipationReport(
                        participation,
                        questions,
                        submissions
                      )
                    }
                    sx={{
                      textTransform: "capitalize",
                      minWidth: "max-content",
                      background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`,
                      "&:hover": {
                        background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`,
                      },
                    }}
                  >
                    Export report
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  flexGrow: 1,
                  bgcolor: palette.contentBg,
                  border: `1px solid ${palette.divider}`,
                  borderRadius: "5px",
                  margin: "20px",
                }}
              >
                <Box
                  sx={{
                    padding: "20px",
                    maxWidth: "1150px",
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {submissions &&
                    questions &&
                    !inProgress &&
                    questions.map( ( question, index ) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 1,
                          width: "100%",
                        }}
                        key={index}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                            mr: 2,
                          }}
                        >
                          <Submission
                            refresh={getData}
                            question={{
                              ...question.question,
                              question_text: `<strong>${question.question.question_text}</strong>`,
                            }}
                            answer={submissions[question.question.question_id]}
                            options={question.options}
                            keyAnswer={scheme[question.question.question_id]}
                            index={index}
                          />
                        </Box>
                      </Box>
                    ) )}
                </Box>

                {/* {!inProgress && (
                  <Box
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      flexGrow: 1,
                      fontSize: "14px",
                      background: palette.contentBg,
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        bgcolor: `${palette.primary.light}22`,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        padding="10px"
                        variant="subtitle2"
                        color="primary"
                      >
                        Remark
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => setShowRemarks( !showRemarks )}
                      >
                        {!showRemarks
                          ? Icons.default.KeyboardArrowUp
                          : Icons.default.KeyboardArrowDownIcon}
                      </IconButton>
                    </Box>
                    {showRemarks && (
                      <Box sx={{ padding: "20px", paddingTop: 0 }}>
                        <Formik
                          onSubmit={remarksSubmit}
                          validationSchema={validationSchema}
                          initialValues={initialValues}
                        >
                          {( { setFieldValue } ) => (
                            <Form method="post" autoComplete="off">
                              <FormControl margin="normal" fullWidth>
                                <Typography
                                  variant="subtitle1"
                                  gutterBottom
                                  color={palette.text.secondary}
                                >
                                  Remarks
                                </Typography>
                                <TextField
                                  placeholder="Eg: Good work keep it up!"
                                  multiline
                                  name="remarks"
                                  defaultValue={remarks}
                                  onChange={( e ) =>
                                    setFieldValue( "remarks", e.target.value )
                                  }
                                />
                                <FormHelperText
                                  sx={{ color: "error.main", marginLeft: 0 }}
                                >
                                  <ErrorMessage name="remarks" />
                                </FormHelperText>
                                <Button
                                  disableElevation
                                  type="submit"
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "fit-content",
                                    height: "53px",
                                    marginTop: "10px",
                                  }}
                                >
                                  Submit remarks
                                </Button>
                              </FormControl>
                            </Form>
                          )}
                        </Formik>
                      </Box>
                    )}
                  </Box>
                )} */}
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
      {!accessDenied && !notFound && !participation && !inProgress && (
        <Typography marginTop="10px" variant="h6" color="textSecondary">
          Participation not found
        </Typography>
      )}
      {inProgress && (
        <Paper sx={{ marginTop: "10px" }}>
          <DataLoadingSpinner waitingMessage="Loading data..." />
        </Paper>
      )}
    </Box> : <Box
      sx={{
        // padding: "20px",
        flexGrow: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
        <img src={warningImg} width="40px" alt="No permission" />
        <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
      </Paper>
    </Box>
  )
} )


export default AdmissionExamEvaluate