import { Box, Button, CircularProgress, IconButton, Paper, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { Icons, leadingZeroFormatter } from '../../utils/utilities'
import React, { useRef } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { AudioFileOutlined, CancelOutlined, FolderOpenOutlined, KeyboardVoiceOutlined, Mic, Pause, PlayArrow, Stop } from '@mui/icons-material'
import AudioPlayer from './AudioPlayer'
const mimeType = "audio/webm"
const audioFileType = "audio/wav"

let maxPossibleAudioLength

const AudioRecorder = ( { maxSize = 1024 * 5, maxLength = 60, selectedAudioFiles, setSelectedAudioFiles, setRecordedCardStatus, container, supportedAudioFormats = ['mp3', 'weba'], multiple = false } ) => {
    const [permission, setPermission] = useState( false )
    const mediaRecorder = useRef( null )
    const [stream, setStream] = useState( null )
    const [audioChunks, setAudioChunks] = useState( [] )
    const [recordLength, setRecordLength] = useState( 0 )
    const [timer, setTimer] = useState( "00:00" )
    const [recorderStatus, setRecorderStatus] = useState( "idle" )
    const [draggingOver, setDraggingOver] = useState( false )
    const [selectedAudioFilesData, setSelectedAudioFilesData] = useState( [] )
    const [tabValue, setTabValue] = useState( 'browse' )
    const [isGettingMicrophonePermission, setIsGettingMicrophonePermission] = useState( false )

    const getMicrophonePermission = async () => {
        setIsGettingMicrophonePermission( true )
        if ( "MediaRecorder" in window ) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia( {
                    audio: true,
                    video: false,
                } )
                setPermission( true )
                setStream( streamData )
            } catch ( err ) {
                toast( err.message )
            } finally {
                setIsGettingMicrophonePermission( false )
            }
        } else {
            toast( "The MediaRecorder API is not supported in your browser." )
        }
    }

    const handleTabChange = ( e, newVal ) => {
        setTabValue( newVal )
        if ( newVal === 'record' && !permission )
            getMicrophonePermission()
    }

    const handleAudioFileInput = async ( e ) => {
        try {
            if ( multiple ) {
                for ( let i = 0; i < e.target.files.length; i++ ) {
                    const audioFile = e.target.files[i]
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        if ( audioFile.size / 1024 < 1024 ) {
                            setSelectedAudioFiles( p => [...p, audioFile] )
                            setSelectedAudioFilesData( p => [...p, [audioFile.name, fileReader.result]] )
                        }
                    }
                    fileReader.readAsDataURL( audioFile )
                }
            } else {
                const audioFile = e.target.files[0]
                const fileReader = new FileReader()
                fileReader.onload = async () => {
                    const audioContext = new AudioContext()
                    const response = await fetch( fileReader.result )
                    const buffer = await response.arrayBuffer()
                    const audioBuffer = await audioContext.decodeAudioData( buffer )
                    const duration = audioBuffer.duration
                    if ( duration > maxLength ) {
                        toast( `Selected file exceds maximum audio length(${maxLength} min)` )
                    } else {
                        setSelectedAudioFilesData( [[audioFile.name, fileReader.result]] )
                        setSelectedAudioFiles( [audioFile] )
                    }
                }
                fileReader.readAsDataURL( audioFile )
            }
            e.target.value = ''
        } catch ( err ) { }
    }

    const startRecording = async () => {
        setRecorderStatus( "recording" )
        const media = new MediaRecorder( stream, { type: mimeType } )
        mediaRecorder.current = media
        mediaRecorder.current.start()
        let localAudioChunks = []
        mediaRecorder.current.ondataavailable = ( event ) => {
            if ( typeof event.data === "undefined" ) return
            if ( event.data.size === 0 ) return
            localAudioChunks.push( event.data )
        }
        setAudioChunks( localAudioChunks )
    }

    const stopRecording = () => {
        mediaRecorder.current.stop()
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob( audioChunks, { type: mimeType } )
            const audioUrl = URL.createObjectURL( audioBlob )
            if ( multiple ) {
                const recordedFile = new File( [audioBlob], `voice${Date.now()}.wav`, { type: audioFileType } )
                setSelectedAudioFilesData( [...selectedAudioFilesData, [recordedFile.name, audioUrl]] )
                setSelectedAudioFiles( [...selectedAudioFiles, recordedFile] )
            }
            else {
                const recordedFile = new File( [audioBlob], `voice${Date.now()}.wav`, { type: audioFileType } )
                setSelectedAudioFilesData( [[recordedFile.name, audioUrl]] )
                setSelectedAudioFiles( [recordedFile] )
            }
            setAudioChunks( [] )
        }
        setRecorderStatus( "stoped" )
        setRecordLength( 0 )
    }

    const removeAudioFile = ( index ) => {
        const updatedFilesDataList = [...selectedAudioFilesData]
        const updatedFilesList = [...selectedAudioFiles]
        updatedFilesDataList.splice( index, 1 )
        updatedFilesList.splice( index, 1 )
        setSelectedAudioFilesData( updatedFilesDataList )
        setSelectedAudioFiles( updatedFilesList )
    }

    useEffect( () => {
        let minutes = Math.floor( recordLength / 60 )
        let seconds = recordLength % 60
        setTimer( `${leadingZeroFormatter.format( minutes )}:${leadingZeroFormatter.format( seconds )}` )
    }, [recordLength] )

    useEffect( () => {
        let interval = null
        if ( recorderStatus === "recording" ) {
            interval = setInterval( () => {

                setRecordLength( prev => {
                    if ( prev + 1 === parseInt( maxLength ) ) {
                        clearInterval( interval )
                        stopRecording()
                    }
                    return prev + 1
                } )
            }, 1000 )
        } else {
            clearInterval( interval )
        }
        return () => {
            clearInterval( interval )
        }
        // eslint-disable-next-line
    }, [recorderStatus] )

    useEffect( () => {
        if ( selectedAudioFiles.length === 0 ) {
            setSelectedAudioFilesData( [] )
        }
    }, [selectedAudioFiles] )


    useEffect( () => {
        maxPossibleAudioLength = 0
        let minutes = Math.floor( maxLength / 60 )
        let seconds = maxLength % 60
        maxPossibleAudioLength = `${leadingZeroFormatter.format( minutes )}:${leadingZeroFormatter.format( seconds )}`
    }, [maxLength] )

    return (
        <Paper elevation={container === 'paper' ? 1 : 0} sx={{ border: container === 'paper' ? "none" : "1px solid #d3d3d3", "&:hover": { border: container === 'paper' ? "none" : "1px solid #444" } }}>
            <Box borderRadius="5px 5px 0 0" borderBottom="1px solid #d3d3d3" paddingRight="10px" display="flex" justifyContent="space-between">
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                >
                    <Tab value='browse' sx={{ textTransform: "capitalize", color: tabValue === 'browse' ? "secondary.main" : "none" }} label={<Typography display="flex" gap="5px" alignItems="center"> <FolderOpenOutlined sx={{ fontSize: "18px" }} /> Browse System</Typography>} />
                    <Tab value='record' sx={{ textTransform: "capitalize", color: tabValue === 'record' ? "secondary.main" : "none" }} label={<Typography display="flex" gap="5px" alignItems="center"> <Mic sx={{ fontSize: "18px" }} />Record Audio</Typography>} />
                </Tabs>
                <IconButton color='error' size="small" onClick={() => { setRecordedCardStatus( false ); setSelectedAudioFiles( [] ) }}>
                    {Icons.CloseIcon}
                </IconButton>
            </Box>
            <Box position="relative" flexWrap="wrap" borderBottom="1px solid #d3d3d3" display="flex" alignItems="center" gap="10px">
                {tabValue === 'record' && <Box borderRadius="0 0 5px 5px" display="flex" flexDirection="column" width="100%" justifyContent="center" height="200px" alignItems="center" gap="10px">
                    <Box display="flex" borderRadius="0 0 5px 5px" gap="10px" alignItems="center">
                        {( recorderStatus === "idle" || recorderStatus === "stoped" ) && permission && <Tooltip title={recorderStatus === "stoped" ? "Start new recording" : "Start recording"}>
                            <IconButton color="secondary" disabled={!permission} sx={{ background: "#eee" }} size="small" onClick={startRecording}>
                                <KeyboardVoiceOutlined sx={{ fontSize: "40px" }} />
                            </IconButton>
                        </Tooltip>}
                        {( recorderStatus === "recording" || recorderStatus === "paused" ) &&
                            <Box display="flex" alignItems="center" gap="10px">
                                <Tooltip title={recorderStatus === "idle" ? "Start Recording" : recorderStatus === "paused" ? "Resume Recording" : "Pause Recording"}>
                                    <IconButton sx={{ border: "1px solid #d3d3d3" }} size="small"
                                        onClick={() => {
                                            if ( recorderStatus === "paused" ) {
                                                mediaRecorder.current.resume()
                                                setRecorderStatus( "recording" )
                                            } else {
                                                mediaRecorder.current.pause()
                                                setRecorderStatus( "paused" )
                                            }
                                        }}>
                                        {recorderStatus === "paused" ? <PlayArrow sx={{ fontSize: "40px" }} /> : <Pause sx={{ fontSize: "40px" }} />}
                                    </IconButton>
                                </Tooltip>
                                <KeyboardVoiceOutlined className='blink' sx={{ fontSize: "40px", background: "rgba(200,0,0,0.6)", color: "#fff", padding: "5px", borderRadius: "50%" }} />
                                <Tooltip title="Stop Recording">
                                    <IconButton sx={{ border: "1px solid #d3d3d3" }} size="small" onClick={stopRecording}>
                                        <Stop sx={{ fontSize: "40px" }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>}
                    </Box>
                    {( recorderStatus === "recording" || recorderStatus === "paused" ) && <Typography borderRadius="0 0 5px 5px" variant='subtitle2' fontSize="20px" alignItems="center" display="flex" gap="5px"> {timer} / {maxPossibleAudioLength}</Typography>}
                    {permission && <Typography variant="subtitle2" color="GrayText" >
                        {recorderStatus === "idle" && "Start Recording"}
                        {recorderStatus === "recording" && "Recording"}
                        {recorderStatus === "paused" && "Recording Paused"}
                        {recorderStatus === "stoped" && "Start New Recording"}
                    </Typography >}
                    <Typography variant="subtitle2" fontSize="12px" >
                        Max Length: {maxLength} seconds
                    </Typography >
                    {!permission && <Box>
                        {!isGettingMicrophonePermission && <Button onClick={getMicrophonePermission} variant='outlined' sx={{ textTransform: "capitalize" }} color="warning" >
                            Allow microphone permission
                        </Button>}
                        {isGettingMicrophonePermission && <Typography display="flex" gap="10px" alignItems="center" variant="subtitle2" color="GrayText" >
                            <CircularProgress size={14} /> Getting required permissions
                        </Typography>}
                    </Box>}
                </Box>}
            </Box>
            {tabValue === 'browse' && <Box
                onDragOver={( e ) => { e.preventDefault(); setDraggingOver( true ) }}
                onDragEnter={() => setDraggingOver( true )}
                onDragLeave={() => setDraggingOver( false )}
                onDrop={() => setDraggingOver( false )}
                position="relative"
                height="200px"
                bgcolor={draggingOver ? "#fff" : "#eee"}
                flexDirection="column"
                gap="5px"
                padding="20px 10px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="0 0 5px 5px"
            >
                <AudioFileOutlined sx={{ fontSize: "45px" }} />
                <Typography variant='subtitle2' color="TextSecondary">
                    Drag and Drop or <Typography color="secondary" fontSize="14px" fontWeight="500" display="inline-block" sx={{ textDecoration: "underline", fontStyle: "italic", cursor: "pointer" }}>Browse</Typography> audio file
                </Typography>
                <Typography fontSize="12px" textTransform="uppercase">
                    Supported formats: {supportedAudioFormats.join( ', ' )}
                </Typography>
                <Typography variant="subtitle2" fontSize="12px">
                    Max Size: {maxSize / 1024} MB
                </Typography>
                <input multiple={multiple} accept='audio/webm,audio/mpeg,audio/mp3,audio/ogg' onInput={handleAudioFileInput} style={{ position: "absolute", cursor: "pointer", opacity: "0", top: "0", left: "0", width: "100%", height: "100%" }} type='file' />
            </Box>}
            {selectedAudioFilesData.length > 0 && <Box padding="5px" display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                {selectedAudioFilesData.map( ( audio, index ) => (
                    <Box border="1px solid #d3d3d3" display="flex" gap="5px" padding="2px 10px" alignItems="center" key={index}>
                        <AudioPlayer audioName={audio[0]} audioSrc={audio[1]} />
                        <Tooltip title="Remove this audio file">
                            <IconButton onClick={() => removeAudioFile( index )} color="error">
                                <CancelOutlined sx={{ fontSize: "20px" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) )}
            </Box>}
        </Paper>
    )
}

AudioRecorder.propTypes = {
    labelVariant: PropTypes.oneOf( ['body1', 'body2', 'button', 'caption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'inherit', 'overline', 'subtitle1', 'subtitle2', null, undefined] ),
    container: PropTypes.oneOf( ['paper', 'bordered-box'] )
}

export default AudioRecorder


