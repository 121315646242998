import { Box, CircularProgress, IconButton, Tooltip, Card, CardContent, TableContainer, Typography, Paper, Tab, FormControl, InputLabel, Select, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, Chip, TextField, useTheme, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridViewIcon from '@mui/icons-material/GridView'
import ListView from '../../components/exams-components/ListView'
import GridView from '../../components/exams-components/GridView'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { useExamStore } from '../../store/exam.store'
import { useOrganizationStore } from '../../store/organization.store'
import noExamImg from '../../assets/noexams.svg'
import { TabContext, TabList } from '@mui/lab'
import { Icons, flatColors } from '../../utils/utilities'
import { observer } from 'mobx-react'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import ExamViewer from '../../components/admin/ExamViewer'
import api from '../../service/api'
import SearchIcon from '@mui/icons-material/Search'

const columns = [
    { id: 0, name: "Sl.no", paddingLeft: "20px", minWidth: "30px", width: "50px" },
    { id: 1, name: "Title", minWidth: "100px", width: "100px" },
    { id: 3, name: "Details", minWidth: "100px", width: "110px" },
    { id: 2, name: "Exam Date", minWidth: "100px", width: "100px" },
]


const Exams = observer( () => {
    const navigate = useNavigate()
    const UserStore = useUserStore()
    const ExamStore = useExamStore()
    const OrganizationStore = useOrganizationStore()
    const [isInstitutesLoading, setIsInstitutesLoading] = useState( false )
    const [inProgress, setInProgresss] = useState( true )
    const [isExamsLoading, setIsExamsLoading] = useState( false )
    const [totalMarks, setTotalMarks] = useState( 0 )
    const [participations, setParticipations] = useState( [] )
    const [filteredParticipations, setFilteredParticipations] = useState( [] )
    const [questions, setQuestions] = useState( [] )
    const [tabValue, setTabValue] = useState( "manage" )

    const { palette } = useTheme()

    const [searchParams, setSearchParams] = useSearchParams( { mode: "manage", searchText: "", instituteCode: "0", view: window.localStorage.getItem( 'aliveExamsViewType' ) ? window.localStorage.getItem( 'aliveExamsViewType' ) : "grid" } )
    const instituteCode = searchParams.get( 'instituteCode' )
    const mode = searchParams.get( 'mode' )
    const searchText = searchParams.get( "searchText" )
    const view = searchParams.get( "view" )

    const setMode = ( newMode ) => {
        setSearchParams( prev => {
            prev.set( 'mode', newMode )
            return prev
        }, { replace: true } )
    }

    const getExams = async () => {
        try {
            await ExamStore.fetchExams()
        } catch ( e ) {
            if ( "Network Error" === e.message ) {
                navigate( '/networkerror' )
            }
            else
                toast.error( e.response.data.message )
        } finally {
            setInProgresss( false )
        }
    }

    const getAuditData = async () => {
        try {
            if ( UserStore.getUser.user_auditor ) {
                setIsInstitutesLoading( true )
                await OrganizationStore.fetchOrganization()
                setIsInstitutesLoading( false )
                try {
                    if ( instituteCode !== "" ) {
                        setIsExamsLoading( true )
                        await ExamStore.fetchExamsByInstitute( instituteCode )
                    }
                } catch ( err ) {
                    console.log( err )
                } finally {
                    setIsExamsLoading( false )
                }
            } else {
                await ExamStore.fetchExamsByInstitute( UserStore.getUser.user_org_code )
            }
        } catch ( err ) {
            console.log( err )
        }
    }

    const handleTabChange = async ( e, newValue ) => {
        // setSearchParams( prev => {
        //     prev.set( 'instituteCode', "0" )
        //     return prev
        // }, { replace: true } )
        setTabValue( newValue )
        setSearchParams( prev => {
            prev.set( 'mode', newValue )
            return prev
        }, { replace: true } )
        if ( newValue === "list" ) {
            await getAuditData()
        } else {
            await getExams()
        }
    }

    // AUDIT FUNCTIONS

    const openExam = async ( id ) => {
        setIsExamsLoading( true )
        await ExamStore.fetchExam( id )
        console.log( ExamStore.getExam )
        const questionsRes = await api.fetchQuestions( id )
        setQuestions( questionsRes.data )
        setSearchParams( prev => {
            prev.set( 'mode', "view" )
            return prev
        }, { replace: true } )
        setIsExamsLoading( false )
    }

    const handleInstituteChange = async ( event ) => {
        setSearchParams( prev => {
            prev.set( 'instituteCode', event.target.value )
            return prev
        }, { replace: true } )
        setIsExamsLoading( true )
        try {
            await ExamStore.fetchExamsByInstitute( event.target.value )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsExamsLoading( false )
        }
    }

    useEffect( () => {
        const getExams = async () => {
            try {
                await ExamStore.fetchExams()
            } catch ( e ) {
                if ( "Network Error" === e.message ) {
                    navigate( '/networkerror' )
                }
                else
                    toast.error( e.response.data.message )
            } finally {
                setInProgresss( false )
            }
        }
        getExams()
    }, [ExamStore, navigate] )

    return (
        <Box sx={{ padding: "20px", paddingTop: "0" }}>
            {( UserStore.getUser.user_role === 'PRINCIPAL' || UserStore.getUser.user_auditor ) && <TabContext value={tabValue}>
                <Box bgcolor="#fff" borderBottom="none">
                    <TabList sx={{ borderBottom: "2px solid rgba(0,0,0,0.2)" }} onChange={handleTabChange}>
                        <Tab iconPosition='start' sx={{ "&:hover": { background: "#eee" } }} icon={Icons.DescriptionIcon} value={"manage"} label="Your Exams" />
                        <Tab iconPosition='start' sx={{ "&:hover": { background: "#eee" } }} icon={Icons.InsightsIcon} value={"list"} label="Audit" />
                    </TabList>
                </Box>
            </TabContext>}
            <Paper sx={{ height: "100%", overflow: "auto" }} >
                <Box padding="20px 20px 20px 20px" display="flex" borderBottom="1px solid #d3d3d3" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant='h5'>{mode === "manage" ? "Exams" : "Exams audit"}</Typography>
                        <Typography variant='subtitle2' color="textSecondary">{mode === "manage" ? "View all your exams" : UserStore.getUser.user_role === "PRINCIPAL" ? `Audit ${UserStore.getUser.user_org_code} Exams` : "Audit all the exams across institutes"}</Typography>
                    </Box>
                    {!inProgress && <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                        <Box>
                            <Tooltip title="List view">
                                <IconButton onClick={() => { setSearchParams( prev => { prev.set( "view", 'list' ); return prev }, { replace: true } ); window.location.hash = "#list"; window.localStorage.setItem( 'aliveExamsViewType', 'list' ) }}><FormatListBulletedIcon fontSize='small' sx={view === "list" ? { color: "#273679 !important", fontWeight: "bolder" } : null} /></IconButton>
                            </Tooltip>
                            <Tooltip title="Grid view">
                                <IconButton onClick={() => { setSearchParams( prev => { prev.set( "view", 'grid' ); return prev }, { replace: true } ); window.location.hash = "#grid"; window.localStorage.setItem( 'aliveExamsViewType', 'grid' ) }}><GridViewIcon fontSize='small' sx={view === "grid" ? { color: "#273679 !important", fontWeight: "bolder" } : null} /></IconButton>
                            </Tooltip>
                        </Box>
                        {UserStore.getUser.user_role !== 'STUDENT' && mode === "manage" && <Button variant="contained" startIcon={Icons.AddIcon} sx={{ textTransform: "capitalize" }} onClick={() => navigate( "/faculty/addexam" )} color='primary'>
                            create
                        </Button>}
                    </Box>}
                </Box>
                {mode === "manage" && <Box>
                    {ExamStore.getExams.length !== 0 && !inProgress && view === 'grid' && <GridView exams={ExamStore.getExams} userRole={UserStore.getUser.user_role} />}
                    {ExamStore.getExams.length !== 0 && !inProgress && view === 'list' && <ListView exams={ExamStore.getExams} userRole={UserStore.getUser.user_role} />}
                </Box>}
                {mode !== "manage" && <Box marginTop="10px">
                    {UserStore.getUser.user_auditor && mode !== "manage" && !( mode === "view" || mode === "details" ) && < Box padding="0 20px">
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Select Institute</InputLabel>
                            <Select size="small" variant="outlined" disabled={isInstitutesLoading} onChange={handleInstituteChange} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={instituteCode} label="Select Institute---">
                                <MenuItem sx={{ fontSize: "14px" }} value="0">{isInstitutesLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                                {
                                    OrganizationStore.getOrganization.map( ( obj, i ) => {
                                        return <MenuItem sx={{ fontSize: "14px", textTransform: "capitalize" }} value={obj.school_name_short} key={'key-' + i}> {( obj.school_name ).toLowerCase()} </MenuItem>
                                    } )
                                }
                            </Select>
                        </FormControl>
                        <TextField onChange={( e ) => setSearchParams( prev => { prev.set( "searchText", e.target.value ); return prev }, { replace: true } )} value={searchText} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px" }, startAdornment: <SearchIcon sx={{ marginRight: "10px" }} /> }} sx={{ margin: "10px 0 20px 0", border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", minWidth: "150px", width: "100%", color: "black" }} placeholder="Search by exam name" variant="standard" type="text" />
                    </Box>}
                    <Box>
                        {view === 'grid' && mode === "list" && ExamStore.getExams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).length > 0 &&
                            <Box display="flex" padding="0 20px 20px 20px" gap="10px" flexWrap="wrap">
                                {ExamStore.getExams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).map( ( exam, index ) => <Card onClick={() => openExam( exam.exam_id )} key={exam.exam_id} sx={{ position: "relative", height: "fit-content", maxWidth: "270px", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" } }} elevation={1}>
                                    <CardContent sx={{ display: "grid", cursor: "pointer", height: "100%", gridTemplateRows: "40% 60%", padding: "0 !important", "&:hover": { gridTemplateRows: "fit-content auto" } }}>
                                        <div style={{ color: "white", height: "100px", padding: "10px 15px", gap: "10px", display: "flex", alignItems: "flex-end", justifyContent: "space-between", background: flatColors[index % flatColors.length] }}>
                                            <Typography variant='h6' noWrap fontSize="16px" title={exam.title} maxWidth="160px" textTransform="capitalize">{exam.exam_name} </Typography>
                                            <Tooltip title={exam.exam_status === 'Published' ? "Published" : "Draft"}>
                                                <Box bgcolor="#fff" display="flex" alignItems="center" justifyContent="center" width="20px" borderRadius="50%" height="20px" top="10px" right="10px" fontWeight="bold" color={exam.exam_status !== 'Published' ? "black" : "green"} fontSize="14px" textTransform="uppercase">{exam.exam_status[0]}</Box>
                                            </Tooltip>
                                        </div>
                                        <Box display="flex" gap="5px" flexWrap="nowrap" flexDirection="column" justifyContent="center" padding="20px 15px">
                                            <Box display="flex" maxWidth="100px" flexWrap="nowrap" gap="5px">
                                                {exam.exam_topics.slice( 0, 2 ).map( ( topic, i ) => (
                                                    <Chip key={topic} size="small" title={topic} sx={{ width: "fit-content", maxWidth: "100px", background: flatColors[i % flatColors.length], color: "white" }} label={topic} />
                                                ) )}
                                                {exam.exam_topics.length > 2 && <Chip key={"morechips"} size="small" sx={{ width: "fit-content", background: flatColors[0], color: "white" }} label={`+${exam.exam_topics.length - 2} more`} />}
                                            </Box>
                                            <Typography variant='subtitle2' textAlign="justify" fontSize="12px" color="textSecondary"> {`${exam.exam_description.substring( 0, 60 )}${exam.exam_description.length > 60 ? "..." : ""}`}</Typography>
                                            <Typography variant='subtitle2' textAlign="justify" fontSize="12px" color="primary">Exam Date: {formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</Typography>
                                            <Typography variant='subtitle2' textAlign="justify" fontSize="12px">By: {exam?.created_by_data?.employee_name}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card> )}
                            </Box>}

                        {view === 'list' && mode === "list" && ExamStore.getExams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).length > 0 &&
                            <TableContainer sx={{ background: "white", maxHeight: "450px" }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map( ( column ) => (
                                                <TableCell key={column.id} align="left" sx={{ paddingLeft: column.paddingLeft, background: palette.primary.main, color: "#fff", minWidth: `${column.minWidth} !important`, width: column.width, }}>{column.name}</TableCell>
                                            ) )}
                                            <TableCell key={4} align="right" sx={{ background: palette.primary.main, color: "#fff", minWidth: "100px", width: "100px", }}>Created By</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ExamStore.getExams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).map( ( exam, index ) => (
                                            <TableRow onClick={() => openExam( exam.exam_id )} key={exam.exam_id} sx={{ cursor: "pointer", "&:hover": { background: "#eee" } }}>
                                                <TableCell sx={{ paddingLeft: "20px" }}>{index + 1}</TableCell>
                                                <TableCell>{exam.exam_name} <Tooltip title={exam.exam_status === 'Published' ? "Published" : "Draft"}>
                                                    <Chip size='small' sx={{ color: exam.exam_status === 'Published' ? "green" : "gray", fontSize: "12px" }} label={exam.exam_status.toUpperCase().substring( 0, 1 )}></Chip>
                                                </Tooltip></TableCell>
                                                <TableCell style={{ fontSize: "12px" }}>{`${exam.exam_description.substring( 0, 50 )}${exam.exam_description.length > 50 ? "..." : ""}`}</TableCell>
                                                <TableCell style={{ fontSize: "12px", textTransform: "uppercase" }}>{formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</TableCell>
                                                <TableCell key={4} align="right" sx={{ minWidth: "50px", width: "50px", background: "transparent" }} >{exam.created_by_data?.employee_name ? exam.created_by_data.employee_name : exam.created_by}</TableCell>
                                            </TableRow>
                                        ) )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Box>
                    {( mode === "view" || mode === "details" ) &&
                        <ExamViewer participations={participations} setParticipations={setParticipations} totalMarks={totalMarks} setTotalMarks={setTotalMarks} setFilteredParticipations={setFilteredParticipations} filteredParticipations={filteredParticipations} setMode={setMode} isExamsLoading={isExamsLoading} setIsExamsLoading={setIsExamsLoading} mode={mode} examQuestion={questions} />
                    }
                </Box>}
                {( inProgress ) && < Typography padding="20px" variant='h6'> <CircularProgress size={20} sx={{ marginRight: "10px" }} /> Loading exams... </Typography>}
                {
                    !inProgress && ( ExamStore.getExams.length === 0 ) && <Typography flexDirection="column" display="flex" alignItems="center" padding="20px" bgcolor="#ddd" gap="20px" color="textSecondary" variant='subtitle2'>
                        <img src={noExamImg} width="200px" alt="no exams!" />
                        No exams to show! {UserStore.getUser.user_role !== 'STUDENT' && mode === "manage" && <Button startIcon={Icons.AddIcon} sx={{ textTransform: "capitalize" }} variant="outlined" onClick={() => navigate( `/faculty/addexam` )} >Create one</Button>}
                    </Typography>
                }
            </Paper >
        </Box >
    )
} )

export default Exams
