import { Box, Card, CardContent, Chip, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { formatDateTimeForDisplay, flatColors, calculateDurationBetween } from '../../utils/exam-utilities'
import { Link } from 'react-router-dom'

export default function GridView( { exams, userRole, isAuditing } ) {
    return (
        <Box padding="0 20px 20px 20px" sx={{ display: "flex", flexWrap: "wrap", gap: "20px", gridTemplateColumns: { lg: "1fr 1fr 1fr 1fr", md: "1fr 1fr 1fr", sm: "1fr 1fr", xs: "1fr" } }}>
            {exams.length > 0 && exams.map( ( exam, index ) => (
                <Box key={index} sx={{ marginTop: "10px", width: "270px", maxWidth: "270px", maxHeight: "450px" }}>
                    <Link to={userRole === "STUDENT" ? `/student/exams/${exam.exam_id}` : `/faculty/exams/${exam.exam_id}`} style={{ margin: "10px 0" }}>
                        <Card sx={{ position: "relative", height: "fit-content", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" } }} elevation={1}>
                            <CardContent sx={{ display: "grid", height: "100%", gridTemplateRows: "40% 60%", padding: "0 !important", "&:hover": { gridTemplateRows: "fit-content auto" } }}>
                                <Box sx={{ color: "white", height: "80px", padding: "10px 15px", gap: "10px", display: "flex", alignItems: "flex-end", justifyContent: "space-between", background: flatColors[index % flatColors.length] }}>
                                    <Box>
                                        <Typography variant='h6' noWrap fontSize="18px" title={exam.title} maxWidth="180px" textTransform="capitalize">{exam.exam_name} </Typography>
                                        {userRole === "STUDENT" && <Typography fontSize="12px" width="99%" noWrap>{exam.created_by_data?.employee_name}</Typography>}
                                    </Box>
                                    {userRole !== "STUDENT" &&
                                        <Box position="absolute" right="10px" top="65px">
                                            <Tooltip title={exam.exam_status === 'Published' ? "Published" : "Draft"}>
                                                <Box bgcolor="#fff" display="flex" alignItems="center" justifyContent="center" width="20px" borderRadius="50%" height="20px" top="10px" right="10px" fontWeight="bold" color={exam.exam_status !== 'Published' ? "black" : "green"} fontSize="14px" textTransform="uppercase">{exam.exam_status[0]}</Box>
                                            </Tooltip>
                                        </Box>
                                    }
                                </Box>
                                <Box display="flex" gap="5px" flexWrap="nowrap" flexDirection="column" justifyContent="center" padding="20px 15px">
                                    <Box display="flex" maxWidth="100px" flexWrap="nowrap" gap="5px">
                                        {exam.exam_topics.slice( 0, 2 ).map( topic => (
                                            <Chip key={topic} size="small" title={topic} sx={{ width: "fit-content", maxWidth: "100px", background: flatColors[Math.floor( Math.random() * flatColors.length )], color: "white" }} label={topic} />
                                        ) )}
                                        {exam.exam_topics.length > 2 && <Chip key={"morechips"} size="small" sx={{ width: "fit-content", background: flatColors[Math.floor( Math.random() * flatColors.length )], color: "white" }} label={`+${exam.exam_topics.length - 2} more`} />}
                                    </Box>
                                    <Typography sx={{ overflow: "hidden !important", display: "inline-block !important", wordWrap: "break-word !important" }} variant='subtitle2' fontSize="12px" color="textSecondary"> {`${exam.exam_description.substring( 0, 60 )}${exam.exam_description.length > 60 ? "..." : ""}`}</Typography>
                                    <Typography variant='subtitle2' textAlign="justify" fontSize="12px" color="primary">Exam Date: {formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</Typography>
                                    {( exam.exam_duration === "null" || exam.exam_duration === null ) && <Typography variant='subtitle2' textAlign="justify" fontSize="12px" color="GrayText">
                                        Exam Duration: {calculateDurationBetween( { date: exam.exam_start_date, time: exam.exam_start_time }, { date: exam.exam_end_date, time: exam.exam_end_time } )}
                                    </Typography>}
                                    {exam.exam_duration !== "null" && exam.exam_duration !== null && <Typography variant='subtitle2' textAlign="justify" fontSize="12px" color="GrayText" > Exam Duration: {`${exam.exam_duration.split( ":" )[0]} Hrs ${exam.exam_duration.split( ":" )[1]} Min`}</Typography>}
                                </Box>
                            </CardContent>
                        </Card>
                    </Link>
                </Box>
            ) )}
            {exams.length === 0 && <Typography marginX="10px" padding="10px" borderRadius="5px" sx={{ background: "#eee" }} variant='h5' className='mt-2' >No exams!</Typography>}
        </Box>
    )
}
