import React, { useCallback, useEffect, useState } from 'react'
import api from '../../service/api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import alivelogo from '../../assets/alivelogo.png'
import warning from '../../assets/warning.svg'
import { toast } from 'react-toastify'
import { erpResultsPageLink } from '../../utils/utilities'

const Verify = () => {
    const [exceedsMaximumNumberOfAttempts, setExceedsMaximumNumberOfAttempts] = useState( false )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const [searchParams] = useSearchParams()
    const application_no = searchParams.get( 'application_no' )

    const navigate = useNavigate()

    const navigateToResultsPage = () => {
        window.location = `${erpResultsPageLink}?ref_no=${encodeURIComponent( application_no )}`
    }

    const attempt = useCallback( async ( id ) => {
        try {
            // setAttempting( true )
            const { status, data } = await api.admissionExams.attempt( id )
            if ( status === 200 ) {
                if ( !data.isNewParticipation ) toast.success( "Proceeding with the previously unfinished exam!", { containerId: "main" } )
                const url = `/admission-exam/${id}/${data.participation_id}/attempt/${data.attempt}`

                navigate( url, { replace: true } )
            }
        } catch ( e ) {
            console.log( e )
            if ( ( e?.response?.data?.message || e?.response?.data ) === "Maximum attempts already over!" )
                toast( "Maksimal urinishlar soni allaqachon tugadi" )
            else
                toast.error( e?.response?.data?.message || e?.response?.data || "Something went wrong", { containerId: "main" } )
        } finally {
            // setAttempting( false )
        }
    }, [navigate] )

    const verifyUser = useCallback( async () => {
        setLoading( true )
        try {
            const { data } = await api.admissionExams.verifyCandidate( { application_no: application_no?.replace( /%2F/gi, "/" ) } )
            if ( data.exceedsMaximumNumberOfAttempts )
                setExceedsMaximumNumberOfAttempts( true )
            else {
                localStorage.setItem( "id_token", data.token )
                localStorage.setItem( 'admission_exam_candidate_ref_no', application_no?.replace( /%2F/gi, "/" ) )
                await attempt( data.exam_id )
            }
            // navigate( `/take-admission-exam/${data.exam_id}` )
        } catch ( err ) {
            console.log( err )
            setError( { code: err?.response?.status, message: err?.response?.data?.message } )
        } finally {
            setLoading( false )
        }
    }, [application_no, attempt] )


    useEffect( () => {
        verifyUser()
    }, [verifyUser] )
    return (
        <Box sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" height="100%" alignItems="center" justifyContent="center">
            {loading && !error && <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                <img width="70px" src={alivelogo} alt="Alive" />
                <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px"> <CircularProgress size={14} /> Loading Alive...</Typography>
            </Paper>}
            {!loading && exceedsMaximumNumberOfAttempts && <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                <img width="70px" src={warning} alt="Alive" />
                <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px">Siz allaqachon imtihonni yakunlagansiz!</Typography>
                <Button color="secondary" variant='contained' disableElevation sx={{ textTransform: "capitalize" }} onClick={navigateToResultsPage} >Natijangizni tekshiring</Button>
            </Paper>}
            {error && <TryAgainMessageBlock err={error?.message} code={error.code} getData={verifyUser} loading={loading} />}
        </Box>
    )
}

export default Verify
