import React, { useState } from 'react'
import { Drawer, IconButton, Box, List, Divider, ListItem, ListItemButton, Icon, ListItemText, Typography, ListItemIcon, Collapse, useTheme } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Icons } from '../../utils/utilities'
import { Link } from 'react-router-dom'
import { useLayoutContext } from '../../contexts/layout.context'

// const iconList = [<DashboardIcon fontSize='13px' />, <SchoolIcon fontSize='13px' />, <PeopleAltIcon fontSize='13px' />, <EventIcon fontSize='13px' />, <AccountBalanceWalletIcon fontSize='13px' />, <CalendarMonthIcon fontSize='13px' />, <MessageIcon fontSize='13px' />, <NotificationsIcon fontSize='13px' />]

const menu = [
    { active: true, label: "Home", children: null, link: `/student/home`, icon: Icons.HomeIcon },
    { active: true, label: "Study Material", children: null, link: `/student/material`, icon: Icons.DescriptionIcon },
    { active: true, label: "Assignment", children: null, link: `/student/assignment`, icon: Icons.AssignmentIcon },
    { active: true, label: "Exam", children: ["Exams", "Scores"], link: ["/student/exams", "/student/scores"], icon: Icons.QuizIcon },
    // { active: true, label: <span>Resume Builder <small style={{ fontSize: "8px", background: "#eee3", padding: "3px", borderRadius: "5px" }}><b><i>BETA</i></b> </small> </span>, children: null, link: "/student/resume-builder", icon: Icons.Protrait },
    { active: false, label: "Online Course", children: ["Online Courses", "Enrolled courses"], link: ["/student/online-course", "/student/my-online-course"], icon: Icons.PlayCircleFilledWhiteIcon },
    { active: true, label: "Attendance", children: null, link: `/student/attendance`, icon: Icons.EmojiPeopleIcon },
    { active: true, label: "Feedback", children: null, link: `/student/feedback`, icon: Icons.FeedbackIcon },
    { active: true, label: "FAQs", children: null, link: `/student/faq`, icon: Icons.LiveHelpIcon }
]

const SideDrawer = function ( { width, closeSideMenu } ) {

    const { setCurrentLoc, currentLoc, sidedrawerState, setSidedrawerState, isSmallScreen } = useLayoutContext()
    const [dropdownStatus, setDropdownStatus] = useState( {} )
    const { palette } = useTheme()

    const closeOnMobileScreen = () => {
        if ( window.innerWidth < 900 )
            closeSideMenu()
    }

    return (
        <>
            <Drawer variant='permanent'
                onMouseEnter={() => setSidedrawerState( true )}
                onMouseLeave={() => !isSmallScreen && ( setSidedrawerState( false ), setDropdownStatus( {} ) )}
                sx={{ width: isSmallScreen ? sidedrawerState ? width : "0px" : sidedrawerState ? width : "60px", flexShrink: 0, height: "100%", zIndex: "1 !important", flexSrink: 0, transition: "0.1s ease-in-out !important" }}
                PaperProps={{
                    sx: {
                        width: isSmallScreen ? sidedrawerState ? width : "0px" : sidedrawerState ? width : "60px",
                        transition: "0.1s ease-in-out",
                        overflowX: sidedrawerState ? "inherit" : "hidden",
                        background: palette.primary.main,
                        color: "white", borderRight: "none",
                        borderRadius: "0"
                    },
                    className: 'slim-custom-scrollbar'
                }}
                anchor="left"
                open={sidedrawerState}>
                <Box sx={{ display: sidedrawerState ? "block" : "none" }} >
                    <Box>
                        <Icon sx={{ position: "fixed", top: "20px", borderRadius: "50%", left: "100%", padding: "10px", background: "#fff", display: "flex", alignItems: "center", justifyContent: "center", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19)", zIndex: 3 }}>
                            <ChevronRightIcon color='primary' />
                        </Icon>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex !important",
                    alignItems: "center !important",
                    padding: "30px 20px !important",
                    justifyContent: "space-between !important",
                }}>
                    <Box display="flex" gap="20px" alignItems="center" position="relative">
                        {/* <Box padding="10px" display="flex" alignItems="center" justifyContent="center" borderRadius="50%" width="60px" height="60px" bgcolor="white">
                            <img src={aliveTextLogo} width="40px" alt="Alive logo" />
                        </Box>
                        <Box>
                            <Typography variant='h6'>ALIVE</Typography>
                            <Typography fontWeight="normal" fontSize="12px">Digital Classroom</Typography>
                        </Box> */}
                        <Box sx={{ display: { md: currentLoc.includes( 'stream' ) ? "block" : "none", xs: "block" }, justifyContent: "flex-end", position: "absolute", right: "-60px", top: "0", transform: "translateY(-50%)" }}>
                            <IconButton sx={{ width: "fit-content", color: "white" }} size='small' onClick={closeSideMenu} >
                                {Icons.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <List>
                    {menu.map( ( item, index ) => {
                        if ( item.active ) {
                            if ( item.children === null ) {
                                return <Link onClick={() => { closeOnMobileScreen(); setCurrentLoc( item.link ) }} key={item.label} to={item.link} style={{ textDecoration: "none !important", color: "inherit !important", display: "flex !important", alignItems: "center !important" }}>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{
                                            color: "white",
                                            background: currentLoc.includes( item.link ) ? "rgba(255,255,255,0.1) !important" : "none",
                                            transition: "0.2s ease !important",
                                            marginY: "2px !important",
                                            "&:hover": {
                                                background: "rgba(255,255,255,0.1) !important",
                                            }
                                        }}>
                                            <Box paddingLeft={sidedrawerState && !isSmallScreen ? "5px" : "0px"} sx={{ transition: "0.2s ease-in-out" }} display="flex" alignItems="center">
                                                <ListItemIcon sx={{ color: currentLoc.includes( item.link ) ? palette.customThemeColor.main : "inherit", }}>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText sx={{ marginLeft: "-5px" }}><Typography noWrap fontSize="14px" sx={{ opacity: sidedrawerState ? 1 : 0, transition: "0.2s ease-in", marginLeft: sidedrawerState ? "0px" : "-10px" }}> {item.label}</Typography> </ListItemText>
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            }
                            else {
                                return <ListItem sx={{ display: 'flex', flexDirection: "column", width: "100%" }} key={index} disablePadding>
                                    <ListItemButton sx={{
                                        color: "white",
                                        background: item.link.includes( currentLoc ) ? "rgba(255,255,255,0.1) !important" : dropdownStatus[item.label] ? "primary.light" : "inherit",
                                        transition: "0.2s ease !important",
                                        marginY: "2px !important",
                                        width: "100%",
                                        "&:hover": {
                                            background: "rgba(255,255,255,0.1) !important",
                                        }
                                    }} onClick={() => setDropdownStatus( { ...dropdownStatus, [item.label]: !dropdownStatus[item.label] } )}>
                                        <Box flexGrow={1} maxHeight="29px" paddingLeft={sidedrawerState && !isSmallScreen ? "5px" : "0px"} sx={{ transition: "0.2s ease-in-out" }} display="flex" alignItems="center">
                                            <ListItemIcon sx={{ color: item.link.includes( currentLoc ) ? palette.customThemeColor.main : "inherit", fontSize: "14px" }}>
                                                {item.icon}
                                            </ListItemIcon>
                                            <Box flexGrow={1} display="flex" justifyContent="space-between" alignItems="center">
                                                <ListItemText sx={{ flexGrow: 1, marginLeft: "-5px" }}><Typography width="100%" fontSize="14px" sx={{ opacity: sidedrawerState ? 1 : 0, transition: "0.2s ease-in", marginLeft: sidedrawerState ? "0px" : "-10px" }}> {item.label}</Typography></ListItemText>
                                                {dropdownStatus[item.label] ? <ExpandLess /> : <ExpandMore />}
                                            </Box>
                                        </Box>
                                    </ListItemButton>
                                    <Collapse sx={{ width: "100%" }} timeout={"auto"} unmountOnExit in={dropdownStatus[item.label] && sidedrawerState}>
                                        <List sx={{ flexDirection: "column", display: "flex" }} component="div" disablePadding>
                                            {item.children.map( ( sublink, k ) => {
                                                return <Link onClick={() => { closeOnMobileScreen(); setCurrentLoc( item.link[k] ) }} to={item.link[k]} key={k}>
                                                    <ListItemButton key={sublink} sx={{
                                                        color: "white",
                                                        background: currentLoc.includes( item.link[k] ) ? "rgba(255,255,255,0.1) !important" : "none",
                                                        transition: "0.2s ease !important",
                                                        marginY: "2px !important",
                                                        paddingLeft: "85px",
                                                        "& .a": {
                                                            width: "100%",
                                                            display: "block"
                                                        },
                                                        "&:hover": {
                                                            background: "rgba(255,255,255,0.1) !important",
                                                        }
                                                    }} >
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            {sublink}
                                                        </Typography>
                                                    </ListItemButton>
                                                </Link>
                                            } )}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </ListItem>
                            }
                        } else {
                            return ''
                        }
                    } )}
                </List>
            </Drawer>
        </>
    )
}

export default SideDrawer



// import DashboardIcon from '@mui/icons-material/Dashboard'
// import SchoolIcon from '@mui/icons-material/School'
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import EventIcon from '@mui/icons-material/Event'
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
// import MessageIcon from '@mui/icons-material/Message'
// import NotificationsIcon from '@mui/icons-material/Notifications'