import { Box, Button, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Divider, IconButton, FormControl, FormControlLabel, Paper, TextField, Typography, InputLabel, FormHelperText, CircularProgress, Select, MenuItem, RadioGroup, Radio } from '@mui/material'
import React, { useEffect } from 'react'
import { Icons } from '../../../utils/utilities'
import { useState } from 'react'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { observer } from 'mobx-react'
import { EMAIL_PATTERN, URL_PATTERN } from '../../../utils/resume-builder-utilities'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

const DateOrPresent = observer( ( { attr, value, attrkey, handleFieldChange } ) => {

    return (
        <Box sx={{ flexGrow: "1", width: "100%" }}>
            <Box display="flex" alignItems="center" gap="5px">
                {value !== "present" && <Box>
                    <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                format='DD/MM/YYYY'
                                slotProps={{ textField: { size: 'small' } }}
                                value={dayjs( value ? value : "" )}
                                onAccept={( e ) => {
                                    handleFieldChange( "dateorpresent", attrkey, new Date( e.$d ).toLocaleDateString( 'en-US' ) )
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Typography variant="subtitle2" sx={{ fontStyle: "italic", fontSize: "12px" }} color="GrayText">Date selected: {value ? new Date( value ).toLocaleDateString( 'en-IN' ) : ""}</Typography>
                </Box>
                }
                <FormControl margin='dense' fullWidth sx={{ flexGrow: "1" }}>
                    <FormControlLabel sx={{ marginLeft: "10px" }} control={<Checkbox size='small' color='primary' onChange={( e ) => handleFieldChange( "dateorpresent", attrkey, e.target.checked ? "present" : "" )} checked={value === "present" ? true : false} />} label={<Typography color="GrayText" fontSize="14px">Present</Typography>} />
                </FormControl>
            </Box>
        </Box>
    )
} )

const CgpaOrPercentage = observer( ( { attr, value, attrkey, handleFieldChange } ) => {

    const [type, setType] = useState( "CGPA" )
    const [fieldValue, setFieldValue] = useState( value )

    const handleChange = ( e ) => {
        setFieldValue( e.target.value )
    }

    const handleBlur = e => {
        let val = e.target.value
        if ( type === "CGPA" ) {
            val = val > 10 ? 10 : val
            val = val < 0 ? 0 : val
            setFieldValue( val )
            handleFieldChange( "cgpaorpercentage", attrkey, `${val} CGPA` )
        } else {
            val = val > 100 ? 100 : val
            val = val < 0 ? 0 : val
            setFieldValue( val )
            handleFieldChange( "cgpaorpercentage", attrkey, `${val}%` )
        }
    }

    return (
        <Box width="100%">
            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
            <RadioGroup sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }} onChange={( e ) => { setType( e.target.value ); setFieldValue( 0 ) }} value={type}>
                <FormControlLabel value="CGPA" control={<Radio size='small' />} label="CGPA" />
                <FormControlLabel value="Percentage" control={<Radio size='small' />} label="Percentage" />
            </RadioGroup>
            <TextField value={fieldValue} size="small" onBlur={handleBlur} onChange={( e ) => handleChange( e )} variant='outlined' inputProps={{ step: "0.1" }} InputProps={{ step: "0.1", startAdornment: <Typography paddingRight="5px" fontSize="14px" color="GrayText">{type}:</Typography> }} type="number" fullWidth />
        </Box>
    )
} )

const MultipleValueArrayInput = observer( ( { attrIndex, attr, values, setSectionResponseJson, sectionResponseJson, attrkey, setChangesMade, saveChanges, section } ) => {

    const [textfieldValue, setTextfieldValue] = useState( '' )
    const handleChange = ( e ) => {
        setTextfieldValue( e.target.value )
    }

    const removeItem = ( i ) => {
        let newValues = [...values]
        newValues.splice( i, 1 )
        setSectionResponseJson( { ...sectionResponseJson, [attrkey]: [...newValues] } )
        saveChanges( section.section_title, { ...sectionResponseJson, [attrkey]: [...newValues] } )
        setChangesMade( true )
    }

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            e.preventDefault()
            setSectionResponseJson( { ...sectionResponseJson, [attrkey]: [...values, textfieldValue] } )
            saveChanges( section.section_title, { ...sectionResponseJson, [attrkey]: [...values, textfieldValue] } )
            setChangesMade( true )
            setTextfieldValue( "" )
        }
    }

    return (
        <Box marginBottom="10px" flexGrow={1} key={attrIndex}>
            <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography>
            <Box marginTop="10px" padding="5px 0 0 5px" borderRadius="5px" border="1px solid #d3d3d3">
                <FormControl sx={{ flexGrow: 1 }} fullWidth>
                    <TextField value={textfieldValue} onChange={handleChange} onKeyDown={handleKeyDown} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px", "@media(max-width:900px)": { width: "100%" } } }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder={`To add ${attr.entity_detail.label}, type and press 'Enter'`} variant="standard" type="text" />
                    {/* <TextField value={textfieldValue} onChange={handleChange} InputProps={{ sx: { outline: "none", border: "none" } }} size="small" type='text' /> */}
                </FormControl>
                <Box padding="10px">
                    <Typography variant='subtitle2' gutterBottom={values.length !== 0} color="GrayText">{values.length === 0 ? `No ${attr.entity_name} Yet` : ``}</Typography>
                    {values.length > 0 && <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
                        {values.map( ( val, i ) => (
                            <Chip key={i} label={val} size='large' deleteIcon={Icons.CloseIcon} onDelete={() => { removeItem( i ) }} sx={{ border: "1px solid #d3d3d3", fontSize: "14px", fontWeight: "600" }} />
                        ) )}
                    </Box>}
                </Box>
            </Box>
        </Box>
    )
} )


const AddEditDialog = observer( ( { section, mode, addMode, save, setAddMode, setSectionResponseJson, sectionResponseJson, setChangesMade, saveChanges, responseJson } ) => {

    const [values, setValues] = useState( {} )
    const [emailTypeErrors, setEmailTypeErrors] = useState( {} )
    const [urlTypeErrors, setUrlTypeErrors] = useState( {} )
    const [fileUploadWait, setFileUploadWait] = useState( {} )
    const [fileValues, setFileValues] = useState( {} )

    const handleFieldChange = ( type, key, value ) => {
        setValues( { ...values, [key]: value } )
    }

    const handleEmailFieldChange = ( attrkey, e ) => {
        if ( EMAIL_PATTERN.test( e.target.value ) ) {
            setEmailTypeErrors( { ...emailTypeErrors, [`${section.section_title}-${attrkey}`]: null } )
        } else {
            setEmailTypeErrors( { ...emailTypeErrors, [`${section.section_title}-${attrkey}`]: true } )
        }
        handleFieldChange( "email", attrkey, e.target.value.trim() )
    }


    const handleUrlFieldChange = ( attrkey, e ) => {
        if ( URL_PATTERN.test( e.target.value ) ) {
            setUrlTypeErrors( { ...urlTypeErrors, [`${section.section_title}-${attrkey}`]: null } )
        } else {
            setUrlTypeErrors( { ...urlTypeErrors, [`${section.section_title}-${attrkey}`]: true } )
        }
        handleFieldChange( "url", attrkey, e.target.value.trim() )
    }

    const handleUrlEithLabelFieldChange = ( attrkey, e ) => {
        if ( URL_PATTERN.test( e.target.value ) ) {
            setUrlTypeErrors( { ...urlTypeErrors, [attrkey]: null } )
        } else {
            setUrlTypeErrors( { ...urlTypeErrors, [attrkey]: true } )
        }
        handleFieldChange( "url", attrkey, e.target.value.trim() )
    }

    const handleFileFieldChange = async ( attrkey, e ) => {
        if ( e.target.files.length > 0 ) {
            try {
                const formData = new FormData()
                formData.append( 'profileFile', e.target.files[0] )
                formData.append( 'section', section.section_title )
                formData.append( 'attrkey', attrkey )
                setFileUploadWait( { ...fileUploadWait, [`${section.section_title}-${attrkey}`]: true } )
                const res = await api.uploadStudentProfileToResume( formData )
                handleFieldChange( "url", attrkey, res.data.fileData.location )
                const fileReader = new FileReader()
                fileReader.onload = ( e ) => {
                    setFileValues( { ...fileValues, [`${section.section_title}-${attrkey}`]: e.target.result } )
                }
                fileReader.readAsDataURL( e.target.files[0] )
            } catch ( err ) {
                console.log( err )
                toast( ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            } finally {
                setFileUploadWait( { ...fileUploadWait, [`${section.section_title}-${attrkey}`]: false } )
                e.target.value = ''
            }
        }
    }

    const addDetail = () => {
        setSectionResponseJson( [...sectionResponseJson, values] )
        saveChanges( section.section_title, [...sectionResponseJson, values] )
        setChangesMade( true )
        setAddMode( false )
        const initialValues = {}
        for ( let i = 0; i < section.attributes.length; i++ ) {
            const group = section.attributes[i]
            for ( let j = 0; j < group.length; j++ ) {
                const attr = group[j]
                if ( attr.entity_detail.data_type === "boolean" )
                    initialValues[attr.entity_detail.key] = false
                else if ( attr.entity_detail.data_type === 'linkwithlabel' ) {
                    initialValues[attr.entity_detail.key] = ''
                    initialValues[`${attr.entity_detail.key}-link`] = ''
                } else if ( attr.entity_detail.data_type === "array" )
                    initialValues[attr.entity_detail.key] = []
                else if ( attr.entity_detail.data_type === "select" )
                    initialValues[attr.entity_detail.key] = ''
                else
                    initialValues[attr.entity_detail.key] = ''
            }
        }
        save( { ...responseJson, [section.section_title]: [...sectionResponseJson, values] } )
        setValues( initialValues )
    }

    useEffect( () => {
        const setInitialValues = () => {
            const initialValues = {}
            for ( let i = 0; i < section.attributes.length; i++ ) {
                const group = section.attributes[i]
                for ( let j = 0; j < group.length; j++ ) {
                    const attr = group[j]
                    if ( attr.entity_detail.data_type === "boolean" )
                        initialValues[attr.entity_detail.key] = false
                    else if ( attr.entity_detail.data_type === 'linkwithlabel' ) {
                        initialValues[attr.entity_detail.key] = ''
                        initialValues[`${attr.entity_detail.key}-link`] = ''
                    }
                    else if ( attr.entity_detail.data_type === "array" )
                        initialValues[attr.entity_detail.key] = []
                    else if ( attr.entity_detail.data_type === "select" )
                        initialValues[attr.entity_detail.key] = ''
                    else
                        initialValues[attr.entity_detail.key] = ''
                }
            }
            setValues( initialValues )
        }
        setInitialValues()
    }, [section] )

    return (
        <Dialog open={addMode}>
            <DialogTitle sx={{ textTransform: "capitalize" }}>{`${mode} ${section.section_title}`}</DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: '10px' }} elevation={section.multiple ? 2 : 0}>
                    {section.attributes.map( ( group, groupIndex ) => (
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }} elevation={section.multiple ? 2 : 0} key={groupIndex}>
                            {/* eslint-disable-next-line */}
                            {group.map( ( attr, attrIndex ) => {
                                const type = attr.entity_detail.data_type
                                const children = attr.entity_detail.children
                                const attrkey = attr.entity_detail.key
                                if ( type === 'string' || type === 'number' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type={type === 'string' ? "text" : "number"} rows={4} />
                                        </Box>
                                    )
                                } else if ( type === 'boolean' ) {
                                    return (
                                        <FormControl margin='dense' fullWidth sx={{ flexGrow: "1" }} key={attrIndex}>
                                            <FormControlLabel sx={{ marginLeft: "10px" }} control={<Checkbox color='primary' onChange={( e ) => handleFieldChange( type, attrkey, e.target.checked )} checked={values[attrkey] ? values[attrkey] : false} />} label={<Typography>{attr.entity_detail.label}</Typography>} />
                                        </FormControl>
                                    )
                                } else if ( type === 'email' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleEmailFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type="email" rows={4} />
                                            {emailTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid E-mail </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'date' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer sx={{ width: "100%" }} components={['DatePicker']}>
                                                    <DatePicker
                                                        disableFuture
                                                        sx={{ width: "100%" }}
                                                        format='DD/MM/YYYY'
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs( values[attrkey] ? values[attrkey] : "" )}
                                                        onAccept={( e ) => {
                                                            handleFieldChange( type, attrkey, new Date( e.$d ).toLocaleDateString( 'en-US' ) )
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            <Typography variant="subtitle2" sx={{ fontStyle: "italic", fontSize: "12px" }} color="GrayText">Date selected: {values[attrkey] ? new Date( values[attrkey] ).toLocaleDateString( 'en-IN' ) : ""}</Typography>
                                        </Box>
                                    )
                                } else if ( type === 'url' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleUrlFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} rows={4} />
                                            {urlTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'linkwithlabel' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth type="text" rows={4} />
                                            <InputLabel sx={{ marginTop: "10px" }}><Typography variant="subtitle2" color="GrayText">{attr.entity_detail.link_label}</Typography> </InputLabel>
                                            <TextField value={values[`${attrkey}-link`] ? values[`${attrkey}-link`] : ""} size="small" onChange={( e ) => handleUrlEithLabelFieldChange( `${attrkey}-link`, e )} variant='outlined' fullWidth type="text" rows={4} />
                                            {urlTypeErrors[`${attrkey}-link`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'file' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <Box display="flex" gap="10px" alignItems="center">
                                                {( fileValues[`${section.section_title}-${attrkey}`] || values[attrkey] ) && <img width="50px" height="50px" alt='profile' src={fileValues[`${section.section_title}-${attrkey}`] ? fileValues[`${section.section_title}-${attrkey}`] : values[attrkey] ? values[attrkey] : ""} style={{ objectFit: "cover", borderRadius: "50%" }} />}
                                                <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="5px" sx={{ cursor: "pointer", "&:hover": { background: "#efefef" } }} border="1px solid #d3d3d3" position="relative" padding="5px 10px">
                                                    {!fileUploadWait[`${section.section_title}-${attrkey}`] && <Box width="fit-content" display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                                                        <Button sx={{ textTransform: "capitalize", color: "GrayText" }} startIcon={Icons.UploadFileIcon} >Upload file</Button>
                                                        <Typography fontSize="12px" color="GrayText">Accepted formats: .JPG, .JPEG and .PNG</Typography>
                                                    </Box>}
                                                    {fileUploadWait[[`${section.section_title}-${attrkey}`]] && <Typography display="flex" alignItems="center" gap="10px" variant="subtitle2" color="GrayText"> <CircularProgress size={20} /> Uploading your file...  </Typography>}
                                                    <Box sx={{ opacity: "0", position: "absolute", top: "0", right: "0", bottom: "0", left: "0" }}>
                                                        <input onChange={( e ) => handleFileFieldChange( attrkey, e )} accept='image/gif,image/jpeg,image/png' style={{ width: "100%", height: "100%", cursor: "pointer" }} type='file' />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                } else if ( type === 'cgpaorpercentage' ) {
                                    let fieldValue = values[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue.includes( "%" ) ) {
                                            fieldValue = parseInt( fieldValue.replace( '%', "" ) )
                                        } else {
                                            fieldValue = parseInt( fieldValue.replace( ' CGPA', "" ) )
                                        }
                                    }
                                    return <CgpaOrPercentage key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'dateorpresent' ) {
                                    let fieldValue = values[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue !== "present" )
                                            fieldValue = dayjs( fieldValue )
                                    }
                                    return <DateOrPresent key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'select' ) {
                                    return ( <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                        <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                        <Select value={values[attrkey] ? values[attrkey] : ''} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth>
                                            <MenuItem value={""} disabled>Select {attr.entity_detail.label}</MenuItem>
                                            {children.map( child => (
                                                <MenuItem key={child} value={child}>{child}</MenuItem>
                                            ) )}
                                        </Select>
                                    </Box> )
                                }
                                else {
                                    return (
                                        <MultipleValueArrayInput saveChanges={saveChanges} setChangesMade={setChangesMade} responseJson={responseJson} section={section} sectionResponseJson={values} values={values[attrkey] ? values[attrkey] : []} setSectionResponseJson={setValues} key={attrIndex} attrkey={attrkey} attrIndex={attrIndex} attr={attr} />
                                    )
                                }
                            } )}
                        </Box>
                    ) )}
                </Box>
                <Box display="flex" alignItems="centre" gap="10px" marginTop="20px">
                    <Button sx={{ textTransform: "capitalize" }} onClick={addDetail} disableElevation size='small' variant='contained'>{`${mode} ${section.section_title}`}</Button>
                    <Button sx={{ textTransform: "capitalize" }} onClick={() => setAddMode( false )} size='small' variant='outlined' color='error' >Cancel</Button>
                </Box>
            </DialogContent>
        </Dialog >
    )
} )


const SectionForm = observer( ( { section, setResponseJson, responseJson, saveData, setChangesMade, setSaveData } ) => {

    const [addMode, setAddMode] = useState( false )
    const [sectionResponseJson, setSectionResponseJson] = useState( {} )
    const [emailTypeErrors, setEmailTypeErrors] = useState( {} )
    const [urlTypeErrors, setUrlTypeErrors] = useState( {} )
    const [fileValues, setFileValues] = useState( {} )
    const [fileUploadWait, setFileUploadWait] = useState( {} )

    const ResumeBuilderStore = useResumeBuilderStore()

    const handleFieldChange = ( type, key, value ) => {
        setSectionResponseJson( { ...sectionResponseJson, [key]: value } )
        setChangesMade( true )
        saveChanges( section.section_title, { ...sectionResponseJson, [key]: value } )
    }

    const clearSection = async ( sectionTitle, sectionData ) => {
        if ( section.multiple ) {
            setResponseJson( { ...responseJson, [sectionTitle]: [] } )
            setSectionResponseJson( [] )
            setChangesMade( true )
            await save( { ...responseJson, [sectionTitle]: [] } )
        }
        else {
            const initialValues = {}
            // for ( let i = 0; i < section.attributes.length; i++ ) {
            //     const group = section.attributes[i]
            //     for ( let j = 0; j < group.length; j++ ) {
            //         const attr = group[j]
            //         if ( attr.entity_detail.data_type === "boolean" )
            //             initialValues[attr.entity_detail.key] = false
            //         else if ( attr.entity_detail.data_type === "array" )
            //             initialValues[attr.entity_detail.key] = []
            //         else
            //             initialValues[attr.entity_detail.key] = ''
            //     }
            // }
            setResponseJson( { ...responseJson, [sectionTitle]: initialValues } )
            setSectionResponseJson( initialValues )
            await save( { ...responseJson, [sectionTitle]: initialValues } )
        }
    }

    const deleteMultipleValueAt = ( index ) => {
        const newData = [...sectionResponseJson]
        newData.splice( index, 1 )
        setSectionResponseJson( newData )
        setChangesMade( true )
        saveChanges( section.section_title, newData )
    }

    const saveChanges = async ( sectionTitle, sectionData ) => {
        setResponseJson( { ...responseJson, [sectionTitle]: sectionData } )
    }

    const save = async ( data ) => {
        try {
            setSaveData( { ...saveData, statusText: "saving" } )
            if ( await ResumeBuilderStore.updateStudentResumeInfo( data ) ) {
                setSaveData( { status: true, statusText: "saved", lastSaved: new Date() } )
            }
            else {
                setSaveData( { ...saveData, status: false } )
            }
            setChangesMade( false )
        } catch ( err ) {

        }
    }

    const handleEmailFieldChange = ( attrkey, e ) => {
        if ( EMAIL_PATTERN.test( e.target.value ) ) {
            setEmailTypeErrors( { ...emailTypeErrors, [`${section.section_title}-${attrkey}`]: null } )
        } else {
            setEmailTypeErrors( { ...emailTypeErrors, [`${section.section_title}-${attrkey}`]: true } )
        }
        handleFieldChange( "email", attrkey, e.target.value.trim() )
    }


    const handleUrlFieldChange = ( attrkey, e ) => {
        if ( URL_PATTERN.test( e.target.value ) ) {
            setUrlTypeErrors( { ...urlTypeErrors, [`${section.section_title}-${attrkey}`]: null } )
        } else {
            setUrlTypeErrors( { ...urlTypeErrors, [`${section.section_title}-${attrkey}`]: true } )
        }
        handleFieldChange( "url", attrkey, e.target.value.trim() )
    }

    const handleFileFieldChange = async ( attrkey, e ) => {
        if ( e.target.files.length > 0 ) {
            try {
                const formData = new FormData()
                formData.append( 'profileFile', e.target.files[0] )
                formData.append( 'section', section.section_title )
                formData.append( 'attrkey', attrkey )
                setFileUploadWait( { ...fileUploadWait, [`${section.section_title}-${attrkey}`]: true } )
                const res = await api.uploadStudentProfileToResume( formData )
                handleFieldChange( "url", attrkey, res.data.fileData.location )
                const fileReader = new FileReader()
                fileReader.onload = ( e ) => {
                    setFileValues( { ...fileValues, [`${section.section_title}-${attrkey}`]: e.target.result } )
                }
                fileReader.readAsDataURL( e.target.files[0] )
            } catch ( err ) {
                console.log( err )
                toast( ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            } finally {
                setFileUploadWait( { ...fileUploadWait, [`${section.section_title}-${attrkey}`]: false } )
                e.target.value = ''
            }
        }
    }

    useEffect( () => {
        const setInitialValues = () => {
            if ( !section.multiple ) {
                const savedData = responseJson[section.section_title] ? responseJson[section.section_title] : {}
                const initialValues = {}
                for ( let i = 0; i < section.attributes.length; i++ ) {
                    const group = section.attributes[i]
                    for ( let j = 0; j < group.length; j++ ) {
                        const attr = group[j]
                        if ( attr.entity_detail.data_type === "boolean" )
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : false
                        else if ( attr.entity_detail.data_type === "array" )
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : []
                        else if ( attr.entity_detail.data_type === 'linkwithlabel' ) {
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : ''
                            initialValues[`${attr.entity_detail.key}-link`] = savedData[`${attr.entity_detail.key}-link`] ? savedData[`${attr.entity_detail.key}-link`] : ''
                        }
                        else if ( attr.entity_detail.data_type === "select" )
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : ''
                        else {
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : ''
                        }
                    }
                }
                setSectionResponseJson( initialValues )
            } else {
                const savedData = responseJson[section.section_title] ? responseJson[section.section_title] : []
                setSectionResponseJson( savedData )
            }
        }
        setInitialValues()
        // eslint-disable-next-line
    }, [section] )

    return (
        <Paper>
            <Box display="flex" alignItems="center" justifyContent="space-between" padding="10px 20px" borderBottom="1px solid #d3d3d3">
                <Typography variant='h6'>
                    {section.section_title}
                </Typography>
                <Box display="flex" alignItems="center" gap="10px">
                    <Button color="error" onClick={() => clearSection( section.section_title, sectionResponseJson )} size="small" sx={{ textTransform: "capitalize" }}>Clear Section</Button>
                    {section.multiple && sectionResponseJson.length > 0 && <Button sx={{ textTransform: "capitalize" }} onClick={e => setAddMode( true )} startIcon={Icons.AddIcon} size='small' color='secondary'>Add {section.section_title}</Button>}
                </Box>
            </Box>
            {section.multiple && <Box>
                {sectionResponseJson.length > 0 && sectionResponseJson.map( ( detail, index ) => (
                    <Paper key={index} sx={{ margin: "5px", position: "relative", padding: "10px 20px", border: "1px solid #d3d3d3" }} elevation={1}>
                        <Box display="flex" gap="10px" alignItems="center" sx={{ position: "absolute", top: "20px", right: "20px" }}>
                            <IconButton onClick={() => { deleteMultipleValueAt( index ) }} sx={{ color: "error.dark" }} size='small'>
                                {Icons.DeleteIcon}
                            </IconButton>
                        </Box>
                        {section.attributes.map( ( group, groupIndex ) => (
                            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }} elevation={section.multiple ? 2 : 0} key={groupIndex}>
                                {group.map( ( attr, attrIndex ) => {
                                    return (
                                        <Typography fontSize={groupIndex === 0 ? "14px" : groupIndex === 1 ? "17px" : "15px"} variant={groupIndex === 0 ? "h6" : groupIndex === 1 ? "h5" : "subtitle2"} color={groupIndex === 0 ? "primary" : groupIndex === 1 ? "black" : "textSecondary"} key={attrIndex}>{detail[attr.entity_detail.key]}</Typography>
                                    )
                                } )}
                            </Box>
                        ) )}
                    </Paper>
                ) )}
                {sectionResponseJson.length === 0 && <Box padding="20px">
                    <Typography variant="subtitle2" fontWeight="bold" color="GrayText">No {section.section_title} yet!</Typography>
                    <Button onClick={e => setAddMode( true )} variant="outlined" sx={{ textTransform: "capitalize", marginTop: "10px" }} color="secondary" startIcon={Icons.AddIcon}>Add one</Button>
                </Box>}
                <AddEditDialog save={save} setChangesMade={setChangesMade} saveChanges={saveChanges} responseJson={responseJson} setResponseJson={setResponseJson} sectionResponseJson={sectionResponseJson} setSectionResponseJson={setSectionResponseJson} section={section} setAddMode={setAddMode} addMode={addMode} mode={"add"} />
            </Box>}
            {!section.multiple && <Box padding="20px">
                <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }} elevation={section.multiple ? 2 : 0}>
                    {section.attributes.map( ( group, groupIndex ) => (
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }} elevation={section.multiple ? 2 : 0} key={groupIndex}>
                            {/* eslint-disable-next-line */}
                            {group.map( ( attr, attrIndex ) => {
                                const type = attr.entity_detail.data_type
                                const children = attr.entity_detail.children
                                const attrkey = attr.entity_detail.key
                                if ( type === 'string' || type === 'number' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type={type === 'string' ? "text" : "number"} rows={4} />
                                        </Box>
                                    )
                                } else if ( type === 'boolean' ) {
                                    return (
                                        <FormControl margin='dense' fullWidth sx={{ flexGrow: "1" }} key={attrIndex}>
                                            <FormControlLabel sx={{ marginLeft: "10px" }} control={<Checkbox color='primary' onChange={( e ) => handleFieldChange( type, attrkey, e.target.checked )} checked={sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : false} />} label={<Typography>{attr.entity_detail.label}</Typography>} />
                                        </FormControl>
                                    )
                                } else if ( type === 'email' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleEmailFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type="email" rows={4} />
                                            {emailTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid E-mail </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'date' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer sx={{ width: "100%" }} components={['DatePicker']}>
                                                    <DatePicker
                                                        onClick={() => console.log( "Date click" )}
                                                        disableFuture
                                                        sx={{ width: "100%" }}
                                                        format='DD/MM/YYYY'
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs( sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : "" )}
                                                        onAccept={( e ) => {
                                                            handleFieldChange( type, attrkey, new Date( e.$d ).toLocaleDateString( 'en-US' ) )
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            <Typography variant="subtitle2" sx={{ fontStyle: "italic", fontSize: "12px" }} color="GrayText">Date selected: {sectionResponseJson[attrkey] ? new Date( sectionResponseJson[attrkey] ).toLocaleDateString( 'en-IN' ) : ""}</Typography>
                                        </Box>
                                    )
                                } else if ( type === 'url' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleUrlFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} rows={4} />
                                            {urlTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL, Start with https:// or http:// </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'linkwithlabel' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth type="text" rows={4} />
                                            <InputLabel sx={{ marginTop: "10px" }}><Typography variant="subtitle2" color="GrayText">{attr.entity_detail.link_label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson[`${attrkey}-link`] ? sectionResponseJson[`${attrkey}-link`] : ""} size="small" onChange={( e ) => handleUrlFieldChange( `${attrkey}-link`, e )} variant='outlined' fullWidth type="text" rows={4} />
                                            {urlTypeErrors[`${attrkey}-link`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'file' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            {/* <TextField value={urlValues[`${section.section_title}-${attrkey}`] ? urlValues[`${section.section_title}-${attrkey}`] : ""} size="small" onChange={( e ) => handleUrlFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type="email" rows={4} /> */}
                                            <Box display="flex" gap="10px" alignItems="center">
                                                {( fileValues[`${section.section_title}-${attrkey}`] || sectionResponseJson[attrkey] ) && <img width="50px" height="50px" alt='profile' src={fileValues[`${section.section_title}-${attrkey}`] ? fileValues[`${section.section_title}-${attrkey}`] : sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} style={{ objectFit: "cover", borderRadius: "50%" }} />}
                                                <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius="5px" sx={{ cursor: "pointer", "&:hover": { background: "#efefef" } }} border="1px solid #d3d3d3" position="relative" padding="5px 10px">
                                                    {!fileUploadWait[`${section.section_title}-${attrkey}`] && <Box width="fit-content" display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                                                        <Button sx={{ textTransform: "capitalize", color: "GrayText" }} startIcon={Icons.UploadFileIcon} >Upload file</Button>
                                                        <Typography fontSize="12px" color="GrayText">Accepted formats: .JPG, .JPEG and .PNG</Typography>
                                                    </Box>}
                                                    {fileUploadWait[[`${section.section_title}-${attrkey}`]] && <Typography display="flex" alignItems="center" gap="10px" variant="subtitle2" color="GrayText"> <CircularProgress size={20} /> Uploading your file...  </Typography>}
                                                    <Box sx={{ opacity: "0", position: "absolute", top: "0", right: "0", bottom: "0", left: "0" }}>
                                                        <input onChange={( e ) => handleFileFieldChange( attrkey, e )} accept='image/gif,image/jpeg,image/png' style={{ width: "100%", height: "100%", cursor: "pointer" }} type='file' />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                } else if ( type === 'cgpaorpercentage' ) {
                                    let fieldValue = sectionResponseJson[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue.includes( "%" ) ) {
                                            fieldValue = parseInt( fieldValue.replace( '%', "" ) )
                                        } else {
                                            fieldValue = parseInt( fieldValue.replace( ' CGPA', "" ) )
                                        }
                                    }
                                    return <CgpaOrPercentage key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'dateorpresent' ) {
                                    let fieldValue = sectionResponseJson[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue !== "present" )
                                            fieldValue = dayjs( fieldValue )
                                    }
                                    return <DateOrPresent key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'select' ) {
                                    return ( <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                        <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                        <Select value={sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ''} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth>
                                            <MenuItem value={""} disabled>Select {attr.entity_detail.label}</MenuItem>
                                            {children.map( child => (
                                                <MenuItem key={child} value={child}>{child}</MenuItem>
                                            ) )}
                                        </Select>
                                    </Box> )
                                }
                                else {
                                    return (
                                        <MultipleValueArrayInput setChangesMade={setChangesMade} saveChanges={saveChanges} responseJson={responseJson} section={section} sectionResponseJson={sectionResponseJson} values={sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : []} setSectionResponseJson={setSectionResponseJson} key={attrIndex} attrkey={attrkey} attrIndex={attrIndex} attr={attr} />
                                    )
                                }
                            } )}
                        </Box>
                    ) )}
                </Box>
            </Box>}
        </Paper>
    )
} )

export default SectionForm
