import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useAssignmentStore } from '../../store/assignment.store'
import { Box, Button, MenuItem, FormControl, InputLabel, Select, Card, CardContent, TextField, Typography, CircularProgress, Collapse, Chip, Dialog, DialogContent, IconButton, Tooltip } from '@mui/material'
import { chipColors, erpDashboardLink, Icons } from '../../utils/utilities'
import api from '../../service/api'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import sessionExpiredImg from '../../assets/warning.svg'
import { ArrowBack } from '@mui/icons-material'

const CreateAssignment = ( { setView, view } ) => {

    const AssignmentStore = useAssignmentStore()

    const navigate = useNavigate()

    const [batchAcademicYears, setBatchAcademicYears] = useState( [] )
    const [academicYearId, setAcademicYearId] = useState( "" )
    const [loaded, setLoaded] = useState( false )
    const [uploadStatus, setUploadStatus] = useState( false )
    const [isLoadingBatches, setIsLoadingBatches] = useState( false )
    const [attachments, setAttachments] = useState( [] )
    const [assignmentTitle, setAssignmentTitle] = useState( "" )
    const [assignmentDescription, setAssignmentDescription] = useState( "" )
    const [assignmentSubmissionDate, setAssignmentSubmissionDate] = useState( new Date() )
    const [sessionExpired, setSessionExpired] = useState( false )

    const [assignmentBatchId, setAssignmentBatchId] = useState( "0" )


    useEffect( () => {
        const fetchAcademicYear = async () => {
            try {
                const res = await api.getAllBatchAcademicYear()
                console.log( res )
                if ( res["data"]["success"] ) {
                    setBatchAcademicYears( res["data"]["data"] ? res["data"]["data"] : [] )
                } else if ( res["data"]["message"] === "Invalid Token" && res["data"]["success"] === false ) {
                    setSessionExpired( true )
                }
            } catch ( err ) {
                console.log( err )
            } finally {
                setLoaded( true )
            }
            if ( AssignmentStore.doEdit ) {
                setAssignmentSubmissionDate( dayjs( AssignmentStore.selectedAssignment.assignment_due_date ) )
            }
        }
        fetchAcademicYear()
    }, [AssignmentStore, navigate, view] )


    const uploadAssignment = async ( e ) => {

        e.preventDefault()

        setUploadStatus( true )
        if ( attachments.length === 0 || assignmentTitle === "" || assignmentDescription === "" || assignmentBatchId === "" ) {
            toast.error( "Note title, Batch are required and Files must be selected" )
            setUploadStatus( false )
            return
        }
        const formData = new FormData()

        for ( let i = 0; i < attachments.length; i++ ) {
            formData.append( "attachments", attachments[i] )
        }

        formData.append( "assignment_name", assignmentTitle )
        formData.append( "assignment_details", assignmentDescription )
        formData.append( "assignment_batch_id", assignmentBatchId )
        formData.append( "assignment_due_date", assignmentSubmissionDate )
        //console.log(`payload `, formData)
        try {
            await AssignmentStore.uploadAssignment( formData )
            setUploadStatus( false )
            setAssignmentBatchId( "" )
            setAssignmentTitle( "" )
            setAssignmentDescription( "" )
            setAssignmentSubmissionDate( new Date() )
            setView( "manage" )
        } catch ( e ) {
            setUploadStatus( false )
        }
    }

    const handleFileInputChange = ( e ) => {
        const files = e.target.files
        let selectedFileList = []
        for ( let i = 0; i < files.length; i++ ) {
            selectedFileList.push( files[i] )
        }
        setAttachments( [...attachments, ...selectedFileList] )
        e.target.value = ''
    }

    const handleAcademicYearChange = async ( e ) => {
        setIsLoadingBatches( true )
        setAcademicYearId( e.target.value )
        if ( !await AssignmentStore.fetchBatch( e.target.value ) ) {
            console.log( "Batch ERROR" )
            setSessionExpired( true )
        } setIsLoadingBatches( false )
    }

    const removeAttachment = ( index ) => {
        const updatedAttachments = [...attachments]
        updatedAttachments.splice( index, 1 )
        setAttachments( updatedAttachments )
    }

    const updateAssignment = async ( e ) => {
        e.preventDefault()
        setUploadStatus( true )
        let payload = { "assignment_due_date": assignmentSubmissionDate }
        await AssignmentStore.updateAssignmentDetail( payload )
        AssignmentStore.setUpdateStatus( AssignmentStore.selectedAssignment, false )
        setUploadStatus( false )
        setView( "manage" )
    }

    return (

        <Card  >
            <CardContent sx={{ padding: 0, overflow: "hidden" }}>
                {/* <CardHeader sx={{ padding: "0", minWidth: "220px", width: "300px" }}  title={view === 'update' ? "Update assignment" : "Create new assignment"} /> */}
                <Box borderBottom="1px solid #d3d3d3" padding="20px" gap="10px" display="flex" alignItems="center">
                    <Tooltip placement='top' title="Back to assignments">
                        <IconButton onClick={() => setView( "manage" )} size='small'>
                            <ArrowBack fontSize='small' color='GrayText' />
                        </IconButton>
                    </Tooltip>
                    <Box>
                        <Typography variant='h5'>{view === 'update' ? "Update assignment" : "Create new assignment"}</Typography>
                        <Typography variant="subtitle2" color="textSecondary">Create assignment by providing all required details</Typography>
                    </Box>
                </Box>
                <Box padding="20px">
                    <form onSubmit={view === 'update' ? updateAssignment : uploadAssignment}>
                        <Box>
                            <FormControl fullWidth>
                                <TextField type="text"
                                    InputLabelProps={{ sx: { fontSize: "14px" } }}
                                    value={view === 'update' ? AssignmentStore.selectedAssignment["assignment_name"] : assignmentTitle}
                                    label="Assignment Title *"
                                    onChange={( e ) => setAssignmentTitle( e.target.value )} style={{ "width": "100%" }}
                                    //defaultValue={ view==='update'  ? AssignmentStore.selectedAssignment["assignment_name"] : '' }
                                    disabled={view === 'update'}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl sx={{ marginTop: "20px" }} fullWidth>
                                <TextField
                                    InputLabelProps={{ sx: { fontSize: "14px" } }}
                                    id="outlined-multiline-static"
                                    label="Description *"
                                    multiline
                                    rows={4}
                                    onChange={( e ) => setAssignmentDescription( e.target.value )} style={{ "width": "100%" }}
                                    value={view === 'update' ? AssignmentStore.selectedAssignment["assignment_details"] : assignmentDescription}
                                    disabled={view === 'update'}
                                />
                            </FormControl>
                        </Box>

                        {view !== 'update' && <Box>
                            <FormControl sx={{ marginTop: "20px" }} fullWidth>
                                <InputLabel sx={{ fontSize: "14px" }} id="ac-year-id">Select year of academic batch *</InputLabel>
                                <Select
                                    sx={{ fontSize: "14px" }}
                                    labelId="ac-year-id"
                                    label="Select year of academic batch *"
                                    value={academicYearId}
                                    disabled={!loaded}
                                    id="ac-year-id-selector" onChange={handleAcademicYearChange}
                                >
                                    {
                                        batchAcademicYears.map( ( batchYear, k ) => {

                                            return ( <MenuItem sx={{ fontSize: "14px" }} key={batchYear.ac_year_id} value={batchYear.ac_year_id}>
                                                {batchYear.ac_year}
                                            </MenuItem> )
                                        } )
                                    }
                                    {batchAcademicYears.length === 0 && <Typography padding="5px 10px" color="GrayText" variant='subtitle2'>No academic years present for your account!</Typography>}
                                </Select>

                            </FormControl>
                        </Box>}


                        {view !== 'update' && <Box>
                            <FormControl sx={{ marginTop: "20px" }} fullWidth>
                                <InputLabel id="batch-id" sx={{ fontSize: "14px" }}>Select Batch / Section *</InputLabel>
                                <Select
                                    disabled={!loaded || isLoadingBatches || ( AssignmentStore.getBatch["batches"].length === 0 && AssignmentStore.getBatch["sections"].length === 0 )}
                                    label="Select Batch / Section *"
                                    sx={{ fontSize: "14px" }}
                                    labelId="batch-id"
                                    //value={assignmentBatchId}
                                    id="batch-id-selector" onChange={( e ) => setAssignmentBatchId( e.target.value )}
                                    value={view === 'update' ? AssignmentStore.selectedAssignment["assignment_batch_id"] : assignmentBatchId}>
                                    <MenuItem value="0" key="key" disabled sx={{ fontSize: "14px" }} color='textSecondary'> {isLoadingBatches || !loaded ? <Typography display="flex" alignItems="center" gap="10px" color="textSecondary"> <CircularProgress size={14} /> Loading batches...</Typography> : "Select batch"} </MenuItem>
                                    {AssignmentStore.getBatch["batches"].length !== 0 && <Typography paddingLeft="10px" color="secondary">Batches</Typography>}
                                    {
                                        AssignmentStore.getBatch["batches"].map( ( batch, k ) => {
                                            return ( <MenuItem sx={{ fontSize: "14px" }} key={batch.batch_assignment_id} value={batch.batch_assignment_id}>
                                                {batch.batch_name + "-" + batch.course_short_name + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name}
                                            </MenuItem> )
                                        } )
                                    }
                                    {AssignmentStore.getBatch["sections"].length !== 0 && <Typography paddingLeft="10px" color="secondary">Sections</Typography>}
                                    {
                                        AssignmentStore.getBatch["sections"].map( ( batch, k ) => {
                                            return ( <MenuItem sx={{ fontSize: "14px" }} key={batch.section_assignment_id} value={batch.section_assignment_id + "-" + batch.section_short_name}>
                                                {batch.course_branch_short_name + "-" + batch.course_short_name + " Y" + batch.current_year + " S" + batch.current_sem + " (" + batch.section_short_name + ")"}
                                            </MenuItem> )
                                        } )
                                    }
                                    {AssignmentStore.getBatch["batches"].length === 0 && AssignmentStore.getBatch["sections"].length === 0 && <Typography padding="5px 10px" color="GrayText" variant='subtitle2'>No batches or sections for the selected academic year!</Typography>}
                                </Select>

                            </FormControl>
                        </Box>}
                        <Box>
                            <FormControl fullWidth sx={{ marginTop: "20px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        sx={{ "width": "100%", fontSize: "14px" }}
                                        minDate={dayjs( new Date() )}
                                        disableToolbar
                                        variant="inline"
                                        format="DD-MM-YYYY"
                                        id="date-picker-inline"
                                        label={view === 'update' ? "Update Submission Date *" : "Last Submission Date *"}
                                        value={dayjs( assignmentSubmissionDate )}
                                        onChange={( val ) => setAssignmentSubmissionDate( val )}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                        {view !== 'update' && <Box>
                            <Box sx={{ position: "relative", cursor: "pointer", flexDirection: "column", gap: "10px", display: "flex", alignItems: "center", justifyContent: "center", marginY: "20px", height: "150px", border: "2px dashed rgba(0,0,0,0.3)", width: "100%" }}>
                                <input type="file" style={{ opacity: "0", position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }} onChange={handleFileInputChange} multiple />
                                <Typography color="textSecondary">{Icons.UploadFileIcon}</Typography>
                                <Typography color="secondary" fontWeight="600">Drag and Drop or click to</Typography>
                                <Typography fontSize="12px" fontWeight="bold">Upload file</Typography>
                            </Box>
                            <Collapse in={attachments.length > 0}>
                                <Box display="flex" gap="10px" marginBottom="20px" flexWrap="wrap">
                                    {attachments.length > 0 && attachments.map( ( file, index ) => (
                                        <Chip key={index} deleteIcon={<RemoveCircleIcon sx={{ color: "white !important" }} />} onDelete={() => removeAttachment( index )} sx={{ color: "white", background: chipColors[index % chipColors.length] }} label={file.name} />
                                    ) )}
                                </Box>
                            </Collapse>
                        </Box>}
                        <Box>
                            {
                                view !== 'update' ?
                                    <Box display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                                        <Button disabled={uploadStatus} disableElevation sx={{ textTransform: "capitalize", height: "53px" }} type="submit" variant="contained" color="primary">
                                            {uploadStatus === false ? 'Create' : "Creating..."}
                                        </Button>
                                        <Button disabled={uploadStatus} disableElevation onClick={() => setView( "manage" )} sx={{ textTransform: "capitalize", height: "53px" }} type="submit" variant="outlined" color="error">
                                            Cancel
                                        </Button>
                                    </Box> :
                                    <Box display="flex" marginTop="20px" gap="10px" alignItems="center" flexWrap="wrap">
                                        <Button disabled={uploadStatus} disableElevation sx={{ textTransform: "capitalize", height: "53px" }} type="submit" variant="contained" color="primary">
                                            {uploadStatus === false ? 'Update' : "Updating..."}
                                        </Button>
                                        <Button disabled={uploadStatus} disableElevation onClick={() => setView( "manage" )} sx={{ textTransform: "capitalize", height: "53px" }} type="submit" variant="outlined" color="error">
                                            Cancel
                                        </Button>
                                    </Box>
                            }

                        </Box>
                    </form>
                </Box>
            </CardContent>
            <Dialog open={sessionExpired}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img width="150px" src={sessionExpiredImg} alt="Session Expired!" />
                        <Typography marginTop="10px">Session has expired! please login again</Typography>
                        <Button onClick={() => window.location.replace( erpDashboardLink )} color='error'>Login</Button>
                    </Box>
                </DialogContent>
            </Dialog>

        </Card>
    )
}

export default CreateAssignment
