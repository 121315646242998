import { Box, Chip, Button, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, useTheme } from '@mui/material'
import React from 'react'
import { ATTACHMENTS_BASE_URL, formatDateTimeForDisplay, chipColors, formatDateTimeForInputField, exportExamParticipation } from '../../utils/exam-utilities'
import { useExamStore } from '../../store/exam.store'
import api from '../../service/api'
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { Icons, flatColors } from '../../utils/utilities'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import AttachmentIcon from '@mui/icons-material/Attachment'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useState } from 'react'
import { toast } from 'react-toastify'

const columns = [{ name: "Sl.no", width: "70px !important", paddingLeft: "20px", color: "white", align: "left" },
{ name: "AUID", color: "white", minWidth: "80px", align: "left" },
{ name: "Student", color: "white", minWidth: "100px", align: "left" },
{ name: "attempt", color: "white", width: "10px", align: "right" },
{ background: "#f18f08", color: "white", name: "Status", align: "right" },
{ background: "#f18f08", minWidth: "120px", color: "white", name: "Score", align: "right" },
{ minWidth: "130px", color: "white", name: "Remarks", align: "left" },
]

const ExamViewer = ( { mode, setMode, setIsExamsLoading, isExamsLoading, examQuestion, participations, setParticipations, filteredParticipations, setFilteredParticipations, totalMarks, setTotalMarks } ) => {

    const ExamStore = useExamStore()
    const navigate = useNavigate()
    const { palette } = useTheme()

    const [questions, setQuestions] = useState( examQuestion )
    const [rowsPerPage, setRowsPerPage] = useState( 10 )
    const [page, setPage] = useState( 0 )

    const getExamDetails = async () => {
        setMode( "details" )
        setIsExamsLoading( true )
        try {
            const participationData = await api.fetchExamParticipation( ExamStore.getExam.exam.exam_id )
            console.log( participationData.data )
            setParticipations( participationData.data.participations )
            setFilteredParticipations( participationData.data.participations )
            setTotalMarks( participationData.data.totalMarks )
        } catch ( err ) {
            console.log( err )
            toast( err.response.data )
        }
        setIsExamsLoading( false )
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    return (
        <Box>
            <Box display="flex" padding="10px 20px" alignItems="center" justifyContent="space-between">
                <Button onClick={() => mode === "details" ? setMode( "view" ) : setMode( "list" )} startIcon={Icons.ChevronLeftIcon} sx={{ textTransform: "capitalize" }}>{mode === 'details' ? "Back to exam" : "Back to exams"}</Button>
                {mode === "details" && participations.length > 0 && <Button variant='outlined' size="small" disableElevation sx={{ textTransform: "capitalize" }} onClick={() => exportExamParticipation( participations, totalMarks )}>Export as Excel</Button>}
            </Box>
            <Box paddingX="20px">
                {mode === "view" && <Box border="#d3d3d3 1px solid" overflow="auto" bgcolor="white" borderRadius="0 0 5px 5px">
                    <Box style={{ minHeight: "200px", display: "flex", alignItems: "flex-end", padding: "20px", color: "white" }} sx={{ background: flatColors[Math.floor( Math.random() * flatColors.length )] }} >
                        <Box>
                            <Typography variant='h4' >
                                {ExamStore.getExam.exam.exam_name}<small style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px", letterSpacing: "1px", color: "white", textTransform: "uppercase" }}>{ExamStore.getExam.exam.exam_status}</small>
                            </Typography>
                            <Typography gutterBottom variant='body2' color='white' >{`${formatDateTimeForDisplay( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time )} - ${formatDateTimeForDisplay( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time )}`}</Typography>
                            {ExamStore.getExam.exam.exam_duration !== "null" && ExamStore.getExam.exam.exam_duration !== null && <Typography variant='body2' color='white' > {`Duration: ${ExamStore.getExam.exam.exam_duration.split( ":" )[0]} Hours ${ExamStore.getExam.exam.exam_duration.split( ":" )[1]} Minutes`}</Typography>}
                            <Typography variant='body2' color='white' >Maximum Attempts: {ExamStore.getExam.exam.maximum_attempts}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px", "@media(max-width:800px)": { flexDirection: "column", alignItems: "flex-start", flexWrap: "wrap" } }} padding="20px" paddingBottom={"20px"}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
                            <Button disableElevation startIcon={<InfoIcon />} onClick={getExamDetails} sx={{ "&:hover": { color: "white" } }} variant='contained' style={{ textTransform: "capitalize !important" }}>Details</Button>
                        </Box>
                    </Box>
                    <Box padding="0 20px 20px 20px">
                        <Divider sx={{ marginBottom: "10px", marginTop: "10px", background: "#273679", height: "1px" }} />
                        <p style={{ textAlign: "justify", fontSize: "14px" }}>
                            {ExamStore.getExam.exam.exam_description}
                        </p>
                        <Typography variant='h6'>Topics</Typography>
                        <Box sx={{ padding: "10px 0", display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="5px">
                            {ExamStore.getExam.exam.exam_topics && ExamStore.getExam.exam.exam_topics.map( ( topic, index ) => (
                                <Chip key={topic} label={topic} sx={{ background: chipColors[index % flatColors.length], color: "white" }} />
                            ) )}
                        </Box>
                        <Box marginY="10px">
                            <Typography marginBottom="5px" variant='h6'>Exam for batch(es):</Typography>
                            <Box display="flex" flexWrap="wrap" alignItems="center">
                                {ExamStore.getExam.batches && ExamStore.getExam.batches.map( ( batch, index ) => (
                                    <Chip key={batch} label={batch} sx={{ marginRight: "10px", background: chipColors[index % flatColors.length], color: "white" }} />
                                ) )}
                                {( !ExamStore.getExam.batches || ExamStore.getExam.batches.length === 0 ) && <Typography variant='body2' color="textSecondary">Exam is not yet assigned to batches!</Typography>}
                            </Box>
                        </Box>
                        <Typography variant='h6'>Attachments<AttachmentIcon sx={{ margin: "-2px 0 0 5px" }} fontSize="small" /> </Typography>
                        <Box sx={{ padding: "10px 0", display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="10px">
                            {ExamStore.getExam.exam.exam_attachments && ExamStore.getExam.exam.exam_attachments.map( ( attachment ) => (
                                <Chip href={`${ATTACHMENTS_BASE_URL}${attachment}`} clickable deleteIcon={Icons.DownloadIcon} onDelete={e => window.open( `${ATTACHMENTS_BASE_URL}${attachment}` )} sx={{ fontWeight: "bold", fontSize: "12px", padding: "5px", border: "1px solid grey", marginRight: "5px", display: "block" }} label={attachment.split( "ExamStore.getExam.exam/" )[1]} key={attachment} />
                            ) )}
                            {!ExamStore.getExam.exam.exam_attachments &&
                                <Typography variant='body2' color="textSecondary" mt={-1}>No attachments provided!</Typography>
                            }
                        </Box>
                        <Box>
                            <Box display="flex" justifyContent="space-between" marginTop="10px" flexWrap="wrap" gap="10px">
                                <Typography variant='h6'>Exam questions</Typography>
                                <Box display="flex" gap="20px">
                                    <Button onClick={async () => {
                                        const questionsRes = await api.fetchQuestions( ExamStore.getExam.exam.exam_id )
                                        setQuestions( questionsRes.data )
                                    }} sx={{ textTransform: "capitalize" }} color='primary' startIcon={<RefreshIcon />}>
                                        Refresh
                                    </Button>
                                    {!( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' ) && questions && questions.length > 0 && <Button variant='contained' onClick={() => navigate( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/questions` )} sx={{ fontSize: "14px", textTransform: "capitalize" }} startIcon={<EditIcon sx={{ fontSize: "16px !important" }} />} >Edit questions</Button>}
                                </Box>
                            </Box>
                            {questions && questions.length > 0 && <Box display="flex" marginTop="10px" flexDirection="column" gap="10px">
                                {questions.map( ( question, index ) => (
                                    <Box sx={{ display: "flex", alignItems: "center", padding: "20px", background: "white", boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)", gap: "10px", fontSize: "14px", borderRadius: "5px", "& p": { margin: "0 " } }} key={question.question.question_id}>
                                        <span style={{ display: "flex", borderRadius: "50%", alignItems: "center", justifyContent: "center", minWidth: "30px", maxWidth: "30px", minHeight: "30px", maxHeight: "30px", background: "rgb(48,48,48)", color: "white" }}>{index + 1}</span>
                                        <Box>
                                            <Box>
                                                {parse( question.question.question_text.trim() )}
                                            </Box>
                                            <Box marginTop="5px">
                                                <Chip size='small' color='secondary' sx={{ marginLeft: "5px" }} label={question.question.question_type}></Chip>
                                                {question.question.question_type === 'MCQ' && <Chip size='small' color='secondary' sx={{ marginLeft: "5px" }} label={question.question.question_answer_selection}></Chip>}
                                            </Box>
                                        </Box>
                                    </Box>
                                ) )}
                            </Box>}
                            {( !questions || questions.length === 0 ) && <Typography color="textSecondary" variant='body2'>Questions not yet added!</Typography>}
                        </Box>
                    </Box>
                </Box>}
                {mode === "details" &&
                    <Box bgcolor="#fff" paddingBottom="20px" overflow="auto">
                        {participations.length > 0 && <Box border="1px solid #d3d3d3">
                            <TableContainer sx={{ paddingBottom: "10px", minHeight: "300px", maxHeight: "450px", "&::-webkit-scrollbar": { width: "10px !important", height: "10px !important" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": { background: "#c1c1c1" } }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow sx={{ color: "white" }}>
                                            {columns && columns.map( ( column, index ) => (
                                                <TableCell align={column.align} key={index} sx={{ paddingLeft: column.paddingLeft, background: palette.primary.main, color: "white", minWidth: column.minWidth, position: column.position, right: column.right, zIndex: column.zIndex, width: column.width }} >{column.name}</TableCell>
                                            ) )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredParticipations
                                            .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                            .map( ( participation, index ) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ fontSize: "12px", paddingLeft: "20px" }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ minWidth: "80px" }}>{participation.student_id}</TableCell>
                                                    <TableCell sx={{ fontSize: "12px", minWidth: "100px" }}>{participation.student_name}</TableCell>
                                                    <TableCell align='right' sx={{ fontSize: "12px" }}>{participation.exam_participation_attempt}</TableCell>
                                                    <TableCell align='right'><Typography color="textSecondary" variant="body2" sx={{ textTransform: "uppercase", minWidth: "100px", fontSize: "12px !important" }}>
                                                        {participation.exam_participation_status === 'Completed' ? <>completed <span style={{ display: "block" }}>{`${new Date( participation.exam_participation_end_time ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" )}, ${new Date( participation.exam_participation_end_time ).toLocaleTimeString( 'en-IN', { hour: "2-digit", minute: "2-digit" } )}`}</span></>
                                                            : participation.exam_participation_status}</Typography>
                                                    </TableCell>
                                                    <TableCell align='right' sx={{ paddingY: "5px", minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >{participation.exam_participation_score ? participation.exam_participation_score : "0"} <span style={{ fontSize: "14px" }}>OUT OF </span> {totalMarks}
                                                    </TableCell>
                                                    <TableCell sx={{ minWidth: "100px" }}><Typography variant='body2' fontSize="12px">{participation.exam_participation_remark ? participation.exam_participation_remark : "No remarks."}</Typography></TableCell>
                                                </TableRow>
                                            ) )}
                                    </TableBody>
                                </Table>
                            </TableContainer >

                            <TablePagination
                                sx={{ background: "#eee" }}
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={participations.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>}
                        {participations.length === 0 && !isExamsLoading &&
                            <Typography variant='h5' padding="20px" color="textSecondary" bgcolor="#eee" marginTop="20px">No participation!</Typography>
                        }
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default ExamViewer
