import { Typography, Box, Table, TableContainer, TableRow, TableHead, TableCell, TableBody, Button, CircularProgress, IconButton, Select, FormControl, InputLabel, MenuItem, Tooltip, TablePagination, Paper, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { exportExamParticipation } from '../../utils/exam-utilities'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import api from '../../service/api'
import { Icons } from '../../utils/utilities'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

export default function ExamParticipation() {

    const { id } = useParams()
    const [participations, setParticipations] = useState( null )
    const [totalMarks, setTotalMarks] = useState( 0 )
    const [inProgress, setInProgress] = useState( true )
    const [exam, setExam] = useState( null )
    const [selectedAttempt, setSelectedAttempt] = useState( 0 )
    const [filteredParticipations, setFilteredParticipations] = useState( null )
    const [rowsPerPage, setRowsPerPage] = useState( 10 )
    const [page, setPage] = useState( 0 )
    const [sortState, setSortState] = useState( {} )

    const navigate = useNavigate()
    const { palette } = useTheme()

    const handleAttemptChange = ( e ) => {
        setSelectedAttempt( e.target.value )
        if ( e.target.value === 'latest' ) {
            const groupByID = participations.reduce( ( dict, item ) => {
                if ( dict[item.student_id] ) {
                    if ( parseInt( dict[item.student_id].exam_participation_attempt ) < item.exam_participation_attempt )
                        dict[item.student_id] = item
                } else
                    dict[item.student_id] = item
                return dict
            }, {} )
            setFilteredParticipations( Object.values( groupByID ) )
        } else if ( parseInt( e.target.value ) !== 0 ) {
            const filteredList = participations.filter( p => String( p.exam_participation_attempt ) === String( e.target.value ) )
            setFilteredParticipations( filteredList )
        } else {
            setFilteredParticipations( participations )
        }
    }

    const sortByProperty = ( property ) => {
        let newFilteredParticipations = [...filteredParticipations]
        newFilteredParticipations.sort( ( a, b ) => {
            if ( typeof a[property] == "number" ) {
                if ( !sortState[property] )
                    return ( a[property] - b[property] )
                else
                    return ( b[property] - a[property] )
            } else {
                if ( !sortState[property] )
                    return ( ( a[property] < b[property] ) ? -1 : 0 )
                else
                    return ( ( a[property] > b[property] ) ? -1 : 0 )
            }
        } )
        setSortState( { ...sortState, [property]: sortState[property] ? false : true } )
        setFilteredParticipations( newFilteredParticipations )
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    useEffect( () => {
        const getData = async () => {
            const participationData = await api.fetchExamParticipation( id )
            setParticipations( participationData.data.participations )
            setFilteredParticipations( participationData.data.participations )
            setTotalMarks( participationData.data.totalMarks )
            setExam( participationData.data.exam )
            setInProgress( false )
        }
        getData()
    }, [id] )


    const columns = [{ name: "Sl.no", width: "70px !important", paddingLeft: "20px", color: "white", align: "left" },
    {
        name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
            AUID
            <IconButton onClick={() => { sortByProperty( "student_id" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText", width: "20px", height: "20px" } }} size='small'>
                {sortState.student_id ? <ArrowDownwardIcon fontSize='small' /> : <ArrowUpwardIcon fontSize="small" />}
            </IconButton>
        </Box>
        , color: "white", minWidth: "100px", align: "left"
    },
    {
        name: <Box width="fit-content" display="flex" gap="10px" alignItems="center">
            Student <IconButton onClick={() => { sortByProperty( "student_name" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText" } }} size='small'>
                {sortState.student_name ? <ArrowDownwardIcon fontSize='small' /> : <ArrowUpwardIcon fontSize="small" />}
            </IconButton>
        </Box>, color: "white", minWidth: "100px", align: "left"
    },
    { name: "attempt", color: "white", width: "10px", align: "right" },
    { background: "#f18f08", color: "white", name: "Status", align: "right" },
    {
        background: "#f18f08", minWidth: "120px", color: "white", name: <Box justifyContent="flex-end" display="flex" gap="10px" alignItems="center">
            Score <IconButton onClick={() => { sortByProperty( "exam_participation_score" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText" } }} size='small'>
                {sortState.exam_participation_score ? <ArrowDownwardIcon fontSize='small' /> : <ArrowUpwardIcon fontSize="small" />}
            </IconButton>
        </Box>, align: "right"
    },
    { minWidth: "130px", color: "white", name: "Remarks", align: "left" },
    ]

    return (
        <Box sx={{ padding: "20px", paddingTop: "0" }}>
            <Typography fontSize="14px" marginBottom="10px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to={`/faculty/exams`} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} <Link to={`/faculty/exams/${id}`} className="breadcrumb-link">{exam && exam.exam_name ? exam.exam_name : <CircularProgress sx={{ color: "textSecondary" }} size={12} />}</Link> {Icons.SmallChevronRightIcon} Participation</Typography>
            <Paper >
                <Box padding="20px" width="100%" borderBottom="1px solid #d3d3d3">
                    <Typography variant='h5'>{exam?.exam_name}</Typography>
                    <Typography variant='subtitle2' color="textSecondary">
                        Participation
                    </Typography>
                </Box>
                {exam && participations && participations.length > 0 && <Box gap="20px" padding="20px" display="flex" alignItems={{ lg: "center", md: "center", sm: "center", xs: "flex-start" }} justifyContent="space-between" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} >
                    <Box display="flex" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} gap="20px" width={{ lg: "fit-content", md: "fit-content", sm: "fit-content", xs: "100%" }} alignItems="center">
                        <FormControl sx={{ width: { lg: "200px", md: "200px", sm: "200px", xs: "100%" } }}>
                            <InputLabel>Attempt No.</InputLabel>
                            <Select sx={{ fontSize: "14px" }} value={selectedAttempt} onChange={handleAttemptChange} label="Attempt No." >
                                <MenuItem sx={{ fontSize: "14px" }} value={0}>All attempts</MenuItem>
                                <MenuItem sx={{ fontSize: "14px" }} value="latest">Latest attempts</MenuItem>
                                {new Array( parseInt( 10 ) ).fill( 1 ).map( ( e, index ) => (
                                    <MenuItem sx={{ fontSize: "14px" }} key={index} value={index + 1}>{index + 1}</MenuItem>
                                ) )}
                            </Select>
                        </FormControl>
                        <Tooltip title="Export participation data as CSV file">
                            <Button onClick={() => exportExamParticipation( participations, totalMarks )} sx={{ textTransform: "capitalize", height: "53px", width: { sm: "150px", xs: "100%" } }} variant='outlined' startIcon={<DownloadForOfflineIcon />}>
                                Export All
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>}
                <Box>
                    <div>
                        {!inProgress && participations && participations.length > 0 &&
                            <Box bgcolor="#fff" overflow="auto">
                                <TableContainer sx={{ paddingBottom: "10px", minHeight: "300px", maxHeight: "450px", "&::-webkit-scrollbar": { width: "10px !important", height: "10px !important" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": { background: "#c1c1c1" } }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow sx={{ background: palette.primary.main, color: "white" }}>
                                                {columns && columns.map( ( column, index ) => (
                                                    <TableCell align={column.align} key={index} sx={{ paddingLeft: column.paddingLeft, background: palette.primary.main, color: "white", minWidth: column.minWidth, position: column.position, right: column.right, zIndex: 100, width: column.width }} >{column.name}</TableCell>
                                                ) )}
                                                <TableCell sx={{ position: "sticky", right: 0, zIndex: 3, width: "fit-content", background: palette.primary.main, color: "white", "@media(max-width:800px)": { boxShadow: "0px 5px 5px 3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" } }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredParticipations
                                                .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                .map( ( participation, index ) => (
                                                    <TableRow key={index} sx={{ "&:nth-of-type(even)": { background: "#eee" } }}>
                                                        <TableCell sx={{ fontSize: "12px", padding: "0 20px" }}>{( index + 1 ) + ( page ) * rowsPerPage}</TableCell>
                                                        <TableCell sx={{ minWidth: "100px", padding: "0" }}>{participation.student_id}</TableCell>
                                                        <TableCell sx={{ fontSize: "12px", minWidth: "100px", padding: "0" }}>{participation.student_name}</TableCell>
                                                        <TableCell align='right' sx={{ fontSize: "12px", minWidth: "80px", padding: "0" }}>{participation.exam_participation_attempt}</TableCell>
                                                        <TableCell sx={{ padding: "0px" }} align='right'><Typography color="textSecondary" variant="body2" sx={{ textTransform: "uppercase", minWidth: "100px", fontSize: "12px !important" }}>
                                                            {participation.exam_participation_status === 'Completed' ? <>completed <span style={{ display: "block" }}>{`${new Date( participation.exam_participation_end_time ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" )}, ${new Date( participation.exam_participation_end_time ).toLocaleTimeString( 'en-IN', { hour: "2-digit", minute: "2-digit" } )}`}</span></>
                                                                : participation.exam_participation_status}</Typography>
                                                        </TableCell>
                                                        <TableCell align='right' sx={{ padding: "0 5px", minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >{participation.exam_participation_score ? participation.exam_participation_score : "0"} <span style={{ fontSize: "14px" }}>OUT OF </span> {totalMarks}
                                                        </TableCell>
                                                        <TableCell sx={{ minWidth: "100px" }}><Typography variant='body2' fontSize="12px">{participation.exam_participation_remark ? participation.exam_participation_remark : "No remarks."}</Typography></TableCell>
                                                        <TableCell align='right' sx={{ position: "sticky", right: 0, zIndex: 2, background: index % 2 !== 0 ? "#eee" : "#fff", width: "54px", padding: 0, paddingRight: "20px", "@media(max-width:800px)": { boxShadow: "0px 5px 5px 3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" } }}>
                                                            <Box width="fit-content" height="100%" padding="10px">
                                                                <Tooltip title="Evaluate" placement='top'>
                                                                    <IconButton
                                                                        onClick={() => navigate( `/faculty/exams/${id}/evaluate/${participation.participation_id}` )}
                                                                        disabled={participation.exam_participation_status.toLowerCase() !== 'completed'} size='small'
                                                                    >
                                                                        <OpenInNewIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ) )}
                                        </TableBody>
                                    </Table>
                                </TableContainer >

                                <TablePagination
                                    sx={{ background: "#eee" }}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                    component="div"
                                    count={filteredParticipations.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                            </Box>
                        }
                        {!inProgress && participations.length <= 0 && <Typography padding="20px" variant='h6' color="textSecondary">No participants yet!</Typography>}
                        {inProgress && <Typography padding="20px" variant='h6'> <CircularProgress size={20} sx={{ marginRight: "10px" }} />Loading... </Typography>}
                    </div >
                </Box >
            </Paper>
        </Box >
    )
}
