import React, { useState, useEffect } from 'react'
import { downloadParticipationReport } from '../../utils/exam-utilities'
import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Divider, FormControl, FormHelperText, TextField, Tooltip, Typography } from '@mui/material'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import Submission from '../../components/exams-components/Submission'
import { Formik, ErrorMessage, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import api from '../../service/api'
import { Link } from 'react-router-dom'
import { Icons, flatColors } from '../../utils/utilities'
import { useLayoutContext } from '../../contexts/layout.context'

export default function Evaluate() {
    const [participation, setParticipation] = useState( null )
    const [exam, setExam] = useState( null )
    const [submissions, setSubmissions] = useState( null )
    const [questions, setQuestions] = useState( null )
    const [remarks, setRemarks] = useState( '  ' )
    const [totalMarks, setTotalMarks] = useState( 0 )
    const [inProgress, setInProgress] = useState( true )
    const [scheme, setScheme] = useState( null )
    const { id, participation_id } = useParams()
    const { setCurrentLoc } = useLayoutContext()

    const initialValues = {
        remarks: '  '
    }

    const validationSchema = Yup.object().shape( {
        remarks: Yup.string().required( "This field is required!" ).test( {
            name: "TEXT_LENGTH", message: "Remark must be atleast 20 characters long", test: ( value ) => {
                if ( !value || value.split( "" ).length < 20 ) {
                    return false
                }
                else return true
            }
        } )
    } )

    const remarksSubmit = async ( values, props ) => {
        try {
            // const res = await Axios.post( `/exams/${id}/participation/${participation_id}/remark`, values, {
            //     headers: {
            //         "Authorization": `Bearer ${localStorage.getItem( "id_token" )}`
            //     }
            // } )
            const res = await api.submitRemark( id, participation_id, values )
            toast.success( res.data )
        } catch ( e ) {
            toast.error( e.response.data )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const participationData = await api.fetchParticipationData( id, participation_id )
                const examData = await api.getExam( id )
                const submissionData = await api.fetchParticipationSubmissions( id, participation_id )
                const questionsData = await api.fetchQuestions( id )
                const schemeData = await api.fetchEvaluationScheme( id )
                setParticipation( participationData.data.participation )
                setTotalMarks( participationData.data.marks.sum )
                setExam( examData.data.exam )
                setRemarks( participationData.data.participation.exam_participation_remark )
                let subs = {}
                let tempScheme = {}
                for ( let i = 0; i < submissionData.data.length; i++ ) {
                    subs[submissionData.data[i].question_id] ? subs[submissionData.data[i].question_id].push( submissionData.data[i] ) : subs[submissionData.data[i].question_id] = [submissionData.data[i]]
                }
                for ( let i = 0; i < schemeData.data.length; i++ ) {
                    tempScheme[schemeData.data[i].question_id] = schemeData.data[i]
                }
                setSubmissions( subs )
                setScheme( tempScheme )
                setQuestions( questionsData.data )
                setInProgress( false )
            }
            catch ( e ) {
                toast.error( e.response.data )
            }
        }
        getData()
    }, [participation_id, id] )

    return (
        <Box sx={{ padding: "20px", paddingTop: "0", height: "100%" }}>
            <Typography fontSize="14px" marginBottom="10px" fontWeight={500} display="flex" alignItems="center" color="textSecondary">
                <Link to={`/faculty/exams`} onClick={() => setCurrentLoc( '/faculty/exams' )} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon}
                <Link to={`/faculty/exams/${id}`} className="breadcrumb-link">{exam && exam.exam_name ? exam.exam_name : <CircularProgress sx={{ color: "textSecondary" }} size={12} />}</Link>{Icons.SmallChevronRightIcon}
                <Link to={`/faculty/exams/${id}/participation`} onClick={() => setCurrentLoc( '/faculty/participations' )} className="breadcrumb-link">{exam && exam.exam_name ? "Participations" : <CircularProgress sx={{ color: "textSecondary" }} size={12} />}</Link>{Icons.SmallChevronRightIcon}
                <Link to={`/faculty/exams/${id}/evaluate/${participation_id}`} onClick={() => setCurrentLoc( '/faculty/participations' )} className="breadcrumb-link"> {exam && exam.exam_name ? participation_id : <CircularProgress sx={{ color: "textSecondary" }} size={12} />}</Link>
            </Typography>
            {/* <Link to={`/faculty/exams/${id}/participation`} style={{ marginLeft: "-5px" }}> <Button startIcon={<ArrowBackIcon fontSize='small' />} sx={{ textTransform: "capitalize" }}>Back to Participations</Button> </Link> */}
            {exam && participation && !inProgress && <Box marginTop="10px" display="flex" justifyContent="center" gap="20px" sx={{ flexDirection: { lg: "row-reverse", md: "row-reverse", sm: "column", xs: "column" } }}>
                <Box height="fit-content" display="flex" justifyContent="center" padding="20px" marginTop="10px" borderRadius="5px" bgcolor={flatColors[0]} boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)">
                    {exam && participation && !inProgress && <Box maxWidth="350px" minWidth="300px" sx={{ color: "white" }} display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" textAlign="center" width="100%">
                            <Typography width="100%" variant='h4' textTransform="capitalize">{exam.exam_name}</Typography>
                            <Typography width="100%" variant='subtitle1' fontWeight="bold" sx={{ color: "white" }} textTransform="capitalize"> Submissions of {participation.student_name.toLowerCase()}</Typography>
                            <Typography width="100%" variant='body2' sx={{ color: "white" }} textTransform="capitalize"> Attempt {participation.exam_participation_attempt}</Typography>
                            <Typography width="100%" fontWeight="bold" variant='h4'>{participation.exam_participation_score ? participation.exam_participation_score : "0"} <span style={{ fontWeight: "400", fontSize: "18px", textTransform: "uppercase" }}>Out Of</span> {totalMarks}</Typography>
                            <Tooltip title="Download Report as CSV">
                                <Button variant='outlined' startIcon={<DownloadForOfflineIcon />} onClick={() => downloadParticipationReport( participation, questions, submissions )} sx={{ color: "white", marginTop: "10px", border: "1px solid white", textTransform: "capitalize", "&:hover": { color: "#273679", background: "white" } }}>
                                    Export report
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>}
                </Box>
                <Box flexGrow="1" padding="20px" marginTop="10px" borderRadius="5px" bgcolor="white" boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)">
                    <Typography width="100%" variant='h5' textTransform="capitalize">{exam.exam_name}</Typography>
                    <Typography width="100%" variant='subtitle2' color="textSecondary" textTransform="capitalize"> Submissions of {participation.student_name.toLowerCase()}</Typography>
                    <Divider sx={{ marginTop: "10px" }} />
                    {submissions && questions && !inProgress && questions.map( ( question, index ) => (
                        <div key={index}>
                            <Submission key={index} question={question.question} answer={submissions[question.question.question_id]} options={question.options} keyAnswer={scheme[question.question.question_id]} index={index} />
                        </div>
                    ) )}
                    {!inProgress && <Formik onSubmit={remarksSubmit} validationSchema={validationSchema} initialValues={initialValues}>
                        {( { setFieldValue } ) => (
                            <Form method="post" autoComplete='off'>
                                <Typography variant='h5' marginBottom="10px">Add remarks</Typography>
                                <FormControl fullWidth>
                                    <TextField multiline name='remarks' defaultValue={remarks} label="Add remarks" onChange={e => setFieldValue( 'remarks', e.target.value )} />
                                    <FormHelperText sx={{ color: "#de1738", marginLeft: "0px" }}> <ErrorMessage name="remarks" /> </FormHelperText>
                                    <Button type="submit" variant='contained' sx={{ textTransform: 'capitalize', width: "fit-content", height: "53px", marginTop: "10px" }}>Submit remarks</Button>
                                </FormControl>
                            </Form>
                        )}
                    </Formik>}
                </Box>
            </Box>}
            {!participation && !inProgress && <Typography marginTop="10px" variant='h6' color="textSecondary">Participation not found</Typography>}
            {inProgress && <Typography variant='h6' padding="20px" borderRadius="5px" bgcolor="#fff" marginTop="20px"><CircularProgress size={20} sx={{ marginRight: "10px" }} /> Loading... </Typography>}
        </Box >
    )
}
