import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { NavigateNext } from '@mui/icons-material'

const BreadcrumbList = ( { items = [] } ) => {
    return (
        <Breadcrumbs sx={{ color: "textSecondary", marginBottom: "20px", fontSize: "14px", '& .MuiBreadcrumbs-separator': { margin: "0" } }} separator={<NavigateNext fontSize="small" />} >
            {items.map( ( breadcrumb, index ) => {
                if ( breadcrumb === undefined || breadcrumb.length === 0 ) return ""
                if ( typeof breadcrumb !== 'string' && breadcrumb.link ) {
                    return <Link key={index} className='breadcrumb-link' to={breadcrumb.link}>
                        {breadcrumb.label}
                    </Link>
                } else {
                    return <Typography color="textSecondary" variant="subtitle2" key={index} textTransform="capitalize">{breadcrumb}</Typography>
                }
            } )}
        </Breadcrumbs>
    )
}

export default BreadcrumbList
