import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress, Select, FormControl, InputLabel, MenuItem, IconButton, Tooltip, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useState } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import api from '../../service/api'
import { Link, useNavigate } from 'react-router-dom'
import { Icons } from '../../utils/utilities'
import { useLayoutContext } from '../../contexts/layout.context'

const columns = [
    { name: "Exam Name", borderRadius: "5px 0 0 0", maxWidth: "100%", minWidth: "120px", align: "left" },
    { name: "Attempt", width: "fit-content", maxWidth: "100%", minWidth: "20px", align: "right" },
    { name: "Exam date", maxWidth: "50px", minWidth: '120px', align: "right" },
    { name: "Score", minWidth: '130px', align: "right" },
    { name: "Remark", minWidth: '130px', align: "left" }
]
export default function Scores() {
    const [data, setData] = useState( null )
    const [participations, setParticipations] = useState( null )
    const [exams, setExams] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [examList, setExamList] = useState( null )
    const [selectedExam, setSelectedExam] = useState( "-1" )
    const { setCurrentLoc } = useLayoutContext()

    const navigate = useNavigate()

    const handleExamChange = ( e ) => {
        if ( e.target.value !== "-1" ) {
            const filteredParticipations = data.filter( p => String( p.exam_id ) === String( e.target.value ) )
            setParticipations( filteredParticipations )
        } else {
            setParticipations( data )
        }
        setSelectedExam( e.target.value )
    }

    const getData = async () => {
        try {
            const res = await api.fetchUserParticipations()
            setData( res.data.participation )
            setParticipations( res.data.participation )
            setInProgress( false )
            let examsMap = {}
            let uniqueExams = {}
            res.data.marks.forEach( exam => {
                examsMap[exam.exam_id] = exam
            } )
            res.data.participation.forEach( p => {
                uniqueExams[p.exam_id] = p.exam_title
            } )
            setExamList( uniqueExams )
            setExams( examsMap )
        }
        catch ( e ) {
            toast.error( e.response.data )
        }
    }

    useEffect( () => {
        getData()
    }, [] )

    return (
        <Box padding="0 20px 20px 20px" height="100%" display="flex" alignItems="flex-start" flexDirection="column">
            <Typography fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to="/student/exams" onClick={() => setCurrentLoc( '/student/exams' )} className="breadcrumb-link">Exams</Link> {Icons.SmallChevronRightIcon} Scores </Typography>
            <Paper sx={{ marginTop: "10px", width: "100%", display: "flex", flexDirection: "column" }}>
                <Box paddingBottom="0" display="flex" gap="20px" flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }} alignItems={{ lg: "center", md: "center", sm: "flex-start", xs: "flex-start" }}>
                    <Box padding="20px" borderBottom="1px solid #d3d3d3" flexGrow="1">
                        <Typography variant="h5">My scores</Typography>
                        <Typography variant="subtitle2" color="textSecondary">Natijangizni tekshiring</Typography>
                    </Box>
                    {data && data.length > 0 && exams && !inProgress && <FormControl sx={{ width: { lg: "200px", md: "200px", sm: "100%", xs: "100%" } }} >
                        <InputLabel>Exam:</InputLabel>
                        <Select sx={{ fontSize: "14px" }} value={selectedExam} onChange={handleExamChange} label="Exam:" >
                            <MenuItem sx={{ fontSize: "14px" }} key={"-1"} value="-1">All exams</MenuItem>
                            {examList && Object.keys( examList ).map( exam => (
                                <MenuItem sx={{ fontSize: "14px" }} key={exam} value={exam}>{examList[exam]}</MenuItem>
                            ) )}
                        </Select>
                    </FormControl>}
                </Box>
                {!inProgress && data && data.length > 0 && exams && <Box flexGrow={1} overflow="auto" sx={{}}>
                    {<TableContainer sx={{ height: "100%", background: "white", "&::-webkit-scrollbar": { width: "10px !important", height: "10px !important" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": { background: "#c1c1c1" } }} >
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow sx={{ background: "#623f8f", color: "white" }}>
                                    {columns.map( ( column, index ) => (
                                        <TableCell key={index} sx={{ background: "#623f8f", color: "white", padding: "15px", width: column.width, maxWidth: column.maxWidth, minWidth: column.minWidth, textAlign: column.align, position: column.position, right: column.right, zIndex: column.zIndex, fontWeight: "bold" }}>{column.name}</TableCell>
                                    ) )}
                                    <TableCell sx={{ position: "sticky", right: 0, zIndex: 3, background: "#623f8f", color: "white", width: "fit-content" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {participations.map( ( participation, index ) => (
                                    <TableRow key={index} sx={{ "&:nth-of-type(even)": { background: "#eee" }, padding: "50px" }}>
                                        <TableCell sx={{ padding: "5px 20px", fontSize: "12px" }}>{participation.exam_title}</TableCell>
                                        <TableCell sx={{ padding: "5px 20px", fontSize: "12px", textAlign: "right" }} align="left">{participation.exam_participation_attempt}</TableCell>
                                        <TableCell sx={{ fontSize: "12px", textAlign: "right" }}>{`${new Date( participation.exam_participation_start_time ).toLocaleDateString( 'en-IN', { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}, ${new Date( participation.exam_participation_start_time ).toLocaleTimeString( 'en-IN', { hour: "2-digit", minute: "2-digit" } )}`}</TableCell>
                                        <TableCell sx={{ minWidth: "130px", textAlign: "right" }}> <Typography fontWeight="bold">{participation.exam_participation_score ? `${participation.exam_participation_score} ` : `${0} `}<span style={{ fontSize: "12px" }}>OUT OF</span>  {exams[participation.exam_id].sum}</Typography></TableCell>
                                        <TableCell sx={{ padding: "5px 20px", minWidth: "130px" }}>
                                            <Typography fontSize="12px">{participation.exam_participation_remark ? ( participation.exam_participation_remark ).length > 150 ? ( participation.exam_participation_remark ).substring( 0, 150 ) + "..." : ( participation.exam_participation_remark ) : "No remarks."}</Typography>
                                        </TableCell>
                                        <TableCell sx={{ position: "sticky", right: 0, zIndex: 2, background: index % 2 !== 0 ? "#eee" : "#fff", width: "54px", padding: "0", "@media(max-width:720px)": { boxShadow: "0px 5px 5px 3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)" } }}>
                                            <Box width="fit-content" height="100%" padding="10px">
                                                <Tooltip title="View Details" placement='top'>
                                                    <IconButton onClick={() => navigate( `/student/scores/${participation.exam_id}/${participation.participation_id}` )} size='small'>
                                                        <OpenInNewIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Box>}
                {inProgress && <Typography padding="20px" display="flex" alignItems="center" color="textSecondary" gap="10px" variant='h6'> <CircularProgress size={20} /> Loading...</Typography>}
                {( !data || data.length === 0 ) && !inProgress && <Typography padding="20px" color="textSecondary" variant="h6">No participations!</Typography>}
            </Paper >
        </Box >
    )
}
