import { Warning } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material'
import React from 'react'

const ConfirmDialog = ( { actionName, confirmAction, message, status, cancelAction, isReversible } ) => {
    return (
        <Dialog open={status}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                {!isReversible && <Typography marginBottom="10px" gap="10px" display="flex" alignItems="center" color="error.light" fontSize="12px" fontWeight="600">
                    <Warning fontSize='small' />
                    This action is irreversible
                </Typography>}
                <Typography variant='subtitle2'>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button disableElevation variant='contained' onClick={confirmAction} sx={{ textTransform: "capitalize" }}>{actionName}</Button>
                <Button variant='outlined' onClick={cancelAction} sx={{ textTransform: "capitalize" }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog
